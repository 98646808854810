import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-company-bankaccount',
  templateUrl: './company-bankaccount.component.html',
  styleUrls: ['./company-bankaccount.component.less']
})
export class CompanyBankaccountComponent implements OnInit {

  bankAccountForm: FormGroup = this.formBuilder.group({
    name: [null, [Validators.required]],
    account: [null, [Validators.required]],
    address: [null, [Validators.required]]
  });

  bank_accounts_single = {account:"",is_primary:false,name:"",address:""}

  _bankAccounts: any;
  get bankAccounts() { return this._bankAccounts };
  set bankAccounts(value: any) {
    this._bankAccounts = value;
  }

  _saving: boolean;
  get saving() { return this._saving };
  set saving(value: boolean) {
    this._saving = value;
  }

  constructor(public dialogRef: MatDialogRef<CompanyBankaccountComponent>, private formBuilder: FormBuilder, private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  saveAccount(){
    this.saving = true;
    this.dialogRef.close();
  }

  reject(){
    this.saving = false;
    this.dialogRef.close();
  }

  appendAccount(){
    let acc = Object.create(this.bank_accounts_single);
    acc.account = this.bankAccountForm.controls.account.value;
    acc.address = this.bankAccountForm.controls.address.value;
    acc.name = this.bankAccountForm.controls.name.value;
    if(this.bankAccountForm.valid){
      // console.log(this.bankAccounts);
      let repr = this.bankAccounts.find(acco => acco.account == acc.account);
      console.log(repr);
      if(repr){
        let index = this.bankAccounts.findIndex(acco => acco.account == acc.account);
        console.log(index);
        if(index > -1){
          this.bankAccounts.splice(index,1);
        }
        console.log(this.bankAccounts);
      }
      this.bankAccounts.push(acc);
      this.changeDetection.detectChanges();
    }else{
      console.log("invalid");
      console.log(this.bankAccountForm.value);
    }
  }

  deleteAcc(rep){
    let index = this.bankAccounts.findIndex(item => item.account == rep.account);
    if(index > -1){
      this.bankAccounts.splice(index, 1);
    }
  }

  editRow(acc){
    this.bankAccountForm.get("name").setValue(acc.name);
    this.bankAccountForm.get("account").setValue(acc.account);
    this.bankAccountForm.get("address").setValue(acc.address);
  }


}
