<h2 mat-dialog-title>{{'workflow_templates_coworker-attribution' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="addCoworkerForm">
        <app-select-search [selectionItems]="coworkerSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_name' | translate" [selectCtrl]="addCoworkerForm.controls.coworker" [required]="true">
        </app-select-search>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="addCoworker()" class="positiveButton">{{'workflow_templates_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'workflow_templates_reject' | translate}}</button>
</mat-dialog-actions>
