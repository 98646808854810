import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Role } from '../../../data/role';
import { RolesService } from '../../../services/roles.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-role-editor',
  templateUrl: './role-editor.component.html',
  styleUrls: ['./role-editor.component.less']
})
export class RoleEditorComponent implements OnInit {

  roleForm: FormGroup = this.formBuilder.group({
    id: null,
    //auditor_id: null,
    is_active: true,
    caption: [null, [Validators.required]],
    group_code: null,
    roles: new FormGroup({
      ROLE_CAP_SIGNER: new FormControl(false),
      ROLE_CAP_COMPANY_SIGNER: new FormControl(false),
      ROLE_CAP_CUSTOMER_MANAGER: new FormControl(false),
      ROLE_CAP_CONTRACT_MANAGER: new FormControl(false),
      ROLE_CAP_WF_CREATOR: new FormControl(false),
      ROLE_CAP_TIMESHEET: new FormControl(false),
      ROLE_CAP_WFE_MANAGER: new FormControl(false),
      ROLE_CAP_UNI_READER: new FormControl(false),
      ROLE_CAP_UNI_WRITER: new FormControl(false),
      ROLE_CAP_UNI_CUSTOMER_LISTER: new FormControl(false),
      ROLE_CAP_UNI_CONTRAC_TLISTER: new FormControl(false),
      ROLE_CAP_UNI_WFE_LISTER: new FormControl(false),
      ROLE_CAP_REPORTER: new FormControl(false),
      ROLE_CAP_WF_APPROVER: new FormControl(false)
    })
  });

  _role: Role;
  set role(value: any) {
    this._role = value;
    this.roleForm.patchValue(value);
    value.roles.forEach((role)=>{
      this.roleForm.get("roles").get(role).setValue(true);
    });
    // console.log(value);
  }

  _functionsText: any;
  get functionsText() { return this._functionsText };
  set functionsText(value: any) {
    this._functionsText = value;
  }

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<RoleEditorComponent>, private service: RolesService) { }

  ngOnInit(): void {
    /*if(this._role.group_code){
      //this.roleForm.controls['caption'].setValue(this.translateService.get(this.roleForm.controls['caption'].value).)
      //this.roleForm.controls['caption'].disable(true);
    }*/
  }

  saveRole(){
    if (this.roleForm.valid) {
      let form = JSON.parse(JSON.stringify((this.roleForm.getRawValue())))
      form.roles = [];
      for (let role in this.roleForm.getRawValue().roles){
        if (this.roleForm.getRawValue().roles[role]){
          form.roles.push(role)
        }
      }
      if (this.roleForm.get("id").value){
        this.service.modifyRole(form).subscribe((response)=>{
          this.dialogRef.close(response);
        })
      }
      else{
        this.service.addRole(form).subscribe((response)=>{
          this.dialogRef.close(response);
        })
      }
    } else {
      this.roleForm.markAllAsTouched();
    }
  }

}
