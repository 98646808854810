<mat-card [formGroup]="loginForm" class="login-card" (keyup.enter)="login()">
    <div class="login-logo"></div>
    <h3 class="login-title">BEJELENTKEZÉS / LOGIN</h3>
    <!-- button mat-button [matMenuTriggerFor]="languageMenu" matTooltip="{{'button_title_language_switcher' | translate}}" [ngStyle]="{color: '#039050'}">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
        <button mat-menu-item (click)="changeLanguage(language.locale)" *ngFor="let language of languageService.languageList" style="display: flex; align-items: center;"><img [src]="'data:'+language.logo_mime+';base64,'+language.logo_content" style="width: 25px; height: auto; margin-right: 5px;">{{language.locale}}</button>
    </mat-menu -->
    <mat-form-field appearance="outline">
        <mat-label>Felhasználónév: / Username:</mat-label>
        <input matInput formControlName="username">
        <mat-error *ngIf="loginForm.controls.username.hasError('required')">
            Kérem, írja be a felhasználónevét!
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Jelszó / password:</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="loginForm.controls.password.hasError('required')">
            Kérem, írja be a jelszót!
        </mat-error>
    </mat-form-field>
    <span class="login-link" (click)="openLostPassword()">Elfelejtett jelszó / Forgotten password</span>
    <button mat-raised-button color="primary" class="login-button" (click)="login()">
        Bejelentkezés / Login
  </button>
</mat-card>
