<h3 mat-dialog-title>Írja be a felhasználónevet! / Please enter your username!</h3>
<mat-dialog-content>
    <form [formGroup]="lostPasswordForm">
        <mat-form-field appearance="outline">
            <mat-label>Felhasználónév / Username</mat-label>
            <input matInput formControlName="username">
            <mat-error *ngIf="lostPasswordForm.controls.username.hasError('required')">
            {{'error_message_please_enter_your_username' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-raised-button class="positiveButton" (click)="sendResetPasswordEmail()">Send</button>
    <button mat-raised-button class="negativeButton" (click)="dialogRef.close()">Cancel</button>
</mat-dialog-actions>
