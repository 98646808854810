<mat-toolbar color="primary">
    <div>
        <button mat-icon-button matTooltip="{{'country_parameter-list' | translate}}" [disabled]="selection.selected.length !== 1" (click)="setCountryParams(selection.selected[0])">
            <mat-icon>tune</mat-icon>
        </button>
    </div>
</mat-toolbar>
<table mat-table [dataSource]="data" matSort>

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-radio-button (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-radio-button>
        </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'country_identifier-code' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.iso_code}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'country_name' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
    </tr>
</table>

<mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="handlePage($event)" [showFirstLastButtons]="true"></mat-paginator>
