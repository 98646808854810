<h2 mat-dialog-title>{{'file_system_master-list-selection' | translate}}</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="taxNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_identifier' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.taxNumber  | taxNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_mail-address' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
      </ng-container>
      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_connector-name' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.contact}} </td>
      </ng-container>
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_company-name' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.companyName}}</td>
      </ng-container>
      <ng-container matColumnDef="postalAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_postal-address' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.postalAddress}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
      </tr>
    </table>
  
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
  <button mat-button (click)="save()" class="positiveButton">{{'button_title_save' | translate}}</button>
  <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_back' | translate}}</button>
</mat-dialog-actions>