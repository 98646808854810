<div style="display: flex; justify-content: space-between; align-items: baseline;">
    <h2 mat-dialog-title>{{'common_title_help' | translate}}</h2>
    <button mat-button (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
    <div class="folder-structure-content">
        <div class="column" style="width: 50%;">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                  <li class="mat-tree-node" (click)="onTreeClick(node)" [class.active]="selectedTree?.topic == node.topic" [style.cursor]="'pointer'">
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>
                    {{node.topic}}
                  </li>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                  <li>
                    <div class="mat-tree-node" (click)="onTreeClick(node)" [class.active]="selectedTree?.topic == node.topic" [style.cursor]="'pointer'" >
                      <button mat-icon-button matTreeNodeToggle
                              [attr.aria-label]="'Toggle ' + node.topic">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>
                      {{node.topic}}
                    </div>
                    <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                      <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                  </li>
                </mat-nested-tree-node>
              </mat-tree>
              
        </div>
        <div class="column" style="width: 50%; padding: 0 10px;">
            <div [innerHtml]="helpContent" style="white-space: pre-wrap;"></div>
        </div>
    </div>
</mat-dialog-content>