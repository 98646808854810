import {Injectable, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ProgressService} from "./progress.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class StateService implements OnInit{

  constructor(private http: HttpClient, private progressService: ProgressService, private snackBar: MatSnackBar, public router: Router, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    localStorage.setItem('mobileState', '');
  }

  public static get actualState() {
    return localStorage.getItem('mobileState');
  }

  public static set actualState(state: any) {
    localStorage.setItem('mobileState', state);
  }

  public static setStateBack(){
    if(this.actualState == 'WF'){
      this.actualState = '';

    }
  }

}

