import {Component} from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import {AiWorkflowTitleService} from "./services/ai-workflow-title.service";
import {FileSystemService} from "./services/file-system.service";
import {filter, map, mergeMap} from "rxjs/operators";
import {ProgressService} from "./services/progress.service";
import {CredentialsService} from "./services/credentials.service";
import {StateService} from "./services/state.service";
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "./services/language.service";
import {MatDialog} from "@angular/material/dialog";
import {BreakpointObserver} from "@angular/cdk/layout";
import {versions} from "../environments/versions";
import {Location} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  showProgress = false;
  CredentialsService: any = CredentialsService;
  StateService: any = StateService;
  versions: any = versions;

  menuState:string = "owner";

  route:string= "";


  constructor(private snackBar: MatSnackBar, private location: Location, private bpo: BreakpointObserver, public router: Router, private activatedRoute: ActivatedRoute, public titleService: AiWorkflowTitleService, private translateService: TranslateService, private dialogService: MatDialog, private credentialsService: CredentialsService,
              public progressService: ProgressService, private languageService: LanguageService, private fileSystemService: FileSystemService,) {
    progressService.taskInProgress.subscribe(taskInProgress =>
      this.showProgress = taskInProgress.length > 0
    );
    router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          progressService.addTask((event as NavigationStart).url);
          
          break;
        }

        case event instanceof NavigationEnd:{
          this.route = this.location.path();
          console.log(this.route);
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          progressService.removeTaskByName((event as NavigationStart).url);
          break;
        }
        default: {
          break;
        }
      }
    });
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      this.titleService.setTitle(event.title);
      this.titleService.setDescription(event.description);
    });
  }


  defaultLang: string = navigator.language.replace("-", "_");
  language: string = 'hu_HU';
  ownCompany: string;

  ngOnInit() {
    
    StateService.actualState = '';
    if(this.defaultLang==null)this.defaultLang = 'hu_HU';
    this.language = this.language ? this.language : this.defaultLang;
    localStorage.setItem("language", this.language);
  
    
    if (sessionStorage.getItem("jwtToken")){
      this.translateService.use(this.language);
      this.languageService.loadLanguages().subscribe((response) => {
        this.languageService.languageList = response.payload;
      });
      this.credentialsService.getWhoAmI().subscribe((data)=>{
        this.credentialsService.userData = data.payload;
        console.log(data.payload);
        this.credentialsService.userData['locale'] = 'hu'; // fixMe - ez miért HIányzik?
        //this.ownCompany = data.payload.email_address;
      })
    }
    console.log()
    //this.languageService.languageList = this.languageService.loadLanguages();
  }

  changeLanguage(lang) {
    localStorage.setItem("language", lang);
    this.translateService.use(lang);
  }

  changeMenu(){
    if(this.menuState === "owner"){
      this.menuState= "user";
      return;
    }
    if(this.menuState==="user"){
      this.menuState='owner';
      return;
    }
  }

  showHelp(fileID){
    const dialog = this.fileSystemService.getStaticFile(fileID).subscribe((response)=>{
      this.handleFileContent(response)
    })
  }

  handleFileContent(response){
    if(response){
      if(response.type == "OK"){
        if(response.payload.content){
          let byteCharacters = atob(response.payload.content);
          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          let file = new File([byteArray], `Help`, { type: response.payload.mime_type});

          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);

          // let link = document.createElement("a");
          // link.href = URL.createObjectURL(file);
          // //'data:'+response.payload.mime_type+',' + byteArray;
          // link.download = response.payload.name;
          // link.click();

        }else{
          this.snackBar.open("A megadott file nem található!", null, {panelClass: 'success-snackbar'});
        }
      }
    }
  }

}
