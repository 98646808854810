import {AfterViewInit, Component, ViewChildren} from '@angular/core';
import {Template, TemplateDictionary} from "../../data/template";
import {OkDialogComponent} from "../ok-dialog/ok-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {merge, of} from "rxjs";
import {catchError, delay, map, startWith, switchMap} from "rxjs/operators";
import {PaginatedAnswer} from "../../data/response";
import {TemplateEditorComponent} from "./template-editor/template-editor.component";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { TemplateService } from '../../services/template.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {CredentialsService} from "../../services/credentials.service";
import { T } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.less']
})
export class TemplatesComponent implements AfterViewInit {
  typeList: TemplateDictionary[] = [];
  subtypeList = [];
  
  templateList: Template[] = [/*
    {
      id: 1,
      tipus: 1,
      gazdalkodoTipus: 1,
      nyelv: 1,
      link: 'https://auditworkflow.hu/'
    },
    */
  ]
  data: Template[] = [];

  isLoadingResults = true;

  displayedColumns: string[] = ['select', 'type', 'subtype', 'language'];
  selection = new SelectionModel<Template>(false, []);

  languageSelectionItems = [];

  selectedTemplate;

  templateObjects;


  @ViewChildren('tableRowType') tableRowsType;
  @ViewChildren('tableRowSubtype') tableRowsSubtype;

  translatableTexts: any[] = [
    {value: "common_message_template_delete_successful", name: ""},
    {value: "error_message_template_delete_unsuccessful", name: ""},
    {value: "common_message_template_save_successful", name: ""},
    {value: "error_message_template_save_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService, private languageService: LanguageService, public service: TemplateService) { }

  ngOnInit(){
    this.languageService.loadLanguages().subscribe((response) => {
      response.payload.forEach((language)=>{
        this.languageSelectionItems.push({value: language.locale, name: language.locale})
      })
    });

    this.service.getSubtemplates(CredentialsService.role).then((response) => {
      // console.log(response);
      response.payload.content.forEach((subtype)=>{
        if (subtype.is_active && !subtype.is_empty){
          this.subtypeList.push({value: subtype.id, name: subtype.caption})
        }
      })
    });



    this.translateService.stream(['common_message_template_delete_successful', 'error_message_template_delete_unsuccessful', 'common_message_template_save_successful', 'error_message_template_save_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_template_delete_successful", name: res["common_message_template_delete_successful"]},
        {value: "error_message_template_delete_unsuccessful", name: res["error_message_template_delete_unsuccessful"]},
        {value: "common_message_template_save_successful", name: res["common_message_template_save_successful"]},
        {value: "error_message_template_save_unsuccessful", name: res["error_message_template_save_unsuccessful"]}
      ];
    });
  }
  
  ngAfterViewInit() {  
    this.loadTableData();
  }

  loadTableData(){
    merge()
    .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.service.listTemplates(CredentialsService.role);
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;

          return data.payload;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
    ).subscribe((data) => {
      console.log(data);
      this.filterData(data);
      let filterData = [];
      this.typeList = [];
      data.forEach((template: any) => {
        this.typeList.push({name: template.template_code, value: template.template_code})
        if (template.childs && template.childs.length > 0) {
          template.childs.forEach((subChild: any) => {
            if (subChild.childs && subChild.childs.length > 0) {
              subChild.childs.forEach((local: any) => {
                filterData.push(local);
              })
            } else {
              filterData.push(subChild);
            }
          })
        } else {
          filterData.push(template);
        }
      })
      
      //this.data = data;

      this.data = filterData;
      
      
      if (this.selectedTemplate){
        this.selectTemplate({checked: true}, this.selectedTemplate)
      }
      setTimeout(()=>{
        this.tableRowsType?._results.forEach((row, i) => {
          row.nativeElement.classList.add(this.calculateTypeTableStripe(this.data, i));
        })
        this.tableRowsSubtype?._results.forEach((row, i) => {
          row.nativeElement.classList.add(this.calculateSubtypeTableStripe(this.data, i));
        })
      }, 0)
    });
  }

  filterData(templates){
    // console.log(this.data);
    let templateObjects = [];
    templates.forEach((temp) => {
      // console.log(temp);
      let templateObject = {name: temp.template_code, value: temp.template_code, childs: [], path: temp.path};
      if(temp.childs.length > 0){
        temp.childs.forEach(child => {
          let templateChild = { child, lang: []};
          // console.log(child.subtemplate_code);
          if(child.childs.length > 0){
            child.childs.forEach(lang => {
              
              templateChild.lang.push(lang);
            })
          }
          templateObject.childs.push(templateChild);
        });
      }
      templateObjects.push(templateObject);
    });

    // console.log(templateObjects);
    this.templateObjects = templateObjects;
    
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.forEach(row => this.selection.select(row));
  }

  /*getTipus(row: Template) {
    return this.tipusList.find(tipus => tipus.id === row.tipus).label;
  }

  getNyelv(row: Template) {
    return this.nyelvList.find(nyelv => nyelv.id === row.nyelv).label;
  }*/

  getSubtype(subtemplate_code) {
    if(!subtemplate_code)return "";
    return this.subtypeList.find(subtype => subtype.value === +subtemplate_code)?.name;
  }

  deleteSablon(sablon) {
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('template_remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.backendDeleteSablon(sablon).then((resp:any) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_template_delete_successful").name, null, {panelClass: 'success-snackbar'});
              this.loadTableData()
              this.selectedTemplate = null;
              this.tableTypeStripe = "tableStripeWhite";
              this.tableSubtypeStripe = "tableStripeWhite";
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_template_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
        });
      }
    })
  }

  backendDeleteSablon(sablon: any){
    let parentPath =sablon.path.split("/");
    parentPath.pop();
    parentPath = parentPath.join("/");
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        locale: sablon.locale,
        subtemplate_id: +sablon.subtemplate_code,
        template_code: sablon.template_code
      },
    };
    return this.http.delete<any>(environment.serverEndpoint + '/owner/template', options)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_template_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  editSablon(template?: Template) {
    
    if (template) {
      const okDialog = this.dialogService.open(OkDialogComponent);
      this.translateService.get('template_already-exist').subscribe((value)=>okDialog.componentInstance.title = value)
      this.translateService.get('1').subscribe((value)=>okDialog.componentInstance.positiveButtonText = value)
      this.translateService.get('0').subscribe((value)=>okDialog.componentInstance.negativeButtonText = value)
      okDialog.afterClosed().subscribe(response => {
        if (response) {
          const dialog = this.dialogService.open(TemplateEditorComponent);
          dialog.componentInstance.languageSelectionItems = this.languageSelectionItems;
          dialog.componentInstance.subtypeList = this.subtypeList;
          dialog.componentInstance.typeList = this.typeList;
          dialog.componentInstance.template = template;
          dialog.afterClosed().subscribe(response => {
            if (response){
              if (response.type == "OK") {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_template_save_successful").name, null, {panelClass: 'success-snackbar'});
                this.loadTableData();
                this.tableTypeStripe = "tableStripeWhite";
                this.tableSubtypeStripe = "tableStripeWhite";
              }
              else{
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_template_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                let dialog = this.dialogService.open(ErrorDialogComponent);
                dialog.componentInstance.error = response.payload;
              }
            }
          })
        }
      })
    }
    else{
      const dialog = this.dialogService.open(TemplateEditorComponent);
      dialog.componentInstance.languageSelectionItems = this.languageSelectionItems;
      dialog.componentInstance.subtypeList = this.subtypeList;
      dialog.componentInstance.typeList = this.typeList;
      dialog.afterClosed().subscribe(response => {
        if (response){
          if (response.type == "OK") {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_template_save_successful").name, null, {panelClass: 'success-snackbar'});
            this.loadTableData();
            this.tableTypeStripe = "tableStripeWhite";
            this.tableSubtypeStripe = "tableStripeWhite";
          }
          else{
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_template_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
            let dialog = this.dialogService.open(ErrorDialogComponent);
            dialog.componentInstance.error = response.payload;
          }
        }
      })
    }
  }

  selectTemplate(event, template){
    if (event.source.checked){
      this.selectedTemplate = template;
    } else{
      this.selectedTemplate = null;
    }
  }

  openTemplate(template){
    this.service.getTemplate(template).then(response =>{
      var byteCharacters = atob(response.payload.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var fileName = `${template.template_code}-${this.getSubtype(template.subtemplate_code)}-${template.locale}`
      var file = new File([byteArray], fileName , { type: response.payload.mime_type});
      // var fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
      var link = document.createElement("a");
      var fileURL = URL.createObjectURL(file);        
      link.href = fileURL;
      link.download = fileName;
      link.click();        
      
    })

  }


  tableTypeStripe = "tableStripeWhite";
  calculateTypeTableStripe(list, i: number){
    let template1 = list[i-1];
    let template2 = list[i];

    if (!template1){
      return this.tableTypeStripe;
    }

    if (template1.template_code == template2.template_code){
      return this.tableTypeStripe;
    }
    else{
      if (this.tableTypeStripe == "tableStripeWhite"){
        this.tableTypeStripe = "tableStripeGrey"
        return this.tableTypeStripe;
      }
      else {
        this.tableTypeStripe = "tableStripeWhite"
        return this.tableTypeStripe;
      }
    }    
  }

  tableSubtypeStripe = "tableStripeWhite";
  calculateSubtypeTableStripe(list, i: number){
    let template1 = list[i-1];
    let template2 = list[i];

    if (!template1){
      return this.tableSubtypeStripe;
    }

    if (template1.template_code == template2.template_code && template1.subtemplate_code == template2.subtemplate_code){
      return this.tableSubtypeStripe;
    }
    else{
      if (this.tableSubtypeStripe == "tableStripeWhite"){
        this.tableSubtypeStripe = "tableStripeGrey"
        return this.tableSubtypeStripe;
      }
      else {
        this.tableSubtypeStripe = "tableStripeWhite"
        return this.tableSubtypeStripe;
      }
    }
  } 

}
