import {Component, ElementRef, OnInit, ViewChild, AfterContentInit, AfterViewInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WfTemplateService } from '../../../services/wf-template.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CredentialsService } from 'src/app/services/credentials.service';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { UserWfsService } from '../../../services/user-wfs.service';
import {DateAdapter} from "@angular/material/core";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import {DateParsePipe} from "../../../pipes/date-parse.pipe";


@Component({
  selector: 'app-workflow-template-create',
  templateUrl: './workflow-template-create.component.html',
  styleUrls: ['./workflow-template-create.component.less']
})
export class WorkflowTemplateCreateComponent implements AfterContentInit, AfterViewInit, OnInit {

  @ViewChild("input_task_start") inputTaskStart: ElementRef;
  @ViewChild("input_task_end") inputTaskEnd: ElementRef;
  @ViewChild("coworkerControl") coworkerControl: ElementRef;

  maxday = new Date(new Date(2999,12,31).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0];

  workflowCreateForm: FormGroup = this.formBuilder.group({
    id: null,
    caption: [null, Validators.required],
    workflow_element_group_id: [null, Validators.required],
    workflow_element_time_sid: [null],
    task_start_date: [null, Validators.required],
    task_end_date: [null, Validators.required],
    estimated_work_hours: null,
    workflow_element_type_sid: [null, Validators.required],
    responsible_coworker_id: [null],
    workflow_template_id: null,
    coworker_ids: [[]],
    locale: null
  })

  _workflowElement: any;
  get workflowElement() { return this._workflowElement };
  set workflowElement(value: any) {
    this._workflowElement = value;
    this.workflowCreateForm.patchValue(value);
    this.workflowCreateForm.get("estimated_work_hours").setValue(this.workflowCreateForm.get("estimated_work_hours").value/3600);
  }

  _workflowTemplate: any;
  get workflowTemplate() { return this._workflowTemplate };
  set workflowTemplate(value: any) {
    this._workflowTemplate = value;
    this.workflowCreateForm.get("workflow_template_id").setValue(this.workflowTemplate.id)
  }

  _workflowMainGroupSelectionItems: any;
  get workflowMainGroupSelectionItems() { return this._workflowMainGroupSelectionItems };
  set workflowMainGroupSelectionItems(value: any) {
    this._workflowMainGroupSelectionItems = value;
  }

  _timeCodeSelectionItems: any;
  get timeCodeSelectionItems() { return this._timeCodeSelectionItems };
  set timeCodeSelectionItems(value: any) {
    this._timeCodeSelectionItems = value;
  }

  _typeSelectionItems: any;
  get typeSelectionItems() { return this._typeSelectionItems };
  set typeSelectionItems(value: any) {
    this._typeSelectionItems = value;
  }

  _coworkerSelectionItems: any;
  get coworkerSelectionItems() { return this._coworkerSelectionItems };
  set coworkerSelectionItems(value: any) {
    this._coworkerSelectionItems = value;
  }
  
  CredentialsService: any = CredentialsService;

  constructor(private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<WorkflowTemplateCreateComponent>, private dialogService: MatDialog, private el: ElementRef, private service: WfTemplateService, private userWfService: UserWfsService) { }

  ngAfterContentInit(): void{
    this._adapter.setLocale(localStorage.getItem("language") == "hu_HU"?'hu':'en');
  }

  ngAfterViewInit(){
    if(this._workflowElement) {
      if(this._workflowElement.task_start_date != null)this.inputTaskStart.nativeElement.value = this.pipeFormat.transform(this._workflowElement.task_start_date * 1000);
      if(this._workflowElement.task_end_date != null)this.inputTaskEnd.nativeElement.value = this.pipeFormat.transform(this._workflowElement.task_end_date * 1000);
    }

  }
  ngOnInit(): void {
    if (CredentialsService.role=='owner' || CredentialsService.role=='admin'){
      this.inputTaskStart.nativeElement.disabled = true;
      this.inputTaskEnd.nativeElement.disabled = true;
      //this.coworkerControl.nativeElement.disabled = true;
      this.workflowCreateForm.get("responsible_coworker_id").disable();
//      this.workflowCreateForm.controls.task_start_date.disable();
//      this.workflowCreateForm.controls.task_end_date.disable();
    }
    else if (CredentialsService.role == 'user'){

      if (this.workflowCreateForm.get("id").value){
//        this.workflowCreateForm.get("task_start_date").setValue(new Date(new Date(this.workflowCreateForm.get("task_start_date").value * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0])
//        this.workflowCreateForm.get("task_end_date").setValue(new Date(new Date(this.workflowCreateForm.get("task_end_date").value * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0])
      }
    }
  }

  /*reject(){
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = 'Figyelem';
    dialog.componentInstance.description =
        'Elveti a módosításokat?';
    dialog.componentInstance.positiveButtonText = "Igen";
    dialog.componentInstance.negativeButtonText = "Nem";
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.dialogRef.close();
      }
    })
  }*/

  save(){
  //  if (this.workflowCreateForm.valid) {
      let form;
      if (CredentialsService.role == 'user'){
        form = JSON.parse(JSON.stringify(this.workflowCreateForm.value))
        form.workflow_id = form.workflow_template_id;
        delete form.workflow_template_id;
        form.task_start_date = new Date(this.pipeParse.transform(this.inputTaskStart.nativeElement.value)).getTime() / 1000;
        form.task_end_date = new Date(this.pipeParse.transform(this.inputTaskEnd.nativeElement.value)).getTime() / 1000;
        form.estimated_work_hours = this.workflowCreateForm.get("estimated_work_hours").value*3600;

//        form.task_start_date = new Date(form.task_start_date).getTime() / 1000
//        form.task_end_date = new Date(form.task_end_date).getTime() / 1000
        form.workflow_element_status_sid = "WFE_STAT_ACTIVE"
        delete form.coworker_ids
      }
      if (this.workflowCreateForm.get("id").value){
        if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin'){
          let coworker_ids = [];
          if(this.workflowElement.coworkers) {
            this.workflowElement.coworkers.forEach((coworker) => {
              if (coworker.is_active) {
                coworker_ids.push(coworker.id)
              }
            })
          }
          this.workflowCreateForm.get("coworker_ids").setValue(coworker_ids)
          //this.workflowCreateForm.get("coworker_ids").disable();
          this.service.modifyWfElement(this.workflowCreateForm.value).subscribe((response)=>{
            this.dialogRef.close(response);
          })
        }
        else if (CredentialsService.role == 'user'){
          this.userWfService.modifyWfElement(form).subscribe((response)=>{
            this.dialogRef.close(response);
          })
        }
        
      }
      else{
        this.workflowCreateForm.get("estimated_work_hours").setValue(+this.workflowCreateForm.get("estimated_work_hours").value*3600)
        this.workflowCreateForm.get("id").disable()
        this.workflowCreateForm.get("locale").setValue(localStorage.getItem('language'))
        if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin'){
          this.service.createWfElement(this.workflowCreateForm.value).subscribe((response)=>{
            this.dialogRef.close(response);
          })
        }
        else if (CredentialsService.role == 'user'){
          this.userWfService.createWfElement(form).subscribe((response)=>{
            this.dialogRef.close(response);
          })
        }
      }
 //   } else {
 //     this.workflowCreateForm.markAllAsTouched();
//      let firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
 //     this.scrollToFirstInvalidControl(firstInvalidControl);
 //   }
  }

  private scrollToFirstInvalidControl(firstInvalidControl) {
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  createName(coworker){
    return localStorage.getItem("language") == "hu_HU" ? coworker.last_name + " " + coworker.first_name : coworker.first_name + " " + coworker.last_name
  }

}
