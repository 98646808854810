import {HttpClient} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ProgressService} from "src/app/services/progress.service";
import {BalanceSheetImportComponent} from "./balance-sheet-import/balance-sheet-import.component";
import {BalanceSheetDetailComponent} from "./balance-sheet-detail/balance-sheet-detail.component";
import {UserWfsService} from "../../../services/user-wfs.service";

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.less']
})
export class BalanceSheetComponent implements OnInit {

  balanceForm: FormGroup = this.formBuilder.group({
    data: [null, [Validators.required]]
  });

  _schemaItems: any;
  get schemaItems() { return this._schemaItems };
  set schemaItems(value: any) {
    this._schemaItems = value;
  }

  _gvSchema_id: any;
  get gvSchema_id() { return this._gvSchema_id };
  set gvSchema_id(value: any) {
    this._gvSchema_id = value;
  }

  _customer_id: any;
  get customer_id() { return this._customer_id };
  set customer_id(value: any) {
    this._customer_id = value;
  }

  language = localStorage.getItem("language").replace("_", "-");

  elementAssets = [];
  elementLiabs = [];

  balanceExistsCurrent = "";
  balanceExistsBasis = "";
  selected = new FormControl(0);
  
  translatableTexts: any[] = [
    {value: "common_message_are_you_sure_you_want_to_overwrite_current_balance_sheet_data", name: ""},
    {value: "error_message_balance_sheet_save_unsuccessful", name: ""},
    {value: "balance_sheet_deviation_current", name: ""},
    {value: "balance_sheet_deviation_basis", name: ""},
  ];

  constructor(private dialogService: MatDialog, private userWfService: UserWfsService, private translateService: TranslateService, public dialogRef: MatDialogRef<BalanceSheetComponent>, private httpClient: HttpClient, private snackBar: MatSnackBar, private progressService: ProgressService,  private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.translateService.stream(['common_message_are_you_sure_you_want_to_overwrite_current_balance_sheet_data', 'error_message_balance_sheet_save_unsuccessful', 'balance_sheet_deviation_current', 'balance_sheet_deviation_basis']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_are_you_sure_you_want_to_overwrite_current_balance_sheet_data", name: res["common_message_are_you_sure_you_want_to_overwrite_current_balance_sheet_data"]},
        {value: "error_message_balance_sheet_save_unsuccessful", name: res["error_message_balance_sheet_save_unsuccessful"]},
        {value: "balance_sheet_deviation_current", name: res["balance_sheet_deviation_current"]},
        {value: "balance_sheet_deviation_basis", name: res["balance_sheet_deviation_basis"]} //res["balance_sheet_deviation_basis"]
      ];

      this.prepare();
    });

    ////////////// Értékek elkérése
    /*this.userWfService.getBalanceSheetSummary(this.gvSchema_id).subscribe((response)=>{
      if(response){
        if(response.type == "OK"){
          response.payload.forEach(item => {
            let schemaItem = this.schemaItems.find(singleSchemaItem => singleSchemaItem.sid == item.gv_line_sid);
            if(schemaItem){
              schemaItem.initial_amount = item.basis_amount;
              schemaItem.end_amount = item.current_amount;
              schemaItem.gv_value_id = item.gv_value_id;
            }
          });
          let rootItem = this.schemaItems.find(singleSchemaItem => singleSchemaItem.sid == 'TOP');
          this.recursiveSum(rootItem);

          this.schemaItems.forEach(item => {
            let itemElements = item.sid.split('_');
            if(itemElements.length>=2) {
              item.code = itemElements[itemElements.length - 1];
              if (itemElements[1] == "ASSETS") {
                this.elementAssets.push(item);
              } else {
                this.elementLiabs.push(item);
              }
            }
          })
          this.elementAssets.sort((a,b) => {return a.serial_number - b.serial_number});
          this.elementLiabs.sort((a,b) => {return a.serial_number - b.serial_number});
          let i = 1;
          this.elementAssets.forEach(item => {
            item.serial = ("0"+i).slice(-2);
            i++;
          });
          i = 1;
          this.elementLiabs.forEach(item => {
            item.serial = ("0"+i).slice(-2);
            i++;
          });
        }
      }
    })*/

  }

  prepare(){
    this.elementAssets = [];
    this.elementLiabs = [];
    let rootItem = this.schemaItems.find(singleSchemaItem => singleSchemaItem.gv_line_sid == 'TOP');
    this.recursiveSum(rootItem);

    let assetsItem = this.schemaItems.find(singleSchemaItem => singleSchemaItem.gv_line_sid == 'TOP_ASSETS');
    let liabsItem = this.schemaItems.find(singleSchemaItem => singleSchemaItem.gv_line_sid == 'TOP_LIABS');
    if(Math.abs(assetsItem.current_amount-liabsItem.current_amount)>0.01){
      this.balanceExistsCurrent = (this.translatableTexts.find(element => element.value === "balance_sheet_deviation_current").name) + ": "+(Math.round((assetsItem.current_amount-liabsItem.current_amount)*100)/100);
    }else{
      this.balanceExistsCurrent = "";
    }
    if(Math.abs(assetsItem.basis_amount-liabsItem.basis_amount)>0.01){
      this.balanceExistsBasis = (this.translatableTexts.find(element => element.value === "balance_sheet_deviation_basis").name) + ": "+(Math.round((assetsItem.basis_amount-liabsItem.basis_amount)*100)/100);
    }else{
      this.balanceExistsBasis = "";
    }

    this.schemaItems.forEach(item => {
      let itemElements = item.gv_line_sid.split('_');
      if(itemElements.length>=2) {
        item.code = itemElements[itemElements.length - 1];
        if(item.code == "ASSETS" || item.code == "LIABS"){
            item.code = "";
        }
        if(item.basis_amount)item.basis_amount = item.basis_amount*1;
        if(item.current_amount)item.current_amount = item.current_amount*1;
        if(item.sum_of_items)item.sum_of_items = item.sum_of_items*1;
        if (itemElements[1] == "ASSETS") {
          this.elementAssets.push(item);
        } else {
          this.elementLiabs.push(item);
        }
      }
    })
    this.elementAssets.sort((a,b) => {return a.gv_line_serial_number - b.gv_line_serial_number});
    this.elementLiabs.sort((a,b) => {return a.gv_line_serial_number - b.gv_line_serial_number});
    let i = 1;
    this.elementAssets.forEach(item => {
      item.serial = ("0"+i).slice(-2);
      i++;
    });
    i = 1;
    this.elementLiabs.forEach(item => {
      item.serial = ("0"+i).slice(-2);
      i++;
    });
  }
  recursiveSum(subRootItem){
    if(!this.hasChildItem(this.schemaItems, subRootItem)){
      let basis_amount = 0;
      let current_amount = 0;
      let sum_of_items = 0;
      if(subRootItem.basis_amount)basis_amount=subRootItem.basis_amount;
      if(subRootItem.current_amount)current_amount=subRootItem.current_amount;
      if(subRootItem.sum_of_items)sum_of_items=Math.abs(subRootItem.sum_of_items);
      return [basis_amount, current_amount, sum_of_items];
    }else {
      let valuesArr = [0, 0, 0];
      let sub_initial_amount = 0;
      let sub_current_amount = 0;
      let sub_sum_of_items = 0;
      this.getChildren(this.schemaItems, subRootItem).forEach(item => {
          valuesArr = this.recursiveSum(item);
          if(valuesArr) {
            sub_initial_amount = sub_initial_amount + valuesArr[0];
            sub_current_amount = sub_current_amount + valuesArr[1];
            sub_sum_of_items = sub_sum_of_items + Math.abs(valuesArr[2]);
          }
      });
      subRootItem.basis_amount = sub_initial_amount;
      subRootItem.current_amount = sub_current_amount;
      subRootItem.sum_of_items = sub_sum_of_items ;
      return [sub_initial_amount, sub_current_amount, sub_sum_of_items];
    }
  }

  sumPartial(sid){
    let partSid = sid;
    while (partSid.lastIndexOf("_")>-1){
      partSid = partSid.substr(0,partSid.lastIndexOf("_"));
      let singleItem = this.schemaItems.find(item => item.gv_line_sid == partSid);
      let valueArr = this.sumChildren(partSid);
      if(valueArr){
        singleItem.basis_amount = valueArr[0]*1;
        singleItem.current_amount = valueArr[1]*1;
        singleItem.sum_of_items = valueArr[2]*1;
        this.saveValues(singleItem.gv_line_id, this.gvSchema_id, singleItem.basis_amount, singleItem.current_amount);
      }
    }
  }

  sumChildren(sid){
    let sumInitial = 0;
    let sumCurrent = 0;
    let sumOfItems = 0;
    this.schemaItems.forEach(item => {
      if((item.gv_line_sid+"_").indexOf(sid+"_")>-1 && sid.split("_").length+1 == item.gv_line_sid.split("_").length && (item.gv_line_sid+"_") != (sid+"_")){sumInitial+=item.basis_amount;sumCurrent+=item.current_amount*1;sumOfItems+=item.sum_of_items*1;};
    });
    return [sumInitial, sumCurrent, sumOfItems]
  }

  getChildren(arr, parentItem): any {
    let result = [];
    arr.forEach(item => {
      if((item.gv_line_sid+"_").indexOf(parentItem.gv_line_sid+"_")>-1 && item.gv_line_hiearchy_level == parentItem.gv_line_hiearchy_level+1)result.push(item);
    })
    return result;
  }

  hasChildItem(arr, parentItem): boolean {
    let answer = false;
    arr.forEach(item => {
      if((item.gv_line_sid+"_").indexOf(parentItem.gv_line_sid+"_")>-1 && item.gv_line_hiearchy_level == parentItem.gv_line_hiearchy_level+1)
      {
        answer = true;
      }
    })
    return answer;
  }

  absNum(i){return i?Math.abs(i):0;}

  myformat(element, param, event, sid){
    /*if (this.language == 'hu-HU'){
      element[param]=Number(event.target.value.replace(",", "."));
    }
    else{
      element[param] = event.target.value;
    }*/

    if(event.target.value && event.target.value!="") {
      let current_item = this.schemaItems.find(item => item.gv_line_sid == sid);
      if (param == 'basis_amount') {
        current_item.basis_amount = event.target.value.replace(",",".").replace(" ","") * 1;
      } else {
        current_item.current_amount = event.target.value.replace(",",".").replace(" ","") * 1;
      }
      this.sumPartial(sid);
      let basis_amount = 0;
      let current_amount = 0;
      let error_amount = 0;
      if (current_item.basis_amount)basis_amount = current_item.basis_amount;
      if (current_item.current_amount)current_amount = current_item.current_amount;
      if (current_item.error_amount)error_amount = current_item.error_amount;

      let assetsItem = this.schemaItems.find(singleSchemaItem => singleSchemaItem.gv_line_sid == 'TOP_ASSETS');
      let liabsItem = this.schemaItems.find(singleSchemaItem => singleSchemaItem.gv_line_sid == 'TOP_LIABS');
      if(Math.abs(assetsItem.current_amount-liabsItem.current_amount)>0.01){
        this.balanceExistsCurrent = (this.translatableTexts.find(element => element.value === "balance_sheet_deviation_current")?.name) + ": "+(Math.round((assetsItem.current_amount-liabsItem.current_amount)*100)/100);
      }else{
        this.balanceExistsCurrent = "";
      }
      if(Math.abs(assetsItem.basis_amount-liabsItem.basis_amount)>0.01){
        this.balanceExistsBasis = (this.translatableTexts.find(element => element.value === "balance_sheet_deviation_basis").name) + ": "+(Math.round((assetsItem.basis_amount-liabsItem.basis_amount)*100)/100);
      }else{
        this.balanceExistsBasis = "";
      }

      this.saveValues(current_item.gv_line_id, this.gvSchema_id, basis_amount, current_amount);
    }
  }

  saveValues(line_id, schema_id, basis_value, current_value){
    this.userWfService.putGVValue(line_id, schema_id, basis_value, current_value).subscribe((response)=> {
      if (response) {
        if (response.type == "OK") {
          //current_item.gv_value_id = response.payload.id;
        }
      }
    })

  }

  balanceSheetSchemaDownload(){
    let locale = this.translateService.currentLang;
    this.userWfService.getGVFile(locale, this.gvSchema_id).then(response =>{
      var byteCharacters = atob(response.payload.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new File([byteArray], `BalanceSheet`, { type: response.payload.mime_type});
//      var fileURL = URL.createObjectURL(file);
//      window.open(fileURL);
      var link = document.createElement("a");
      var fileURL = URL.createObjectURL(file);        
      link.href = fileURL;
      link.download = response.payload.name;
      link.click();       
    })
  }

  importBalanceSheet(){
    const dialog = this.dialogService.open(BalanceSheetImportComponent);
    dialog.componentInstance.workflow_element_id = this.gvSchema_id;
    dialog.afterClosed().subscribe((response) => {
      //if(response==true){
        console.log(response);
        this.refreshWindow();
      //}
    })
  }

  refreshWindow(){
    this.userWfService.getBalanceSheetSchema(this.gvSchema_id, 'GV_SCHEMA_YEARLY').subscribe((response)=>{
      if(response){
        if(response.type == "OK"){
          this.schemaItems = response.payload;
          this.prepare();
        }
      }
    })

  }

  openDetail(event, element) {
    if (element.gv_line_is_writable && element.gv_value_id) {
      if (event.target.nodeName != 'INPUT') {
        this.userWfService.getGVItems(element.gv_value_id).subscribe((response)=>{
          if(response){
            if(response.type == "OK"){
              const dialog = this.dialogService.open(BalanceSheetDetailComponent, {width: '95vw'});
              dialog.componentInstance.elements = response.payload;
              dialog.componentInstance.element = element;
              dialog.componentInstance.customer_id = this.customer_id;
              dialog.afterClosed().subscribe((response) => {
                if(response==true){
                  this.refreshWindow();
                }
              })
            }
          }
        })
      }
    }
  }

  saveBalanceSheet(){
    /*console.log(this.elements)
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = this.translatableTexts.find(element => element.value === "common_message_are_you_sure_you_want_to_overwrite_current_balance_sheet_data").name;
    dialog.componentInstance.positiveButtonText = "Igen";
    dialog.componentInstance.negativeButtonText = "Nem";
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.saveBackend(this.balanceForm.getRawValue()).subscribe((response) => {
          this.dialogRef.close(response);
        });
      }
    })*/
  }

  saveBackend(body){
    /*const task = this.progressService.addTask(this.constructor.name + '_save_balance_sheet');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(environment.serverEndpoint + '',body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_balance_sheet_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );*/
  }

}
