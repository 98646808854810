import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Coworker } from 'src/app/data/coworker';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-coworker-editor',
  templateUrl: './coworker-editor.component.html',
  styleUrls: ['./coworker-editor.component.less']
})
export class CoworkerEditorComponent implements OnInit {

  coworkerForm: FormGroup = this.formBuilder.group({
    id: null,
    last_name: [null, [Validators.required]],
    first_name: [null, [Validators.required]],
    username: [null, [Validators.required]],
    email_address: [null, [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    group_ids: [null, [Validators.required]],
    is_qc_partner: [null, [Validators.required]],
    is_active: [true, [Validators.required]],
    locale: [null],
    chamber_code: null,
    mother_name: null,
    phone_number: null,
    postal_address: null,
    tax_number: null,
    signer_mail: [null, [Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
  });


  _coworker: Coworker;
  set coworker(value: Coworker) {
    this._coworker = value;
    this.coworkerForm.patchValue(value);
    let group_ids = [];
    value.groups.forEach((group)=>{
      group_ids.push(group.id)
    })
    this.coworkerForm.controls.group_ids.setValue(group_ids)
  }

  _groupSelectionItems: any;
  get groupSelectionItems() { return this._groupSelectionItems };
  set groupSelectionItems(value: any) {
    this._groupSelectionItems = value;
  }

  _qcPartnerSelectionItems: any;
  get qcPartnerSelectionItems() { return this._qcPartnerSelectionItems };
  set qcPartnerSelectionItems(value: any) {
    this._qcPartnerSelectionItems = value;
  }

  _statusSelectionItems: any;
  get statusSelectionItems() { return this._statusSelectionItems };
  set statusSelectionItems(value: any) {
    this._statusSelectionItems = value;
  }

  _languageSelectionItems: any;
  get languageSelectionItems() { return this._languageSelectionItems };
  set languageSelectionItems(value: any) {
    this._languageSelectionItems = value;
  }

  translatableTexts: any[] = [
    {value: "error_message_error_creating_user", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<CoworkerEditorComponent>, public http: HttpClient, private snackBar: MatSnackBar, private el: ElementRef, private translateService: TranslateService) { }

  ngOnInit(): void {
    if (this.coworkerForm.controls.id.value){
      this.coworkerForm.get("locale").clearValidators();
    } else {
      this.coworkerForm.get("locale").setValidators([Validators.required]);
      this.coworkerForm.get("is_active").disable();
    }
    this.coworkerForm.get("locale").updateValueAndValidity();

    this.translateService.stream(['error_message_error_creating_user']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_creating_user", name: res["error_message_error_creating_user"]}
      ];
    });
   }

  saveCoworker(){
    if (this.coworkerForm.valid) {
      if (this.coworkerForm.controls.id.value){
        this.modify().then((response) => {
          this.dialogRef.close(response);
         });
      }else{
        this.save().then((response) => {
          this.dialogRef.close(response);
         });
      }
    } else {
      this.coworkerForm.markAllAsTouched();
      let firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
      this.scrollToFirstInvalidControl(firstInvalidControl);
    }
  }

  private scrollToFirstInvalidControl(firstInvalidControl) {
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  save() {
    
    let body = this.coworkerForm.getRawValue();
    
    body.is_active = true;
    
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(environment.serverEndpoint + '/owner/coworker', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_user").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  modify() {
    let body = this.coworkerForm.getRawValue();

    /*body.is_callcenter = false;
    body.is_signer = false;
    body.groups.forEach((group)=>{

    })

    delete body.groups;*/

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.serverEndpoint + '/owner/coworker', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_user").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
