import {Component, OnInit, Input} from "@angular/core";
import {StateService} from "../../../../services/state.service";

@Component({
  selector: 'app-mobile-workflow',
  templateUrl: './mobile-workflow.component.html',
  styleUrls: ['./mobile-workflow.component.less']
})
export class MobileWorkflowComponent implements OnInit {

  @Input() workflows: any = [];
  @Input() stateService: StateService;

  selectedWorkflow : any;

  constructor() { }

  ngOnInit(): void {

  }


}
