<h2 mat-dialog-title>{{'workflows_workflow-creation' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="newWorkflowForm">
        <mat-radio-group formControlName="base" class="base-radio-group" (change)="changeBase($event.value)">
            <mat-radio-button [value]="'empty'" class="base-radio-button" style="padding-top: 28px;">
                <span>{{'workflows_blankly' | translate}}</span>
            </mat-radio-button>
            <mat-radio-button [value]="'previousDate'" class="base-radio-button">
                <div class="base-radio-container">
                    <span class="radio-title">{{'workflows_from-previous-year' | translate}}</span>
                    <div class="selectContainer">
                        <app-select-search [selectionItems]="previousDateSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_workflow' | translate" [selectCtrl]="newWorkflowForm.controls.dateWorkflow" [required]="this.newWorkflowForm.get('base').value == 'previousDate'"></app-select-search>
                    </div>
                </div>
             </mat-radio-button>
            <mat-radio-button [value]="'template'" class="base-radio-button">
                <div class="base-radio-container">
                    <span class="radio-title">{{'workflows_based-on-template' | translate}}</span>
                    <div class="selectContainer">
                        <app-select-search [selectionItems]="templateSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_template' | translate" [selectCtrl]="newWorkflowForm.controls.templateWorkflow" [required]="this.newWorkflowForm.get('base').value == 'template'">
                        </app-select-search>
                    </div>
                </div>
            </mat-radio-button>
            <mat-radio-button [value]="'existingWorkflow'" class="base-radio-button">
                <div class="base-radio-container">
                    <span class="radio-title">{{'workflows_copy-existing-workflow' | translate}}</span>
                    <div class="selectContainer">
                        <app-select-search [selectionItems]="workflowCompanySelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_company' | translate" [selectCtrl]="newWorkflowForm.controls.company" [required]="this.newWorkflowForm.get('base').value == 'existingWorkflow'">
                        </app-select-search>
                    </div>                            
                </div>
            </mat-radio-button>
                <div class="base-radio-container" style="justify-content: flex-end">
                    <span class="radio-title"></span>
                    <div class="selectContainer">
                        <app-select-search [selectionItems]="workflowTemplateSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_workflow' | translate" [selectCtrl]="newWorkflowForm.controls.workflow" [required]="this.newWorkflowForm.get('base').value == 'existingWorkflow'">
                        </app-select-search>    
                    </div>                         
                </div>
        </mat-radio-group>        
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="next()" class="positiveButton" [disabled]="!newWorkflowForm.valid">{{'workflows_next' | translate}}</button>
    <button mat-button (click)="reject()" class="negativeButton">{{'workflows_cancel' | translate}}</button>
</mat-dialog-actions>