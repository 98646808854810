import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialogRef} from "@angular/material/dialog";
import {FileSystemService} from "../../../services/file-system.service";
import {UserWfsService} from "../../../services/user-wfs.service";
import {MatSnackBar} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-special-file-table',
  templateUrl: './special-file-table.component.html',
  styleUrls: ['./special-file-table.component.less']
})
export class SpecialFileTableComponent implements OnInit {

  displayedColumns: string[] = [];

  dataSource = new MatTableDataSource<any>([]);

  translatableTexts: any[] = [
    {value: 'company_name', name: ''},
    {value: 'master_partner_code', name: ''},
    {value: 'email_address', name: ''},
    {value: 'tax_number', name: ''},
    {value: 'detail_partner_code', name: ''},
    {value: 'general_ledger_account', name: ''},
    {value: 'open_balance', name: ''},
    {value: 'currency', name: ''},
 //   {value: 'open_balance_foreign_currency', name: ''},
    {value: 'required_fields_empty', name: ''}
  ];

  _workflow_element_id;
  get workflow_element_id() { return this._workflow_element_id };
  set workflow_element_id(value: any) {
    this._workflow_element_id = value;
  }

  _headers: any;
  get headers() { return this._headers };
  set headers(value: any) {
    this._headers = value;
  }

  _lines: any;
  get lines() { return this._lines };
  set lines(value: any) {
    this._lines = value;
  }

  _column_count: number = 0;
  get column_count() { return this._column_count };
  set column_count(value: any) {
    this._column_count = value;
  }

  _listType: boolean = false;
  get listType() { return this._listType };
  set listType(value: any) {
    this._listType = value;
  }

  _dimensions: boolean = false;
  get dimensions() { return this._dimensions };
  set dimensions(value: any) {
    this._dimensions = value;
  }

  _doNotChange: boolean = false;
  get doNotChange() { return this._doNotChange };
  set doNotChange(value: any) {
    this._doNotChange = value;
  }

  columnCode = [];

  valueSet = [];

  constructor(public dialogRef: MatDialogRef<SpecialFileTableComponent>, private userWfsService: UserWfsService, private service: FileSystemService, private snackBar: MatSnackBar, private translateService: TranslateService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

    this.translateService.stream([
        'company_name',
        'master_partner_code',
        'email_address',
        'email_address',
        'tax_number',
        'currency',
        'detail_partner_code',
        'general_ledger_account',
        'open_balance',
     //   'open_balance_foreign_currency',
        'required_fields_empty'
      ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: 'company_name', name: res['company_name']},
        {value: 'master_partner_code', name: res['master_partner_code']},
        {value: 'email_address', name: res['email_address']},
        {value: 'tax_number', name: res['tax_number']},
        {value: 'currency', name: res['currency']},
        {value: 'detail_partner_code', name: res['detail_partner_code']},
        {value: 'general_ledger_account', name: res['general_ledger_account']},
        {value: 'open_balance', name: res['open_balance']},
   //     {value: 'open_balance_foreign_currency', name: res['open_balance_foreign_currency']},
        {value: 'required_fields_empty', name: res['required_fields_empty']}
      ];
    });

    for(let i=1;i<=this.column_count;i++){
      this.displayedColumns.push("Oszlop_"+i);
    }


    this.userWfsService.getValueSet(this.workflow_element_id, this.listType).subscribe((response) => {
      if(response) {
        if (response.type == "OK") {
          Object.keys(response.payload).forEach(colName => {
            this.valueSet.push(colName);
          });
          Object.keys(response.payload).forEach(col => {
            this.columnCode[`Oszlop_${response.payload[col] + 1}`] = this.valueSet.indexOf(col);
          });

        }
      }
    });

    this.lines.forEach(line => {
      var row = [];
      var item = {};
      var oszlop = 0;
      line.forEach(cellValue => {
        oszlop++;
        item["Oszlop_"+oszlop] = cellValue;
      });
      this.dataSource.data.push(item);
    });

  }

  tableValidation(requiredParams, selectedParams) {

    let isValid = true;
    const invalidElements = [];

    Object.entries({...requiredParams, ...selectedParams}).forEach(entry => {
      const [key, value] = entry;
      if (value === null) {
        isValid = false;
        invalidElements.push(this.translatableTexts.find(text => text.value === key).name);
      }
    });

    return {
      isValid,
      invalidElements
    };
  }

  save(){

    const baseRequiredParams = {
      company_name: null,
      master_partner_code: null,
      email_address: null,
      tax_number: null
    };

    const detailRequiredParams = {
      detail_partner_code: null,
      general_ledger_account: null,
      open_balance: null,
 //     open_balance_foreign_currency: null,
      currency: null
    };

    const payload = {workflow_element_id : this.workflow_element_id};
    const columnArray = {};
    let colIndex = 0;
    for (var key in this.columnCode) {
      if (this.columnCode.hasOwnProperty(key))
        colIndex = Number(key.split('_')[1])-1;
        columnArray[this.valueSet[this.columnCode[key]]] = colIndex;
    }

    const requiredValidation = this.tableValidation(this.listType === 'baseTable' ? baseRequiredParams : detailRequiredParams, columnArray);

    if (!requiredValidation.isValid) {
      this.snackBar.open(this.translatableTexts.find(text => text.value === 'required_fields_empty').name + ': ' + requiredValidation.invalidElements.join(', '), null, {panelClass: 'error-snackbar'});
      return false;
    }

    for (var key in columnArray) {
      payload[key] = columnArray[key];
    }

    //payload.columns = columnArray;
    if('baseTable' == this.listType) {
      this.service.setMasterDim(payload).subscribe((response)=> {
        this.dialogRef.close()
      });
    }
    if('detailTable' == this.listType) {
      this.service.setDetailDim(payload).subscribe((response)=> {
        this.dialogRef.close()
      });
    }
  }

  isitchanged(s){
    for (var key in this.columnCode) {
      if(key!=s && this.columnCode[s]==this.columnCode[key])this.columnCode[key]="";
    }
  }

}
