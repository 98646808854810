import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {TokenGuardService} from './guards/token-guard.service';
import {LoginComponent} from './components/login/login.component';
import {CompaniesComponent} from './components/companies/companies.component';
import {AuditorsComponent} from "./components/auditors/auditors.component";
import {CountryComponent} from "./components/country/country.component";
import {CredentialsService} from "./services/credentials.service";
import {FolderStructureComponent} from "./components/folder-structure/folder-structure.component";
import {TemplatesComponent} from "./components/templates/templates.component";
import { TemplateFolderComponent } from './components/template-folder/template-folder.component';
import { UploadersComponent } from './components/uploaders/uploaders.component';
import { CoworkersComponent } from './components/coworkers/coworkers.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ParametersComponent } from './components/parameters/parameters.component';
import { WorkflowElementGroupsComponent } from './components/workflow-element-groups/workflow-element-groups.component';
import { WorkflowTemplatesComponent } from './components/workflow-templates/workflow-templates.component';
import { RolesComponent } from './components/roles/roles.component';
import { DocumentSubtypesComponent } from './components/document-subtypes/document-subtypes.component';
import { TranslateModule } from '@ngx-translate/core';
import { ResetPasswordComponent } from './components/password/reset-password/reset-password.component';
import { FileSystemComponent } from './components/file-system/file-system.component';
import { WorkflowsComponent } from './components/workflows/workflows.component';
import { TimesheetComponent } from './components/timesheet/timesheet.component';
import {ReportingComponent} from "./components/reporting/reporting.component";
import {DebitBalanceComponent} from "./debit-balance/debit-balance.component";
import {MobileComponent} from "./components/mobile/mobile.component";
import { SystemLogsComponent } from './components/system-logs/system-logs.component';

const routes: Routes = [
  {
    path: 'folderstructure',
    component: FolderStructureComponent,
    data: {
      title: 'Mappastruktúra',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'templates',
    component: TemplatesComponent,
    data: {
      title: 'Sablonok',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'document-subtypes',
    component: DocumentSubtypesComponent,
    data: {
      title: 'Dokumentum altípusok',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: 'Saját adatok',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'template-folder',
    component: TemplateFolderComponent,
    data: {
      title: 'Sablon-mappa',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'roles',
    component: RolesComponent,
    data: {
      title: 'Szerepkörök',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'coworkers',
    component: CoworkersComponent,
    data: {
      title: 'Munkatársak',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'parameters',
    component: ParametersComponent,
    data: {
      title: 'Működési paraméterek',
      description: '',
      roles: ['owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'workflow-element-groups',
    component: WorkflowElementGroupsComponent,
    data: {
      title: 'Workflow-elem főcsoportok',
      description: '',
      roles: ['admin', 'owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'workflow-templates',
    component: WorkflowTemplatesComponent,
    data: {
      title: 'Workflow sablonok',
      description: '',
      roles: ['admin', 'owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'countries',
    component: CountryComponent,
    data: {
      title: 'Országkódok',
      description: '',
      roles: ['admin']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'auditors',
    component: AuditorsComponent,
    data: {
      title: 'Könyvvizsgáló ügyfelek',
      description: '',
      roles: ['admin']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    data: {
      title: 'Cégek',
      description: '',
      roles: ['user', 'owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'workflows',
    component: WorkflowsComponent,
    data: {
      title: 'Workflowk',
      description: '',
      roles: ['user', 'owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'file-system',
    component: FileSystemComponent,
    data: {
      title: 'Aktarendszer',
      description: '',
      roles: ['user', 'owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'timesheet',
    component: TimesheetComponent,
    data: {
      title: 'Timesheet',
      description: '',
      roles: ['user', "owner"]
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'riportok',
    component: ReportingComponent,
    data: {
      title: 'Riportok',
      description: '',
      roles: ['user', 'owner']
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Bejelentkezés',
      description: ''
    },
  },
  {
    path: 'mobile',
    component: MobileComponent,
    data: {
      title: 'Mobil',
      description: ''
    },
  },
  {
    path: 'lostpassword/:token',
    component: ResetPasswordComponent,
    data: {
      title: 'Új jelszó beállítása',
      description: ''
    },
  },
  {
    path: 'debitbalance/:token',
    component: DebitBalanceComponent,
    data: {
      title: 'Egyenlegközlő nyilatkozat',
      description: ''
    },
  },
  {
    path: 'uploaders',
    component: UploadersComponent,
    data: {
      title: 'Uploaders',
      description: ''
    },
  },
  {
    path: 'system-logs',
    component: SystemLogsComponent,
    data: {
      title: 'Rendszerinformációk',
      description: '',
      roles: ['admin', 'owner']
    }
  },
  {
    path: '',
    redirectTo:
        CredentialsService.role === 'admin' ? 'auditors' :
        (CredentialsService.role === 'owner' ? 'folderstructure' : 
        (CredentialsService.role === 'user' ? 'companies' : "login")),
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: 'AI Workflow - Az oldal nem található',
      description: 'Az oldal nem található. Biztos hogy jól írta be a címet?'
    },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule {

}
