import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { FileSystemService } from '../../../services/file-system.service';

@Component({
  selector: 'app-folder-create-editor',
  templateUrl: './folder-create-editor.component.html',
  styleUrls: ['./folder-create-editor.component.less']
})
export class FolderCreateEditorComponent implements OnInit {

  folderForm: FormGroup = this.formBuilder.group({
    folderName: [null, [Validators.required]]
  });

  
  _folderNode: any;
  get folderNode() { return this._folderNode };
  set folderNode(value: any) {
    this._folderNode = value;
  }

  _customer: any;
  get customer() { return this._customer };
  set customer(value: any) {
    this._customer = value;
  }
 
  constructor(private formBuilder: FormBuilder, public http: HttpClient, private snackBar: MatSnackBar,  public dialogRef: MatDialogRef<FolderCreateEditorComponent>, private service: FileSystemService) { }

  ngOnInit(): void {
  }

  saveFolder(){
    if (this.folderForm.valid) {
      let body = {
        customer_id: this.customer,
        name: this.folderForm.get('folderName').value,
        parent_path: this.folderNode.path
      };

     this.service.addFolder(body).then((response) => {
      this.dialogRef.close({path: this._folderNode.path, response: response});
     });
    }
    else {
      this.folderForm.markAllAsTouched();
    }
  }

}
