<h2 mat-dialog-title>{{'file_system_add-label' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="labelForm">
        <div class="row">
            <div class="col-12 ">
                <mat-form-field appearance="outline">
                    <mat-label>{{'file_system_name' | translate}}</mat-label>
                    <input matInput formControlName="fileName" required disabled>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" >
                    <mat-label>{{'file_system_label' | translate}}</mat-label>
                    <input matInput formControlName="caption">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button class="positiveButton" (click)="saveLabel()">{{'button_title_save' | translate}}</button>
    <button mat-button class="negativeButton" (click)="dialogRef.close()">{{'button_title_cancel' | translate}}</button>
</mat-dialog-actions>
