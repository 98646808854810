import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {TemplateCodes} from "../../../data/mail-address";

@Component({
  selector: 'app-template-folder-editor',
  templateUrl: './template-folder-editor.component.html',
  styleUrls: ['./template-folder-editor.component.less']
})
export class TemplateFolderEditorComponent implements OnInit {

  templateFolderForm: FormGroup = this.formBuilder.group({
    id: null,
    code: [null, [Validators.required]],
    address: [null, [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
  });

  _templateFolder: TemplateCodes;
  set templateFolder(value: TemplateCodes) {
    this._templateFolder = value;
    this.templateFolderForm.patchValue(value);
  }

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<TemplateFolderEditorComponent>) { }

  ngOnInit(): void { }

  saveTemplateFolder(){
    if (this.templateFolderForm.valid) {
      this.dialogRef.close(this.templateFolderForm.getRawValue());
    } else {
      this.templateFolderForm.markAllAsTouched();
    }
  }

}
