import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-ugyfel-timesheet',
  templateUrl: './report-ugyfel-timesheet.component.html',
  styleUrls: ['./report-ugyfel-timesheet.component.less']
})
export class ReportUgyfelTimesheetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
