import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {AuditorService} from "../../services/auditor.service";
import {Auditor} from "../../data/auditor";
import {AuditorEditorComponent} from "./auditor-editor/auditor-editor.component";
import {merge, of, Subscription} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {PaginatedAnswer} from "../../data/response";
import {OkDialogComponent} from "../ok-dialog/ok-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import { MatTable } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FilterTableComponent } from '../filter-table/filter-table.component';
import { StorageService } from '../../services/storage.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-auditors',
  templateUrl: './auditors.component.html',
  styleUrls: ['./auditors.component.less']
})
export class AuditorsComponent implements OnInit, AfterViewInit {
  data: Auditor[] = [];

  statusList: any[] = [{value: true, name: "Aktív"}, {value: false, name: "Inaktív"}];

  columnSelectionItems: any[] = [{value: "business_code", name: ""}, {value: "name", name: ""}, {value: "email_address", name: ""}, {value: "city", name: ""}, {value: "tax_number", name: ""}];

  columnSelectionForm: FormGroup = this.formBuilder.group({
    columnName: "name"
  });

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  allColumns = [{value:'business_code', name: 'auditors_identifier-code'},  {value:'name', name: 'auditors_name'},  {value:'email_address', name: 'auditors_email-address'},  {value:'city', name: 'auditors_city'},  {value:'tax_number', name: 'auditors_tax-number'},  {value:'auditor_status_sid', name: 'auditors_status'}]

  displayedColumns: string[] = ['select', 'business_code', 'name', 'email_address', 'city', 'tax_number', 'auditor_status_sid'];
  selection = new SelectionModel<any>(false, []);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchInput') searchInput;

  pageSize: number = 10;
  currentPage: number = 1;
  searchPattern: string ="";
  searchColumn: string = this.columnSelectionForm.get("columnName").value;

  columnNameSubscription: Subscription;
  sortChangeSubscription: Subscription;

  translatableTexts: any[] = [
    {value: "common_message_audit_client_save_successful", name: ""}, 
    {value: "error_message_audit_client_save_unsuccesful", name: ""}, 
    {value: "common_message_password_reset_successful", name: ""}, 
    {value: "error_message_password_reset_unsuccesful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private service: AuditorService, private snackBar: MatSnackBar, private formBuilder: FormBuilder, private translateService: TranslateService, private storageService: StorageService) {
  }

  ngOnInit(): void {
    this.columnNameSubscription = this.columnSelectionForm.get("columnName").valueChanges.subscribe((data: any) => this.searchColumn = data)

    this.translateService.stream(['auditors_identifier-code', 'auditors_name', 'auditors_email-address', 'auditors_city', 'auditors_tax-number', 'auditors_status']).subscribe((res: string) => {
      this.columnSelectionItems = [{value: "business_code", name: res["auditors_identifier-code"]}, {value: "name", name: res["auditors_name"]}, {value: "email_address", name: res["auditors_email-address"]}, {value: "city", name: res["auditors_city"]}, {value: "tax_number", name: res["auditors_tax-number"]}];
    });

    this.translateService.stream(['common_message_audit_client_save_successful', 'error_message_audit_client_save_unsuccesful', 'common_message_password_reset_successful', 'error_message_password_reset_unsuccesful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_audit_client_save_successful", name: res["common_message_audit_client_save_successful"]},
        {value: "error_message_audit_client_save_unsuccesful", name: res["error_message_audit_client_save_unsuccesful"]},
        {value: "common_message_password_reset_successful", name: res["common_message_password_reset_successful"]},
        {value: "error_message_password_reset_unsuccesful", name: res["error_message_password_reset_unsuccesful"]}
      ];
    });

    this.storageService.readJsonStorage({"name": ["auditorsTableColumns"]}).subscribe((response) => {
      if(response.auditorsTableColumns){
        this.displayedColumns = JSON.parse(response.auditorsTableColumns).json;   
      }   
    })
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sortChangeSubscription = this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.currentPage = 1;
    });

    this.loadTableData();
  }

  ngOnDestroy(){
    if(this.columnNameSubscription)this.columnNameSubscription.unsubscribe();
    if(this.sortChangeSubscription)this.sortChangeSubscription.unsubscribe();
  }

  loadTableData(response?){
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.service.listAuditors({
              "page": this.currentPage,
              "size": this.pageSize,
              "order": [
                {    
                  "column": this.sort.active ? this.sort.active : "name",
                  "direction": this.sort.direction
                }
              ],
              "like": {
                "operator" : "AND",
                "filters": [
                  {
                    "column": this.searchColumn,
                    "pattern": this.searchPattern
                  }
                ]
              }
            }
          );
        }),
        map((data: PaginatedAnswer<Auditor>) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.payload.total;

          return data.payload.content;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return of([]);
        })
    ).subscribe((data) => {
      this.data = data

      if (this.isEdit){
        let index = this.data.findIndex(data=>data.id == response.payload.id)
        if (index > -1){
          this.selection.select(this.data[index]);
        }
        this.isEdit = false;
      }
      else{
        this.selection.clear();
      }
      
    });
  }

  handlePage(event){
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.selection.clear();
  }

  searchAuditor(event){
    this.searchPattern = event.target.value;
    this.loadTableData();
  }

  clearSearch(){
    this.searchInput.nativeElement.value = null;
    this.searchPattern = null;
    this.loadTableData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.forEach(row => this.selection.select(row));
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }


  isEdit = false;
  editAuditor(auditor?: Auditor) {
    const dialog = this.dialogService.open(AuditorEditorComponent, {width: '90%', maxWidth: '800px'});
    if (auditor) {
      dialog.componentInstance.auditor = auditor;
    }
    dialog.afterClosed().subscribe(response => {
      if (response){
        if (response.type == "OK") {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_audit_client_save_successful").name, null, {panelClass: 'success-snackbar'});
          if (auditor){
            this.isEdit = true;
          }
          this.loadTableData(response);
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_audit_client_save_unsuccesful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }
    });
  }

  restorePassword(auditor: Auditor) {
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('auditors_password-reset-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.service.passwordReset(auditor.email_address).subscribe((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_password_reset_successful").name, null, {panelClass: 'success-snackbar'});
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_password_reset_unsuccesful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
        });
      }
    })
  }

  filterTableColumns(){
    const dialog = this.dialogService.open(FilterTableComponent);
    dialog.componentInstance.tableColumns = this.allColumns;
    dialog.componentInstance.displayedColumns = this.displayedColumns;
    dialog.componentInstance.table = "auditors";
    dialog.afterClosed().subscribe(() => {
        this.storageService.readJsonStorage({"name": ["auditorsTableColumns"]}).subscribe((response) => {
          if (response.auditorsTableColumns){
            this.displayedColumns = JSON.parse(response.auditorsTableColumns).json;      
          }
      })
    })
  }

  reorderColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    let body = {
      "name": "auditorsTableColumns",
      "value": {
        "json": this.displayedColumns
      }}
    this.storageService.writeJsonStorage(body).subscribe();
  }
}
