import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Company, Contract } from 'src/app/data/company';
import { PaginatedAnswer, PaginatedRequest } from 'src/app/data/response';
import { CompanyService } from 'src/app/services/company.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { FilterTableComponent } from '../../filter-table/filter-table.component';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { ContractEditorComponent } from './contract-editor/contract-editor.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.less']
})
export class ContractsComponent implements OnInit {

  allColumns = [{value:'contract_code', name: 'Szerződés iktatószáma'},  {value:'registered_at', name: 'Kelt'},  {value:'registered_by_name', name: 'Aláíró könyvvizsgáló'},  {value:'type', name: 'Típus'},  {value:'valid_from', name: 'Érvényesség kezdete'},  {value:'valid_to', name: 'Érvényesség lejárta'}, {value:'status', name: 'Státusz'}]

  displayedColumns: string[] = ['select', 'contract_code', 'registered_at', 'registered_by_name', 'type', 'valid_from', 'valid_to', 'status'];
  dataSource = new MatTableDataSource<Contract>([]);
  selection = new SelectionModel<Contract>(false, []);

  @Output() showContracts = new EventEmitter<any>();

  @Input() customerId: string;
  @Input() customer: any;

  contracts: Contract[];

  selectedContract: Contract;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  searchPattern: string ="";

  @ViewChild('searchInput') searchInput;

  pageSize: number = 10;
  currentPage: number = 1;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  usertexts:any;

  typeSelectionItems = []
  signatoryAuditorSelectionItems = []
  rightToSignSelectionItems = []
  statusSelectionItems = []

  //TODO
  contractResultSelectionItems = [{value: "CON_STAT_ACTIVE", name: "company-accepted"}, {value: "CON_STAT_REJETED", name: "company-rejected"}]

  constructor(private dialogService: MatDialog, private snackBar: MatSnackBar, private storageService: StorageService,  private service: CompanyService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.dataSource.data = this.contracts;

    //console.log(this.customer);

    this.translateService.onLangChange.subscribe((event)=>{
      this.signatoryAuditorSelectionItems = [];
      this.rightToSignSelectionItems = [];
      this.service.getSigners().subscribe((response)=>{
        response.payload.forEach((signer)=>{
          if (signer.is_active){
           this.signatoryAuditorSelectionItems.push({name:this.createName(signer), value:signer.id}) 
          }
        })
      })
  
      this.service.getCompanySigners().subscribe((response)=>{
        response.payload.forEach((signer)=>{
          // console.log(signer);
          if (signer.is_active){
           this.rightToSignSelectionItems.push({name:this.createName(signer), value: {id: signer.id}}) 
          }
        })
      })
    })


    this.storageService.readJsonStorage({"name": ["contractsTableColumns"]}).subscribe((response) => {
      if (response.contractsTableColumns){
        this.displayedColumns = JSON.parse(response.contractsTableColumns).json;  
        // console.log(this.displayedColumns);
      }
    })

    this.service.getContractTypes().subscribe((response)=>{
      response.payload.forEach((type)=>{
        if (type.is_active){
         this.typeSelectionItems.push({name:type.sid, value:type.sid}) 
        }
      })
    })

    this.service.getContractStatuses().subscribe((response)=>{
      response.payload.forEach((status)=>{
        if (status.is_active){
         this.statusSelectionItems.push({name:status.sid, value:status.sid}) 
        }
      })
    })

    this.service.getSigners().subscribe((response)=>{
      response.payload.forEach((signer)=>{
        if (signer.is_active && signer.first_name != undefined){
          this.signatoryAuditorSelectionItems.push({name:this.createName(signer), value:signer.id}) 
         }
      })
    })

    this.service.getCompanySigners().subscribe((response)=>{
      response.payload.forEach((signer)=>{
        // console.log(signer);
        if (signer.is_active && signer.first_name != undefined){
          this.rightToSignSelectionItems.push({name:this.createName(signer), value: signer.id}) 
         }
      })
    })

    this.getUsertexts();

  
  }

  ngAfterViewInit() {
    /*this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;*/

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.currentPage = 1;
      // console.log("sort changed");
      // console.log(this.sort.active);
    });

    this.loadTableData();
    // console.log(this.contracts);
  }

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

  createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }

  getUsertexts(){
    let body = {
      customer_id: this.customerId,
      contract_id: 0,
      locale: localStorage.getItem('language')
    };
    this.service.getUserTexts(body).subscribe(data => {
      // console.log(data);
      this.usertexts = data;
    })
  }




  loadTableData(response?){
    // console.log(this.sort.active);
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          let req;
          req = {
            "customer_id": this.customerId,
            "page": this.currentPage,
            "size": this.pageSize,
            "locale": localStorage.getItem("language"),
            "order": [
              {
                "column": this.sort.active ? this.sort.active : "contract_code",
                "direction": this.sort.direction ? this.sort.direction : "asc"
              }
            ]
          }
          return this.service.listContracts(req);
        }),
        map((data: PaginatedAnswer<Contract>) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.payload.total;

          return data.payload.content;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return of([]);
        })
    ).subscribe((data) => {
      this.dataSource.data = data
      // console.log(data);
      if (this.isEdit){
        let index = this.dataSource.data.findIndex(data=>data.id == response.payload.id)
        if (index >- 1){
          this.selection.select(this.dataSource.data[index]);
        }
        this.isEdit = false;
      }
      else{
        this.selection.clear();
      }
    });
  }

  handlePage(event){
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.selection.clear();
  }

  goBack(){
    this.showContracts.emit(false);
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  searchContract(event){
    this.searchPattern = event.target.value;
    //this.loadTableData();
  }

  clearSearch(){
    this.searchInput.nativeElement.value = null;
    this.searchPattern = null;
    //this.loadTableData();
  }

  isEdit = false;
  editContract(contract?: Contract, editable?:boolean){
    const dialog = this.dialogService.open(ContractEditorComponent);
    dialog.componentInstance.typeSelectionItems = this.typeSelectionItems;
    dialog.componentInstance.signatoryAuditorSelectionItems = this.signatoryAuditorSelectionItems;
    dialog.componentInstance.rightToSignSelectionItems = this.rightToSignSelectionItems;
    dialog.componentInstance.contractResultSelectionItems = this.contractResultSelectionItems;
    dialog.componentInstance.customerId = this.customerId;
    dialog.componentInstance.currencyISOCode = this.customer.currency_iso_code;
    dialog.componentInstance.editable = editable;
    dialog.componentInstance.usertexts = this.usertexts;
    if (contract) {
      dialog.componentInstance.contract = contract;
    }
    dialog.afterClosed().subscribe(response => {
      if (response){
        if (response.type == "OK") {
          this.snackBar.open("A szerződés mentése sikeres", null, {panelClass: 'success-snackbar'});
          if (contract){
            this.isEdit = true;
          }
          this.loadTableData(response);
        }
        else{
          this.snackBar.open("A szerződés mentése sikertelen", null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        } 
      }
      
    });
  }

  deleteContract(contract: Contract){
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = 'Figyelem';
    dialog.componentInstance.description =
        'Biztos benne, hogy törölni szeretné a kiválasztott szerződést?';
    dialog.componentInstance.positiveButtonText = "Igen";
    dialog.componentInstance.negativeButtonText = "Nem";
    dialog.afterClosed().subscribe(response => {
      if (response) {        
        this.service.deleteContract(contract.id).subscribe((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open("A szerződés törlése sikeres", null, {panelClass: 'success-snackbar'}); 
            }
            else{
              this.snackBar.open("A szerződés törlése sikertelen", null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
          
        });
      }
    })
  }

  filterTableColumns(){
    const dialog = this.dialogService.open(FilterTableComponent);
    dialog.componentInstance.tableColumns = this.allColumns;
    dialog.componentInstance.displayedColumns = this.displayedColumns;
    dialog.componentInstance.table = "contracts";
    dialog.afterClosed().subscribe(() => {
        this.storageService.readJsonStorage({"name": ["contractsTableColumns"]}).subscribe((response) => {
          if (response.contractsTableColumns){
            this.displayedColumns = JSON.parse(response.contractsTableColumns).json;      
          }  
      })
    })
  }

  reorderColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    let body = {
      "name": "contractsTableColumns",
      "value": {
        "json": this.displayedColumns
      }}
    // console.log(body);
    this.storageService.writeJsonStorage(body).subscribe();
  }

  signContract(contract){
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = 'Figyelem';
    dialog.componentInstance.description =
        'Biztos benne, hogy kiküldi aláírásra a szerződést?';
    dialog.componentInstance.positiveButtonText = "Igen";
    dialog.componentInstance.negativeButtonText = "Nem";
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.service.sendContractToSign(contract.id).subscribe((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open("A szerződés kiküldése sikeres", null, {panelClass: 'success-snackbar'});
              contract.contract_status_sid = 'CON_STAT_SIGNING';
            }
            else{
              this.snackBar.open("A szerződés kiküldése sikertelen", null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }

        });
      }
    })
  }

}
