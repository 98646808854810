<h2 mat-dialog-title>{{template ? ('template_template-modification' | translate) : ('template_create-new' | translate)}}</h2>
<mat-dialog-content>
    <form [formGroup]="templateForm">
        <app-select-search [selectionItems]="typeList" [selectionType]="'singleSelect'" [placeholder]="'template_type' | translate" [selectCtrl]="templateForm.controls.template_code" [required]="true" [disabled]="templateForm.get('template_code').disabled"></app-select-search>
        <app-select-search [selectionItems]="subtypeList" [selectionType]="'singleSelect'" [placeholder]="'template_subtype' | translate" [selectCtrl]="templateForm.controls.subtemplate_id" [required]="false" [disabled]="templateForm.get('subtemplate_id').disabled"></app-select-search>
        <app-select-search [selectionItems]="languageSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'template_lang' | translate" [selectCtrl]="templateForm.controls.locale" [required]="true" [disabled]="templateForm.get('locale').disabled"></app-select-search>

        <app-file-upload [description]="fileName" formControlName="content"></app-file-upload>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveTemplate()" class="positiveButton">
        {{'template_save' | translate}}
    </button>
    <button mat-button (click)="dialogRef.close()"  class="negativeButton">{{'template_reject' | translate}}</button>
</mat-dialog-actions>
