import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WfTemplateService } from 'src/app/services/wf-template.service';
import { CredentialsService } from '../../../services/credentials.service';
import { UserWfsService } from '../../../services/user-wfs.service';

@Component({
  selector: 'app-workflow-element-coworker',
  templateUrl: './workflow-element-coworker.component.html',
  styleUrls: ['./workflow-element-coworker.component.less']
})
export class WorkflowElementCoworkerComponent implements OnInit {

  _allCoworkers: any;
  get allCoworkers() { return this._allCoworkers };
  set allCoworkers(value: any) {
    this._allCoworkers = value;
  }

  _wfElement: any;
  get wfElement() { return this._wfElement };
  set wfElement(value: any) {
    this._wfElement = value;
  }

  wfElem;

  selectedAllCoworker;
  selectedCurrentCoworker;

  constructor(public dialogRef: MatDialogRef<WorkflowElementCoworkerComponent>, private service: WfTemplateService, private userWfService: UserWfsService) { }

  ngOnInit(): void {
    this.wfElem = JSON.parse(JSON.stringify(this.wfElement));
    /*this.wfElement.coworkers = [];
    this.wfElement.coworker_ids.forEach((id)=>{
      this.wfElement.coworkers.push(this.allCoworkers.find(coworker => coworker.id == id));
    })*/
  }

  addCoworker(){
    if (this.selectedAllCoworker){
      let index = this.wfElem.coworkers.findIndex(coworker => coworker.id == this.selectedAllCoworker.id);
      if (index == -1){
        this.wfElem.coworkers.push(this.selectedAllCoworker);
      }
    }
  }

  removeCoworker(){
    if (this.selectedCurrentCoworker){
      let index = this.wfElem.coworkers.findIndex(coworker => coworker.id == this.selectedCurrentCoworker.id);
      if (index > -1){
        this.wfElem.coworkers.splice(index, 1)
        this.selectedCurrentCoworker = null;
      }
    }
  }

  saveCoworkers(){
    let coWorker = {id: this.wfElement.id, coworker_ids: []};
    coWorker.id = this.wfElement.id;
    this.wfElem.coworkers.forEach((coworker)=>{coWorker.coworker_ids.push(coworker.id)});
    console.log(coWorker);
    if (CredentialsService.role == 'user'){
      this.service.addElementCoworker(coWorker).subscribe((response)=>{
        this.dialogRef.close(response);
      })  
    }else{
      this.dialogRef.close({type:"OK", payload: this.wfElem});
    }
  }

}
