<div class="image-drop-zone" (click)="squareImageInput.click(); touched()"
     (drop)="onFileDrop($event)" (dragover)="onFileDragOver($event)"
     (dragleave)="onFileDragLeave($event)" [ngClass]="{ 'file-hovered': hovered,
     'error': ngControl.errors && Object.keys(ngControl.errors).length > 0 && ngControl.touched }">
  <h3 *ngIf="label">{{label}}</h3>
  <p>
    {{description}}
  </p>
  <input type="file" accept='{{accept}}' hidden #squareImageInput
         (change)="setFile($any($event.target).files[0])">
  <button mat-flat-button color="warn" *ngIf="file"
          (click)="$event.stopImmediatePropagation(); setFile(null);">
    {{'button_title_delete' | translate}}
  </button>
</div>
