import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-timesheet-detail',
  templateUrl: './timesheet-detail.component.html',
  styleUrls: ['./timesheet-detail.component.less']
})
export class TimesheetDetailComponent implements OnInit {

  _data: any;
  get data() { return this._data };
  set data(value: any) {
    this._data = value;
  }

  constructor(public dialogRef: MatDialogRef<TimesheetDetailComponent>, private dialogService: MatDialog) { }

  ngOnInit(): void {
  }

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

}
