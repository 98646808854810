import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Parameter } from 'src/app/data/parameter';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parameter-editor',
  templateUrl: './parameter-editor.component.html',
  styleUrls: ['./parameter-editor.component.less']
})
export class ParameterEditorComponent implements OnInit {

  parameterForm: FormGroup = this.formBuilder.group({
    value: [null, [Validators.required]]
  });

  _parameters: Parameter[];
  get parameters() { return this._parameters };
  set parameters(value: Parameter[]) {
    this._parameters = value;
  }

  _parameter: Parameter;
  get parameter() { return this._parameter };
  set parameter(value: Parameter) {
    this._parameter = value;
    this.parameterForm.patchValue(value);
  }

  _trueFalseSelectionItems: any;
  get trueFalseSelectionItems() { return this._trueFalseSelectionItems };
  set trueFalseSelectionItems(value: any) {
    this._trueFalseSelectionItems = value;
  }

  translatableTexts: any[] = [
    {value: "error_message_operating_parameter_save_unsuccessful", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ParameterEditorComponent>, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.stream(['error_message_operating_parameter_save_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_operating_parameter_save_unsuccessful", name: res["error_message_operating_parameter_save_unsuccessful"]}
      ];
    });
  }

  saveParameter(){
    if (this.parameterForm.valid) {
     this.save().then((response) => {
        this.dialogRef.close({param: this.parameter, response: response});
      })
    } else {
      this.parameterForm.markAllAsTouched();
    }
  }

  save() {
    let body = {}
    this.parameters.forEach((param)=>{
      body[param.origName] = param.origValue; 
    })
    body[this.parameter.origName] = this.parameter.type == 'time' ? this.calculateSecFromDay(this.parameterForm.get("value").value) : this.parameterForm.get("value").value;
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.serverEndpoint + '/owner/operatingparams', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_operating_parameter_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  calculateSecFromDay(day){
    return day * 24 * 60 * 60;
  }

}
