import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FolderNode} from "../../../data/folder";
import {MatDialogRef} from "@angular/material/dialog";
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-folder-editor',
  templateUrl: './folder-editor.component.html',
  styleUrls: ['./folder-editor.component.less']
})
export class FolderEditorComponent implements OnInit {
  folderForm: FormGroup = this.formBuilder.group({
    caption: [null, Validators.required],
    auditor_program_folder_code: [null],
  });

  _folderNode: FolderNode;
  auditorFolderList: FolderNode[];
  set folderNode(value: FolderNode) {
    this._folderNode = value;
    if(!this.isNewFolder){
      this.folderForm.patchValue(value);
    }
  }

  _isNewFolder: any;
  get isNewFolder() { return this._isNewFolder };
  set isNewFolder(value: any) {
    this._isNewFolder = value;
  }

  translatableTexts: any[] = [
    {value: "error_message_error_getting_audit_codes", name: ""},
    {value: "error_message_error_creating_folder", name: ""},
    {value: "error_message_error_editing_folder", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, public http: HttpClient, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<FolderEditorComponent>, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this._folderNode?.childs.length == 0 || this.isNewFolder){
      this.folderForm.get("auditor_program_folder_code").enable();
    }
    else{
      this.folderForm.get("auditor_program_folder_code").disable();
    }
    this.getAuditorFolders()
    .then((response:any) => {
      this.auditorFolderList = response.payload;      
    });

    this.translateService.stream(['error_message_error_getting_audit_codes', 'error_message_error_creating_folder', 'error_message_error_editing_folder']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_getting_audit_codes", name: res["error_message_error_getting_audit_codes"]},
        {value: "error_message_error_creating_folder", name: res["error_message_error_creating_folder"]},
        {value: "error_message_error_editing_folder", name: res["error_message_error_editing_folder"]}
      ];
    });
  }

  objectKeys = Object.keys;

  getAuditorFolders(){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(environment.serverEndpoint + '/owner/auditorfolders', {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_audit_codes").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  saveFolder() {
    if (this.folderForm.valid) {
      if(!this.isNewFolder){
        this.modify().then((response) => {
          this.dialogRef.close({path: this._folderNode ? this._folderNode.path : "/", response: response});
         });
      }
      else{
        this.save().then((response) => {
        this.dialogRef.close({path: this._folderNode ? this._folderNode.path : "/", response: response});
       });
      }
    } else {
      this.folderForm.markAllAsTouched();
    }
  }

  save() {
    let body = {
      caption: this.folderForm.get('caption').value,
      parent_path: this._folderNode ? this._folderNode.path : "/",
      auditor_program_folder_code: this.folderForm.get('auditor_program_folder_code').value
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(environment.serverEndpoint + '/owner/folder', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_folder").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  modify() {
    let body = 
    {
      caption: this.folderForm.get('caption').value,
      path: this._folderNode.parentFolder + "/" + this._folderNode.caption,
      auditor_program_folder_code: this.folderForm.get('auditor_program_folder_code').value
    }
    
    /*{
      old_path: this._folderNode.parentFolder + "/" + this._folderNode.name,
      new_path: this._folderNode.parentFolder + "/" + this.folderForm.get('name').value
    };*/
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.serverEndpoint + '/owner/folder', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_editing_folder").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
