<mat-toolbar color="primary" style="display: flex; justify-content: space-between;">
    <div>
        <button mat-icon-button matTooltip="{{'workflows_back' | translate}}" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button *ngIf="workflowElement.workflow_element_type_sid == 'WFE_TYPE_DEBIT' || workflowElement.workflow_element_type_sid == 'WFE_TYPE_CREDIT' " mat-button (click)="showBalanceStatement()">{{'workflows_balance-operator-specific-tasks' | translate}}</button>
        <span>{{'common_title_set_workflow_element_status' | translate}}</span>
        <app-select-search [appearanceStyle]="'standard'" [selectionItems]="statusSelectionItems" [selectionType]="'singleSelect'" [selectCtrl]="status" [required]="false"></app-select-search>
        <button mat-button (click)="saveWorkflowElementStatus()">{{'button_title_save' | translate}}</button>
    </div>
    <div *ngIf="selected.value == 2">
        <button mat-icon-button matTooltip="{{'workflows_download' | translate}}" (click)="dnFile()" [disabled]="!selectedFile">
            <mat-icon>file_download</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'button_title_upload' | translate}}" (click)="uploadFile()" [disabled]="!selectedFolder">
            <mat-icon>file_upload</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflows_assignment' | translate}}" (click)="addFile()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflows_remove' | translate}}" (click)="deleteFile()" [disabled]="!selectedFile">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <div *ngIf="selected.value == 3">
        <button mat-icon-button matTooltip="{{'button_title_assignment' | translate}}" (click)="addCoworker()">
            <mat-icon>add</mat-icon>
        </button>
        <!-- button mat-icon-button matTooltip="{{'button_title_delete' | translate}}" (click)="deleteCoworker()" [disabled]="!selectedCoworker">
            <mat-icon>delete</mat-icon>
        </button -->
    </div>
    <div style="width:100%">
    <h2 mat-dialog-title>{{workflowElement.fix_code ? (workflowElement.fix_code | translate) : workflowElement.caption}} ({{ workflowElement.workflow_element_group.fix_code ? ( workflowElement.workflow_element_group.fix_code | translate ) : workflowElement.workflow_element_group.caption }}, {{workflowElement.workflow_element_type_sid | translate}})</h2>
    </div>
</mat-toolbar>
<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" animationDuration="0ms">
    <mat-tab label="{{'workflows_workflow-element-generic-data-' | translate}}">
        <div class="table-content" id="generic-data">
            <div class="column">
                <div class="table-header">
                    <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                        {{'common_title_title' | translate}}
                    </div>
                    <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                        {{'common_title_value' | translate}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflows_task-name' | translate}}
                    </div>
                    <div class="table-text">
                        {{workflowElement.fix_code ? (workflowElement.fix_code | translate) : workflowElement.caption}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflows_workflow-element-main-group' | translate}}
                    </div>
                    <div class="table-text">
                        {{workflowElement.workflow_element_group.fix_code ? ( workflowElement.workflow_element_group.fix_code | translate ) : workflowElement.workflow_element_group.caption}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflows_timing-code' | translate}}
                    </div>
                    <div class="table-text">
                        {{workflowElement.workflow_element_time_sid | translate}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflows_task-start' | translate}}
                    </div>
                    <div class="table-text">
                        {{workflowElement.task_start_date ? createDateString(workflowElement.task_start_date * 1000 ) : ""}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflows_task-deadline' | translate}}
                    </div>
                    <div class="table-text">
                        {{workflowElement.task_end_date ? createDateString(workflowElement.task_end_date * 1000 ) : ""}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflows_type' | translate}}
                    </div>
                    <div class="table-text">
                        {{workflowElement.workflow_element_type_sid | translate}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflows_liable-coworker' | translate}}
                    </div>
                    <div class="table-text">
                        {{(findSelectionItem(coworkerSelectionItems, workflowElement.responsible_coworker_id)?.name)}}
                    </div>
                </div>
                

            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{'workflows_journal-entries' | translate}}">
        <div class="table-content">
            <div class="column">
                <div class="table-header">
                    <div class="table-text" [style.width]="'55%'">
                        {{'workflows_text' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{'workflows_recording-time' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                        {{'workflows_recording-user' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'5%'">
                        
                    </div>
                </div>
                <div *ngFor="let entry of logEntries" class="table-item" (click)="selectedEntry == entry ? selectedEntry = null : selectedEntry = entry" [class.selected]="selectedEntry == entry">
                    <div class="table-text" [style.width]="'55%'" [style.justify-content]="'flex-start'" (click)="openEntry(entry)">
                        {{entry.content}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{entry.created_at ? createDateString(entry.created_at * 1000) : ""}}
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                        {{createName(entry.coworker)}}
                    </div>
                    <div class="table-text" [style.width]="'5%'">
                        <span class="material-icons" (click)="deleteEntry(entry)" [style.color]="'red'">clear</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; align-items: baseline;">
            <mat-form-field appearance="outline" style="margin: 0 10px; width: 100%;">
                <mat-label>{{'workflows_personal-comment' | translate}}</mat-label>
                <input matInput [formControl]="logEntry">
        </mat-form-field>
        <button mat-icon-button matTooltip="{{'button_title_send' | translate}}" (click)="saveLogEntry()">
            <mat-icon>send</mat-icon>
        </button>
    </div>

    </mat-tab>
    <mat-tab label="{{'workflows_file-system' | translate}}">
        <div class="table-content">
            <div class="column">
                <div class="table-header">
                    <div class="table-text" [style.width]="'20%'">
                        {{'workflows_folder-name' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{'workflows_stock-name' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{'workflows_comment' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{'workflows_uploader-user' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{'workflows_uploading-time' | translate}}
                    </div>
                </div>
                <div *ngFor="let folder of flatFolders" class="table-item" (click)="selectedFile == folder ? this.selectedFile = null : this.selectedFile = folder" [class.selected]="selectedFile == folder">
                    <div class="table-text" [style.width]="'20%'">
                        <span *ngIf="folder.is_folder">{{folder.name}}</span>
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        <span *ngIf="folder.is_file">{{folder.name}}</span>
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{folder.comment}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{folder.uploader}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{folder.uploadTime}}
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{'workflows_coworkers' | translate}}">
        <div class="table-content">
            <div class="column">
                <div class="table-header">
                    <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                        {{'common_title_name' | translate}}
                    </div>
                    <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'25%'">
                        {{'workflows_comulate-time' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        {{'common_title_independent' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                        {{'common_title_statement_management' | translate}}
                    </div>
                </div>
                <div *ngFor="let coworker of coworkers" class="table-item" (click)="selectedCoworker == coworker ? selectedCoworker = null : selectedCoworker = coworker" [class.selected]="selectedCoworker == coworker">
                    <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                        {{createName(coworker)}}
                    </div>
                    <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'25%'">
                        {{coworker.hours}}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        {{coworker.is_stated ? (coworker.is_independent ? "Igen" : "Nem ") : ""}}
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                        <ng-container *ngIf="coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_edit_statement'|translate}}" (click)="uploadFile(coworker, coworker.is_stated)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_upload_statement'|translate}}" (click)="uploadFile(coworker, false)">
                                <mat-icon>upload</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_view_statement'|translate}}" (click)="downloadFile(coworker.id)">
                                <mat-icon>download</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_download_template'|translate}}" (click)="downloadTemplateFile()">
                                <mat-icon>image</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="table-item" [style.border-top]="'1px solid rgba(0, 0, 0, 0.12)'">
                    <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                        {{'workflows_all-coworker' | translate}}
                    </div>
                    <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'25%'">
                        {{coworkerHours}}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
