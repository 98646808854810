import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxNumber'
})
export class TaxNumberPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    const stringValue = value.toString(10);
    if (stringValue.length === 11) {
      return stringValue.slice(0, 8) + '-' + stringValue.slice(8, 9) + '-' + stringValue.slice(9, 11);
    } else {
      return stringValue.toString();
    }
  }

}
