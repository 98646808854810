import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileSystemService } from '../../../services/file-system.service';

@Component({
  selector: 'app-file-uploader-editor',
  templateUrl: './file-uploader-editor.component.html',
  styleUrls: ['./file-uploader-editor.component.less']
})
export class FileUploaderEditorComponent implements OnInit {

  fileForm: FormGroup = this.formBuilder.group({
    caption: [null, []],
    file: [null, [Validators.required]],
  });

  _folderNode: any;
  get folderNode() { return this._folderNode };
  set folderNode(value: any) {
    this._folderNode = value;
  }

  _customer: any;
  get customer() { return this._customer };
  set customer(value: any) {
    this._customer = value;
  }

  _customerId: any;
  get customerId() { return this._customerId };
  set customerId(value: any) {
    this._customerId = value;
  }

  _label: boolean;
  get label() { return this._label };
  set label(value: any) {
    this._label = value;
  }
  _contractId: boolean;
  get contractId() { return this._contractId };
  set contractId(value: any) {
    this._contractId = value;
  }

  _template: any;
  get template() { return this._template };
  set template(value: any) {
    this._template = value;
  }

  _workflowId:any;
  get workflowId() {return this._workflowId};
  set workflowId(value:any){
    this._workflowId = value;
  }
 
  _locale:any;
  get locale() {return this._locale};
  set locale(value:any){
    this._locale = value;
  }
  constructor(private formBuilder: FormBuilder, public http: HttpClient, private snackBar: MatSnackBar,  public dialogRef: MatDialogRef<FileUploaderEditorComponent>, private service: FileSystemService) { }


  ngOnInit(): void {
  }

  async saveFile(){
    if (this.fileForm.valid) {

      let content;
      if (this.fileForm.get("file").value instanceof File){
        content = await this.toBase64String(this.fileForm.get("file").value);
        content = content.split(",")[1];
      }

      let body = {
        caption: this.fileForm.get("caption").value,
        content: content,
        customer_id: this.customer,
        name: this.fileForm.get("file").value.name,
        parent_path: this.folderNode.path
      }


     this.service.addFile(body).subscribe((response) => {
      this.dialogRef.close({path: this._folderNode.path, response: response});
     });
    }
    else{
      this.fileForm.markAllAsTouched();
    }
  }

  async uploadFile(){
    console.log(this._workflowId);
    if (this.fileForm.valid) {
      console.log("valid");
      let content;
      if (this.fileForm.get("file").value instanceof File){
        content = await this.toBase64String(this.fileForm.get("file").value);
        content = content.split(",")[1];
      }

      

      if(this._workflowId){
        console.log("workflow id");
        let body = {
          content: content,
          workflow_id: this._workflowId,
          name: this.fileForm.get("file").value.name,
          locale: this._locale
        }
        console.log(body);
        this.service.addAuditFile(body).subscribe((response) => {
          this.dialogRef.close({response: response});
         });
      }else{
        console.log("else");
        let template_code = "";
        let subtemplate_code = "";
        if(this._template.template_code){template_code=this._template.template_code}else{template_code=this._template.path.split("/")[1]}
        if(this._template.subtemplate_code){subtemplate_code=this._template.subtemplate_code}else{subtemplate_code=this._template.subtemplate_id}
        let body = {
          content: content,
          customer_id: this.customer,
          name: this.fileForm.get("file").value.name,
          locale: this._template.locale,
          subtemplate_id: +subtemplate_code,
          template_code: template_code,
          contract_id: this._contractId
        }
        console.log(body);
        this.service.addFile(body, true).subscribe((response) => {
          this.dialogRef.close({response: response});
        });
      }
    }
    else{
      console.log("invalid");
      this.fileForm.markAllAsTouched();
    }
  }

  toBase64String(file: File){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
    });
  }
}
