<h2 mat-dialog-title>{{'workflows_opened-item-list-choice' | translate}}</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_company-name' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.companyName}} </td>
      </ng-container>
      <ng-container matColumnDef="receiptNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_invoice-number' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.receiptNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="supplierBuyer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_supplier-purchaser' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.supplierBuyer}} </td>
      </ng-container>
      <ng-container matColumnDef="receiptDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_invoice-date' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.receiptDate}}</td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_foreign-currency' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.currency}} </td>
      </ng-container>
      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_total-price' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.totalAmount | thousandsFormat}} </td>
      </ng-container>
      <ng-container matColumnDef="openBalance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'workflows_opened-balance' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.openBalance | thousandsFormat}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
      </tr>
    </table>
  
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
  <button mat-button (click)="save()" class="positiveButton">{{'button_title_save' | translate}}</button>
  <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_back' | translate}}</button>
</mat-dialog-actions>