<h2 mat-dialog-title>{{'common_title_filter_table_columns' | translate }}</h2>
<mat-dialog-content style="padding-bottom: 1rem;">
    <form [formGroup]="tableColumnForm">
        <div *ngFor="let column of tableColumns" style="margin: 0.5rem 0;">
            <mat-checkbox formControlName="{{column.value}}">{{column.name | translate}}</mat-checkbox>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button class="positiveButton" (click)="saveTableColumns()">
        {{'button_title_save' | translate}}
    </button>
    <button mat-button class="negativeButton" (click)="dialogRef.close()">{{'button_title_cancel' | translate}}</button>
</mat-dialog-actions>
