<h2 mat-dialog-title>{{workflowGroupForm.controls.id.value ? ('workflow_element_groups_workflow-modification' | translate) : ('workflow_element_groups_workflow-creation' | translate)}}</h2>
<mat-dialog-content>
    <form [formGroup]="workflowGroupForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_element_groups_workflow-name' |translate}}</mat-label>
            <input matInput formControlName="caption" required>
            <mat-error *ngIf="workflowGroupForm.controls.caption.hasError('required')">
                {{'error_message_name_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <app-select-search [selectionItems]="timeCodeSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_timing-code' | translate" [selectCtrl]="workflowGroupForm.controls.workflow_element_group_time_sid" [required]="true">
        </app-select-search>
        <app-select-search [selectionItems]="workflowGroupSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_element_groups_after-next' |translate" [placeholderError]="'Workflow-elem'" [selectCtrl]="workflowGroupForm.controls.order" [required]="workflowGroupForm.controls.isFirst.value != true" [disabled]="this.workflowGroupForm.get('order').disabled">
        </app-select-search>

        <mat-checkbox formControlName="isFirst" (change)="changeIsFirst($event.checked)" style="display: inline-block; margin-bottom: 0.5rem;" labelPosition="before">{{'workflow_element_groups_be-first' | translate}}</mat-checkbox>

    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveWorkflowGroup()" class="positiveButton">{{'workflow_element_groups_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'workflow_element_groups_reject' | translate}}</button>
</mat-dialog-actions>