import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profile } from 'src/app/data/profile';
import { environment } from 'src/environments/environment';
import { TaxNumberPipe } from '../../../pipes/tax-number.pipe';
import { RegNumberPipe } from '../../../pipes/reg-number.pipe';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.less']
})
export class ProfileEditorComponent implements OnInit {

  profileForm: FormGroup = this.formBuilder.group({
    postal_code: [null, [Validators.required]],
    city: [null, [Validators.required]],
    postal_address: [null, [Validators.required]],
    registration_code: [null, [Validators.required]],
    chamber_code: [null],
    tax_number: [null, [Validators.required]],
    email_address: [null, [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    business_code: null,
    country_iso_code: null,
    name: null,
    logo: [null],
    locale: null
  });

  _profile: Profile;
  get profile() { return this._profile };
  set profile(value: Profile) {
    this._profile = value;
    this.profileForm.patchValue(value);
  }

  translatableTexts: any[] = [
    {value: "error_message_error_modifying_logo", name: ""},
    {value: "error_message_own_data_edit_unsuccessful", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ProfileEditorComponent>, public taxNumberPipe: TaxNumberPipe,  public regNumberPipe: RegNumberPipe, public http: HttpClient, private snackBar: MatSnackBar, private el: ElementRef, private dialogService: MatDialog, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.stream(['error_message_error_modifying_logo', 'error_message_own_data_edit_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_modifying_logo", name: res["error_message_error_modifying_logo"]},
        {value: "error_message_own_data_edit_unsuccessful", name: res["error_message_own_data_edit_unsuccessful"]}
      ];
    });
  }

  async saveProfile(){
    if (this.profileForm.valid) {
      let content = null;
      if (this.profileForm.get("logo").value){
        content = await this.toBase64String(this.profileForm.get("logo").value);
      }
      this.save().then((response)=>{
        if (content){
          if (content == this.profile.logo){
            this.dialogRef.close({response: response, logo: {logo_content: content.split(",")[1], logo_mime: content.split(",")[0].split(":")[1].split(";")[0]}});
          }else{
            this.saveLogo(content.split(",")[1]).then((logoResp)=>{
              if (logoResp){
                if (logoResp.type == "OK"){
                  this.dialogRef.close({response: response, logo: {logo_content: content.split(",")[1], logo_mime: content.split(",")[0].split(":")[1].split(";")[0]}});
                } 
                else {
                  this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_modifying_logo").name, null, {panelClass: 'error-snackbar'});
                  let dialog = this.dialogService.open(ErrorDialogComponent);
                  dialog.componentInstance.error = logoResp.payload;
                }
              }
            })
          }
        }
        else{
          if (this.profile.logo){
            this.saveLogo(content).then((logoResp)=>{
              if (logoResp){
                if (logoResp.type == "OK"){
                  this.dialogRef.close({response: response});
                } 
                else {
                  this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_modifying_logo").name, null, {panelClass: 'error-snackbar'});
                  let dialog = this.dialogService.open(ErrorDialogComponent);
                  dialog.componentInstance.error = logoResp.payload;
                }
              }
            })
            /*.catch((logoResp) => {
              this.dialogRef.close({response: response})
            })*/
          }else{
            this.dialogRef.close({response: response})
          }
        }
      })
    } else {
      this.profileForm.markAllAsTouched();
      let firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
      this.scrollToFirstInvalidControl(firstInvalidControl);
    }
  }

  save() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.serverEndpoint + '/owner/ownparams', this.profileForm.getRawValue())
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_own_data_edit_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  saveLogo(logo_content){
    return this.http.put<any>(environment.serverEndpoint + '/owner/logo', {logo_content: logo_content})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_modifying_logo").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  private scrollToFirstInvalidControl(firstInvalidControl) {
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  toBase64String(file: File){
    if (file instanceof File){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onerror = error => reject(error);
      });
    }
    else{
      return file;
    }
  }

  validateFileType(event){
    if (event){
      setTimeout(()=>{this.profileForm.get("logo").setErrors({'incorrect': true})},0)
    }
    else{
      this.profileForm.get("logo").setErrors(null)
    }

    this.profileForm.get("logo").updateValueAndValidity();
 
  }

}
