<h2 mat-dialog-title *ngIf="this.debitbalance">{{this.title}}</h2>
<h2 mat-dialog-title *ngIf="!this.debitbalance">{{'error_message_error' | translate}}</h2>
<mat-dialog-content *ngIf="!this.debitbalance">
    {{ (this.error.payload.code | translate) != this.error.payload.code ? (this.error.payload.code | translate) : 'error_message_attention_an_error_occured_during_the_operation' | translate}}
</mat-dialog-content>
<!-- mat-dialog-content>
    <textarea rows="10" cols="100" readonly *ngIf="error.payload">{{error.payload | json}}</textarea>
    <textarea rows="10" cols="100" readonly *ngIf="!error.payload">{{error | json}}</textarea>
</mat-dialog-content -->
<mat-dialog-actions [style.justify-content]="'center'" *ngIf="!this.debitbalance">
     <button mat-button mat-dialog-close>{{'error_message_close' | translate}}</button>
     <button mat-button (click)="detailWindowOpen()">{{'error_message_technical_informations' | translate}}</button>
</mat-dialog-actions>