import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { LogEntryDialogComponent } from '../log-entry-dialog/log-entry-dialog.component';
import { FileSystemComponent } from '../../file-system/file-system.component';
import { WorkflowTemplateAddCoworkerComponent } from '../../workflow-templates/workflow-template-add-coworker/workflow-template-add-coworker.component';
import { BalanceStatementComponent } from '../balance-statement/balance-statement.component';
import { TranslateService } from '@ngx-translate/core';
import { UserWfsService } from '../../../services/user-wfs.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkflowElementAddCoworkerComponent } from '../workflow-element-add-coworker/workflow-element-add-coworker.component';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import {CredentialsService} from "../../../services/credentials.service";
import {WorkflowElementCoworkerComponent} from "../../workflow-templates/workflow-element-coworker/workflow-element-coworker.component";
import {FileSystemService} from "../../../services/file-system.service";
import {FileUploaderIndependentEditorComponent} from "../../file-system/file-uploader-independent-editor/file-uploader-independent-editor.component";
import {TemplateService} from "../../../services/template.service";

@Component({
  selector: 'app-workflow-element-detail',
  templateUrl: './workflow-element-detail.component.html',
  styleUrls: ['./workflow-element-detail.component.less']
})
export class WorkflowElementDetailComponent implements OnInit {

  @Output() isElementDetailEvent = new EventEmitter<any>();

  status = new FormControl();
  selected = new FormControl(0);
  logEntry = new FormControl();

  @Input() workflowElement;
  @Input() workflowMainGroupSelectionItems;
  @Input() coworkerSelectionItems;
  @Input() workflow;
  @Input() contracts;
  @Input() customer_id;
  @Input() customer_currency_iso_code;
  statusSelectionItems = [{value: "WFE_STAT_ACTIVE", name: "Aktív"}, {value: "WFE_STAT_INACTIVE", name: "Inaktív"}];

  selectedEntry;

  logEntries;

  selectedFile;
  folders = [];
  /*
  folders = [
    {
      name: "Mappa 1",
      is_folder: true,
      childs: [
        {
          is_file: true,
          name: "Állomány 1",
          comment: "Megjegyzés",
          uploader: "Vezetéknév Keresztnév",
          uploadTime: "2021.01.01."
        },
        {
          is_file: true,
          name: "Állomány 2",
          comment: "",
          uploader: "Vezetéknév Keresztnév",
          uploadTime: "2021.01.01."
        }
      ]
    },
    {
      name: "Mappa 2",
      is_folder: true,
      childs: [
        {
          is_file: true,
          name: "Állomány 3",
          comment: "Megjegyzés",
          uploader: "Vezetéknév Keresztnév",
          uploadTime: "2021.01.01."
        },
        {
          is_file: true,
          name: "Állomány 4",
          comment: "",
          uploader: "Vezetéknév Keresztnév",
          uploadTime: "2021.01.01."
        }
      ]
    }
  ]*/
  flatFolders = [];

  folderTemplate = {
    name: "",
    is_folder: true,
    childs: []
  }
  childTemplate = {
    name: "",
    uploader: "",
    uploadTime: "",
    comment: "",
    is_file: true,
    parent: ""
  }

  selectedCoworker;
  coworkers;
  coworkerHours = 0;

  translatableTexts: any[] = [
    {value: "common_message_workflow_element_save_successful", name: ""},
    {value: "error_message_workflow_element_save_unsuccessful", name: ""},
    {value: "common_message_attention", name: ""},
    {value: "common_message_are_you_sure_you_want_to_delete_the_log_entry", name: ""},
    {value: "1", name: ""},
    {value: "0", name: ""},
    {value: "common_message_log_entry_delete_successful", name: ""},
    {value: "error_message_log_entry_delete_unsuccessful", name: ""},
    {value: "common_message_log_entry_save_successful", name: ""},
    {value: "error_message_log_entry_save_unsuccessful", name: ""},
    {value: "common_message_coworker_save_successful", name: ""},
    {value: "error_message_coworker_save_unsuccessful", name: ""},
    {value: "common_message_coworker_delete_successful", name: ""},
    {value: "error_message_coworker_delete_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private translateService: TranslateService, private userWfsService: UserWfsService, public templateService: TemplateService, private fileSystemService: FileSystemService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.status.setValue(this.workflowElement.workflow_element_status_sid);
    this.userWfsService.getWfeComments(this.workflowElement.id).subscribe((response)=> {
      this.logEntries = [];
      response.payload.content.forEach((content) => {
        if(content.is_active)this.logEntries.push(content);
      })
      //this.logEntries = response.payload.content;
    })

    this.getCoworkers();

    this.folders = [];

    this.getFilesAndFolders();



    //this.userWfsService.getWfeFiles(this.workflowElement.id).subscribe((response) => {})

    //TODO
    //this.userWfsService.getWfElement((this.workflowElement.id)).subscribe((response) => {})

    this.translateService.stream([
      'common_message_workflow_element_save_successful',
      'error_message_workflow_element_save_unsuccessful',
      'common_message_attention',
      'common_message_are_you_sure_you_want_to_delete_the_log_entry',
      '1',
      '0',
      'common_message_log_entry_delete_successful',
      'error_message_log_entry_delete_unsuccessful',
      'common_message_log_entry_save_successful',
      'error_message_log_entry_save_unsuccessful',
      'common_message_coworker_save_successful',
      'error_message_coworker_save_unsuccessful',
      'common_message_coworker_delete_successful',
      'error_message_coworker_delete_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_workflow_element_save_successful", name: res["common_message_workflow_element_save_successful"]},
        {value: "error_message_workflow_element_save_unsuccessful", name: res["error_message_workflow_element_save_unsuccessful"]},
        {value: "common_message_attention", name: res["common_message_attention"]},
        {value: "common_message_are_you_sure_you_want_to_delete_the_log_entry", name: res["common_message_are_you_sure_you_want_to_delete_the_log_entry"]},
        {value: "1", name: res["1"]},
        {value: "0", name: res["0"]},
        {value: "common_message_log_entry_delete_successful", name: res["common_message_log_entry_delete_successful"]},
        {value: "error_message_log_entry_delete_unsuccessful", name: res["error_message_log_entry_delete_unsuccessful"]},
        {value: "common_message_log_entry_save_successful", name: res["common_message_log_entry_save_successful"]},
        {value: "error_message_log_entry_save_unsuccessful", name: res["error_message_log_entry_save_unsuccessful"]},
        {value: "common_message_coworker_save_successful", name: res["common_message_coworker_save_successful"]},
        {value: "error_message_coworker_save_unsuccessful", name: res["error_message_coworker_save_unsuccessful"]},
        {value: "common_message_coworker_delete_successful", name: res["common_message_coworker_delete_successful"]},
        {value: "error_message_coworker_delete_unsuccessful", name: res["error_message_coworker_delete_unsuccessful"]}
      ];
    });
  }

    getCreatedDate(created_at){
        var a = new Date(created_at * 1000);
        var year = a.getFullYear();
        var month = (a.getMonth()<=9?"0":"") + a.getMonth();
        var date = (a.getDate()<=9?"0":"") + a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = year + '.' + month + '.' + date + ' ' + hour + ':' + min + ':' + sec ;
        return time;
    }

    getFilesAndFolders(){
    this.flatFolders = [];
    this.folders = [];
    this.userWfsService.getWfeFilesFlat(this.workflowElement.id).subscribe((response)=> {
      if(response){
        if(response.type=="OK"){
          let actFolder = Object.create(this.folderTemplate);
          actFolder.childs.length = 0;
          let actFile = Object.create(this.childTemplate);
          actFile = this.childTemplate;
          let actFolderName = "";
          for(var i = 0; i<response.payload.length;i++){
            let item = response.payload[i];
            if(item.parent_path != actFolderName){
              if(actFolder.childs.length>0){
                this.folders.push(actFolder);
              }
              actFolderName = item.parent_path;
              actFolder = Object.create(this.folderTemplate);
              actFolder.childs.length = 0;
              actFolder.name=item.parent_path;
            }
            actFile.name = item.name;
            actFile.comment = item.caption;
            actFile.uploader = item.coworker.first_name+", "+item.coworker.last_name;
            actFile.uploadTime = this.getCreatedDate(item.upload_date); //toLocaleDateString(localStorage.getItem("language").replace("_", "-"));
            actFile.parent = item.parent_path;
            actFolder.childs.push(actFile);
            actFile = Object.create(this.childTemplate)
          }
          if(actFolder.childs.length>0){
            this.folders.push(actFolder);
          }
          this.recursiveGetFolderFromArray(this.folders);
          // console.log(this.folders);
        }
      }
    });
  }
  goBack(){
    this.isElementDetailEvent.emit({value: false, element:this.workflowElement});
  }

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

  createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }

  showBalanceStatement() {
    const dialog = this.dialogService.open(BalanceStatementComponent);
    dialog.componentInstance.customer_id = this.customer_id;
    dialog.componentInstance.customer_currency_iso_code = this.customer_currency_iso_code;
    dialog.componentInstance.workflow_element_id = this.workflowElement.id;
    /*this.userWfsService.getBalanceParam(this.workflowElement.id).subscribe((response) => {
      if(response) {
        if (response.type == "OK") {
          const dialog = this.dialogService.open(BalanceStatementComponent);
          dialog.componentInstance.customer_id = this.customer_id;
          dialog.componentInstance.workflow_element_id = this.workflowElement.id;
        }else{
          this.snackBar.open("Az egyenlegközlő paramétereit nem sikerült lekérdezni!", null, {panelClass: 'error-snackbar'});
        }
      }
    })*/
  }

  saveWorkflowElementStatus(){
    let wfElem = JSON.parse(JSON.stringify(this.workflowElement))
    wfElem.workflow_element_status_sid = this.status.value;
    this.userWfsService.modifyWfElement(wfElem).subscribe((response) =>{
      if (response){
        if (response.type == "OK"){
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_element_save_successful").name, null, {panelClass: 'success-snackbar'});
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }
    })
  }

  openEntry(entry){
    const dialog = this.dialogService.open(LogEntryDialogComponent);
    dialog.componentInstance.text = entry.content;
  }

  deleteEntry(entry){
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = this.translatableTexts.find(element => element.value === "common_message_attention").name;
    dialog.componentInstance.description = this.translatableTexts.find(element => element.value === "common_message_are_you_sure_you_want_to_delete_the_log_entry").name;
    dialog.componentInstance.positiveButtonText = this.translatableTexts.find(element => element.value === "1").name;
    dialog.componentInstance.negativeButtonText = this.translatableTexts.find(element => element.value === "0").name;
    dialog.afterClosed().subscribe(response => {
      if (response) {
        //TODO DELETE ENTRY
        this.userWfsService.deleteWfeComment(entry.id).then((resp) =>{
          if (resp){
            if (resp.type == "OK"){
              this.userWfsService.getWfeComments(this.workflowElement.id).subscribe((response)=> {
                this.logEntries = [];
                response.payload.content.forEach((content) => {
                  if(content.is_active)this.logEntries.push(content);
                })
                //this.logEntries = response.payload.content;
                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_log_entry_delete_successful").name, null, {panelClass: 'success-snackbar'});
              })
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_log_entry_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
        })
      }
    })
  }

  saveLogEntry(){
    if (this.logEntry.value){
      this.userWfsService.postWfeComment(this.logEntry.value, this.workflowElement.id).subscribe((response) => {
        if (response){
          if (response.type == "OK"){
            this.userWfsService.getWfeComments(this.workflowElement.id).subscribe((resp)=> {
              this.logEntries = [];
              resp.payload.content.forEach((content) => {
                if(content.is_active)this.logEntries.push(content);
              })
//              this.logEntries = resp.payload.content;
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_log_entry_save_successful").name, null, {panelClass: 'success-snackbar'});
              this.logEntry.setValue(null);
            })
          }
          else{
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_log_entry_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
            let dialog = this.dialogService.open(ErrorDialogComponent);
            dialog.componentInstance.error = response.payload;
          }
        }
      })
    }
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  recursiveGetFolderFromArray(items: any) {
    items.forEach((item: any)=> {
      this.flatFolders.push(item)
      if(item.childs && item.childs.length > 0){
        this.recursiveGetFolderFromArray(item.childs);
      }
    })
  }

  downloadTemplateFile(){
    let template = {
      subtemplate_id: 0,
//	subtemplate_id: this.template.contracts.subtemplate_id,
      template_code: "TEMPLATE_CODE_FUGNYIL"
    }

    this.templateService.getTemplate(template).then(response =>{
      var byteCharacters = atob(response.payload.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new File([byteArray], `${template.template_code}-template`, { type: response.payload.mime_type});
//      var fileURL = URL.createObjectURL(file);
//      window.open(fileURL);
      var link = document.createElement("a");
      var fileURL = URL.createObjectURL(file);        
      link.href = fileURL;
      link.download = response.payload.name;
      link.click();       
    })
  }

  dnFile(){
    let fileName = this.selectedFile.name;
    let filePath = "/"+this.selectedFile.parent;
    this.fileSystemService.getFile(filePath, fileName,this.customer_id).then(response =>{
      var byteCharacters = atob(response.payload.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new File([byteArray], fileName, {type: response.payload.mime_type});
//      var fileURL = URL.createObjectURL(file);
//      window.open(fileURL);
      var link = document.createElement("a");
      var fileURL = URL.createObjectURL(file);        
      link.href = fileURL;
      link.download = response.payload.name;
      link.click();       
    })
  }

  downloadFile(coworkerId){
    let body = {
      coworker_id: coworkerId,
      workflow_id: this.workflow.id
    }
    // console.log(body);
    this.templateService.getIndependence(body).then(response =>{
      var byteCharacters = atob(response.payload.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new File([byteArray], `template`, { type: response.payload.mime_type});
//      var fileURL = URL.createObjectURL(file);
//      window.open(fileURL);
      var link = document.createElement("a");
      var fileURL = URL.createObjectURL(file);        
      link.href = fileURL;
      link.download = response.payload.name;
      link.click();       
    })
  }

  uploadFile(coworker, isStated){
    const dialog = this.dialogService.open(FileUploaderIndependentEditorComponent);
    dialog.componentInstance.coworkerId = coworker.id;
    dialog.componentInstance.workflowId = this.workflow.id;
    dialog.componentInstance.isStated = isStated;
    dialog.afterClosed().subscribe(closeResponse => {
      if(closeResponse) {
        coworker.is_stated = true;
        coworker.is_independent = closeResponse;
      }
    });
  }

  addFile(){
    const dialog = this.dialogService.open(FileSystemComponent);
    dialog.componentInstance.isInDialog = true;
    dialog.componentInstance.customer_id = this.customer_id;
    dialog.componentInstance.workflow_element_id = this.workflowElement.id;
    dialog.afterClosed().subscribe(() => this.getFilesAndFolders());
  }

  deleteFile(){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('workflows_file-workflow-element-assignment-remove-confimation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        let payload = {parent_path : this.selectedFile.parent, name : this.selectedFile.name, id : this.workflowElement.id};
        this.userWfsService.removeFile(this.workflowElement.id, this.selectedFile.name, this.selectedFile.parent).then((resp:any)=>{
          if(resp){
            if(resp.type=="OK"){
              this.getFilesAndFolders();
            }
          }
        })
      }
    })
  }

  getCoworkers(){
    this.coworkerHours = 0;
    this.coworkers = [];
    this.userWfsService.getWfeCoworkers(this.workflowElement.id).subscribe((resp) => {
      resp.payload.content.forEach(cow => {
        if(cow.is_active && cow.first_name != undefined){
          this.coworkers.push(cow);
          this.coworkerHours += cow.hours;
        }
      })
      /*
      this.coworkers = resp.payload.content;
      this.coworkers.forEach((coworker)=>{
        this.coworkerHours += coworker.hours;
      })
      */
    })
  }

  addCoworker(){
    this.userWfsService.getWfCoworkers(this.workflow.id).subscribe((response) => {
      if(response) {
        const dialog = this.dialogService.open(WorkflowElementCoworkerComponent);
        dialog.componentInstance.allCoworkers = response.payload.content;
        dialog.componentInstance.wfElement = this.workflowElement;
        dialog.afterClosed().subscribe(response => {
          if (response) {
            if (response.type == "OK") {
              this.userWfsService.getWfes(this.workflowElement.workflow_id).subscribe((response) => {
                response.payload.content.forEach((element)=> {
                  if (element.id = this.workflowElement.id) {
                    this.workflowElement = element;
                    this.coworkers = element.coworkers;
                  }
                });
                this.snackBar.open("A workflow-elemhez tartozó munkatársak mentése sikeres", null, {panelClass: 'success-snackbar'});
              })
            }
            else {
              this.snackBar.open("A workflow-elemhez tartozó munkatársak mentése sikertelen", null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = response.payload;
            }
          }
        })
      }
    });
    /*
    const dialog = this.dialogService.open(WorkflowElementAddCoworkerComponent);
    dialog.componentInstance.coworkerSelectionItems = this.coworkerSelectionItems;
    dialog.componentInstance.workflowElement = this.workflowElement;
    dialog.afterClosed().subscribe(response => {
      if (response){
        if (response.type == "OK") {
          this.getCoworkers();
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_save_successful").name, null, {panelClass: 'success-snackbar'});
          this.selectedCoworker = null;
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }
      
    })*/
  }

  deleteCoworker(){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('coworkers_remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.userWfsService.deleteWfeCoworkers({coworker_id:this.selectedCoworker.id, id: this.workflowElement.id}).then((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.getCoworkers();
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_delete_successful").name, null, {panelClass: 'success-snackbar'});
              this.selectedCoworker = null;  
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
          
        });
      }
    })
  }


}
