import {Injectable} from '@angular/core';
//import {Buffer} from 'buffer';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {AIWorkflowTask, ProgressService} from './progress.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthResponse} from '../data/login';
import {ApiEndpoint} from 'src/environments/api-endpoint';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class FileSystemService implements OnInit {

    translatableTexts: any[] = [
        {value: "error_message_error_getting_folders", name: ""},
        {value: "error_message_user_get_unsuccessful", name: ""},
        {value: "error_message_folder_delete_unsuccessful", name: ""},
        {value: "error_message_error_getting_files", name: ""},
        {value: "error_message_error_uploading_file", name: ""},
        {value: "error_message_error_creating_tag", name: ""},
        {value: "error_message_error_applying_master_file", name: ""},
        {value: "error_message_error_applying_detail_file", name: ""},
        {value: "error_message_error_setting_master_file_columns", name: ""},
        {value: "error_message_error_setting_detail_file_columns", name: ""}
    ];

    constructor(private http: HttpClient, private progressService: ProgressService, private snackBar: MatSnackBar, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.translateService.stream([
            'error_message_error_getting_folders',
            'error_message_error_creating_folder',
            'error_message_folder_delete_unsuccessful',
            'error_message_error_getting_files',
            'error_message_error_uploading_file',
            'error_message_error_creating_tag',
            'error_message_error_applying_master_file',
            'error_message_error_applying_detail_file',
            'error_message_error_setting_master_file_columns',
            'error_message_error_setting_detail_file_columns'
        ]).subscribe((res: string) => {
            this.translatableTexts = [
                {value: "error_message_error_getting_folders", name: res["error_message_error_getting_folders"]},
                {value: "error_message_error_creating_folder", name: res["error_message_error_creating_folder"]},
                {
                    value: "error_message_folder_delete_unsuccessful",
                    name: res["error_message_folder_delete_unsuccessful"]
                },
                {value: "error_message_error_getting_files", name: res["error_message_error_getting_files"]},
                {value: "error_message_error_uploading_file", name: res["error_message_error_uploading_file"]},
                {value: "error_message_error_creating_tag", name: res["error_message_error_creating_tag"]},
                {
                    value: "error_message_error_applying_master_file",
                    name: res["error_message_error_applying_master_file"]
                },
                {
                    value: "error_message_error_applying_detail_file",
                    name: res["error_message_error_applying_detail_file"]
                },
                {
                    value: "error_message_error_setting_master_file_columns",
                    name: res["error_message_error_setting_master_file_columns"]
                },
                {
                    value: "error_message_error_setting_detail_file_columns",
                    name: res["error_message_error_setting_detail_file_columns"]
                }
            ];
        });
    }

    getFolders(payload: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get<any>(environment.serverEndpoint + '/user/folders?payload=' + payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_folders").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    addFolder(body) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/folder', body)
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_folder").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    deleteFolder(body) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body
        };
        return this.http.delete<any>(environment.serverEndpoint + '/user/folder', options)
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_folder_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    getFiles(payload: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get<any>(environment.serverEndpoint + '/user/files?payload=' + payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_files").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    addAuditFile(body) {
        const task = this.progressService.addTask(this.constructor.name + '_add_role');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/auditreportfile', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_uploading_file").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        )
    }

    addFile(body, contract: boolean = false) {
        const task = this.progressService.addTask(this.constructor.name + '_add_role');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        if (contract == true) {
            console.log("contract: true");
            console.log(body);
            return this.http.post<any>(environment.serverEndpoint + '/user/contractupload', body, {headers: headers}).pipe(
                catchError(error => {
                    this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_uploading_file").name, null, {panelClass: 'error-snackbar'});
                    console.log(error);
                    return throwError(error);
                }),
                finalize(() => this.progressService.removeTask(task.id))
            )
        }
        return this.http.post<any>(environment.serverEndpoint + '/user/file', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_uploading_file").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    modifyFile(body) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.put<any>(environment.serverEndpoint + '/user/file', body, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_tag").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    deleteFile(file: any, customer_id) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                customer_id: customer_id,
                path: file.path
            },
        };
        return this.http.delete<any>(environment.serverEndpoint + '/user/file', options)
            .toPromise()
            .catch(error => {
                this.snackBar.open("A fájl törlése sikertelen!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    assignFileToWFE(body) {
        const task = this.progressService.addTask(this.constructor.name + '_assign_file_to_wfe');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/wfefile', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a fájl hozzárendelése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    assignFileToGVItem(body) {
        const task = this.progressService.addTask(this.constructor.name + '_assign_file_to_gv_item');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/gvfiletoitem', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a fájl hozzárendelése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    deleteFileFromGVItem(id) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: id
            },
        };
        return this.http.delete<any>(environment.serverEndpoint + '/user/gvfiletoitem', options)
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_gvitem_file_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    setMasterFile(body) {
        const task = this.progressService.addTask(this.constructor.name + '_set_master_file');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/debitbalancemaster', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_applying_master_file").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    setDetailFile(body) {
        const task = this.progressService.addTask(this.constructor.name + '_set_detail_file');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/debitbalancedetail', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_applying_detail_file").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    setMasterDim(body) {
        const task = this.progressService.addTask(this.constructor.name + '_set_detail_dim');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/debitbalancemasterdim', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_setting_master_file_columns").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    setDetailDim(body) {
        const task = this.progressService.addTask(this.constructor.name + '_set_detail_dim');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<any>(environment.serverEndpoint + '/user/debitbalancedetaildim', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_setting_detail_file_columns").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    toBinary(string) {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        const charCodes = new Uint8Array(codeUnits.buffer);
        let result = '';
        for (let i = 0; i < charCodes.byteLength; i++) {
            result += String.fromCharCode(charCodes[i]);
        }
        return result;
    }

    b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        }))
    }

    getFile(path, name, customer_id) {
        let body = {
            parent_path: path,
            name: name,
            customer_id: customer_id
        }
        let payload = this.b64EncodeUnicode(JSON.stringify(body));
        //let payload = btoa(this.toBinary(JSON.stringify(body)));
        //console.log(payload);

        //let payload = Buffer.from(JSON.stringify(body)).toString('base64')
        //console.log(payload);
        //let payload = btoa(this.toBinary(JSON.stringify(body)));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get<any>(environment.serverEndpoint + '/user/file?payload=' + payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open("Hiba a fájl letöltése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    getStaticFile(file_id) {

        let body = {
            file_id: file_id
        }
        let payload = this.b64EncodeUnicode(JSON.stringify(body));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get<any>(environment.serverEndpoint + '/common/staticfile?payload=' + payload, {headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a fájl letöltése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            })
        );
    }

}
