<h2 mat-dialog-title>{{workflowCreateForm.get("id").value ? 'Workflow-elem módosítása' : 'workflow_templates_creation' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="workflowCreateForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_task-name' | translate}}</mat-label>
            <input matInput formControlName="caption" required>
            <mat-error *ngIf="workflowCreateForm.controls.caption.hasError('required')">
                {{'error_message_task_name_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <app-select-search [selectionItems]="workflowMainGroupSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_workflow-element-group' | translate" [selectCtrl]="workflowCreateForm.controls.workflow_element_group_id" [required]="true">
        </app-select-search>

        <!--app-select-search [selectionItems]="timeCodeSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_timing-code' | translate" [selectCtrl]="workflowCreateForm.controls.workflow_element_time_sid">
        </app-select-search -->

        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_task-starting' | translate}}</mat-label>
            <input matInput [disabled]="CredentialsService.role != 'user'" required="true" [imask]="{mask: 'dateformat-mask' | translate}" [matDatepicker]="pickerTaskStartAt" #input_task_start>
            <!-- formControlName="task_start_date" -->
            <mat-datepicker-toggle matSuffix [for]="pickerTaskStartAt"></mat-datepicker-toggle>
            <mat-datepicker #pickerTaskStartAt></mat-datepicker>
            <!-- input matInput type="date" formControlName="task_start_date" required [max]="maxday" -->
            <mat-error *ngIf="workflowCreateForm.controls.task_start_date.hasError('required')">
                {{'error_message_task_start_date_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>


        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_task-deadline' | translate}}</mat-label>
            <input matInput [disabled]="CredentialsService.role != 'user'"  required="true" [imask]="{mask: 'dateformat-mask' | translate}"  [matDatepicker]="pickerTaskEndAt" #input_task_end>
<!--             formControlName="task_end_date" -->
            <mat-datepicker-toggle matSuffix [for]="pickerTaskEndAt"></mat-datepicker-toggle>
            <mat-datepicker #pickerTaskEndAt></mat-datepicker>
            <!--input matInput [type]="'date'" formControlName="task_end_date" required-->
            <mat-error *ngIf="workflowCreateForm.controls.task_end_date.hasError('required')">
                {{'error_message_task_end_date_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_estimated-work-time' | translate}}</mat-label>
            <input matInput [type]="'number'" formControlName="estimated_work_hours">
        </mat-form-field>
        <app-select-search [selectionItems]="typeSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_type' | translate" [selectCtrl]="workflowCreateForm.controls.workflow_element_type_sid" [required]="true"></app-select-search>
        <app-select-search *ngIf="CredentialsService.role == 'user'" [showClearButton]="true" [selectionItems]="coworkerSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_responsible-coworker' | translate" [selectCtrl]="workflowCreateForm.controls.responsible_coworker_id" #coworkerControl>
        </app-select-search>

    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'workflow_templates_reject' | translate}}</button>
    <button mat-button (click)="save()" class="positiveButton">{{workflowCreateForm.get("id").value ? 'Módosítás' : 'workflow_templates_insert' | translate}}</button>
</mat-dialog-actions>
