import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.less']
})
export class OkDialogComponent implements OnInit {
  title = '';
  description = '';
  checkDoc:Boolean;
  positiveButtonText = 'Ok';
  negativeButtonText = 'Cancel';

  translatableTexts: any[] = [
    {value: "button_title_ok", name: ""},
    {value: "button_title_cancel", name: ""},
  ];

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.translateService.stream(['button_title_ok', 'button_title_cancel']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "button_title_ok", name: res["button_title_ok"]},
        {value: "button_title_cancel", name: res["button_title_cancel"]}
      ];
    });

    this.positiveButtonText = this.translatableTexts.find(element => element.value === "button_title_ok").name
    this.negativeButtonText = this.translatableTexts.find(element => element.value === "button_title_cancel").name
  }
}
