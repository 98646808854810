<div class="chatbot-window {{ chatbotWindowStatus }}">
	<button class="chatbot-open" (click)="openChat()">Segítség</button>
	<div class="chatbot-header">
		<span class="chatbot-title">Segítség</span>
		<button title="Problémába ütköztél?" class="chatbot-help" (click)="chatHelp()">?</button>
		<button title="Bezár" class="chatbot-close" (click)="closeChat()"></button>
	</div>
	<div class="chatbot-body {{ chatbotPending }}" #messageList>
	    <div *ngFor="let message of chatbotMessages; let i = index; trackBy: trackById" [class]="messageClassByType(message.type)">
	            <div [innerHtml]="message.message"></div>
	    </div>
	</div>
	<form class="chatbot-form" (submit)="onSubmit($event)">
		<input name="question" type="hidden">
		<div
			class="chatbot-question"
			role="textbox"
			contenteditable="true"
			(keyup.Enter)="getResponse()"
			></div>
		<button (click)="getResponse()">Küld</button>
	</form>
</div>