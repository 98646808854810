import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profile } from 'src/app/data/profile';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { ProfileEditorComponent } from './profile-editor/profile-editor.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {

  objectKeys = Object.keys;

  profile: Profile = {
    postal_code: "",
    city: "",
    postal_address: "",
    chamber_code: "",
    tax_number: "",
    email_address: "",
    registration_code: "",
    logo: ""
  };

  profileData = {
    postal_code: "profile_postal-code", 
    city: "profile_city",
    postal_address: "profile_postal-address",
    chamber_code: "profile_chamber-code",
    tax_number: "profile_tax-number",
    email_address: "profile_email-address",
    registration_code: "profile_registration-number",
    business_code: "profile_business-code",
    country_iso_code: "profile_country-iso-code",
    name: "profile_name",
    locale: "profile_locale",
    logo: "Logo",
  };

  ownParams;
  
  translatableTexts: any[] = [
    {value: "common_message_own_data_edit_successful", name: ""},
    {value: "error_message_own_data_edit_unsuccessful", name: ""},
    {value: "error_message_error_getting_own_data", name: ""}
  ];

  constructor(private dialogService: MatDialog, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService) { }

  ngOnInit(): void {
    Promise.all([this.getProfileData(), this.getLogo()]).then((values)=>{
      this.getProfile(values[0].payload);
      this.profile.logo = values[1].payload.logo_content ?  'data:' + values[1].payload.logo_mime+';base64,' + values[1].payload.logo_content : '';
      this.ownParams.logo = values[1].payload.logo_content ?  'data:' + values[1].payload.logo_mime+';base64,' + values[1].payload.logo_content : '';
    })

    this.translateService.stream(['common_message_own_data_edit_successful', 'error_message_own_data_edit_unsuccessful', 'error_message_error_getting_own_data']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_own_data_edit_successful", name: res["common_message_own_data_edit_successful"]},
        {value: "error_message_own_data_edit_unsuccessful", name: res["error_message_own_data_edit_unsuccessful"]},
        {value: "error_message_error_getting_own_data", name: res["error_message_error_getting_own_data"]}
      ];
    });
  }

  getProfile(data){
      this.ownParams = data;
      this.profile = {
        postal_code: data.postal_code,
        city: data.city,
        postal_address: data.postal_address,
        chamber_code: data.chamber_code,
        tax_number: data.tax_number,
        email_address: data.email_address,
        registration_code: data.registration_code
      };
  }

  getProfileData(){
    return this.http.get<any>(environment.serverEndpoint + '/owner/ownparams')
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_own_data").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  getLogo(){
    return this.http.get<any>(environment.serverEndpoint + '/owner/logo')
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_own_data").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  editProfile() {
    const dialog = this.dialogService.open(ProfileEditorComponent);
    dialog.componentInstance.profile = this.ownParams;
    dialog.afterClosed().subscribe((response)=>{
      if (response){
        if(response.response.type == "OK"){
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_own_data_edit_successful").name, null, {panelClass: 'success-snackbar'});
          this.getProfile(response.response.payload);
          if (response.logo){
            this.profile.logo = response.logo.logo_content ? 'data:'+response.logo.logo_mime+';base64,' + response.logo.logo_content : '';
            this.ownParams.logo = response.logo.logo_content ? 'data:'+response.logo.logo_mime+';base64,' + response.logo.logo_content : '';
          }
          else{
            this.profile.logo = null;
            this.ownParams.logo = null;
          }
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_own_data_edit_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.response.payload;
        } 
      }
      
    })
  }  

}
