<h2 mat-dialog-title>{{(coworkerForm.controls.id.value ? ('coworkers_coworker-modification' | translate) : ('coworkers_new-worker' | translate))}}</h2>
<mat-dialog-content>
    <form [formGroup]="coworkerForm" autocomplete="off">
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_last-name' | translate}}</mat-label>
            <input matInput formControlName="last_name" required>
            <mat-error *ngIf="coworkerForm.controls.last_name.hasError('required')">
                {{'error_message_last_name_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_first-name' | translate}}</mat-label>
            <input matInput formControlName="first_name" required>
            <mat-error *ngIf="coworkerForm.controls.first_name.hasError('required')">
                {{'error_message_first_name_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_username' | translate}}</mat-label>
            <input matInput formControlName="username" required>
            <mat-error *ngIf="coworkerForm.controls.username.hasError('required')">
                {{'error_message_username_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_mail-address' | translate}}</mat-label>
            <input matInput formControlName="email_address" required>
            <mat-error *ngIf="coworkerForm.controls.email_address.hasError('required')">
                {{'error_message_email_address_cannot_be_empty' | translate}}
            </mat-error>
            <mat-error *ngIf="coworkerForm.controls.email_address.hasError('pattern')">
                {{'error_message_email_address_inappropriate_format' | translate}}
            </mat-error>
        </mat-form-field>
        <div>
            <app-select-search [selectionItems]="groupSelectionItems" [selectionType]="'multiSelect'" [placeholder]="'coworkers_role' | translate" [selectCtrl]="coworkerForm.controls.group_ids" [required]="true">
            </app-select-search>
        </div>     
        <div>
            <app-select-search [selectionItems]="qcPartnerSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'coworkers_qc-partner' | translate" [selectCtrl]="coworkerForm.controls.is_qc_partner" [required]="true">
            </app-select-search>
        </div> 
        <div>
            <app-select-search [selectionItems]="statusSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'coworkers_status' | translate" [selectCtrl]="coworkerForm.controls.is_active" [required]="true" [disabled]="coworkerForm.get('is_active').disabled">
            </app-select-search>
        </div>
        <div *ngIf="!coworkerForm.controls.id.value">
            <app-select-search [selectionItems]="languageSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'coworkers_lang' | translate" [selectCtrl]="coworkerForm.controls.locale" [required]="!coworkerForm.controls.id.value">
            </app-select-search>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_chamber-identifier-number' | translate}}</mat-label>
            <input matInput formControlName="chamber_code">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_mother-name' | translate}}</mat-label>
            <input matInput formControlName="mother_name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_phone-number' | translate}}</mat-label>
            <input matInput formControlName="phone_number">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'common_title_address' | translate}}</mat-label>
            <input matInput formControlName="postal_address">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_tax-sign' | translate}}</mat-label>
            <input matInput formControlName="tax_number">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'coworkers_signing-mail' | translate}}</mat-label>
            <input matInput formControlName="signer_mail">
            <mat-error *ngIf="coworkerForm.controls.signer_mail.hasError('pattern')">
                {{'error_message_email_address_inappropriate_format' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveCoworker()" class="positiveButton">{{'coworkers_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'coworkers_reject' | translate}}</button>
</mat-dialog-actions>
