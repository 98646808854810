import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TimesheetDetailComponent } from '../timesheet-detail/timesheet-detail.component';
import { TimesheetEditorComponent } from '../timesheet-editor/timesheet-editor.component';
import {TranslateService} from "@ngx-translate/core";
import {OkDialogComponent} from "../../ok-dialog/ok-dialog.component";
import {TimesheetService} from "../../../services/timesheet.service";

@Component({
  selector: 'app-timesheet-day',
  templateUrl: './timesheet-day.component.html',
  styleUrls: ['./timesheet-day.component.less']
})
export class TimesheetDayComponent implements OnInit {

  _data: any;
  get data() { return this._data };
  set data(value: any) {
    this._data = value;
  }

  timesheetListDay = [];
  displayedColumns: string[] = ['date', 'customer', 'workflow', 'workflow_elem', 'coworker', 'working_hours', 'comment', 'deleteIcon'];

  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(false, []);

  constructor(private timesheetService: TimesheetService, private dialogService: MatDialog, private translateService: TranslateService, public dialogRef: MatDialogRef<TimesheetDayComponent>) { }

  ngOnInit(): void {
    this.data.events.forEach((elem)=>{
      this.timesheetListDay.push(elem.meta.data)
    })
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }
  
  createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }

  createDateString(date, options?){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"), options)
  }

  openDetail(row){
    const dialog = this.dialogService.open(TimesheetDetailComponent);
    dialog.componentInstance.data = row;
  }

  addNewTask(){
    const dialog = this.dialogService.open(TimesheetEditorComponent);
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.dialogRef.close(response);
      }
    });
  }

  removeItem(row){
    const dialog = this.dialogService.open(OkDialogComponent);
    //this.translateService.get('companies_template-send-confirmation').subscribe((value)=>dialog.componentInstance.title = value);
    dialog.componentInstance.title = 'Valóban törli a naptárbejegyzést?';
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.timesheetService.deleteTimesheetItem(row.id).subscribe((resp)=>{
          let respMock = {type:'OK'};
          //this.dialogRef.close(resp);
          this.dialogRef.close(respMock);
        });
      }
    })
  }

}
