import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentsFormat'
})
export class PercentsFormatPipe implements PipeTransform {

    transform(value: any): string {
        if (value) {
            let x = (Math.round(value * 10000)/100 + "").replace(".",",");
            if(x.indexOf(",")==-1)x = x + ",00";
            if(x.indexOf(",")>x.length-3)x = x + "0";
            return x + "%";
        }
        return value;
    }
}