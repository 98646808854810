import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Auditor} from '../data/auditor';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import {ApiEndpoint} from "../../environments/api-endpoint";
import {catchError, finalize} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {CountAnswer, PaginatedAnswer, PaginatedRequest, Response} from "../data/response";
import { Coworker } from '../data/coworker';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService extends BaseService<Coworker> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_timesheets_list_unsuccessful", name: ""},
    {value: "error_message_timesheet_get_unsuccessful", name: ""},
    {value: "error_message_timesheet_delete_unsuccessful", name: ""},
    {value: "error_message_timesheet_create_unsuccessful", name: ""},
    {value: "error_message_timesheet_update_unsuccessful", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
        {
          listUrl: ApiEndpoint.auditorsEndpoint,
          getUrl: ApiEndpoint.auditorsGetEndpoint,
          createUrl: ApiEndpoint.auditorsEndpoint + "/create",
          deleteUrl: '',
          updateUrl: ApiEndpoint.auditorsEndpoint
        },
        {
          listError: 'A timesheetek letöltése sikertelen',
          getError: 'A timesheetek letöltése sikertelen',
          deleteError: 'A timesheetek törlése sikertelen',
          createError: 'A timesheetek létrehozása sikertelen',
          updateError: 'A timesheetek módosítása sikertelen'
        },
        httpClient, snackBar, progressService);
  }

  /*listTimesheets(paginatedRequest): Observable<PaginatedAnswer<Coworker>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(paginatedRequest));
    return this.httpClient.get<PaginatedAnswer<Coworker>>(environment.serverEndpoint + '/user/timesheet?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }*/

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_timesheets_list_unsuccessful',
      'error_message_timesheet_get_unsuccessful',
      'error_message_timesheet_delete_unsuccessful',
      'error_message_timesheet_create_unsuccessful',
      'error_message_timesheet_update_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_timesheets_list_unsuccessful", name: res["error_message_timesheets_list_unsuccessful"]},
        {value: "error_message_timesheet_get_unsuccessful", name: res["error_message_timesheet_get_unsuccessful"]},
        {value: "error_message_timesheet_delete_unsuccessful", name: res["error_message_timesheet_delete_unsuccessful"]},
        {value: "error_message_timesheet_create_unsuccessful", name: res["error_message_timesheet_create_unsuccessful"]},
        {value: "error_message_timesheet_update_unsuccessful", name: res["error_message_timesheet_update_unsuccessful"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_timesheets_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_timesheet_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_timesheet_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_timesheet_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_timesheet_update_unsuccessful").name
  }

  listTimesheets(body): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(body));
    return this.httpClient.get<any>(environment.serverEndpoint + '/user/timesheet?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  deleteTimesheetItem(id: any): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_delete');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id
      },
    };

    return this.httpClient.delete<any>(environment.serverEndpoint + '/user/timesheethours', options).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

}
