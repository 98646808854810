<mat-toolbar color="primary">
    <div>
    </div>
    <div>
        <button mat-icon-button matTooltip="{{'template_new' | translate}}" (click)="editSablon()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'template_modification' | translate}}" [disabled]="!selectedTemplate" (click)="editSablon(selectedTemplate)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'template_remove' | translate}}" [disabled]="!selectedTemplate" (click)="deleteSablon(selectedTemplate)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.width]="'30%'">
                {{'template_type' | translate}}
            </div>
            <div class="table-text" [style.width]="'20%'">
                {{'template_doc-subtype' | translate}}
            </div>
            <div class="table-text" [style.width]="'20%'">
                {{'template_lang' | translate}}
            </div>
            <div class="table-text" [style.width]="'10%'">{{'edit_template' | translate}}</div>
        </div>
     <!-- <ng-container style="margin-left: 50px">
            <div class="table-item-row" *ngFor="let temp of templateObjects">
                <div class="first-column">
                    {{temp.name | translate}}
                </div>
                <div class="second-column" *ngIf="temp.childs.length > 0">
                    <div class="flex-col" *ngFor="let child of temp.childs">
                        <div class="first-half">
                            {{getSubtype(child.child.subtemplate_code) | translate}}
                        </div>
                        <div class="second-half" *ngIf="child.child.childs.length > 0">
                            <div *ngFor="let lang of child.child.childs">
                                {{lang.path.split('/')[3]}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container> -->

        <ng-container>
            <div class="table-item" *ngFor="let template of data; let i = index;">
                <div class="table-text" [style.width]="'30%'" #tableRowType>
                    {{(i == 0 ? template.template_code : data[i].template_code != data[i-1].template_code ? template.template_code : '' )| translate}}
                </div>
                <div class="table-text" [style.width]="'30%'" #tableRowSubtype>
                    {{(i == 0 ? getSubtype(template.subtemplate_code) : (data[i].template_code != data[i-1].template_code || data[i].subtemplate_code != data[i-1].subtemplate_code) ? getSubtype(template.subtemplate_code) : '') | translate}}
                </div>
                <div class="table-text" [style.width]="'10%'">
                    <mat-radio-button
                            (change)="selectTemplate($event, template)"
                            [checked]="template.path == selectedTemplate?.path">
                    </mat-radio-button>
                </div>
                <div class="table-text" [style.width]="'30%'" [style.text-decoration]="'underline'" (click)="openTemplate(template)">
                    {{template.locale}}
                </div>
            </div>
        </ng-container>
    </div>
</div>
