import {Component, OnInit} from "@angular/core";
import {UserWfsService} from "../../services/user-wfs.service";
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.less']
})
export class MobileComponent implements OnInit {

  StateService: any = StateService;

  isWorkflow = false;
  workflows = []

  constructor(private wfService: UserWfsService) { }

  ngOnInit(): void {
    this.wfService.getWfs().subscribe((wfResponse)=>{
      wfResponse.payload.content.forEach((wf)=>{
        if (wf.is_active && wf.workflow_status_sid == "WF_STAT_ACTIVE"){
          this.workflows.push(wf);
        }
      });
    })
    StateService.actualState = ''
  }

  setWorkflow(){
    StateService.actualState = 'WF'
  }


}
