import {Component, OnInit, AfterViewInit} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {AiService} from "../../services/ai.service";

@Component({
  selector: 'app-help-ai',
  templateUrl: './help-ai.component.html',
  styleUrls: ['./help-ai.component.less']
})
export class HelpAiComponent implements OnInit, AfterViewInit {

  helpForm: FormGroup = this.formBuilder.group({
    question: [null, Validators.required]
  });

  session_id = null;
  chats = []

  constructor(private formBuilder: FormBuilder, private service: AiService, public dialogRef: MatDialogRef<HelpAiComponent>) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.goWatson();
  }

  goWatson(){
    if(this.helpForm.controls['question'].value != null)this.chats.push({"type":"Out", "text":this.helpForm.controls['question'].value});
    let szoveg = "Szia BuCo";
    if(this.helpForm.controls['question'].value != null)szoveg = this.helpForm.controls['question'].value;
    let body = {
      "session_id": this.session_id,
      "user_id": "anonymous_IBMuid-5919a9c2-575b-4c32-a4c4-61c5cee69c1d",
      "value": {
        "history":{
          "getWelcome": this.session_id?true:false,
          "silent": true
        },
        "input":{
          "message_type": "text",
          "options":{
            "alternate_responses": false,
            "debug": true,
            "disambiguation":{
              "alternate_responses": false
            }
          },
          "text": szoveg
        },
        "thread_id":"main"
      }
    }
    this.service.postQuestion(body).subscribe((response) => {
      this.helpForm.controls['question'].reset();
      let answer = response.output.generic[0].text;
      this.session_id = response.session_id;
      this.chats.push({"type":"In", "text":answer});
    })
  }

}
