import { Component, OnInit } from '@angular/core';
import {UserWfsService} from "../../services/user-wfs.service";
import {WfTemplateService} from "../../services/wf-template.service";
import {CompanyService} from "../../services/company.service";
import {CredentialsService} from "../../services/credentials.service";

@Component({
  selector: 'app-reporting-component',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.less']
})
export class ReportingComponent implements OnInit {

  reports = [];
  selectedReport: any = null;
  coworkerSelectionItems = [];
  customerSelectionItems = [];
  signerSelectionItems = [];
  workflows = [];
  signers = [];

  constructor(private wfService: UserWfsService, private companyService: CompanyService, private templateService:WfTemplateService) { }

  createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }

  ngOnInit(): void {
    this.getAllCoworkers();
    this.getAllCustomers();
    this.createList();
    this.getAllWorkflows();
    this.getSigners();
  }

  getAllCoworkers(){
    this.templateService.listCoworkers(CredentialsService.role).subscribe((response)=>{
      response.payload.content.forEach((coworker)=>{
        if (coworker.is_active && coworker.first_name != undefined){
          this.coworkerSelectionItems.push({name:this.createName(coworker), value:coworker.id})
        }
      })
    })
  }

  getAllCustomers(){
    this.companyService.getCompanies().subscribe((response)=>{
      response.payload.content.forEach((company)=>{
        if (company.is_active){
          this.customerSelectionItems.push({name: company.short_name, value: company.id})
        }
      })
    });
  };

  getAllWorkflows(){
    this.workflows = [];
    this.wfService.getWfs().subscribe((wfResponse)=>{
      wfResponse.payload.content.forEach((wf)=>{
        if (wf.is_active){
          this.workflows.push(wf);
        }
      });
    })
  }

  getSigners(){
    this.signers = [];
    this.companyService.getSigners().subscribe((response)=>{
      response.payload.forEach((signer)=>{
        if (signer.is_active && !signer.is_system){
          this.signerSelectionItems.push({name:this.createName(signer), value:signer.id})
        }
      })
    });
  }

  createList(){
    this.reports.push({name: "r1", caption: "common_title_coworker_timesheet"});
    this.reports.push({name: "r2", caption: "common_title_client_timesheet"});
    this.reports.push({name: "r3", caption: "common_title_workflow_element_group_plan_fact_report"});
//    this.reports.push({name: "r4", caption: "common_title_workflow_element_group_coworker_report"});
    this.reports.push({name: "r5", caption: "common_title_workflow_element_group_client_report"});
    this.reports.push({name: "r6", caption: "common_title_file_directory_inventory_report"});
    this.reports.push({name: "r7", caption: "common_title_kvv_data_provide_report"});
    this.reports.push({name: "r8", caption: "common_title_list_of_contracts_by_signer_report"});
    this.reports.push({name: "r9", caption: "common_title_balance_sheet_report"});
    this.reports.push({name: "r10", caption: "common_title_coworker_company_relation_report"});
    this.reports.push({name: "r11", caption: "common_title_coworker_workload_report"});
    this.reports.push({name: "r12", caption: "common_title_company_workload_report"});
  }

}
