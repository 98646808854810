import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Template, TemplateDictionary} from "../../../data/template";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.less']
})
export class TemplateEditorComponent implements OnInit {
  typeList: TemplateDictionary[];
  subtypeList: TemplateDictionary[];
  nyelvList: TemplateDictionary[];
  templateForm: FormGroup = this.formBuilder.group({
    template_code: [null, Validators.required],
    subtemplate_id: [null],
    locale: [null, Validators.required],
    content: [null, Validators.required],
  });

  fileName = "Feltöltött sablon";

  _template: any;
  get template() { return this._template };
  set template(value) {
    this.templateForm.patchValue(value);
    this.templateForm.get("subtemplate_id").setValue(+value.subtemplate_code)
    this._template = value;
  }

  _languageSelectionItems: any;
  get languageSelectionItems() { return this._languageSelectionItems };
  set languageSelectionItems(value: any) {
    this._languageSelectionItems = value;
  }

  translatableTexts: any[] = [
    {value: "error_message_error_getting_template", name: ""},
    {value: "error_message_error_creating_template", name: ""},
    {value: "error_message_error_editing_template", name: ""}
  ];

  constructor(public dialogRef: MatDialogRef<TemplateEditorComponent>, private formBuilder: FormBuilder, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService, private dialogService: MatDialog) { }

  ngOnInit(): void {
    if (this.template){
      this.getTemplate().then((result) => {this.templateForm.get("content").setValue(result.payload.content)})
      this.templateForm.get("locale").disable();
      this.templateForm.get("template_code").disable();
      this.templateForm.get("subtemplate_id").disable();
    }

    this.translateService.stream(['error_message_error_getting_template', 'error_message_error_creating_template', 'error_message_error_editing_template']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_getting_template", name: res["error_message_error_getting_template"]},
        {value: "error_message_error_creating_template", name: res["error_message_error_creating_template"]},
        {value: "error_message_error_editing_template", name: res["error_message_error_editing_template"]}
      ];
    });
  }

  getTemplate(){
    let body = {
      locale: this.templateForm.get("locale").value,
      subtemplate_id: this.templateForm.get("subtemplate_id").value,
      template_code: this.templateForm.get("template_code").value
    }
    let payload = btoa(JSON.stringify(body));
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(environment.serverEndpoint + '/owner/template?payload=' + payload, {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_template").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  async saveTemplate() {

    if (this.templateForm.valid) {
      if(this.template){
        let content;
        if (this.templateForm.get("content").value instanceof File){
          content = await this.toBase64String(this.templateForm.get("content").value);
          content = content.split(",")[1];
        }
        else{
          content = this.templateForm.get("content").value   
        }
        this.modify(content).then((response) => {
          this.dialogRef.close(response);
        });
      }
      else{
        let content:any = await this.toBase64String(this.templateForm.get("content").value);
        content = content.split(",")[1];
        this.save(content).then((response) => {
        this.dialogRef.close(response);
       });
      }

    } else {
      this.templateForm.markAllAsTouched();
    }

   
  }

  toBase64String(file: File){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
    });
  }

  save(content) {
    
    let body = {
      content: content,
      locale: this.templateForm.get("locale").value,
      subtemplate_id: this.templateForm.get("subtemplate_id").value == null?0:this.templateForm.get("subtemplate_id").value,
      template_code: this.templateForm.get("template_code").value
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(environment.serverEndpoint + '/owner/template', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_template").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  modify(content) {
    let body = {
      content: content,
      locale: this.template.locale,
      subtemplate_id: +this.template.subtemplate_code,
      template_code: this.template.template_code
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.serverEndpoint + '/owner/template', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_editing_template").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }
}
