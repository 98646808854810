<mat-toolbar color="primary">
    <div>
    </div>
    <div>
        <button mat-icon-button matTooltip="Módosítás" [disabled]="!selectedTemplate" (click)="editTemplate(selectedTemplate)">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                {{'menu_templates' | translate}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                {{'workflows_folder-name' | translate}}
            </div>
        </div>
        <div *ngFor="let template of templates" class="table-item" (click)="selectedTemplate == template ? selectedTemplate = null : selectedTemplate = template" [class.selected]="selectedTemplate == template">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                {{template.caption | translate}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                {{template.path}}
            </div>
        </div>
    </div>
</div>