<ng-container *ngIf="!showContracts"><mat-toolbar color="primary">
  <div>
    <button mat-icon-button matTooltip="{{'common_title_filter_table_columns'|translate}}" (click)="filterTableColumns()">
      <mat-icon>filter_alt</mat-icon>
    </button>
    <button mat-icon-button matTooltip="{{'companies_new-company-recording'|translate}}" (click)="editCompany()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button matTooltip="{{'companies_modification'|translate}}" [disabled]="selection.selected.length !== 1" (click)="editCompany(selection.selected[0])">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button matTooltip="{{'companies_contract'|translate}}" (click)="editContracts()" [disabled]="selection.selected.length !== 1 || selection.selected[0].customer_status_sid !== 'CUS_STAT_ACTIVE' ">
      <mat-icon>article</mat-icon>
    </button>
    <button mat-icon-button matTooltip="{{'companies_started-processes'|translate}}" [disabled]="selection.selected.length !== 1" (click)="openWorkflows(selection.selected[0])">
      <mat-icon>workflow</mat-icon>
    </button>
    <!-- mat-slide-toggle [(ngModel)]="showDeletedCompanies"
      [checked]="showDeletedCompanies">
      {{'companies_removed-companies-show' | translate}}
    </mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="showCompaniesAssignedToOthers"
      [checked]="showCompaniesAssignedToOthers">
      {{'companies_assigned-other-company-visible' | translate}}
    </mat-slide-toggle -->

    <mat-form-field style="padding-top: .75em;">
      <mat-label>{{'common_title_search'|translate}}</mat-label>
      <input matInput autocomplete="off" name="txtSearchCompany" (keydown.enter)="searchCompany($event)" #searchInput>
      <button mat-button *ngIf="searchInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"><mat-icon>close</mat-icon></button>
    </mat-form-field>
    <app-select-search [appearanceStyle]="'standard'" [selectionItems]="columnSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_column' | translate" [selectCtrl]="columnSelectionForm.controls.columnName" [required]="false"></app-select-search>
  </div>
  
</mat-toolbar>
  <table mat-table [dataSource]="dataSource" matSort cdkDropListOrientation="horizontal"
  cdkDropList (cdkDropListDropped)="reorderColumns($event)">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef cdkDragLockAxis="x" cdkDrag>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-radio-button (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-radio-button>
      </td>
    </ng-container>

    <ng-container matColumnDef="long_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_name-of-company' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.long_name}} </td>
    </ng-container>
    <ng-container matColumnDef="tax_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_company-formatted-tax-number' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.tax_number}} </td>
    </ng-container>
    <ng-container matColumnDef="email_address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_contact-person-mail' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.email_address}} </td>
    </ng-container>
    <ng-container matColumnDef="customer_status_sid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_status' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{findSelectionItem(statusSelectionItems, row.customer_status_sid)?.name | translate}}</td>
    </ng-container>
    <ng-container matColumnDef="contractType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_actual-contract-aspect' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.contract_type_sid | translate}} </td>
    </ng-container>
    <ng-container matColumnDef="contractStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_actual-contract-status' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.contract_status_sid |translate}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
    </tr>
  </table>

  <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="handlePage($event)" [showFirstLastButtons]="true"></mat-paginator>
</ng-container>

<app-contracts *ngIf="showContracts" [customerId]="selection.selected[0].id" [customer]="selection.selected[0]" (showContracts)="setShowContracts($event)"></app-contracts>

<!-- app-workflows *ngIf="showWorkflows" [customerId]="selection.selected[0].id" [customer]="selection.selected[0]" (showContracts)="setShowContracts($event)"></app-workflows -->