<div class="app-version">branch: {{versions.branch}} | revision: {{versions.revision}}</div>
<mat-toolbar class="toolbar" *ngIf="router.url.indexOf('login') == -1">
    <div class="toolbar-row">
        <div class="toolbar-column">
            <ng-container
                    *ngIf="router.url.indexOf('mobile') != -1 && CredentialsService.role == 'user' && StateService.actualState != ''">
                <button (click)="StateService.setStateBack()" mat-button [matMenuTriggerFor]="languageMenu"
                        [ngStyle]="{color: 'white'}">
                    <mat-icon>backspace</mat-icon>
                </button>
            </ng-container>
            <div class="toolbar-logo"></div>
            <ng-container
                    *ngIf="router.url.indexOf('login') == -1 && router.url.indexOf('mobile') == -1 && (CredentialsService.role == 'user') || (CredentialsService.role== 'owner' && menuState==='user')">
                <a href="" [routerLink]="['/companies']" routerLinkActive="active">
                    {{'menu_companies' | translate}}
                </a>
                <a href="" [routerLink]="['/workflows']" routerLinkActive="active">
                    {{'menu_workflows' | translate}}</a>
                <a href="" [routerLink]="['/file-system']" routerLinkActive="active">
                    {{'menu_file-system' | translate}}</a>
                <a href="" [routerLink]="['/timesheet']" routerLinkActive="active">
                    {{'menu_timesheet' | translate}}</a>
                <a href="" [routerLink]="['/riportok']" routerLinkActive="active">
                    {{'menu_reports' | translate}}</a>
                <!--a href="" [routerLink]="['/profil']" routerLinkActive="active">
                    {{'menu_personal-profile' | translate}}</a -->
            </ng-container>
            <!--<ng-container *ngIf="router.url.indexOf('login') == -1 && CredentialService.role == 'admin' || CredentialService.role == 'owner'">

            </ng-container>-->
            <ng-container *ngIf="router.url.indexOf('login') == -1 && (CredentialsService.role == 'admin') ">
                <a href="" [routerLink]="['/auditors']" routerLinkActive="active">
                    {{'menu_auditors' | translate}}
                </a>
                <a href="" [routerLink]="['/countries']" routerLinkActive="active">
                    {{'menu_countries' | translate}}
                </a>
                <a href="" [routerLink]="['/workflow-element-groups']" routerLinkActive="active">
                    {{'menu_workflow-element-groups' | translate}}
                </a>
                <a href="" [routerLink]="['/workflow-templates']" routerLinkActive="active">
                    {{'menu_workflow-templates' | translate}}
                </a>
                <a [routerLink]="['/system-logs']" routerLinkActive="active">
                    Rendszerinformációk
                </a>
            </ng-container>
            <ng-container
                    *ngIf="router.url.indexOf('login') == -1 && CredentialsService.role == 'owner' && menuState=== 'owner'">
                <div [style.display]="'flex'" [style.flex-wrap]="'wrap'">
                    <a href="" [routerLink]="['/profile']" routerLinkActive="active">
                        {{'menu_profile' | translate}}
                    </a>
                    <a href="" [routerLink]="['/parameters']" routerLinkActive="active">
                        {{'menu_parameters' | translate}}
                    </a>
                    <a href="" [routerLink]="['/document-subtypes']" routerLinkActive="active">
                        {{'menu_document-subtypes' | translate}}
                    </a>
                    <a href="" [routerLink]="['/template-folder']" routerLinkActive="active">
                        {{'menu_doc_folders' | translate}}
                    </a>
                    <a href="" [routerLink]="['/templates']" routerLinkActive="active">
                        {{'menu_templates' | translate}}
                    </a>
                    <a href="" [routerLink]="['/roles']" routerLinkActive="active">
                        {{'menu_roles' | translate}}
                    </a>
                    <a href="" [routerLink]="['/coworkers']" routerLinkActive="active">
                        {{'menu_coworkers' | translate}}
                    </a>
                    <a href="" [routerLink]="['/workflow-element-groups']" routerLinkActive="active">
                        {{'menu_workflow-element-groups' | translate}}
                    </a>
                    <a href="" [routerLink]="['/workflow-templates']" routerLinkActive="active">
                        {{'menu_workflow-templates' | translate}}
                    </a>
                    <a href="" [routerLink]="['/folderstructure']" routerLinkActive="active">
                        {{'menu_folderstructure' | translate}}
                    </a>
                </div>
            </ng-container>
        </div>
        <div class="toolbar-column">
            <mat-progress-spinner *ngIf="showProgress" [diameter]="25" mode="indeterminate"
                                  [style.marginRight]="'1rem'" [style.stroke]="'white'"></mat-progress-spinner>
            <ng-container *ngIf="router.url.indexOf('login') == -1">
                <button (click)="changeMenu()" *ngIf="CredentialsService.role=='owner'" mat-button
                        class="menu-change-btn positiveButton">{{(this.menuState==='owner' ? 'user_menu' : 'admin_menu')
                    | translate}}
                </button>
                <div class="account_class">
                    {{credentialsService.userData?credentialsService.userData.username:""}}
                </div>
                <button mat-button [matMenuTriggerFor]="languageMenu"
                        matTooltip="{{this.route.includes('debitbalance') ? 'Nyelv/Language' : 'button_title_language_switcher' | translate}}"
                        [ngStyle]="{color: '#039050'}">
                    <mat-icon>language</mat-icon>
                </button>
                <button mat-button (click)="showHelp('manual')" matTooltip="{{'button_title_help' | translate}}" [ngStyle]="{color: '#039050'}">
                    <mat-icon>help_outline</mat-icon>
                </button>
                <mat-menu #languageMenu="matMenu">
                    <button mat-menu-item (click)="changeLanguage(language.locale)"
                            *ngFor="let language of languageService.languageList"
                            style="display: flex; align-items: center;"><img
                            [src]="'data:'+language.logo_mime+';base64,'+language.logo_content"
                            style="width: 25px; height: auto; margin-right: 5px;">{{language.locale | translate}}
                    </button>
                </mat-menu>
                <button *ngIf="this.route.includes('debitbalance')" mat-button [matMenuTriggerFor]="menu"
                        matTooltip="Kijelentkezés/Logout" (click)="credentialsService.logout(true)"
                        [ngStyle]="{color: '#039050'}">
                    <mat-icon>close</mat-icon>
                </button>
                <button *ngIf="!this.route.includes('debitbalance')" mat-button [matMenuTriggerFor]="menu"
                        matTooltip="{{'button_title_logout' | translate}}" (click)="credentialsService.logout()"
                        [ngStyle]="{color: '#039050'}">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="this.route.includes('debitbalance')" mat-menu-item
                            (click)="credentialsService.logout(true)">Kijelentkezés/Logout
                    </button>
                    <button *ngIf="!this.route.includes('debitbalance')" mat-menu-item
                            (click)="credentialsService.logout()">{{'button_title_logout' | translate}}
                    </button>
                </mat-menu>
            </ng-container>
        </div>
    </div>
</mat-toolbar>
<div class="content-holder">
    <ng-container *ngIf="router.url.indexOf('login') == -1">
        <app-chatbot></app-chatbot>
    </ng-container>
    <router-outlet></router-outlet>
</div>
