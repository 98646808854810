<mat-toolbar color="primary">
    <div>
    </div>
    <div>
        <button mat-icon-button matTooltip="{{'workflow_element_groups_new' | translate}}" (click)="editGroup()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflow_element_groups_modification' | translate}}"
                [disabled]="!selectedGroup" (click)="editGroup(selectedGroup)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflow_element_groups_remove' | translate}}"
                [disabled]="!selectedGroup" (click)="deleteGroup(selectedGroup)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <!--            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">-->
            <!--                {{'workflow_element_groups_report-serial-number' |translate}}-->
            <!--            </div>-->
            <div class="table-text" [style.width]="'100%'">
                {{'workflow_templates_timing-code' |translate}}
            </div>
            <div class="table-text" [style.width]="'100%'">
                {{'workflow_element_groups_name' |translate}}
            </div>
        </div>
        <div [style.display]="'flex'">
            <div [style.width]="'100%'">
                <ng-container *ngFor="let group of workflowElementGroups">
                    <div *ngIf="group.is_active" class="table-item"
                         (click)="selectedGroup == group ? selectedGroup = null : selectedGroup = group"
                         [class.selected]="selectedGroup?.id == group.id">
                        <!--                <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">-->
                        <!--                    {{group.serial_number}}-->
                        <!--                </div>-->
                        <div class="table-text" [style.width]="'100%'">
                            {{group.workflow_element_group_time_sid | translate}}
                        </div>
                        <div class="table-text" [style.width]="'100%'">
                            {{group.fix_code ? (group.fix_code | translate) : group.caption}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
