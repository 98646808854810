<h2 mat-dialog-title>{{'common_title_upload_balance_sheet' | translate}}</h2>
<mat-dialog-content>
    <div style="display: flex; align-items: center; margin-bottom: 15px;">
        <button mat-button style="margin: 10px" (click)="importBalanceSheet()" class="neutralButton">{{'button_title_balance_sheet_import' | translate}}</button>
        <button mat-button style="margin: 10px" (click)="balanceSheetSchemaDownload()" class="neutralButton">{{'button_title_balance_sheet_schema_download' | translate}}</button>
        <div class="balancesheet_balance">{{this.balanceExistsBasis}}</div>
        <div class="balancesheet_balance">{{this.balanceExistsCurrent}}</div>
    </div>

    <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" animationDuration="0ms">
        <mat-tab label="{{'common_title_assets' | translate}}">
            
            <div class="table-content">
                <div class="column">
                    <div class="table-header">
                        <div class="table-text" [style.width]="'5%'">
                            {{'common_title_nr' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'50%'">
                            {{'common_title_title' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{'common_title_basis_value' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{'common_title_current_value' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{'common_title_deviation_subject_year' | translate}}
                        </div>
                    </div>
                    <div>
                        <div *ngFor="let element of elementAssets" class="table-item" (click)="openDetail($event, element)">
                                <div class="table-text" [style.width]="'5%'">
                                    {{element.serial}}
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'50%'" [style.justify-content]="'flex-start'" *ngIf="element.code != ''">
                                    {{element.code}}. {{element.gv_line_sid | translate}}
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'50%'" [style.justify-content]="'flex-start'" *ngIf="element.code == ''">
                                    {{element.gv_line_sid | translate}}
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'15%'" [style.justify-content]="'flex-end'">
                                    <ng-container *ngIf="element.gv_line_is_writable">
                                        <mat-form-field floatLabel="never">
                                            <input matInput [ngModel]="element.basis_amount | number:'1.2':language"
                                            (blur)="myformat(element, 'basis_amount', $event, element.gv_line_sid)" style="text-align: right;">
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="!element.gv_line_is_writable">
                                        {{element.basis_amount | number:'1.2':language}}
                                    </ng-container>
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}} {{(element.gv_line_is_writable && (element.current_amount - element.sum_of_items)!=0)?'error_red':''}}" [style.width]="'15%'" [style.justify-content]="'flex-end'">
                                    <ng-container *ngIf="element.gv_line_is_writable">
                                        <mat-form-field floatLabel="never">
                                            <input matInput [ngModel]="element.current_amount | number:'1.2':language"
                                            (blur)="myformat(element, 'current_amount', $event, element.gv_line_sid)" style="text-align: right;">
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="!element.gv_line_is_writable">
                                        {{element.current_amount | number:'1.2':language}}
                                    </ng-container>
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'15%'" [style.justify-content]="'flex-end'">
                                    {{(absNum(element.current_amount - element.sum_of_items)) | number:'1.2':language}}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{'common_title_liabilities_and_shareholders_equities' | translate}}">
            <div class="table-content">
                <div class="column">
                    <div class="table-header">
                        <div class="table-text" [style.width]="'5%'">
                            {{'common_title_nr' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'50%'">
                            {{'common_title_title' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{'common_title_basis_value' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{'common_title_current_value' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{'common_title_deviation_subject_year' | translate}}
                        </div>
                    </div>
                    <div>
                        <div *ngFor="let element of elementLiabs" class="table-item" (click)="openDetail($event, element)">
                                <div class="table-text" [style.width]="'5%'">
                                    {{element.serial}}
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'50%'" [style.justify-content]="'flex-start'" *ngIf="element.code != ''">
                                    {{element.code}}. {{element.gv_line_sid | translate}}
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'50%'" [style.justify-content]="'flex-start'" *ngIf="element.code == ''">
                                    {{element.gv_line_sid | translate}}
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'15%'" [style.justify-content]="'flex-end'">
                                    <ng-container *ngIf="element.gv_line_is_writable">
                                        <mat-form-field floatLabel="never">
                                            <input matInput [ngModel]="element.basis_amount | number:'1.2':language"
                                            (blur)="myformat(element, 'basis_amount', $event, element.gv_line_sid)" style="text-align: right;">
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="!element.gv_line_is_writable">
                                        {{element.basis_amount | number:'1.2':language}}
                                    </ng-container>
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'15%'" [style.justify-content]="'flex-end'">
                                    <ng-container *ngIf="element.gv_line_is_writable">
                                        <mat-form-field floatLabel="never">
                                            <input matInput [ngModel]="element.current_amount | number:'1.2':language"
                                            (blur)="myformat(element, 'current_amount', $event, element.gv_line_sid)" style="text-align: right;">
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="!element.gv_line_is_writable">
                                        {{element.current_amount | number:'1.2':language}}
                                    </ng-container>
                                </div>
                                <div class="table-text style_{{element.gv_line_hiearchy_level}}" [style.width]="'15%'" [style.justify-content]="'flex-end'">
                                    {{(element.current_amount - element.sum_of_items) | number:'1.2':language}}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</mat-dialog-content>
    
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'workflows_close' | translate}}</button>
</mat-dialog-actions>
