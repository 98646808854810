import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-base-file-table',
  templateUrl: './base-file-table.component.html',
  styleUrls: ['./base-file-table.component.less']
})
export class BaseFileTableComponent implements OnInit {

  displayedColumns: string[] = ['taxNumber', 'email', 'contact', 'companyName', 'postalAddress'];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(false, []);
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<BaseFileTableComponent>) { }

  ngOnInit(): void {
    this.dataSource.data = [{taxNumber: "123456", email:"xy@email.hu", contact: "Nagy Aladár", companyName: "Nagy és Társa Bt.", postalAddress: "XX utca 12."},
    {taxNumber: "123457", email:"ab@email.hu", contact: "Kiss Aladár", companyName: "Kiss és Társa Bt.", postalAddress: "XX utca 13."},
    {taxNumber: "123458", email:"cd@email.hu", contact: "Közepes Aladár", companyName: "Közepes és Társa Bt.", postalAddress: "XX utca 14."}]
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  save(){

  }

}
