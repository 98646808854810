<h2 mat-dialog-title>{{'Mail-cím' + (templateFolderForm.controls.id.value ? ' módosítása' : ' létrehozása')}}</h2>
<mat-dialog-content>
    <form [formGroup]="templateFolderForm">
        <mat-form-field appearance="outline">
            <mat-label>Típuskód</mat-label>
            <input matInput formControlName="code" oninput="this.value = this.value.toUpperCase()" required>
            <mat-error *ngIf="templateFolderForm.controls.code.hasError('required')">
                A típuskód nem lehet üres
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Mail-cím</mat-label>
            <input matInput formControlName="address" required>
            <mat-error *ngIf="templateFolderForm.controls.address.hasError('required')">
                A mail-cím nem lehet üres
            </mat-error>
            <mat-error *ngIf="templateFolderForm.controls.address.hasError('pattern')">
                A mail-cím nem megfelelő formátumú
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveTemplateFolder()" class="positiveButton">{{'mail_addresses_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">Elvetés</button>
</mat-dialog-actions>