import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/data/role';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { RoleEditorComponent } from './role-editor/role-editor.component';
import { RolesService } from '../../services/roles.service';
import { CredentialsService } from 'src/app/services/credentials.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.less']
})
export class RolesComponent implements OnInit {

  selectedPrivgroup: Role;
  privgroup;
  roles;
  privgroups: Role[] = []

  objectKeys = Object.keys;

  functionsText = [
    {
      value: "ROLE_CAP_SIGNER", text: "roles_sign-right"
    },
    {
      value: "ROLE_CAP_COMPANY_SIGNER", text: "roles_procuration-right"
    },
    {
      value: "ROLE_CAP_CUSTOMER_MANAGER", text: "roles_firm-creaton"
    },
    {
      value: "ROLE_CAP_CONTRACT_MANAGER", text: "roles_contract-creaton"
    },
    {
      value: "ROLE_CAP_WF_CREATOR", text: "roles_workflow-creaton"
    },
    {
      value: "ROLE_CAP_TIMESHEET", text: "roles_timesheet-report"
    },
    {
      value: "ROLE_CAP_WFE_MANAGER", text: "roles_workflow-record-function"
    },
    {
      value: "ROLE_CAP_UNI_READER", text: "roles_universal-reading"
    },
    {
      value: "ROLE_CAP_UNI_WRITER", text: "roles_universal-writing"
    },
    {
      value: "ROLE_CAP_UNI_CUSTOMER_LISTER", text: "roles_universal-firm-lister"
    },
    {
      value: "ROLE_CAP_UNI_CONTRAC_TLISTER", text: "roles_universal-contract-lister"
    },
    {
      value: "ROLE_CAP_UNI_WFE_LISTER", text: "roles_universal-workflow-lister"
    },
    {
      value: "ROLE_CAP_REPORTER", text: "roles_reporting"
    },
    {
      value: "ROLE_CAP_WF_APPROVER", text: "roles_licenser"
    }
  ]

  translatableTexts: any[] = [
    {value: "common_message_role_save_successful", name: ""},
    {value: "error_message_role_save_unsuccessful", name: ""},
    {value: "common_message_role_delete_successful", name: ""},
    {value: "error_message_role_delete_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private snackBar: MatSnackBar, private translateService: TranslateService, private service: RolesService, private credentialsService: CredentialsService) { }

  ngOnInit(): void {
    this.privgroups = [];
    this.service.listPrivgroups({}).subscribe((response)=>{
      if(Array.isArray(response.payload)) {
        response.payload.forEach((privgroup)=> {
          if (privgroup.is_active) {
            this.privgroups.push(privgroup)
          }
        })
      }
    })

    this.service.listRoles().subscribe((response)=>{
      this.roles = response.payload;
    })

    this.translateService.stream(['common_message_role_save_successful', 'error_message_role_save_unsuccessful', 'common_message_role_delete_successful', 'error_message_role_delete_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_role_save_successful", name: res["common_message_role_save_successful"]},
        {value: "error_message_role_save_unsuccessful", name: res["error_message_role_save_unsuccessful"]},
        {value: "common_message_role_delete_successful", name: res["common_message_role_delete_successful"]},
        {value: "error_message_role_delete_unsuccessful", name: res["error_message_role_delete_unsuccessful"]}
      ];
    });
  }

  hasFunction(func){
    if (this.privgroup?.roles && this.selectedPrivgroup){
      return this.privgroup?.roles.find(role => role == func)
    }
  }

  getPrivgroup(id){
    if (this.selectedPrivgroup){
      this.service.getPrivgroup(id).subscribe((response)=>{
        this.privgroup = response.payload;
      })
    }
    else{
      this.privgroup = null;
    }
  }

  editRole(role?: Role){
    const dialog = this.dialogService.open(RoleEditorComponent);
    dialog.componentInstance.functionsText = this.functionsText;
    if (role) {
      dialog.componentInstance.role = role;
    }
    dialog.afterClosed().subscribe((response)=>{
      if (response){
        if (response.type == "OK"){
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_role_save_successful").name, null, {panelClass: 'success-snackbar'})
          if (role){
            this.selectedPrivgroup = this.privgroups.find(privgroup => privgroup.id == response.id);
            this.getPrivgroup(this.selectedPrivgroup.id);
          }
          else{
            this.selectedPrivgroup = null;
          }
          this.ngOnInit();
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_role_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }
    })
  }

  deleteRole(role: Role){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('roles_remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        // TODO: backend service
        this.service.deleteRole(role.id).subscribe((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_role_delete_successful").name, null, {panelClass: 'success-snackbar'});
              this.selectedPrivgroup = null;
              this.ngOnInit();
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_role_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
        });
      }
    })
    
  }

}
