import {Component, OnInit, ViewChild, ElementRef, AfterContentInit} from '@angular/core';
import {Auditor} from "../../../data/auditor";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {AuditorService} from "../../../services/auditor.service";
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { of, merge } from 'rxjs';
import { PaginatedAnswer } from 'src/app/data/response';
import { CredentialsService } from '../../../services/credentials.service';
import {DateAdapter} from "@angular/material/core";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import {DateParsePipe} from "../../../pipes/date-parse.pipe";

@Component({
  selector: 'app-auditor-editor',
  templateUrl: './auditor-editor.component.html',
  styleUrls: ['./auditor-editor.component.less']
})
export class AuditorEditorComponent implements AfterContentInit, OnInit {

  maxday = new Date(new Date(2999,12,31).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0];
  minValidFrom;

  auditorForm: FormGroup = this.formBuilder.group({
    id: null,
    business_code: [null, [Validators.required, Validators.maxLength(32)]],
    name: [null, [Validators.required]],
    country_iso_code: [null, [Validators.required]],
    city: [null, [Validators.required]],
    postal_code: [null, [Validators.required]],
    postal_address: [null, [Validators.required]],
    chamber_code: [null, [Validators.required]],
    licenc_quantity: [null, [Validators.required]],
    licenc_valid_to: [null, [Validators.required]],
    registration_code: [null, [Validators.required]],
    tax_number: [null, [Validators.required]],
    email_address: [null, [Validators.required]],
    reply_address: null,
    auditor_program_id: [null, [Validators.required]],
    locale: this.credService.userData.locale,
    auditor_status_sid: ["AUDITOR_STAT_ACTIVE", [Validators.required]],
    contract_number_prefix: "",
  });

  @ViewChild("licenc_valid_to") inputLicencValidTo: ElementRef;

  auditorSoftwareList: any[] = [];
  auditSoftwareCount: number;
  countrySelectionList: any[] = [];
  countryCount: number;
  auditorStatuses = [];

  set auditor(value: Auditor) {
    this.auditorForm.patchValue(value);
  }

  constructor(private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AuditorEditorComponent>,
              private auditorService: AuditorService, private credService: CredentialsService) { }

  ngAfterContentInit(): void{
    this._adapter.setLocale(localStorage.getItem("language") == "hu_HU"?'hu':'en');
  }

  ngAfterViewInit(){
    if(this.auditorForm.get("id").value){
      this.inputLicencValidTo.nativeElement.value = this.pipeFormat.transform(this.auditorForm.get("licenc_valid_to").value * 1000);
    }

  }

  ngOnInit(): void {
    this.loadAuditSoftwares();

    this.loadCountries();

/*    this.auditorService.countCountries({}).then((data)=>{
      this.countryCount = data.records;
    });*/

    if (!this.auditorForm.get("id").value){
      this.auditorForm.get("auditor_status_sid").disable();
    }

    this.auditorService.listAuditorStatuses().subscribe((response)=> {
      let statuses = [];
      response.payload.forEach((status) => {
        if (status.is_active){
          statuses.push({name: status.sid, value: status.sid})
        }
      })
      this.auditorStatuses = statuses;
    }
    )


  }

  loadAuditSoftwares(){
    merge()
    .pipe(
        startWith({}),
        switchMap(() => {
          return this.auditorService.listAuditorSoftwares();
        }),
        map((data: PaginatedAnswer<Auditor>) => {
          return data.payload.content;
        }),
        catchError(() => {
          return of([]);
        })
    ).subscribe((data) => {
      data.forEach((item: any)=>{
        item.value = item.id
      })
      this.auditorSoftwareList = data;
    });
  }

  loadCountries(){
    merge()
    .pipe(
        startWith({}),
        switchMap(() => {
          return this.auditorService.getCountries({
              "page": 1,
              "size": this.countryCount
            }
          );
        }),
        map((data: PaginatedAnswer<Auditor>) => {
          return data.payload.content;
        }),
        catchError(() => {
          return of([]);
        })
    ).subscribe((data) => {
      data.forEach((item: any)=>{
        item.value = item.iso_code
        item.name = item.iso_code
      })
      this.countrySelectionList = data;
    });
  }

  saveAuditor() {

    this.auditorForm.get("licenc_valid_to").setValue(new Date(this.pipeParse.transform(this.inputLicencValidTo.nativeElement.value)).getTime() / 1000);
    if (this.auditorForm.valid) {
      if (this.auditorForm.controls.id.value) {
        this.auditorForm.get("contract_number_prefix").disable();
        console.log("this.inputLicencValidTo.nativeElement.value");
        console.log(this.inputLicencValidTo.nativeElement.value);
        this.auditorForm.get("licenc_valid_to").setValue(new Date(this.pipeParse.transform(this.inputLicencValidTo.nativeElement.value)).getTime() / 1000);
        this.auditorService.update(this.auditorForm.value).subscribe(
          (response) => {
          this.dialogRef.close(response);
        });
      } else {
        let body = this.auditorForm.getRawValue();
        body.auditor_status_sid = "AUDITOR_STAT_ACTIVE";
        console.log("this.inputLicencValidTo.nativeElement.value");
        console.log(this.inputLicencValidTo.nativeElement.value);
        body.licenc_valid_to = new Date(this.pipeParse.transform(this.inputLicencValidTo.nativeElement.value)).getTime() / 1000;
        this.auditorService.create(body).subscribe((response) => {
          this.dialogRef.close(response);
        });
      }
    } else {
      this.auditorForm.markAllAsTouched();
    }
  }
}
