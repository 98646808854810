import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export class TranslationHttpLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {

    let observer = new Observable(observer => {
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
      let payload = btoa(JSON.stringify({"locale": lang}));
      this.httpClient.get(environment.serverEndpoint + "/common/languagemap?payload=" + payload, {headers}).subscribe((data: any) => {
          let frontendData = {};
          data.payload.forEach((k: any)=>{
            frontendData[k.msgid]= k.msgstr;
          })
          observer.next(frontendData);
          observer.complete();
        },
        error => {
          console.log(error);
        }
      );
    });
    return observer;
  }
}
