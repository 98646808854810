import { Component, OnInit } from '@angular/core';
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {UserWfsService} from "../../../../../services/user-wfs.service";

@Component({
  selector: 'app-balance-sheet-detail-newitem',
  templateUrl: './balance-sheet-detail-newitem.component.html',
  styleUrls: ['./balance-sheet-detail-newitem.component.less']
})
export class BalanceSheetDetailNewitemComponent implements OnInit {

  balanceSheetDetailNewitemForm: FormGroup = this.formBuilder.group({
    id: null,
    magyar: [null, [Validators.required]],
    angol: [null],
    amount: [null, [Validators.required]]
  });

  _actualItem: any;
  get actualItem() { return this._actualItem };
  set actualItem(value: any) {
    this._actualItem = value;
  }

  _gvItem: any;
  get gvItem() { return this._gvItem };
  set gvItem(value: any) {
    this._gvItem = value;
  }

  constructor(private userWfService: UserWfsService, public dialogRef: MatDialogRef<BalanceSheetDetailNewitemComponent>, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if(this.actualItem){
      this.balanceSheetDetailNewitemForm.get("magyar").setValue(this.actualItem.magyar);
      this.balanceSheetDetailNewitemForm.get("angol").setValue(this.actualItem.angol);
      this.balanceSheetDetailNewitemForm.get("amount").setValue(this.actualItem.amount);
    }
  }

  insertItem(){
    let body = {
      amount: this.balanceSheetDetailNewitemForm.get("amount").value,
      title: [
        {locale: "en_GB", title: this.balanceSheetDetailNewitemForm.get("angol").value?this.balanceSheetDetailNewitemForm.get("angol").value:""},
        {locale: "hu_HU", title: this.balanceSheetDetailNewitemForm.get("magyar").value?this.balanceSheetDetailNewitemForm.get("magyar").value:""},
      ]
    }
    if(!this.actualItem){
      body['gv_value_id'] = this.gvItem.gv_value_id;
      this.userWfService.postGVItem(body).subscribe(()=>this.dialogRef.close("1"));
    }else{
      body['id'] = this.actualItem.id;
      this.userWfService.putGVItem(body).subscribe(()=>this.dialogRef.close("1"));
    }
  }

}
