import {Component, ElementRef, OnInit, AfterViewInit, ViewChild, AfterContentInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {UserWfsService} from "../../../services/user-wfs.service";
import {DateParsePipe} from "../../../pipes/date-parse.pipe";
import {LanguageService} from "../../../services/language.service";
import {DateAdapter} from "@angular/material/core";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FileUploaderEditorComponent } from '../../file-system/file-uploader-editor/file-uploader-editor.component';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.less']
})
export class AuditReportComponent implements OnInit, AfterContentInit, AfterViewInit {

  @ViewChild("input_report_date") inputReportDate: ElementRef;

  auditReportForm: FormGroup = this.formBuilder.group({
    id: null,
    reportDate: [null],
    commentType: [null, Validators.required],
    restrictionComment: [null],
    restrictionType: [null],
    reportCreator: [null, Validators.required],
    reportOwner: [null, Validators.required],
    currency: [null, Validators.required],
    totalIncome: [null, Validators.required],
    balanceSheetTotal: [null, Validators.required],
    taxedTotal: [null, Validators.required],
    areRequirementsMet: [null],
    isBookingForeignCurrency: [null],
    areDataFromGV: [true],
    locale: [null],
    otherLegislation: [null, Validators.required],
    additionalInfo: [''],
  })

  showTextInput: boolean = false;
  lastCurrency: string = null;

  maskOptions = {
    mask: Number,
    signed: true,
    thousandsSeparator: ' '
  };

  valueDependsOnGv = {
    typed: {
      balanceSheetTotal: 0,
      taxedTotal: 0
    },
    requested: {
      balanceSheetTotal: 0,
      taxedTotal: 0
    }
  }

  _commentTypeSelectionItems: any;
  get commentTypeSelectionItems() { return this._commentTypeSelectionItems };
  set commentTypeSelectionItems(value: any) {
    this._commentTypeSelectionItems = value;
  }

  _restrictionTypeSelectionItems: any;
  get restrictionTypeSelectionItems() { return this._restrictionTypeSelectionItems };
  set restrictionTypeSelectionItems(value: any) {
    this._restrictionTypeSelectionItems = value;
  }

  _reportCreatorSelectionItems: any;
  get reportCreatorSelectionItems() { return this._reportCreatorSelectionItems };
  set reportCreatorSelectionItems(value: any) {
    this._reportCreatorSelectionItems = value;
  }

  _currencySelectionItems: any;
  get currencySelectionItems() { return this._currencySelectionItems };
  set currencySelectionItems(value: any) {
    this._currencySelectionItems = value;
  }

  _otherLegislationSelectionItems: any;
  get otherLegislationSelectionItems() { return this._otherLegislationSelectionItems };
  set otherLegislationSelectionItems(value: any) {
    this._otherLegislationSelectionItems = value;
  }

  _workflowId: any;
  get workflowId() { return this._workflowId };
  set workflowId(value: any) {
    this._workflowId = value;
  }


  _localeItems: any;
  get localeItems() { return this._localeItems };
  set localeItems(value: any) {
    this._localeItems = value;
  }

  constructor(private translateService: TranslateService, private dialogService: MatDialog, private snackBar: MatSnackBar, private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, public dialogRef: MatDialogRef<AuditReportComponent>, private formBuilder: FormBuilder, private el: ElementRef, private service: UserWfsService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

    this.service.getAuditReport(this.workflowId).subscribe((response) => {
      if (response) {
        if (response.type == "OK") {
          //this.auditReportForm.controls['reportDate'].setValue(response.report_date)

          this.auditReportForm.controls['currency'].setValue(response.payload.currency_iso_code)
          this.auditReportForm.controls['areDataFromGV'].setValue(response.payload.is_data_from_gv)
          this.auditReportForm.controls['areRequirementsMet'].setValue(response.payload.is_does_meet_business_report)
          this.auditReportForm.controls['isBookingForeignCurrency'].setValue(response.payload.is_foreign_currency)
          this.auditReportForm.controls['locale'].setValue(response.payload.locale)

          if (response.payload.report_date != null) {
            this.inputReportDate.nativeElement.value = this.pipeFormat.transform(response.payload.report_date * 1000);
          }
          this.auditReportForm.controls['restrictionType'].setValue(response.payload.report_modify_reason_sid)
          this.auditReportForm.controls['commentType'].setValue(response.payload.report_opinion_sid)
          this.auditReportForm.controls['otherLegislation'].setValue(response.payload.report_otherlaw_sid)
          this.auditReportForm.controls['reportCreator'].setValue(response.payload.report_plurality_sid)
          this.auditReportForm.controls['reportOwner'].setValue(response.payload.report_owner_plurality_sid)
          this.auditReportForm.controls['restrictionComment'].setValue(response.payload.restriction_comment)
          this.auditReportForm.controls['additionalInfo'].setValue(response.payload.other_law);
          this.auditReportForm.controls['totalIncome'].setValue(this.maskNumber(response.payload.total_income));

          this.valueDependsOnGv.requested.balanceSheetTotal = response.payload.balance_sheet_total_gv;
          this.valueDependsOnGv.requested.taxedTotal = response.payload.profit_after_tax_gv;
          this.valueDependsOnGv.typed.balanceSheetTotal = response.payload.balance_sheet_total;
          this.valueDependsOnGv.typed.taxedTotal = response.payload.profit_after_tax;

          if (this.auditReportForm.controls['areDataFromGV'].value) {
            // console.log("disabling fields");
            this.auditReportForm.controls['balanceSheetTotal'].disable();
            this.auditReportForm.controls['taxedTotal'].disable();
            this.changeGv(true, true);
          } else {
            // console.log("enabling fields");
            this.auditReportForm.controls['balanceSheetTotal'].enable();
            this.auditReportForm.controls['taxedTotal'].enable();
            this.changeGv(false, true);
          }
        }
      }
    });

    this.auditReportForm.controls.otherLegislation.valueChanges.subscribe((value) => {
      // console.log("oL value: "+ value);
      if (value === 'REPORT_OTHERLAW_EXISTS') {
        this.showTextInput = true;
        this.auditReportForm.controls['additionalInfo'].setValidators([Validators.required]);
      } else {
        this.showTextInput = false;
        this.auditReportForm.controls['additionalInfo'].clearValidators();
        this.auditReportForm.controls['additionalInfo'].updateValueAndValidity();
      }
    });

    this.auditReportForm.controls.currency.valueChanges.subscribe((value) => {
      this.updateInputFields();  // kapcsolgatja az enabled/disabled állapotokra, és töltögeti / állítgatja a mezőértékeket

      let numericValue = this.unmaskNumber(this.auditReportForm.controls['totalIncome'].value);

      if ((this.auditReportForm.controls.currency.value != 'HUF') && (this.lastCurrency == 'HUF')) {
        numericValue = numericValue * 1000;
      } else if (this.auditReportForm.controls.currency.value == 'HUF') {
        numericValue=numericValue/1000;
      }

      this.auditReportForm.controls['totalIncome'].setValue(this.maskNumber(numericValue));

      this.lastCurrency = this.auditReportForm.controls.currency.value;

    });

  }

  ngAfterViewInit(): void {
  }

  ngAfterContentInit(): void{
    this._adapter.setLocale(localStorage.getItem("language") == "hu_HU"?'hu':'en');
  }

  pending(){

  }

  changeGv(isFromGv, isInit) {
    let type = this.valueDependsOnGv.typed;
    // console.log("changeGv");
    if(isFromGv) {  // ha változik éppen, és GV-re vált kéziről
      type = this.valueDependsOnGv.requested;
      // console.log("GV");
      if(!isInit) {  // és nem a kezdeti beállítás van
        // console.log("!isInit -> orig GV data");
                    // akkor a mező tartalmát úgy tekintjük, hogy kézzel írta be, ezért elmentjük
        this.valueDependsOnGv.typed.taxedTotal = this.unmaskNumber(this.auditReportForm.controls['taxedTotal'].value);
        this.valueDependsOnGv.typed.balanceSheetTotal = this.unmaskNumber(this.auditReportForm.controls['balanceSheetTotal'].value);
                    // majd visszatöltjük a kapott GV adatokat
        this.auditReportForm.controls['taxedTotal'].setValue(this.maskNumber(this.valueDependsOnGv.requested.taxedTotal))
        this.auditReportForm.controls['balanceSheetTotal'].setValue(this.maskNumber(this.valueDependsOnGv.requested.balanceSheetTotal))
      } else {  // ha a kezdeti beállítás van
                // akkor betöltjük a kapott GV adatokat
        this.auditReportForm.controls['taxedTotal'].setValue(this.maskNumber(this.valueDependsOnGv.requested.taxedTotal))
        this.auditReportForm.controls['balanceSheetTotal'].setValue(this.maskNumber(this.valueDependsOnGv.requested.balanceSheetTotal))
      }
    } else {  // ha változik éppen, és GV-ről kézire vált
              // akkor a korábban elmentett kézzel beírt értékeket töltjük vissza
      // console.log("!GV -> typed data");
      this.auditReportForm.controls['taxedTotal'].setValue(this.maskNumber(this.valueDependsOnGv.typed.taxedTotal))
      this.auditReportForm.controls['balanceSheetTotal'].setValue(this.maskNumber(this.valueDependsOnGv.typed.balanceSheetTotal))
    }
  }

  maskNumber(value: number | string): string {
    if (value == null || value === '' || isNaN(Number(value))) {
      return '';
    }

    let numericValue = Number(value);

    if (this.auditReportForm.controls.currency.value == 'HUF') {
      numericValue = numericValue / 1000;
    }

    return numericValue.toString();
  }

  unmaskNumber(value: string) {
    let numericValue = value?Number(value.toString().replace(/ /gi,'')):0;
    if (this.auditReportForm.controls.currency.value == 'HUF') {
      numericValue = numericValue * 1000;
    }

    return numericValue;
  }

  updateInputFields(){
    // console.log("updateInputFields");
    setTimeout(() => {
      if(!this.auditReportForm.controls.areDataFromGV.value){
        this.auditReportForm.controls['balanceSheetTotal'].enable();
        this.auditReportForm.controls['taxedTotal'].enable();
        this.changeGv(false, false);
      }else{
        this.auditReportForm.controls['balanceSheetTotal'].disable();
        this.auditReportForm.controls['taxedTotal'].disable();
        this.changeGv(true, false);
      }
    }, 50);
  }

  uploadFile(){
    const dialog = this.dialogService.open(FileUploaderEditorComponent);
    dialog.componentInstance.locale = this.auditReportForm.get("locale").value;
    dialog.componentInstance.workflowId = this._workflowId;

    dialog.afterClosed().subscribe(closeResponse => {
      if (closeResponse){
        if (closeResponse.response.type == "OK") {
          this.snackBar.open("Sikeres fájlfelöltés!", "OK", {panelClass: "success-snackbar"});
        }
        else{
          catchError(error => {
            this.snackBar.open("Fájlfeltöltés sikertelen!", null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          })
        }
      }
    })
  }

  handleFileContent(response, filename){
    // console.log(response);
      var byteCharacters = atob(response.payload.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new File([byteArray], filename, {type: response.payload.mime_type});

      var link = document.createElement("a");
      var fileURL = URL.createObjectURL(file);        
      link.href = fileURL;
      link.download = response.payload.name;
      link.click();       
      
  }

  generate(eSign:Boolean){
    // console.log("generate");
    if(eSign){
      const dialog = this.dialogService.open(OkDialogComponent);
      this.translateService.get('companies_check-generated-kvvsz').subscribe((value)=>dialog.componentInstance.title = value)
      this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
      this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    }


    this.auditReportForm.controls['reportDate'].setValue(new Date(this.pipeParse.transform(this.inputReportDate.nativeElement.value)).getTime() / 1000);
    if(!this.auditReportForm.controls['restrictionType'].value)this.auditReportForm.controls['restrictionType'].setValue('REPORT_MODIFYREASON_NONE');
    if (this.auditReportForm.valid){
      // console.log("generate valid");
      let body;

      body = {
        balance_sheet_total: this.unmaskNumber(this.auditReportForm.controls['balanceSheetTotal'].value)*1,
        currency_iso_code: this.auditReportForm.controls['currency'].value,
        is_data_from_gv: this.auditReportForm.controls['areDataFromGV'].value,
        is_does_meet_business_report: this.auditReportForm.controls['areRequirementsMet'].value?"True":"False",
        is_foreign_currency: this.auditReportForm.controls['isBookingForeignCurrency'].value,
        locale: this.auditReportForm.controls['locale'].value,
        profit_after_tax: this.unmaskNumber(this.auditReportForm.controls['taxedTotal'].value)*1,
        report_date: new Date(this.pipeParse.transform(this.inputReportDate.nativeElement.value)).getTime() / 1000,
        report_modify_reason_sid: this.auditReportForm.controls['restrictionType'].value,
        report_opinion_sid: this.auditReportForm.controls['commentType'].value,
        report_otherlaw_sid: this.auditReportForm.controls['otherLegislation'].value,
        report_plurality_sid: this.auditReportForm.controls['reportCreator'].value,
        report_owner_plurality_sid: this.auditReportForm.controls['reportOwner'].value,
        restriction_comment: this.auditReportForm.controls['restrictionComment'].value,
        total_income: this.unmaskNumber(this.auditReportForm.controls['totalIncome'].value)*1,
        other_law: this.auditReportForm.controls['additionalInfo'].value,
        workflow_id: this.workflowId,
        eSignSending: false
      }
      if(eSign){
        body.eSignSending = true;
        body.is_preview = true;
        
      }
      this.service.postAuditReport(body).subscribe((response) => {
        // console.log("postAuditReport");
        if(response) {
          // console.log("postAuditReport response received");
          if(response.type == "OK") {
            // console.log("postAuditReport response.type OK");
//            this.service.getAuditReportFile(this.auditReportForm.controls['locale'].value, this.workflowId).then(response => {
              var byteCharacters = atob(response.payload.content);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var file = new File([byteArray], "AuditReport", {type: response.payload.mime_type});

              var link = document.createElement("a");
              var fileURL = URL.createObjectURL(file);        
              link.href = fileURL;
              link.download = response.payload.name;
              link.click();              
              
//              var fileURL = URL.createObjectURL(file);
//              window.open(fileURL);
//            })
          }
        }
      })
      //////////////////////////////
    }
    else {
      // console.log("generate invalid");
      this.auditReportForm.markAllAsTouched();
      let firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
      this.scrollToFirstInvalidControl(firstInvalidControl);
    }
  }

  private scrollToFirstInvalidControl(firstInvalidControl) {
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

}
