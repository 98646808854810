import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export class AIWorkflowTask {
  id: string;
  name: string;

  constructor(name: string) {
    this.name = name;
    this.id = name + new Date().getTime().toString();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  public taskInProgress = new BehaviorSubject<AIWorkflowTask[]>([]);

  constructor() {
  }

  public addTask(taskName: string): AIWorkflowTask {
    const task = new AIWorkflowTask(taskName);
    const scheduledTask = Promise.resolve(null);
    scheduledTask.then(() => {
      const newList = this.taskInProgress.getValue();
      newList.push(task);
      this.taskInProgress.next(newList);
    });
    return task;
  }

  public removeTask(id: string) {
    const newList = this.taskInProgress.getValue();
    //console.log(newList);
    let index = -1;
    const scheduledTask = Promise.resolve(null);
    scheduledTask.then(() => {
    do {
        index = newList.findIndex(task => task.id == id);
        if (index > -1) {
          newList.splice(index, 1);
          this.taskInProgress.next(newList);
        }
      } while (index > -1);
    });
    /*const index = newList.findIndex(task => task.id == id);
    if (index > -1) {
      const scheduledTask = Promise.resolve(null);
      scheduledTask.then(() => {
        newList.splice(index, 1);
        this.taskInProgress.next(newList);
      });
    }*/
  }

  public removeTaskByName(name: string) {
    const newList = this.taskInProgress.getValue();
    //console.log(newList);
    let index = -1;
    const scheduledTask = Promise.resolve(null);
    scheduledTask.then(() => {
      do {
        index = newList.findIndex(task => task.name == name);
        if (index > -1) {
          newList.splice(index, 1);
          this.taskInProgress.next(newList);
        }
      } while (index > -1);
    });
  }
}
