<mat-tab-group [style.width]="'100%'">
    <mat-tab label="Táblanézegető">
        <div class="table-viewer"[style.display]="'flex'" [style.flex-direction]="'column'" [style.align-items]="'start'">
            <div class="table-params" >
                <form (ngSubmit)="submitTableForm()" [formGroup]="dataForm" >
                    <div [style.display]="'flex'" [style.justify-content]="'space-evenly'" [style.align-items]="'space-evenly'">
                        <mat-form-field appearance="outline" [style.margin]="'10px'">
                            <mat-label>Table name</mat-label>
                            <input matInput type="text" formControlName="tableName">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [style.margin]="'10px'">
                            <mat-label>Where</mat-label>
                            <input matInput type="text" formControlName="where">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [style.margin]="'10px'">
                            <mat-label>Fields</mat-label>
                            <input matInput type="text" formControlName="fields">
                        </mat-form-field>
                    </div>
                    <button [style.margin]="'10px'" type="submit" mat-button class="positiveButton">Lekérdezés</button>
                </form>
            </div>
            <hr>
            <div class="table-results">
                <mat-spinner *ngIf="isLoadingTable"></mat-spinner>
                <table mat-table [dataSource]="colData">
                    <ng-container *ngFor="let col of colNames" [matColumnDef]="col">
                        <th mat-header-cell *matHeaderCellDef>{{col}}</th>
                        <td mat-cell *matCellDef="let row">{{row[col]}}</td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="colNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: colNames;"></tr>
                </table>
                <button [style.margin]="'10px'" mat-button class="positiveButton" (click)="exportData()">Export</button>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Fájlnézegető">
        <div class="log-viewer">
            <div class="log-params">
                <form (ngSubmit)="submitFileForm()" [style.margin]="'20px'" [formGroup]="fileForm" [style.min-width]="'700px'">
                    <div  [style.display]="'flex'" [style.justify-content]="'space-evenly'" [style.align-items]="'space-evenly'">
                        <mat-form-field appearance="outline" [style.margin]="'10px'">
                            <mat-label>File Path</mat-label>
                            <input matInput type="text" formControlName="filePath">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [style.margin]="'10px'">
                            <mat-label>Sorok</mat-label>
                            <input matInput formControlName="lines" type="number">
                        </mat-form-field>
                        <mat-checkbox [style.margin]="'10px'" [style.align-self]="'center'" formControlName="download">Download</mat-checkbox>   
                    </div>
                    <button [style.margin]="'10px'" [style.align-self]="'flex-end'" type="submit" mat-button class="positiveButton">Lekérdezés</button>
                    <div class="log-result">
                        <mat-form-field appearance="outline" [style.margin]="'10px'" [style.width]="'100%'">
                            <textarea readonly="true" matInput formControlName="fileRes"></textarea>
                        </mat-form-field>
                    </div>
                </form>
            </div>        
        </div>
    </mat-tab>
    <mat-tab label="Debug level">
            <form [formGroup]="sysparams" (ngSubmit)="sendParameters()">
                <div [style.display]="'flex'" [style.justify-content]="'flex-start'">
                <mat-form-field appearance="outline" [style.margin]="'10px'" [style.max-width]="'200px'">
                    <mat-label>Debug level</mat-label>
                    <input matInput formControlName="debugLevel" type="number">
                </mat-form-field>
                
                </div>
                <button type="submit" mat-button class="positiveButton" [style.margin]="'10px'">Küldés</button>
            </form>
    </mat-tab>
</mat-tab-group>
