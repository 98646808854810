import {NgModule} from '@angular/core';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSliderModule} from '@angular/material/slider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTreeModule} from '@angular/material/tree';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
  imports: [
    CdkTableModule, MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatButtonToggleModule, MatCardModule, MatStepperModule,
    MatDatepickerModule, MatDialogModule, MatIconModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule,
    MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatSortModule, MatTableModule,
    MatToolbarModule, MatTooltipModule, CdkTreeModule, MatSlideToggleModule, MatChipsModule, MatListModule, MatMenuModule, MatTabsModule,
    DragDropModule, MatSliderModule, MatProgressSpinnerModule, MatTreeModule, MatGridListModule
  ],
  exports: [
    CdkTableModule, MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatButtonToggleModule, MatCardModule, MatStepperModule,
    MatDatepickerModule, MatDialogModule, MatIconModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule,
    MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatSortModule, MatTableModule,
    MatToolbarModule, MatTooltipModule, CdkTreeModule, MatSlideToggleModule, MatChipsModule, MatListModule, MatMenuModule, MatTabsModule,
    DragDropModule, MatSliderModule, MatProgressSpinnerModule, MatTreeModule, MatGridListModule
  ]
})
export class AppMaterialModule {
}
