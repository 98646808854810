import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import { CredentialsService } from '../services/credentials.service';
// import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TokenGuardService implements CanActivate {

  constructor(private router: Router, private credentialsService: CredentialsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log(this.router.url);
    if (CredentialsService.role && (route.data.roles as Array<string>).indexOf(CredentialsService.role) > -1) {
      if (!CredentialsService.jwtToken) {
        this.router.navigate(['/login'], {replaceUrl: true}).then();
        return false;
      } else {
        /*const {exp} = jwt_decode(CredentialsService.jwtToken);
        if (exp * 1000 < Date.now()) {
          CredentialsService.jwtToken = null;
          this.router.navigate(['/login'], {replaceUrl: true}).then();
          return false;
        } else {
          return true;
        }*/
        return true;
      }
    } else {
      this.credentialsService.logout();
      return false;
    }
  }
}
