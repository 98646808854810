import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of, Subject } from 'rxjs';
import { startWith, switchMap, map, catchError, delay, filter } from 'rxjs/operators';
import { PaginatedAnswer } from 'src/app/data/response';
import { ProgressService } from 'src/app/services/progress.service';
import { environment } from 'src/environments/environment';
import { TimesheetService } from '../../services/timesheet.service';
import { TimesheetDetailComponent } from './timesheet-detail/timesheet-detail.component';
import { TimesheetDayComponent } from './timesheet-day/timesheet-day.component';
import { CalendarView, CalendarEvent, DAYS_OF_WEEK, CalendarMonthViewDay } from 'angular-calendar';
import { TranslateService } from '@ngx-translate/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { TimesheetEditorComponent } from './timesheet-editor/timesheet-editor.component';
import { UserWfsService } from '../../services/user-wfs.service';
import { CompanyService } from '../../services/company.service';
import { CoworkerService } from '../../services/coworker.service';
import { WfTemplateService } from '../../services/wf-template.service';

import {
  subMonths,
  addMonths,
  addDays,
  addWeeks,
  subDays,
  subWeeks,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  subYears,
} from 'date-fns';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {CredentialsService} from "../../services/credentials.service";

function addPeriod(period: string, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: string, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: string, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: string, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.less']
})
export class TimesheetComponent implements OnInit {

  view: CalendarView = CalendarView.Month;

  cpt_hour = "";
  cpt_hours = "";

  locale = localStorage.getItem('language')
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = true;

  minDate: Date = subYears(new Date(), 1);
  maxDate: Date = new Date();

  //minDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  //maxDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

  range = new FormGroup({
    start: new FormControl(this.minDate),
    end: new FormControl(this.maxDate)
  });

  _viewDate: Date = new Date();

  set viewDate(value) {
    this._viewDate = value;
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, value, 1))
    );
    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, value, 1))
    );
  }

  get viewDate(){
    return this._viewDate;
  }
  events: CalendarEvent[] = [];

  clickedDate: Date;

  clickedColumn: number;

  selected = new FormControl(0);
  showFilters: boolean = false;

  refresh: Subject<any> = new Subject();

  refreshView(): void {
    this.refresh.next();
  }

  displayedColumns: string[] = ['date', 'customer', 'workflow', 'workflow_elem', 'coworker', 'working_hours', 'comment'];

  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(false, []);

  isLoadingResults = true;

  timesheetList;

  customerSelectionItems: any[] = [{value: "all", name: "timesheet_all-client"}];
  wfSelectionItems: any[] = [{value: "all", name: "timesheet_all-workflow"}];
  wfelemSelectionItems: any[] = [{value: "all", name: "timesheet_all-workflow-element"}];
  coworkerSelectionItems: any[] = [{value: "all", name: "timesheet_all-coworker"}];

  wfs = [];

  searchSelectionForm: FormGroup = this.formBuilder.group({
    customer: "all",
    workflow: {value: "all", disabled: true},
    wfelem: {value: "all", disabled: true},
    coworker: "all"
  });

  params = {"order": [
    {
      "column": "working_day",
      "direction": "asc"
    }
  ]}

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

  translatableTexts: any[] = [
    {value: "error_message_work_save_unsuccessful", name: ""},
    {value: "common_message_work_save_successful", name: ""}
    ];

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar, private progressService: ProgressService, private service: TimesheetService, private dialogService: MatDialog, private translateService: TranslateService, private cdr: ChangeDetectorRef, private _adapter: DateAdapter<any>, private formBuilder: FormBuilder, private userWfService: UserWfsService, private companyService: CompanyService, private wfTemplateService: WfTemplateService) { }

  ngOnInit(): void {

    if(localStorage.getItem('language')=="hu_HU"){this.cpt_hour = "óra";this.cpt_hours = "óra"}
    if(localStorage.getItem('language')=="en_GB"){this.cpt_hour = "hour";this.cpt_hours = "hours"}
/*
    this.searchSelectionForm.controls.customer.valueChanges.subscribe((value)=>{
      this.searchSelectionForm.controls['workflow'].enable();
      this.userWfService.getWfsByCustomer(value).subscribe((response)=>{
        // console.log(response);
        this.wfSelectionItems = [];
        this.wfSelectionItems.push({name: "timesheet_all-workflow", value: "all"});
        response.payload.content.forEach((wf)=>{
          if (wf.is_active){
            this.wfSelectionItems.push({name: wf.caption, value: wf.id});
          }
        });
        this.searchSelectionForm.get("workflow").updateValueAndValidity();
      })
      this.loadData("onInit 1");
    });
*/
    /*
    this.searchSelectionForm.controls.workflow.valueChanges.subscribe((value)=>{
      if(value!="all") {
        this.searchSelectionForm.controls['wfelem'].enable();
        this.userWfService.getWfes(value).subscribe((response)=> {
          // console.log(response);
          this.wfelemSelectionItems = [];
          this.wfelemSelectionItems.push({name: "timesheet_all-workflow-element", value: "all"});
          response.payload.content.forEach((wfe)=> {
            if (wfe.is_active) {
              this.wfelemSelectionItems.push({name: wfe.caption, value: wfe.id});
            }
          });
          this.searchSelectionForm.get("wfelem").updateValueAndValidity();
        })
      }
      this.loadData("onInit 2");
    });

    this.searchSelectionForm.controls.wfelem.valueChanges.subscribe((value)=>{
      this.loadData("onInit 3");
    });
*/
    this.range.controls.start.valueChanges.subscribe((value) => {
      if (value){
        this.minDate = new Date(value);
        this.changeDate(new Date(value), false); 
      }
    });

    this.range.controls.end.valueChanges.subscribe((value) => {
      if (value){
        this.maxDate = new Date(value);
        this.changeDate(new Date(value), false);
      }
    });

    //TODO min és max date range-re lekérni
    this._adapter.setLocale(this.locale.replace("_", "-"));
    // this.loadData("onOninit 4");
    this.translateService.onLangChange.subscribe((event)=>{
      this.locale = event.lang;
      this._adapter.setLocale(event.lang.replace("_", "-"));
      // this.loadData("onInit 5");
    })

    this.changeDate(new Date());
/* */
    this.userWfService.getWfs("timesheet").subscribe((response)=>{
      // console.log(response);
      response.payload.content.forEach((wf)=>{
        if (wf.is_active){
          this.wfs.push(wf)
        }
      })
    })
/* */
    /*
    this.companyService.getCompanies().subscribe((response) =>{
      response.payload.content.forEach((company) => {
        if (company.is_active){
          this.customerSelectionItems.push({name: company.short_name, value: company.id})
        }
      })
    })
*/
    /*
    this.wfTemplateService.listCoworkers(CredentialsService.role).subscribe((response)=>{
      let coworkers = [{value: "all", name: "timesheet_all-coworker"}]
      response.payload.content.forEach((coworker)=>{
        if (coworker.is_active){
          coworkers.push({name:this.createName(coworker), value:coworker.id.toString()})
        }
      })
      this.coworkerSelectionItems = coworkers
    })
*/
    this.translateService.stream(['error_message_work_save_unsuccessful', 'common_message_work_save_successful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_work_save_unsuccessful", name: res["error_message_work_save_unsuccessful"]},
        {value: "common_message_work_save_successful", name: res["common_message_work_save_successful"]}
      ];
    });

  }


  ngAfterViewInit() {
    
  }

  loadData(loc){
    console.log("loadData:"+loc);
    let select = {};
    select['operator'] = "AND";
    select['filters'] = [];
    if(this.minDate)select['filters'].push({column:"working_day", relation:">=", value:Math.round(this.minDate.getTime()/1000)});
    if(this.maxDate)select['filters'].push({column:"working_day", relation:"<=", value:Math.round(this.maxDate.getTime()/1000)});
    if(this.searchSelectionForm.controls.wfelem.value!=null && this.searchSelectionForm.controls.wfelem.value!='all'){
      select['filters'].push({column:"workflow_element_id", relation:"=", value:this.searchSelectionForm.controls['wfelem'].value});
    }
    this.params['select'] = select;
    // this.params['select'] = {}; ///////////// It must be commented out!
/*    this.params = {"order": [
      {
        "column": "working_day",
        "direction": "asc"
      }
    ]}*/
    // console.log(this.params);
    this.service.listTimesheets(this.params).subscribe((response) => {
      this.events = [];
      this.timesheetList = response;

      response.forEach((data) => {
        // console.log(data.customer.short_name);
        let calendarPoint = {
          title: (data.customer.short_name ? data.customer.short_name : data.customer.long_name) + ": " + data.working_time/3600 + (data.working_time > 1 ? " "+this.cpt_hours: " "+this.cpt_hour),
          start: new Date(data.working_day * 1000),
          meta: {
            data: data,
          },
        }
        this.events.push(calendarPoint);


      })

      /*
      this.timesheetList.forEach((timesheet) => {
        console.log(timesheet)
        this.userWfService.getWfElement(timesheet.workflow_element_id).subscribe((response) => {
          timesheet.workflow_element_caption = response.payload.caption;
          let workflow = this.wfs.find(wf => wf.id == response.payload.workflow_id)
          timesheet.workflow = workflow?.caption;
          //timesheet.customer =  this.findSelectionItem(this.customerSelectionItems,this.wfs.find(wf => wf.id == workflow.value))?.name;
        })
      })
*/
      this.refreshView();
    })
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }

  join(t, a, s) {
  function format(m) {
    let f = new Intl.DateTimeFormat('en', m);
    return f.format(t);
  }
  return a.map(format).join(s);
  }

  openDay(day){
    if (day.events.length > 0){
    const dialog = this.dialogService.open(TimesheetDayComponent);
      dialog.componentInstance.data = day;

      dialog.afterClosed().subscribe(response => {
        if (response){
          if (response.type == "OK") {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_work_save_successful").name, null, {panelClass: 'success-snackbar'});
            this.loadData("openDay 1");
          }
          else{
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_work_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
            let dialog = this.dialogService.open(ErrorDialogComponent);
            dialog.componentInstance.error = response.payload;
          }
        }
      });
    } else {
      const dialog = this.dialogService.open(TimesheetEditorComponent);
      let a = [{year: 'numeric'},{month: '2-digit'},{day: '2-digit'}];
      dialog.componentInstance.clickedDay = this.join(day.date, a, '-');
      dialog.afterClosed().subscribe(response => {
        if (response){
          if (response.type == "OK") {
            this.snackBar.open("A munka mentése sikeres", null, {panelClass: 'success-snackbar'});
            this.loadData("openDay 2");
          }
          else{
            this.snackBar.open("A munka mentése sikertelen", null, {panelClass: 'error-snackbar'});
            let dialog = this.dialogService.open(ErrorDialogComponent);
            dialog.componentInstance.error = response.payload;
          }
        } else {
          this.loadData("openDay 3");
        }

      });
    }
  }

  addNewTask(){
    const dialog = this.dialogService.open(TimesheetEditorComponent);
    dialog.afterClosed().subscribe(response => {
      if (response){
        if (response.type == "OK") {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_work_save_successful").name, null, {panelClass: 'success-snackbar'});
          this.loadData("addNewTask 1");
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_work_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        } 
      }
      
    });
  }

  openDetail(row){
      const dialog = this.dialogService.open(TimesheetDetailComponent);
      dialog.componentInstance.data = row;
  
  }

  increment(): void {
    this.changeDate(addPeriod(this.view, this.viewDate, 1));
  }

  decrement(): void {
    this.changeDate(subPeriod(this.view, this.viewDate, 1));
  }


  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  changeDate(date: Date, changeEnabled = true): void {
    this.viewDate = date;
    this.dateChanged(changeEnabled);
  }

  dateChanged(changeEnabled): void {
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
    );
    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
    );

    /*if (changeEnabled){
      if (this.viewDate < this.minDate) {
        this.viewDate = this.minDate;
      } else if (this.viewDate > this.maxDate) {
        this.viewDate = this.maxDate;
      }
    }*/
    this.loadData("dateChanged 1");
    this.cdr.detectChanges();
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }
    });
  }

}
