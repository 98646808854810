import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Auditor} from '../data/auditor';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import {ApiEndpoint} from "../../environments/api-endpoint";
import {catchError, finalize} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {CountAnswer, PaginatedAnswer, PaginatedRequest, Response} from "../data/response";
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuditorService extends BaseService<Auditor> implements OnInit{
  translatableTexts: any[] = [
    {value: "error_message_audit_clients_list_unsuccessful", name: ""},
    {value: "error_message_audit_client_get_unsuccessful", name: ""},
    {value: "error_message_audit_client_delete_unsuccessful", name: ""},
    {value: "error_message_audit_client_create_unsuccessful", name: ""},
    {value: "error_message_audit_client_update_unsuccessful", name: ""},
    {value: "error_message_audit_softwares_get_unsuccessful", name: ""},
    {value: "error_message_auditor_statuses_get_unsuccessful", name: ""},
    {value: "error_message_reset_password_unsuccessful", name: ""},
    {value: "error_message_countries_count_unsuccessful", name: ""},
    {value: "error_message_countries_list_unsuccessful", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
        {
          listUrl: ApiEndpoint.auditorsEndpoint,
          getUrl: ApiEndpoint.auditorsGetEndpoint,
          createUrl: ApiEndpoint.auditorsEndpoint,
          deleteUrl: '',
          updateUrl: ApiEndpoint.auditorsEndpoint
        },
        {
          listError: 'A könyvvizsgáló ügyfelek letöltése sikertelen',
          getError: 'A könyvvizsgáló ügyfél letöltése sikertelen',
          deleteError: 'A könyvvizsgáló ügyfél törlése sikertelen',
          createError: 'A könyvvizsgáló ügyfél létrehozása sikertelen',
          updateError: 'A könyvvizsgáló ügyfél módosítása sikertelen'
        },
        httpClient, snackBar, progressService);
  }

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_audit_clients_list_unsuccessful',
      'error_message_audit_client_get_unsuccessful',
      'error_message_audit_client_delete_unsuccessful',
      'error_message_audit_client_create_unsuccessful',
      'error_message_audit_client_update_unsuccessful',
      'error_message_audit_softwares_get_unsuccessful',
      'error_message_auditor_statuses_get_unsuccessful',
      'error_message_reset_password_unsuccessful',
      'error_message_countries_count_unsuccessful',
      'error_message_countries_list_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_audit_clients_list_unsuccessful", name: res["error_message_audit_clients_list_unsuccessful"]},
        {value: "error_message_audit_client_get_unsuccessful", name: res["error_message_audit_client_get_unsuccessful"]},
        {value: "error_message_audit_client_delete_unsuccessful", name: res["error_message_audit_client_delete_unsuccessful"]},
        {value: "error_message_audit_client_create_unsuccessful", name: res["error_message_audit_client_create_unsuccessful"]},
        {value: "error_message_audit_client_update_unsuccessful", name: res["error_message_audit_client_update_unsuccessful"]},
        {value: "error_message_audit_softwares_get_unsuccessful", name: res["error_message_audit_softwares_get_unsuccessful"]},
        {value: "error_message_auditor_statuses_get_unsuccessful", name: res["error_message_auditor_statuses_get_unsuccessful"]},
        {value: "error_message_reset_password_unsuccessful", name: res["error_message_reset_password_unsuccessful"]},
        {value: "error_message_countries_count_unsuccessful", name: res["error_message_countries_count_unsuccessful"]},
        {value: "error_message_countries_list_unsuccessful", name: res["error_message_countries_list_unsuccessful"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_audit_clients_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_audit_client_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_audit_client_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_audit_client_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_audit_client_update_unsuccessful").name
  }

  listAuditors(paginatedRequest: PaginatedRequest): Observable<PaginatedAnswer<Auditor>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(paginatedRequest));
    return this.httpClient.get<PaginatedAnswer<Auditor>>(environment.serverEndpoint + '/admin/auditors?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  listAuditorSoftwares(): Observable<PaginatedAnswer<Auditor>> {
    const task = this.progressService.addTask(this.constructor.name + '_list_auditor_softwares');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<PaginatedAnswer<Auditor>>(environment.serverEndpoint + '/admin/auditorprograms', {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_audit_softwares_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  listAuditorStatuses(): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_list_auditor_statuses');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/common/auditorstatuses', {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_auditor_statuses_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  passwordReset(email_address: string): Observable<Response<{ success: string }>> {
    return this.httpClient.post<Response<{ success: string }>>(environment.serverEndpoint + '/admin/resetpassword', {email_address})
        .pipe(
            catchError((error) => {
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_reset_password_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              return throwError(error);
            })
        );
  }

  deleteAuditor(id: any): Observable<PaginatedAnswer<Auditor>> {
    const task = this.progressService.addTask(this.constructor.name + '_delete');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id
      },
    };
    
    return this.httpClient.delete<any>(environment.serverEndpoint + '/admin/auditor', options).pipe(
      catchError(error => {
        this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  async countCountries(request): Promise<CountAnswer> {
    const task = this.progressService.addTask(this.constructor.name + '_count_countires');
    return await this.httpClient.post<CountAnswer>(environment.serverEndpoint + '/admin/country/count', request).toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_countries_count_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
    .finally(()=>{this.progressService.removeTask(task.id)})
  }

  getCountries(paginatedRequest: PaginatedRequest): Observable<PaginatedAnswer<Auditor>> {
    const task = this.progressService.addTask(this.constructor.name + '_list_countries');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(paginatedRequest));
    return this.httpClient.get<PaginatedAnswer<Auditor>>(environment.serverEndpoint + '/admin/countries?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_countries_list_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }
}
