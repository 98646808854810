<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div>
        <button mat-icon-button matTooltip="{{'timesheet_filters' | translate}}" (click)="showFilters = !showFilters">
            <mat-icon>filter_alt</mat-icon>
        </button>
                
        <button mat-icon-button matTooltip="{{'timesheet_work_recording' | translate}}" (click)="addNewTask()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="showFilters" class="filters">
    <div id="rangeDatePicker">
      <mat-form-field appearance="standard" *ngIf="showFilters">
        <mat-label>{{'timesheet_period' | translate}}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="'common_title_start' | translate">
          <input matEndDate formControlName="end" placeholder="'common_title_end' | translate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'error_message_invalid_start_date' | translate}}</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'error_message_invalid_end_date' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="showFilters">
    <div>
      <app-select-search [appearanceStyle]="'standard'" [selectionItems]="customerSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'timesheet_client' | translate" [selectCtrl]="searchSelectionForm.controls.customer" [required]="false"></app-select-search>
      <app-select-search [appearanceStyle]="'standard'" [selectionItems]="wfSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'timesheet_workflow' | translate" [selectCtrl]="searchSelectionForm.controls.workflow" [required]="false"></app-select-search>
      <app-select-search [appearanceStyle]="'standard'" [selectionItems]="wfelemSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'timesheet_workflow-element' | translate" [selectCtrl]="searchSelectionForm.controls.wfelem" [required]="false"></app-select-search>
      <app-select-search [appearanceStyle]="'standard'" [selectionItems]="coworkerSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'timesheet_coworker' | translate" [selectCtrl]="searchSelectionForm.controls.coworker" [required]="false"></app-select-search>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" animationDuration="0ms">
    <mat-tab label="{{'timesheet_calendar' | translate}}">

      <div style="display: flex; align-items: center; justify-content: center; margin-top: 1rem; margin-bottom: 1rem;">
        <button mat-icon-button (click)="decrement()" *ngIf="!prevBtnDisabled">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <span style="text-transform: capitalize; font-size: 18px;">{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</span>
        <mat-form-field appearance="fill" id="datePickerField">
          <input matInput [matDatepicker]="pickerInput" [(ngModel)]="viewDate" disabled [min]="range.controls.start.value" [max]="range.controls.end.value">
          <mat-datepicker-toggle matSuffix [for]="pickerInput"></mat-datepicker-toggle>
          <mat-datepicker #pickerInput disabled="false"></mat-datepicker>
        </mat-form-field>        
        
        <button mat-icon-button (click)="increment()" *ngIf="!nextBtnDisabled">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>

        <div [ngSwitch]="view">
            <mwl-calendar-month-view
            *ngSwitchCase="'month'"
            [viewDate]="viewDate"
            [events]="events"
            [locale]="locale"
            [weekStartsOn]="weekStartsOn"
            (columnHeaderClicked)="clickedColumn = $event.isoDayNumber"
            (dayClicked)="openDay($event.day)"
            [refresh]="refresh"
            [cellTemplate]="customCellTemplate"
            (beforeViewRender)="beforeMonthViewRender($event)">
            </mwl-calendar-month-view>
        </div>
    </mat-tab>
    <mat-tab label="{{'timesheet_list' | translate}}">
        <table mat-table [dataSource]="timesheetList">
        
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Dátum</th>
              <td mat-cell *matCellDef="let row"> {{createDateString(row.working_day * 1000)}} </td>
            </ng-container>
            <ng-container matColumnDef="customer">
              <th mat-header-cell *matHeaderCellDef >{{'timesheet_client' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.customer.short_name}} </td>
            </ng-container>
            <ng-container matColumnDef="workflow">
              <th mat-header-cell *matHeaderCellDef>{{'timesheet_workflow' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.workflow}} </td>
            </ng-container>
            <ng-container matColumnDef="workflow_elem">
              <th mat-header-cell *matHeaderCellDef>{{'timesheet_workflow-element' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.workflow_element_caption}} </td>
            </ng-container>
            <ng-container matColumnDef="coworker">
              <th mat-header-cell *matHeaderCellDef>{{'timesheet_coworker' | translate}}</th>
              <td mat-cell *matCellDef="let row">{{createName(row.coworker)}}</td>
            </ng-container>
            <ng-container matColumnDef="working_hours">
              <th mat-header-cell *matHeaderCellDef>{{'timesheet_man-hours' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.working_time/3600 + " " + ((row.working_time/3600) > 1 ? this.cpt_hours: this.cpt_hour)}} </td>
            </ng-container>
            <ng-container matColumnDef="comment">
              <th mat-header-cell *matHeaderCellDef>{{'timesheet_comment' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.comment}}</td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDetail(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>
          
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
            </tr>
          </table>
          
    </mat-tab>
</mat-tab-group>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div>
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
  <small style="margin: 0 10px;" *ngFor="let event of day.events" class="timesheetEvents">{{event?.title}}</small
  >
</ng-template>
