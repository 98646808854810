<h2 mat-dialog-title>{{subtemplateForm.controls.id.value ? ('document_subtype-doc-subtype-modification' | translate): ('document_subtype-create-doc-subtype' | translate)}}</h2>
<mat-dialog-content>
    <form [formGroup]="subtemplateForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'document_subtype-doc-subtype-name' | translate}}</mat-label>
            <input matInput formControlName="caption" required>
            <mat-error *ngIf="subtemplateForm.controls.caption.hasError('required')">
                {{'error_message_name_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveSubtype()" class="positiveButton">{{'document_subtype-save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'document_subtype-reject' | translate}}</button>
</mat-dialog-actions>