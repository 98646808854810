import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Coworker } from 'src/app/data/coworker';
import { PaginatedAnswer } from 'src/app/data/response';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { CoworkerEditorComponent } from './coworker-editor/coworker-editor.component';
import { CoworkerService } from '../../services/coworker.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { RolesService } from '../../services/roles.service';
import { CredentialsService } from '../../services/credentials.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-coworkers',
  templateUrl: './coworkers.component.html',
  styleUrls: ['./coworkers.component.less']
})
export class CoworkersComponent implements OnInit {

  displayedColumns: string[] = ['select', 'last_name', 'first_name', 'username', 'email_address', 'groups', 'is_qc_partner', 'is_active'];

  dataSource = new MatTableDataSource<Coworker>([]);
  selection = new SelectionModel<Coworker>(false, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSize: number = 10;
  currentPage: number = 1;
  resultsLength = 0;
  isLoadingResults = true;

  //TODO
  groupSelectionItems = [];
  qcPartnerSelectionItems = [{value: true, name: "1"}, {value: false, name: "0"}];
  statusSelectionItems = [{value: true, name: "common_title_active"}, {value: false, name: "common_title_inactive"}];
  languageSelectionItems = [];

  coworkers: Coworker[] = [];

  translatableTexts: any[] = [
    {value: "common_message_coworker_save_successful", name: ""},
    {value: "error_message_coworker_save_unsuccessful", name: ""},
    {value: "common_message_coworker_delete_successful", name: ""},
    {value: "error_message_coworker_delete_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private snackBar: MatSnackBar, public http: HttpClient, private service: CoworkerService, private translateService: TranslateService, private languageService: LanguageService, private rolesService: RolesService, private credentialsService: CredentialsService) { }

  ngOnInit() {
    this.translateService.stream(['1', '0']).subscribe((res: string) => {
      //this.qcPartnerSelectionItems = [{value: true, name: res["1"]}, {value: false, name: res["0"]}];
    });

    this.translateService.stream(['common_message_coworker_save_successful', 'error_message_coworker_save_unsuccessful', 'common_message_coworker_delete_successful', 'error_message_coworker_delete_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_coworker_save_successful", name: res["common_message_coworker_save_successful"]},
        {value: "error_message_coworker_save_unsuccessful", name: res["error_message_coworker_save_unsuccessful"]},
        {value: "common_message_coworker_delete_successful", name: res["common_message_coworker_delete_successful"]},
        {value: "error_message_coworker_delete_unsuccessful", name: res["error_message_coworker_delete_unsuccessful"]}
      ];
    });

    this.languageService.loadLanguages().subscribe((response) => {
      response.payload.forEach((language)=>{
        this.languageSelectionItems.push({value: language.locale, name: language.locale})
      })
    });

    this.rolesService.listPrivgroups({}).subscribe((response)=>{
      if(Array.isArray(response.payload)) {
        response.payload.forEach((privgroup)=> {
          if (privgroup.is_active) {
            this.groupSelectionItems.push({value: privgroup.id, name: privgroup.caption});
          }
        })
      }
    })
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.currentPage = 1;
    });

    this.loadTableData()
  }

  loadTableData(response?){
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.service.listCoworkers({
              "page": this.currentPage,
              "size": this.pageSize,
              "order": [
                {    
                  "column": this.sort.active ? this.sort.active : "last_name",
                  "direction": this.sort.direction
                }
              ],
            }
          );
        }),
        map((data: PaginatedAnswer<any>) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.payload.total;

          return data.payload.content;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          return of([]);
        })
    ).subscribe((data) => {
      this.coworkers = [];
      data.forEach(d => {
        if(d.first_name)this.coworkers.push(d);
      })

      if (this.isEdit){
        let index = this.coworkers.findIndex(data=>data.id == response.payload.id)
        if (index >-1 ){
          this.selection.select(this.coworkers[index]);
        }
        this.isEdit = false;
      }
      else{
        this.selection.clear();
      }
    });
  }

  handlePage(event){
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.selection.clear();
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  isEdit = false;
  editCoworker(coworker?: Coworker){

    if (coworker){
      const editorDialog = this.dialogService.open(CoworkerEditorComponent);
      editorDialog.componentInstance.groupSelectionItems = this.groupSelectionItems;
      editorDialog.componentInstance.qcPartnerSelectionItems = this.qcPartnerSelectionItems;
      editorDialog.componentInstance.statusSelectionItems = this.statusSelectionItems;
      editorDialog.componentInstance.coworker = coworker;
      editorDialog.afterClosed().subscribe(resp => {
        if (resp){
          if (resp.type == "OK") {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_save_successful").name, null, {panelClass: 'success-snackbar'});
            this.isEdit = true;
            this.loadTableData(resp);
          }
          else{
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
            let dialog = this.dialogService.open(ErrorDialogComponent);
            dialog.componentInstance.error = resp.payload;
          } 
        }
      });
    } else {    
      const dialog = this.dialogService.open(OkDialogComponent);
      this.translateService.get('coworkers_attention-licence-fee').subscribe((value)=>dialog.componentInstance.title = value)
      this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
      this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
      dialog.afterClosed().subscribe(response => {
        if (response) {
          const editorDialog = this.dialogService.open(CoworkerEditorComponent);
          editorDialog.componentInstance.groupSelectionItems = this.groupSelectionItems;
          editorDialog.componentInstance.qcPartnerSelectionItems = this.qcPartnerSelectionItems;
          editorDialog.componentInstance.statusSelectionItems = this.statusSelectionItems;
          editorDialog.componentInstance.languageSelectionItems = this.languageSelectionItems;
          editorDialog.afterClosed().subscribe(resp => {
            if (resp){
              if (resp.type == "OK") {
                this.loadTableData();
                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_save_successful").name, null, {panelClass: 'success-snackbar'});
                this.selection.clear()
              }
              else{
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                let dialog = this.dialogService.open(ErrorDialogComponent);
                dialog.componentInstance.error = resp.payload;
              } 
            }
          });
        }
      }) 
    }
  }

  deleteCoworker(coworker: Coworker){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('coworkers_remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
        if (response) {
          this.service.deleteCoworker(coworker.id).subscribe((resp) => {
            if (resp){
              if (resp.type == "OK"){
                this.loadTableData();
                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_delete_successful").name, null, {panelClass: 'success-snackbar'});
                this.selection.clear();
              }
              else{
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                let dialog = this.dialogService.open(ErrorDialogComponent);
                dialog.componentInstance.error = resp.payload;
              }
            }
            
          });
        }
    })
  }
  
}
