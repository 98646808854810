import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { FileSystemService } from '../../../services/file-system.service';

@Component({
  selector: 'app-label-editor',
  templateUrl: './label-editor.component.html',
  styleUrls: ['./label-editor.component.less']
})
export class LabelEditorComponent implements OnInit {

  labelForm: FormGroup = this.formBuilder.group({
    fileName: [null, [Validators.required], {disabled: true}],
    caption: [null]
  });

  
  _file: any;
  get file() { return this._file; };
  set file(value: any) {
    this.labelForm.patchValue(value)
    this._file = value;
  }

  _customer: any;
  get customer() { return this._customer };
  set customer(value: any) {
    this._customer = value;
  }

  _folderNode: any;
  get folderNode() { return this._folderNode };
  set folderNode(value: any) {
    this._folderNode = value;
  }

  constructor(private formBuilder: FormBuilder, public http: HttpClient, private snackBar: MatSnackBar,  public dialogRef: MatDialogRef<LabelEditorComponent>, private service: FileSystemService) { }

  ngOnInit(): void {
  }

  saveLabel(){
    if (this.labelForm.valid) {
      let body = {
        caption: this.labelForm.get("caption").value,
        customer_id: this.customer,
        name: this.labelForm.get("fileName"),
        parent_path: this.folderNode.path
      }
     this.service.modifyFile(body).then((response) => {
      this.dialogRef.close(this.labelForm.getRawValue());
     });
    }
    else {
      this.labelForm.markAllAsTouched();
    }
  }

}
