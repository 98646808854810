<h2 mat-dialog-title>{{'workflows_audit-report-generation' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="auditReportForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'workflows_report-date' | translate}}</mat-label>
            <input matInput [matDatepicker]="pickerReportDate" [imask]="{mask: 'dateformat-mask' | translate}" #input_report_date>
            <mat-datepicker-toggle matSuffix [for]="pickerReportDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerReportDate></mat-datepicker>
            <!-- input matInput [type]="'date'" formControlName="reportDate" required -->
            <mat-error *ngIf="auditReportForm.controls.reportDate.hasError('required')">
                {{'error_message_report_date_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <app-select-search [selectionItems]="commentTypeSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflows_opinion-type' | translate" [selectCtrl]="auditReportForm.controls.commentType" [required]="true"></app-select-search>
        <mat-form-field>
            <mat-label>{{'common_title_restrictive_note' | translate}}</mat-label>
            <textarea matInput
                      formControlName="restrictionComment"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
        <app-select-search [selectionItems]="restrictionTypeSelectionItems" [selectionType]="'singleSelect'" [showClearButton]="true" [placeholder]="'workflows_restriction-type' | translate" [selectCtrl]="auditReportForm.controls.restrictionType"></app-select-search>
        <app-select-search [selectionItems]="reportCreatorSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflows_report-creator' | translate" [selectCtrl]="auditReportForm.controls.reportCreator" [required]="true"></app-select-search>
        <app-select-search [selectionItems]="reportCreatorSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'Tulajdonos [HU]'" [selectCtrl]="auditReportForm.controls.reportOwner" [required]="true"></app-select-search>

        <!-- span style="width: 50%; padding-bottom: 1.6rem; display: inline-block; font-weight: bold;">Devizás könyvvezetés</span -->
        <mat-checkbox formControlName="isBookingForeignCurrency" [labelPosition]="'before'" style="width: 50%; padding-bottom: 1.6rem; display: inline-block;">{{'common_title_booking_in_foreign_currency' | translate}}</mat-checkbox>
        <mat-checkbox formControlName="areDataFromGV" [labelPosition]="'before'" (change)="updateInputFields()" style="width: 50%; padding-bottom: 1.6rem; display: inline-block;">{{'common_title_data_from_balance_sheet_schema' | translate}}</mat-checkbox>

        <app-select-search [selectionItems]="currencySelectionItems" [selectionType]="'singleSelect'" [placeholder]="'currency' | translate" [selectCtrl]="auditReportForm.controls.currency" [required]="true"></app-select-search>

        <!-- mat-radio-group formControlName="reportType" [required]="true">
            <mat-radio-button value="isContinuationOfBusiness" [labelPosition]="'before'" style="width: 50%;  padding-bottom: 1.6rem; display: inline-block;">{{'workflows_enterprise-resumption' | translate}}  *</mat-radio-button>
            <mat-radio-button value="isWarning" [labelPosition]="'before'" style="width: 50%;  padding-bottom: 1.6rem; display: inline-block;">{{'workflows_attention' | translate}} *</mat-radio-button>
        </mat-radio-group -->
        <mat-form-field appearance="outline">
            <mat-label>{{'common_title_total_income' | translate}} {{ auditReportForm.controls.currency.value == 'HUF' ? ' (ezer)' : '' }} </mat-label>
            <input matInput [type]="'text'" formControlName="totalIncome" [imask]="maskOptions" required>
            <mat-error *ngIf="auditReportForm.controls.totalIncome.hasError('required')">
                {{'error_message_total_income_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'workflows_balance-sum-total' | translate}} {{ auditReportForm.controls.currency.value == 'HUF' ? ' (ezer)' : '' }} </mat-label>
            <input matInput [type]="'text'" formControlName="balanceSheetTotal" [imask]="maskOptions"  required ui-negative-number>
            <mat-error *ngIf="auditReportForm.controls.balanceSheetTotal.hasError('required')">
                {{'error_message_balance_sum_total_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'workflows_profit-after-tax' | translate}} {{ auditReportForm.controls.currency.value == 'HUF' ? ' (ezer) ' : '' }} </mat-label>
            <input matInput [type]="'text'" formControlName="taxedTotal" [imask]="maskOptions"  required ui-negative-number>
            <mat-error *ngIf="auditReportForm.controls.taxedTotal.hasError('required')">
                {{'error_message_profit_after_tax_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-divider style="padding-bottom: 1.6rem;"></mat-divider>
        <span style="width: 50%; padding-bottom: 1.6rem; display: inline-block; font-weight: bold;">{{'workflows_business-report' | translate}}</span>
        <mat-checkbox formControlName="areRequirementsMet" [labelPosition]="'before'" style="width: 50%; padding-bottom: 1.6rem; display: inline-block;">{{'workflows_rite' | translate}}</mat-checkbox>

        <app-select-search [selectionItems]="otherLegislationSelectionItems" [selectionType]="'singleSelect'"
                           [placeholder]="'workflows_other-law' | translate"
                           [selectCtrl]="auditReportForm.controls.otherLegislation"
                           [required]="true" ></app-select-search>

        <mat-form-field *ngIf="showTextInput" appearance="outline">
            <mat-label>{{'workflows_otherLaw-Additional-Info' | translate}}</mat-label>
            <input matInput [type]="'text'" formControlName="additionalInfo"   required >
            <mat-error *ngIf="auditReportForm.controls.taxedTotal.hasError('required')">
                {{'error_message_otherLaw-Additinal-Info_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>

        <app-select-search [selectionItems]="localeItems" [selectionType]="'singleSelect'" [placeholder]="'template_lang' | translate" [selectCtrl]="auditReportForm.controls.locale" [required]="true"></app-select-search>
        <mat-divider style="padding-bottom: 1.6rem;"></mat-divider>
        <span style="width: 50%; padding-bottom: 1.6rem; display: inline-block; font-weight: bold;">E-aláírás</span>
        <a mat-icon-button matTooltip="Dokumentum feltöltése" (click)="uploadFile()">
            <mat-icon>cloud_done</mat-icon>
        </a>
        <a mat-icon-button matTooltip="E-aláírás indítása" (click)="generate(true)">
            <mat-icon>gavel</mat-icon>
        </a>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="generate(false)" class="positiveButton">{{'workflows_generation' | translate}}</button>
    <button mat-button (click)="pending()" class="neutralButton">{{'button_title_leave_pending' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_cancel' | translate}}</button>
    <!-- button mat-button (click)="generatePdf()" class="neutralButton">{{'workflows_pdf-generation' | translate}}</button -->
</mat-dialog-actions>
