<mat-dialog-content style="text-align: center;">
    <h2>{{this.reportType.caption | translate}}</h2>
    <form [formGroup]="reportForm" class="reportingForm">
        <br/>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="singleCustomerVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_company' | translate}}</span>
            <app-select-search [selectionItems]="customerSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_client' | translate" [selectCtrl]="reportForm.controls.single_customer_id" [required]="true">
            </app-select-search>
        </div>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="singleWorkflowVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_workflow' | translate}}</span>
            <app-select-search [selectionItems]="workflowSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_workflow' | translate" [selectCtrl]="reportForm.controls.single_workflow_id" [required]="true">
            </app-select-search>
        </div>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="signerVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_signers' | translate}}</span>
            <app-select-search [selectionItems]="signerSelectionItems" [selectionType]="'multiSelect'" [placeholder]="'common_title_signers' | translate" [selectCtrl]="reportForm.controls.signer_ids" [required]="true">
            </app-select-search>
        </div>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="intervallumStartVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_start_of_interval' | translate}}</span>
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_start_date' | translate}}</mat-label>
                <input matInput [matDatepicker]="pickerPeriodStart" [placeholder]="'common_title_start_date' | translate" [imask]="{mask: 'dateformat-mask' | translate}" required #input_period_start>
                <mat-datepicker-toggle matSuffix [for]="pickerPeriodStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerPeriodStart></mat-datepicker>
                <!-- input matInput [type]="'date'" required -->
                <mat-error *ngIf="reportForm.controls.intervalStartDate.hasError('required')">
                    {{'error_message_report_date_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="intervallumEndVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_end_of_interval' | translate}}</span>
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_end_date' | translate}}</mat-label>
                <input matInput [matDatepicker]="pickerPeriodEnd" [placeholder]="'dateformat-placeholder' | translate" [imask]="{mask: 'dateformat-mask' | translate}" required #input_period_end>
                <mat-datepicker-toggle matSuffix [for]="pickerPeriodEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerPeriodEnd></mat-datepicker>
                <!-- input matInput [type]="'date'" required -->
                <mat-error *ngIf="reportForm.controls.intervalStartDate.hasError('required')">
                    {{'error_message_report_date_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 1.5rem;" *ngIf="xmlExportVisible">........
                <mat-checkbox formControlName="is_export_xml" labelPosition = "before" [style.width]="'100%'">
                    {{'report_xml_export' | translate}}
                </mat-checkbox>
	    </div>
        <br/>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="coworkerVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_coworker' | translate}}</span>
            <app-select-search [selectionItems]="coworkerSelectionItems" [selectionType]="'multiSelect'" [placeholder]="'common_title_coworker' | translate" [selectCtrl]="reportForm.controls.coworker_ids" [required]="true">
            </app-select-search>
        </div>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="customerVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_company' | translate}}</span>
            <app-select-search [selectionItems]="customerSelectionItems" [selectionType]="'multiSelect'" [placeholder]="'common_title_client' | translate" [selectCtrl]="reportForm.controls.customer_ids" [required]="true">
            </app-select-search>
        </div>
        <div style="margin-bottom: 15px;" class="report_row_style" *ngIf="sequenceVisible">
            <span style="width: 33%; display: inline-block;" class="report_caption">{{'common_title_order' | translate}}</span>
            <app-select-search [selectionItems]="sequenceSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_order' | translate" [selectCtrl]="reportForm.controls.sequence_sid" [required]="true">
            </app-select-search>
        </div>

        <div style="display: flex; justify-content: space-between; margin-bottom: 1.5rem;" *ngIf="liveOnlyVisible">........
            <mat-checkbox [(ngModel)]="isLiveOnly" formControlName="is_liveOnly" labelPosition = "before" [style.width]="'100%'">
                {{'report_liveOnly' | translate}}
            </mat-checkbox>
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 1.5rem;" *ngIf="passedOnlyVisible">........
            <mat-checkbox [(ngModel)]="isPassedOnly" formControlName="is_passedOnly" labelPosition = "before" [style.width]="'100%'">
                {{'report_passedOnly' | translate}}
            </mat-checkbox>
        </div>
    </form>
</mat-dialog-content>
<div mat-dialog-actions align="end">
    <button mat-button (click)="generate()" class="positiveButton">{{'workflows_generation' | translate}}</button>
</div>
