import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import { CountAnswer, PaginatedAnswer, PaginatedRequest } from '../data/response';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkflowTemplate } from 'src/app/data/workflow-template';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AiService extends BaseService<WorkflowTemplate> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_workflows_list_unsuccessful", name: ""},
    {value: "error_message_workflow_get_unsuccessful", name: ""},
    {value: "error_message_workflow_delete_unsuccessful", name: ""},
    {value: "error_message_workflow_create_unsuccessful", name: ""},
    {value: "error_message_workflow_update_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_elements", name: ""},
    {value: "error_message_error_getting_workflow_statuses", name: ""},
    {value: "error_message_error_getting_workflow_element_statuses", name: ""},
    {value: "error_message_error_saving_coworker", name: ""},
    {value: "error_message_coworker_delete_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_element", name: ""},
    {value: "error_message_error_saving_workflow_element_group", name: ""},
    {value: "error_message_error_getting_balance_status", name: ""},
    {value: "error_message_error_displaying_debit_balance", name: ""},
    {value: "error_message_error_saving_workflow_element_log_entry", name: ""},
    {value: "error_message_workflow_element_delete_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_element_log_entries", name: ""},
    {value: "error_message_log_entry_delete_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_element_file_system", name: ""},
    {value: "error_message_error_getting_workflow_element_coworkers", name: ""},
    {value: "error_message_error_saving_workflow_element_coworkers", name: ""},
    {value: "error_message_error_deleting_coworkers", name: ""},
    {value: "error_message_error_uploading_file", name: ""},
    {value: "error_message_error_saving_debit_balance_approval", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
      {
        listUrl: '/user',
        getUrl: '/user',
        createUrl: '/user/wf',
        deleteUrl: '/user/wf',
        updateUrl: '/user/wf'
      },
      {
        listError: 'A workflowk letöltése sikertelen',
        getError: 'A workflow letöltése sikertelen',
        deleteError: 'A workflow törlése sikertelen',
        createError: 'A workflow létrehozása sikertelen',
        updateError: 'A workflow módosítása sikertelen'
      },
      httpClient, snackBar, progressService);
    }

    ngOnInit(): void {
    }

    postQuestion(body){
      const task = this.progressService.addTask(this.constructor.name + 'post_question');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.post<any>(environment.watsonEndpoint, body, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_coworker").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

}