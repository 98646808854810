import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import { CountAnswer, PaginatedAnswer, PaginatedRequest } from '../data/response';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkflowTemplate } from 'src/app/data/workflow-template';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ReportingService extends BaseService<WorkflowTemplate> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_workflows_list_unsuccessful", name: ""},
    {value: "error_message_workflow_get_unsuccessful", name: ""},
    {value: "error_message_workflow_delete_unsuccessful", name: ""},
    {value: "error_message_workflow_create_unsuccessful", name: ""},
    {value: "error_message_workflow_update_unsuccessful", name: ""},
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
      {
        listUrl: '/user',
        getUrl: '/user',
        createUrl: '/user/wf',
        deleteUrl: '/user/wf',
        updateUrl: '/user/wf'
      },
      {
        listError: 'A workflowk letöltése sikertelen',
        getError: 'A workflow letöltése sikertelen',
        deleteError: 'A workflow törlése sikertelen',
        createError: 'A workflow létrehozása sikertelen',
        updateError: 'A workflow módosítása sikertelen'
      },
      httpClient, snackBar, progressService);
    }

    ngOnInit(): void {
      this.translateService.stream([
        'error_message_workflows_list_unsuccessful',
        'error_message_workflow_get_unsuccessful',
        'error_message_workflow_delete_unsuccessful',
        'error_message_workflow_create_unsuccessful',
        'error_message_workflow_update_unsuccessful'
      ]).subscribe((res: string) => {
        this.translatableTexts = [
          {value: "error_message_workflows_list_unsuccessful", name: res["error_message_workflows_list_unsuccessful"]},
          {value: "error_message_workflow_get_unsuccessful", name: res["error_message_workflow_get_unsuccessful"]},
          {value: "error_message_workflow_delete_unsuccessful", name: res["error_message_workflow_delete_unsuccessful"]},
          {value: "error_message_workflow_create_unsuccessful", name: res["error_message_workflow_create_unsuccessful"]},
          {value: "error_message_workflow_update_unsuccessful", name: res["error_message_workflow_update_unsuccessful"]}
        ];
      });
  
      this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_workflows_list_unsuccessful").name;
      this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_workflow_get_unsuccessful").name;
      this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_workflow_delete_unsuccessful").name;
      this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_workflow_create_unsuccessful").name;
      this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_workflow_update_unsuccessful").name;
    }

    getReportRX(body, reportSid){
        const task = this.progressService.addTask(this.constructor.name + 'get_report_rx');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/report/'+reportSid+'?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }


}