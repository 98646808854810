<mat-toolbar color="primary">
    <div>
        <button mat-icon-button matTooltip="{{'roles_new-role' | translate}}" (click)="editRole()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'roles_modification' | translate}}" [disabled]="!selectedPrivgroup" (click)="editRole(privgroup)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'roles_remove' | translate}}" [disabled]="!selectedPrivgroup" (click)="deleteRole(privgroup)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'48%'">
                {{'roles_role' | translate}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-center'" [style.width]="'4%'">
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'48%'">
                {{'roles_function' | translate}}
            </div>

        </div>
        <div [style.display] = "'flex'">
            <div [style.width]="'48%'" [style.borderRight]="'1px solid rgba(0, 0, 0, 0.12)'">
                <div *ngFor="let privgroup of privgroups" class="table-item" (click)="selectedPrivgroup== privgroup ? selectedPrivgroup = null : selectedPrivgroup = privgroup; getPrivgroup(privgroup.id)" [class.selected]="selectedPrivgroup?.id == privgroup.id">
                    <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'100%'">
                        {{privgroup.caption | translate}}
                    </div>
                </div>
            </div>
            <div [style.width]="'52%'" [style.pointerEvents]="'none'">
                <div *ngFor="let func of functionsText" class="table-item">
                    <div class="table-text" [style.justify-content]="'flex-center'" [style.width]="'calc(100%/13)'">
                        <mat-checkbox [checked]="hasFunction(func.value)"></mat-checkbox>
                    </div>
                    <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'calc(100%/13*12)'">
                        {{func.text | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
