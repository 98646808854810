<h2 mat-dialog-title style="display: flex; justify-content: space-between;"><div>{{'workflows_balance-statement' | translate}}</div><div style="align-items: flex-end;font-size:14px;color:#039050">{{debitBalanceFormStatus | translate}}</div></h2>
<mat-dialog-content>
    <form [formGroup]="balanceStatementForm">
        <div style="margin-bottom: 15px; display: flex; align-items: center">
        <span style="width: 33%; display: inline-block;">{{'workflows_tribe-board' | translate}} *</span>
        <span style="width: 33%; display: inline-block;">
            <mat-dialog-actions style="margin-bottom: 0 !important">
                 <button mat-button (click)="addFolder('baseTable')" class="neutralButton" [disabled]="isEmailSent">{{'workflows_choice' | translate}}</button> 
            </mat-dialog-actions>
        </span>
        <span class="pointer" (click)="showMasterAgain()" style="width: 33%; display: inline-block;">
            {{balanceStatementForm.controls.baseTable.value}}
            <span *ngIf="masterFilePath?.length" style="display: block; color: darkgrey">
                {{masterFilePath}}
            </span>
        </span>
        </div>
        <div style="margin-bottom: 15px; display: flex; align-items: center;">
            <span style="width: 33%; display: inline-block;">{{'workflows_detailing-board' | translate}} *</span>
            <span style="width: 33%; display: inline-block;">
                <mat-dialog-actions style="margin-bottom: 0 !important">
                     <button mat-button (click)="addFolder('detailTable')" class="neutralButton" [disabled]="isEmailSent">{{'workflows_choice' | translate}}</button>
                </mat-dialog-actions>
            </span>
            <span class="pointer" (click)="showDetailAgain()" style="width: 33%; display: inline-block;">
                {{balanceStatementForm.controls.detailTable.value}}
                <span *ngIf="detailFilePath?.length" style="display: block; color: darkgrey">
                    {{detailFilePath}}
                </span>
            </span>
        </div>
            <mat-button-toggle-group formControlName="balanceSheetType" [disabled]="balanceStatementParams['status']=='DEBIT_BALANCE_STARTED'">
                <mat-button-toggle value="isReceivable">{{'common_title_receivable_accounts' | translate}}</mat-button-toggle>
                <mat-button-toggle value="isPayable">{{'common_title_payable_accounts' | translate}}</mat-button-toggle>
            </mat-button-toggle-group>
            <br>
            <!--<mat-checkbox value="isContinuationOfBusiness" [disabled]="isEmailSent" formControlName="isReceivable" [labelPosition]="'before'" style="width: 50%;  padding-bottom: 1.6rem; display: inline-block;" (change)="changeBase($event.value)">{{'common_title_receivable_accounts' | translate}}</mat-checkbox>-->

            <div class="row">
                <div class="col-12 col-md-6" *ngIf="this.balanceType=='isReceivable'">            
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 20px">
                        <mat-label>{{'workflows_materiality-sill' | translate}}</mat-label>
                        <input matInput type="number" formControlName="isReceivableThreshold" (change)="checkValue()" [disabled]="balanceStatementParams['status']=='DEBIT_BALANCE_STARTED'">
                        <span matSuffix>{{this.customer_currency_iso_code}}</span>
                        <mat-error *ngIf="balanceStatementForm.controls.isReceivableThreshold.hasError('required')">
                            {{'error_message_materiality_sill_cannot_be_empty' | translate}}
                        </mat-error> 
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6" *ngIf="this.balanceType=='isPayable'">           
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 20px">
                        <mat-label>{{'workflows_materiality-sill' | translate}}</mat-label>
                        <input matInput type="number" formControlName="isPayableThreshold" (change)="checkValue()" [disabled]="balanceStatementParams['status']=='DEBIT_BALANCE_STARTED'">
                        <span matSuffix>{{this.customer_currency_iso_code}}</span>
                        <mat-error *ngIf="balanceStatementForm.controls.isPayableThreshold.hasError('required')">
                            {{'error_message_materiality_sill_cannot_be_empty' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6" *ngIf="this.balanceType=='isPayable' || this.balanceType=='isReceivable'">
                    <mat-form-field appearance="outline" style="width: 100%;  margin-top: 20px" >
                        <mat-label>{{'debit-balance-unique-significant' | translate}}</mat-label>
                        <input matInput type="number" formControlName="uniqueSignificant" (change)="checkValue()" [disabled]="balanceStatementParams['status']=='DEBIT_BALANCE_STARTED'">
                        <span matSuffix>{{this.customer_currency_iso_code}}</span>
                        <mat-error *ngIf="balanceStatementForm.controls.uniqueSignificant.hasError('required')">
                            {{'error_message_unique_significant_cannot_be_empty' | translate}}
                        </mat-error>
                    </mat-form-field>
                    
                </div>
            </div>    
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'debit-balance-milestone' | translate}}</mat-label>
                        <input matInput [matDatepicker]="debitBalanceMilestone" [imask]="{mask: 'dateformat-mask' | translate}" required="true" [attr.disabled]="true" #debit_milestone>
        <!--                 formControlName="debitBalanceMilestone" -->
                        <mat-datepicker-toggle matSuffix [for]="debitBalanceMilestone"></mat-datepicker-toggle>
                        <mat-datepicker #debitBalanceMilestone ></mat-datepicker>
                        <mat-error *ngIf="balanceStatementForm.controls.debitBalanceMilestone.hasError('required')">
                            {{'error_message_validity_start_cannot_be_empty' | translate}}
                        </mat-error>
                    </mat-form-field>
                 </div>
                <div class="col-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'custom_deadline' | translate}}</mat-label>
                        <input matInput [matDatepicker]="debitBalanceDeadline" [imask]="{mask: 'dateformat-mask' | translate}" #debit_deadline>
                        <!-- formControlName="debitBalanceDeadline"                 -->
                        <mat-datepicker-toggle matSuffix [for]="debitBalanceDeadline"></mat-datepicker-toggle>
                        <mat-datepicker #debitBalanceDeadline ></mat-datepicker>
                            <!--<mat-error *ngIf="balanceStatementForm.controls.debitBalanceMilestone.hasError('required')">
                                {{'error_message_validity_start_cannot_be_empty' | translate}}
                            </mat-error>-->
                    </mat-form-field>
                </div>
            </div>            
    </form>
    <mat-divider style="padding-bottom: 1.6rem;"></mat-divider>
    <mat-dialog-actions>
        <div class="report-buttons">
            <button mat-button (click)="saveAndCheck()" class="neutralButton" [disabled]="!isCheckButtonEnabled">{{'workflows_save-plus-check' | translate}}</button>
            <button mat-button (click)="retryReset()" class="neutralButton">{{'reset_retry' | translate}}</button>
            <button mat-button (click)="retryCancel()" class="negativeButton">{{'retry_cancel' | translate}}</button>
        </div>  
        <div class="report-buttons">
            <button mat-button class="positiveButton" (click)="getAnswers()" *ngIf="this.isEmailSent">{{ 'common_title_show_answers' | translate }}</button>
            <button mat-button class="neutralButton" (click)="openFeedback()">Visszajelzés</button>
        </div>
    </mat-dialog-actions>
    <div style="width: 100%; display: inline-block; margin-bottom: 10px;" *ngIf="balanceStatementParams['status'] !='DEBIT_STAT_PENDING' && balanceStatementStat['receivable_sum']!=0">
        <div class="table-content">
            <div class="column">
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_touched-partners' | translate}}</span>
                    <span class="deb_value">{{touchedPartners | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_whole-customers-value' | translate}}</span>
                    <span class="deb_value">{{wholeCustomersValue | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_affected-customers-value' | translate}}</span>
                    <span class="deb_value">{{affectedCustomersValue | thousandsFormat}}</span>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; display: inline-block; margin-bottom: 10px;" *ngIf="balanceStatementParams['status'] !='DEBIT_STAT_PENDING' && balanceStatementStat['payable_sum']!=0">
        <div class="table-content">
            <div class="column">
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_whole-supplier-value' | translate}}</span>
                    <span class="deb_value">{{wholeSupplierValue | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_affected-supplier-value' | translate}}</span>
                    <span class="deb_value">{{affectedSupplierValue | thousandsFormat}}</span>
                </div>
            </div>
        </div>
    </div>
    <mat-divider style="padding-bottom: 1.6rem;"></mat-divider>
    <div style="width: 100%; display: inline-block;" *ngIf="balanceStatementParams['status'] !='DEBIT_STAT_PENDING' && balanceStatementStat['receivable_sum']!=0">
        <div style="margin-bottom: 10px;font-weight: bold">{{'common_title_receivable_accounts' | translate}}</div>
        <div class="table-content">
            <div class="column">
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_received-responds' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['received_answers']}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_wait-for-confimation' | translate}}</span>
                    <span class="deb_value">{{(balanceStatementStat['receivable_not_answered_records']?balanceStatementStat['receivable_not_answered_records']:0)+(balanceStatementStat['receivable_awaiting_confirmation_records']?balanceStatementStat['receivable_awaiting_confirmation_records']:0)}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_value-with-confirmation' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['receivable_value_covered_by_feedback'] | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate">{{'workflows_ratio' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['receivable_feedback_rate'] | percentsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_accepted-value' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['receivable_accepted_sum'] | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate">{{'workflows_ratio' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['receivable_accepted_sum_rate'] | percentsFormat}}</span>
               </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate">{{'workflows_ratio_total' | translate}}</span>                    
                    <span class="deb_value">{{balanceStatementStat['receivable_accepted_per_sum'] | percentsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_rejected-value' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['receivable_rejected_sum'] | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate">{{'workflows_ratio' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['receivable_rejected_sum_rate'] | percentsFormat}}</span>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; display: inline-block;" *ngIf="balanceStatementParams['status'] !='DEBIT_STAT_PENDING' && balanceStatementStat['payable_sum']!=0">
        <div style="margin-bottom: 10px;font-weight: bold">{{'common_title_payable_accounts' | translate}}</div>
        <div class="table-content">
            <div class="column">
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_received-responds' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['received_answers']}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_wait-for-confimation' | translate}}</span>
                    <span class="deb_value">{{(balanceStatementStat['payable_not_answered_records']?balanceStatementStat['payable_not_answered_records']:0)+(balanceStatementStat['payable_awaiting_confirmation_records']?balanceStatementStat['payable_awaiting_confirmation_records']:0)}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_value-with-confirmation' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['payable_value_covered_by_feedback'] | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate"> {{'workflows_ratio' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['payable_feedback_rate'] | percentsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_accepted-value' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['payable_accepted_sum'] | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate">{{'workflows_ratio' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['payable_accepted_sum_rate'] | percentsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate">{{'workflows_ratio_total' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['payable_accepted_per_sum'] | percentsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption">{{'workflows_rejected-value' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['payable_rejected_sum'] | thousandsFormat}}</span>
                </div>
                <div class="table-item stat-element" >
                    <span class="deb_caption_rate">{{'workflows_ratio' | translate}}</span>
                    <span class="deb_value">{{balanceStatementStat['payable_rejected_sum_rate'] | percentsFormat}}</span>
                </div>
            </div>
        </div>        
    </div>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="startProcess()" class="positiveButton" [disabled]="!isDataChecked" *ngIf="!isEmailSent">{{'workflows_process-start' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_back' | translate}}</button>
</mat-dialog-actions>
