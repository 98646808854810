import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { merge, of, Observable, throwError } from 'rxjs';
import { startWith, switchMap, map, catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Auditor } from '../data/auditor';
import { CountAnswer, PaginatedAnswer, PaginatedRequest } from '../data/response';
import { ProgressService } from './progress.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnInit{
    languageCount;

    languageList;

    translatableTexts: any[] = [
      {value: "error_message_languages_get_unsuccessful", name: ""}
    ];

    constructor(private httpClient: HttpClient, private snackBar: MatSnackBar, private progressService: ProgressService, private translateService: TranslateService) {}

    ngOnInit(): void {
      this.translateService.stream([
        'error_message_languages_get_unsuccessful'
      ]).subscribe((res: string) => {
        this.translatableTexts = [
          {value: "error_message_languages_get_unsuccessful", name: res["error_message_languages_get_unsuccessful"]}
        ];
      });
    }

      loadLanguages() {
        const task = this.progressService.addTask(this.constructor.name + '_get_languages');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/common/languages', {headers}).pipe(
            catchError(error => {
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_languages_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              console.log(error);
              return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
      }
    
    /*loadLanguages(){
        merge()
        .pipe(
            startWith({}),
            switchMap(() => {
              return this.listLanguages({
                  "page": 1,
                  "size": this.languageCount
                }
              );
            }),
            map((data: PaginatedAnswer<any>) => {
              return data;
            }),
            catchError(() => {
              return of([]);
            })
        ).subscribe((data) => {
          this.languageList = data;
        });
      }
    
      async countLanguages(request): Promise<CountAnswer> {
        const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
        return await this.httpClient.post<CountAnswer>(environment.serverEndpoint + '/admin/language/count', request).toPromise()
        .catch(error => {
          this.snackBar.open("A nyelvek betöltése sikertelen", null, {panelClass: 'error-snackbar'});
          console.log(error);
          return Promise.reject(error);
        })
      }
    
      listLanguages(paginatedRequest: PaginatedRequest): Observable<PaginatedAnswer<Auditor>> {
        const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(paginatedRequest));
        return this.httpClient.post<PaginatedAnswer<Auditor>>(environment.serverEndpoint + '/admin/language/list',  {paginatedRequest}, {headers}).pipe(
            catchError(error => {
              this.snackBar.open("A nyelvek betöltése sikertelen", null, {panelClass: 'error-snackbar'});
              console.log(error);
              return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
      }*/
}