import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AiWorkflowTitleService {
  public title: BehaviorSubject<string>;

  constructor(private titleService: Title, private meta: Meta) {
    this.title = new BehaviorSubject<string>(titleService.getTitle());
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
    this.meta.updateTag({ property: 'og:title', content: newTitle });
    this.meta.updateTag({ name: 'twitter:title', content: newTitle });
    this.title.next(newTitle);
  }

  setDescription(desc: string): void {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ name: 'twitter:description', content: desc });
  }
}
