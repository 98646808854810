import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import { Company, Contract } from '../data/company';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import { CountAnswer, PaginatedAnswer, PaginatedRequest } from '../data/response';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService<Company> implements OnInit{
  translatableTexts: any[] = [
    {value: "error_message_companies_list_unsuccessful", name: ""},
    {value: "error_message_company_get_unsuccessful", name: ""},
    {value: "error_message_company_delete_unsuccessful", name: ""},
    {value: "error_message_company_create_unsuccessful", name: ""},
    {value: "error_message_company_edit_unsuccessful", name: ""},
    {value: "error_message_customer_statuses_get_unsuccessful", name: ""},
    {value: "error_message_currencies_list_unsuccessful", name: ""},
    {value: "error_message_contracts_list_unsuccessful", name: ""},
    {value: "error_message_contract_get_unsuccessful", name: ""},
    {value: "error_message_contract_types_get_unsuccessful", name: ""},
    {value: "error_message_contract_statuses_get_unsuccessful", name: ""},
    {value: "error_message_signers_get_unsuccessful", name: ""},
    {value: "error_message_company_signers_get_unsuccessful", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
      {
        listUrl: '/user',
        getUrl: '/user',
        createUrl: '/user/customer',
        deleteUrl: '',
        updateUrl: ''
      },
      {
        listError: 'A cégek letöltése sikertelen',
        getError: 'A cég letöltése sikertelen',
        deleteError: 'A cég törlése sikertelen',
        createError: 'A cég létrehozása sikertelen',
        updateError: 'A cég módosítása sikertelen'
      },
      httpClient, snackBar, progressService);
  }

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_companies_list_unsuccessful',
      'error_message_company_get_unsuccessful',
      'error_message_company_delete_unsuccessful',
      'error_message_company_create_unsuccessful',
      'error_message_company_edit_unsuccessful',
      'error_message_customer_statuses_get_unsuccessful',
      'error_message_currencies_list_unsuccessful',
      'error_message_contracts_list_unsuccessful',
      'error_message_contract_get_unsuccessful',
      'error_message_contract_types_get_unsuccessful',
      'error_message_contract_statuses_get_unsuccessful',
      'error_message_signers_get_unsuccessful',
      'error_message_company_signers_get_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_companies_list_unsuccessful", name: res["error_message_companies_list_unsuccessful"]},
        {value: "error_message_company_get_unsuccessful", name: res["error_message_company_get_unsuccessful"]},
        {value: "error_message_company_delete_unsuccessful", name: res["error_message_company_delete_unsuccessful"]},
        {value: "error_message_company_create_unsuccessful", name: res["error_message_company_create_unsuccessful"]},
        {value: "error_message_company_edit_unsuccessful", name: res["error_message_company_edit_unsuccessful"]},
        {value: "error_message_customer_statuses_get_unsuccessful", name: res["error_message_customer_statuses_get_unsuccessful"]},
        {value: "error_message_currencies_list_unsuccessful", name: res["error_message_currencies_list_unsuccessful"]},
        {value: "error_message_contracts_list_unsuccessful", name: res["error_message_contracts_list_unsuccessful"]},
        {value: "error_message_contract_get_unsuccessful", name: res["error_message_contract_get_unsuccessful"]},
        {value: "error_message_contract_types_get_unsuccessful", name: res["error_message_contract_types_get_unsuccessful"]},
        {value: "error_message_contract_statuses_get_unsuccessful", name: res["error_message_contract_statuses_get_unsuccessful"]},
        {value: "error_message_signers_get_unsuccessful", name: res["error_message_signers_get_unsuccessful"]},
        {value: "error_message_company_signers_get_unsuccessful", name: res["error_message_company_signers_get_unsuccessful"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_companies_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_company_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_company_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_company_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_company_edit_unsuccessful").name
  }

    b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }))
    }

  listCompanies(paginatedRequest): Observable<PaginatedAnswer<Company>> {
    // console.log(paginatedRequest);
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = this.b64EncodeUnicode(JSON.stringify(paginatedRequest));
    return this.httpClient.get<PaginatedAnswer<Company>>(environment.serverEndpoint + '/user/customers?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getCompanies(): Observable<PaginatedAnswer<Company>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<PaginatedAnswer<Company>>(environment.serverEndpoint + '/user/customers', {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  createCompany(body) {
    const task = this.progressService.addTask(this.constructor.name + '_create_company');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<PaginatedAnswer<Company>>(environment.serverEndpoint + '/user/customer',body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  modifyCompany(body) {
    const task = this.progressService.addTask(this.constructor.name + '_modify_company');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    // console.log(body);
    return this.httpClient.put<PaginatedAnswer<Company>>(environment.serverEndpoint + '/user/customer',body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  deleteCompany(id: any): Observable<PaginatedAnswer<Company>> {
    const task = this.progressService.addTask(this.constructor.name + '_delete');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id
      },
    };
    
    return this.httpClient.delete<any>(environment.serverEndpoint + '/user/customer', options).pipe(
      catchError(error => {
        this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getCustomerStatuses(): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_get_customer_statuses');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/common/customerstatuses', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_customer_statuses_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getCurrencies(privLevel): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_list_currencies');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/'+privLevel+'/currency/list', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_currencies_list_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getCustomerInfo(request){
    const task = this.progressService.addTask(this.constructor.name + '_get_customer_info');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(request));
    return this.httpClient.get<any>(environment.serverEndpoint + '/user/customerinfo?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getCountryDimList(){
    const task = this.progressService.addTask(this.constructor.name + '_get_countrydimlist');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify({}));
    return this.httpClient.get<any>(environment.serverEndpoint + '/user/countrydimlist?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  listContracts(paginatedRequest): Observable<PaginatedAnswer<Contract>> {
    const task = this.progressService.addTask(this.constructor.name + '_list_contracts');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(paginatedRequest));
    return this.httpClient.get<PaginatedAnswer<Contract>>(environment.serverEndpoint + '/user/contracts?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_contracts_list_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getContracts(id): Observable<PaginatedAnswer<Contract>> {
    const task = this.progressService.addTask(this.constructor.name + '_list_contracts');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify({customer_id: id}));
    return this.httpClient.get<PaginatedAnswer<Contract>>(environment.serverEndpoint + '/user/contracts?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_contracts_list_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getUserTexts(body){
    const task = this.progressService.addTask(this.constructor.name + '_get_user_texts');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(body));
    return this.httpClient.get(environment.serverEndpoint + '/user/usertexts?payload=' + payload, {headers}).pipe(
      catchError(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_contracts_list_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getContract(body){
    const task = this.progressService.addTask(this.constructor.name + '_get_contract');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(body));
    return this.httpClient.get<any>(environment.serverEndpoint + '/user/contract?payload=' + payload, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_contract_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  deleteContract(id: any): Observable<PaginatedAnswer<Contract>> {
    const task = this.progressService.addTask(this.constructor.name + '_delete_contract');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id
      },
    };
    
    return this.httpClient.delete<any>(environment.serverEndpoint + '/user/contract', options).pipe(
      catchError(error => {
        this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getContractTypes(): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_get_contract_types');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/common/contracttypes', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_contract_types_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getContractStatuses(): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_get_contract_statuses');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/common/contractstatuses', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_contract_statuses_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getSigners(): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_get_signers');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/user/signers', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_signers_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getCompanySigners(): Observable<any> {
    const task = this.progressService.addTask(this.constructor.name + '_get_company_signers');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/user/companysigners', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_company_signers_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

    sendContractToSign(contractId) {
        let body = {
            "contract_id": contractId
        }
        const task = this.progressService.addTask(this.constructor.name + '_send_contract_to_sign');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/contract_signing',body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

}
