<ng-container *ngIf="!isTemplate && !isDetail"><mat-toolbar color="primary">
    <div>
        <button mat-icon-button matTooltip="{{'common_title_filter_table_columns' | translate}}" (click)="filterTableColumns()">
            <mat-icon>filter_alt</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflows_new-workflow' | translate}}" (click)="createWorkflow()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflows_modification' | translate}}" [disabled]="selection.selected.length !== 1" (click)="editWorkflow(selection.selected[0])">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflows_insight' | translate}}" (click)="viewWorkflow()" [disabled]="selection.selected.length !== 1">
            <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Workflow törlése" *ngIf="getRole()=='owner'" [disabled]="selection.selected.length !== 1" (click)="deleteWorkflow(selection.selected[0])">
            <mat-icon>delete</mat-icon>
        </button>
        <app-select-search [appearanceStyle]="'standard'" [selectionItems]="companySelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflows_companies' | translate" [selectCtrl]="customerSelectionForm.controls.customer" [required]="false" [showClearButton]="true"></app-select-search>
        <mat-slide-toggle #toggle [(ngModel)]="showOnlyActiveWorkflows" (change)="showOnlyActiveWorkflowsChg()"
            [checked]="showOnlyActiveWorkflows">
            {{'workflows_only-active-workflows' | translate}}
        </mat-slide-toggle>
     
    </div>
  </mat-toolbar>
    <table mat-table [dataSource]="workflows" matSort cdkDropListOrientation="horizontal"
    cdkDropList (cdkDropListDropped)="reorderColumns($event)">
  
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef cdkDragLockAxis="x" cdkDrag>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-radio-button (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-radio-button>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'workflows_related-company' | translate}}</th>
        <!-- <td mat-cell *matCellDef="let row">{{findSelectionItem(companySelectionItems, findById(contracts, row.contract_id)?.customer_id)?.name}}</td> -->
        <td mat-cell *matCellDef="let row">{{getCompanyName(row)}}</td>
      </ng-container>
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'workflows_contract' | translate}}</th>
        <!-- <td mat-cell *matCellDef="let row"> {{findById(contracts, row.contract_id)?.contract_code}} </td> -->
        <td mat-cell *matCellDef="let row"> {{getContractCode(row)}} </td>
      </ng-container>
      <ng-container matColumnDef="businessYearStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'workflows_business-year-start' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{createDateString(row.start_of_business_year * 1000)}} </td>
      </ng-container>
      <ng-container matColumnDef="businessYearEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'workflows_busines-year-end' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{createDateString(row.end_of_business_year * 1000)}} </td>
      </ng-container>
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'workflows_label' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.caption}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'workflows_status' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{findSelectionItem(statusSelectionItems, row.workflow_status_sid)?.name | translate}}</td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
      </tr>
    </table>
  
    
  <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="handlePage($event)" [showFirstLastButtons]="true"></mat-paginator>
  </ng-container>

  <!--[template]=template-->
  <app-workflow-template *ngIf="isTemplate" (isTemplateEvent)="setIsTemplate($event)" [template]="selection.selected[0]" [strictAssignmentControllSelectionItems]="strictAssignmentControllSelectionItems" [strictClosingControllSelectionItems]="strictClosingControllSelectionItems" [customerSelectionItems]="companySelectionItems" [contracts]="contracts"></app-workflow-template>
  
  <app-workflow-detail *ngIf="isDetail" (isDetailEvent)="setIsDetail($event)" [template]="selection.selected[0]" [strictAssignmentControllSelectionItems]="strictAssignmentControllSelectionItems" [strictClosingControllSelectionItems]="strictClosingControllSelectionItems" [elements]="elements" [statusSelectionItems]="wfeStatusSelectionItems" [customerSelectionItems]="companySelectionItems" [customer_id]="customer_id" [gvSchema_id]="gvSchema_id" [contracts]="contracts"></app-workflow-detail>
