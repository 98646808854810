import { Component, OnInit } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-uploader-lib',
  templateUrl: './uploader-lib.component.html',
  styleUrls: ['./uploader-lib.component.less']
})
export class UploaderLibComponent implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_file_upload_unsuccessful", name: ""}
  ];

  constructor(public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.stream(['error_message_file_upload_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_file_upload_unsuccessful", name: res["error_message_file_upload_unsuccessful"]}
      ];
    });
  }

  public files: NgxFileDropEntry[] = [];

  public realFiles: any[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {

          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          this.realFiles.push(file);

          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, droppedFile.relativePath)


          let content:any = await this.toBase64String(file);
          content = content.split(",")[1];
          let postData:  any = {
            content: content,
            customer_id: 1,
            path: '/' + droppedFile.relativePath
          }
          await this.uploadFile(postData);

        });
        
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  toBase64String(file: File){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
    });
  }

  async uploadFile(body: any){
    return await this.http.post<any>(environment.serverEndpoint + '/common/filestorage/file', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_file_upload_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
