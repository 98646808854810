<mat-dialog-content>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                {{'common_title_date' | translate}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                {{'timesheet_workflow-element' | translate}}
            </div>
        </div>
        <div class="table-item">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                {{createDateString(data.working_day * 1000)}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                {{data.workflow_element_id}}
            </div>
           
        </div>
    </div>
</div>
</mat-dialog-content>

<mat-dialog-actions [style.justify-content]="'space-around'">
<button mat-button (click)="dialogRef.close()" class="negativeButton">{{'timesheet_close' | translate}}</button>
</mat-dialog-actions>
