import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateParse'
})
export class DateParsePipe implements PipeTransform {

  transform(value: string): Date {
    let year;
    let month;
    let day;
    let st = value.replace(/\D/g,'');
    if(st.length!=8)return new Date();
    if(localStorage.getItem("language") == "hu_HU"){
      year = st.substr(0,4);
      month = st.substr(4,2);
      day = st.substr(6,2);
    }else{
      day = st.substr(0,2);
      month = st.substr(2,2);
      year = st.substr(4,4);
    }
    return new Date(new Date(year, month-1, day).getTime() - new Date().getTimezoneOffset()*60*1000);
  }

}
