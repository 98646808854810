<mat-toolbar color="primary">
    <div>
    </div>
    <div>
        <button mat-icon-button matTooltip="{{'parameters_modification' |translate}}" [disabled]="!selectedParameter" (click)="editParameter(selectedParameter)">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                {{'parameters_label' | translate}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                {{'parameters_value' | translate}}
            </div>
        </div>
        <ng-container *ngFor="let parameter of parameters">
            <div class="table-item" (click)="selectedParameter == parameter ? selectedParameter = null : selectedParameter = parameter" [class.selected]="selectedParameter?.origName == parameter.origName" *ngIf="parameter.origName != 'token_ttl_period'">
                <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                    {{parameter.name | translate}}
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'" *ngIf="parameter.origName != 'is_auditor_report_required' && parameter.origName != 'is_bcc_email_enabled'">
                    <span [ngClass]="parameter.type == 'password' ? 'hide' : ''">{{parameter.value}}</span>
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'" *ngIf="parameter.origName == 'is_auditor_report_required' || parameter.origName == 'is_bcc_email_enabled'">
                    {{findSelectionItem(trueFalseSelectionItems, parameter.value)?.name | translate}}
                </div>
            </div>
        </ng-container>
        
    </div>
</div>