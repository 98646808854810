<mat-card [formGroup]="resetPasswordForm" class="login-card">
    <h3 class="login-title">Új jelszó / New password</h3>
    <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="login_password">
        <mat-error *ngIf="resetPasswordForm.controls.login_password.hasError('required')">
            Írja be az új jelszót / Please enter your new password
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Password again</mat-label>
        <input matInput type="password" formControlName="login_password_again">
        <mat-error *ngIf="resetPasswordForm.controls.login_password.hasError('required')">
            Írja be az új jelszót még egyszer / Please enter your new password again
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" class="login-button" (click)="resetPassword()">
    Jelszóbeállítás / Set password
  </button>
</mat-card>
