<h2 mat-dialog-title>{{isNewFolder ? ('folder_structure-folder-create' | translate) : ('folder_structure-folder-modification' | translate)}}</h2>
<mat-dialog-content>
    <form [formGroup]="folderForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'folder_structure-name' | translate}}</mat-label>
            <input matInput formControlName="caption" required>
            <mat-error *ngIf="folderForm.controls.caption.hasError('required')">
                {{'error_message_folder_name_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'folder_structure-audit-folder' | translate}}</mat-label>
            <mat-select formControlName="auditor_program_folder_code">
                <mat-option *ngFor="let folder of auditorFolderList" [value]="objectKeys(folder)[0]">{{folder[objectKeys(folder)]}}</mat-option>
            </mat-select>
            <mat-error *ngIf="folderForm.controls.auditor_program_folder_code.hasError('required')">
                {{'error_message_audit_folder_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveFolder()" class="positiveButton">
        {{'folder_structure-save' | translate}}
    </button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'folder_structure-reject' | translate}}</button>
</mat-dialog-actions>
