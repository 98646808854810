import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Country} from '../data/country';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import {ApiEndpoint} from "../../environments/api-endpoint";
import {catchError, finalize} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {PaginatedAnswer, PaginatedRequest} from "../data/response";
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService<Country> implements OnInit{
  translatableTexts: any[] = [
    {value: "error_message_countries_list_unsuccessful", name: ""},
    {value: "error_message_country_get_unsuccessful", name: ""},
    {value: "error_message_country_delete_unsuccessful", name: ""},
    {value: "error_message_country_create_unsuccessful", name: ""},
    {value: "error_message_country_update_unsuccessful", name: ""},
    {value: "error_message_country_parameters_get_unsuccessful", name: ""},
    {value: "error_message_country_parameters_save_unsuccessful", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
        {
          listUrl: ApiEndpoint.countryEndpoint,
          getUrl: ApiEndpoint.countryEndpoint,
          createUrl: ApiEndpoint.countryEndpoint + "/create",
          deleteUrl: ApiEndpoint.countryEndpoint,
          updateUrl: ApiEndpoint.countryEndpoint
        },
        {
          listError: 'Az ország letöltése sikertelen',
          getError: 'Az ország letöltése sikertelen',
          deleteError: 'Az ország törlése sikertelen',
          createError: 'Az ország  létrehozása sikertelen',
          updateError: 'Az ország módosítása sikertelen'
        },
        httpClient, snackBar, progressService);
  }

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_countries_list_unsuccessful',
      'error_message_country_get_unsuccessful',
      'error_message_country_delete_unsuccessful',
      'error_message_country_create_unsuccessful',
      'error_message_country_update_unsuccessful',
      'error_message_country_parameters_get_unsuccessful',
      'error_message_country_parameters_save_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_countries_list_unsuccessful", name: res["error_message_countries_list_unsuccessful"]},
        {value: "error_message_country_get_unsuccessful", name: res["error_message_country_get_unsuccessful"]},
        {value: "error_message_country_delete_unsuccessful", name: res["error_message_country_delete_unsuccessful"]},
        {value: "error_message_country_create_unsuccessful", name: res["error_message_country_create_unsuccessful"]},
        {value: "error_message_country_update_unsuccessful", name: res["error_message_country_update_unsuccessful"]},
        {value: "error_message_country_parameters_get_unsuccessful", name: res["error_message_country_parameters_get_unsuccessful"]},
        {value: "error_message_country_parameters_save_unsuccessful", name: res["error_message_country_parameters_save_unsuccessful"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_countries_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_country_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_country_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_country_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_country_update_unsuccessful").name
  }

  listCountries(paginatedRequest: PaginatedRequest): Observable<PaginatedAnswer<Country>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(paginatedRequest));
    return this.httpClient.get<PaginatedAnswer<Country>>(environment.serverEndpoint + '/admin/countries?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getCountryParam(iso_code: string) {
    const task = this.progressService.addTask(this.constructor.name + '_get_country_param');
    // TODO: what is the response?
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify({"iso_code": iso_code}));
    return this.httpClient.get<any>(environment.serverEndpoint + '/admin/countryparam?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_country_parameters_get_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  postCountryParam(countryParams: any) {
    const task = this.progressService.addTask(this.constructor.name + '_post_country_param');
    return this.httpClient.put<any>(environment.serverEndpoint + '/admin/countryparam', countryParams).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_country_parameters_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  deleteCountry(iso_code: any): Observable<PaginatedAnswer<Country>> {
    const task = this.progressService.addTask(this.constructor.name + '_delete');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        iso_code: iso_code
      },
    };
    
    return this.httpClient.delete<any>(environment.serverEndpoint + '/admin/country', options).pipe(
      catchError(error => {
        this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }
}
