<ng-container *ngIf="!isElementDetail">
    <mat-toolbar color="primary" style="width:100%;z-index: 1000">
        <div>
            <button mat-icon-button matTooltip="{{'button_title_back' | translate}}" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'workflows_audit-report-generation' | translate}}" (click)="generateAuditReport()" *ngIf="selected.value==0">
                <mat-icon>assignment</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'workflows_open' | translate}}" (click)="openWorkflowElement()" *ngIf="selected.value==1" [disabled]="!selectedWorkflowElement">
                <mat-icon>visibility</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common_title_upload_balance_sheet' | translate}}" (click)="fillBalanceSheet()" *ngIf="selected.value==0">
                <mat-icon>account_balance</mat-icon>
            </button>
        </div>
        <div style="width:100%">
            <h2 mat-dialog-title>{{findSelectionItem(customerSelectionItems, findById(contracts, template.contract_id)?.customer_id).name}}, {{template.caption}}</h2>
        </div>
    </mat-toolbar>
    <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" animationDuration="0ms">
        <mat-tab label="{{'workflows_generic-datas' | translate}}">
            <div class="table-content" id="generic-data">
                <div class="column">
                    <div class="table-header">
                        <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                            {{'workflows_generic-data-name' | translate}}
                        </div>
                        <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                            {{'workflows_value' | translate}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflow_templates_cleint' | translate}}
                        </div>
                        <div class="table-text">
                            {{findSelectionItem(customerSelectionItems, findById(contracts, template.contract_id)?.customer_id).name}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflow_templates_contract' | translate}}
                        </div>
                        <div class="table-text">
                            {{findById(contracts, template.contract_id)?.contract_code}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflow_templates_business-year-start' | translate}}
                        </div>
                        <div class="table-text">
                            {{template.start_of_business_year ? createDateString(template.start_of_business_year * 1000) : ''}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflow_templates_business-year-end' | translate}}
                        </div>
                        <div class="table-text">
                            {{template.end_of_business_year ? createDateString(template.end_of_business_year * 1000) : ''}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflows_report-format' | translate}}
                        </div>
                        <div class="table-text">
                            {{template.report_type_sid | translate}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="disabled table-item">
                        <div class="table-text">
                            {{'workflow_templates_inspection-close-date' | translate}}
                        </div>
                        <div class="table-text">
                            {{template.audit_closing_date ? createDateString(template.audit_closing_date * 1000) : ''}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="disabled table-item">
                        <div class="table-text">
                            {{'workflow_templates_audit-report-issue-date' | translate}}
                        </div>
                        <div class="table-text">
                            {{ template.audit_report_date ? createDateString(template.audit_report_date * 1000) : ''}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflow_templates_audit-team-setup-controll' | translate}}
                        </div>
                        <div class="table-text" *ngIf="findSelectionItem(strictAssignmentControllSelectionItems, template.is_strict_assignment_control).name == 'No'">
                            {{'0' | translate}}
                        </div>
                        <div class="table-text" *ngIf="findSelectionItem(strictAssignmentControllSelectionItems, template.is_strict_assignment_control).name != 'No'">
                            {{'1' | translate}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflow_templates_mandatory-close-controll' | translate}}
                        </div>
                        <div class="table-text" *ngIf="findSelectionItem(strictClosingControllSelectionItems, template.is_strict_closure_control).name == 'No'">
                            {{'0' | translate}}
                        </div>
                        <div class="table-text" *ngIf="findSelectionItem(strictClosingControllSelectionItems, template.is_strict_closure_control).name != 'No'">
                            {{'1' | translate}}
                        </div>
                    </div>
                    <div [style.display]="'flex'" class="table-item">
                        <div class="table-text">
                            {{'workflow_templates_status' | translate}}
                        </div>
                        <div class="table-text">
                            {{template.workflow_status_sid | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{'workflows_workflow-elements' | translate}}">
            <div class="table-content">
                <div class="column">
                    <div class="table-header">
                        <div class="table-text" [style.width]="'15%'">
                            {{'workflow_templates_timing-code' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{'workflows_group' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'30%'">
                            {{'workflows_generic-data-name' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'20%'">
                            {{'workflows_estimated-man-hour-requirement' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'10%'">
                            {{'workflows_exploitage' | translate}}
                        </div>
                        <div class="table-text" [style.width]="'10%'">
                            {{'workflows_status' | translate}}
                        </div>
                    </div>
                    <div *ngFor="let element of elements; let i = index;" class="table-item" (click)="selectedWorkflowElement == element ? selectedWorkflowElement = null : selectedWorkflowElement = element" [class.selected]="selectedWorkflowElement?.id == element.id">
                        <div class="table-text" [style.width]="'15%'">
                            {{(i == 0 ? element.workflow_element_group.workflow_element_group_time_sid : elements[i].workflow_element_group.workflow_element_group_time_sid != elements[i-1].workflow_element_group.workflow_element_group_time_sid ? element.workflow_element_group.workflow_element_group_time_sid : '') | translate }}
                        </div>
                        <div class="table-text" [style.width]="'15%'">
                            {{
                            element.workflow_element_group.fix_code!=null ?
                            ((i == 0 ? element.workflow_element_group.fix_code : (elements[i].workflow_element_group.fix_code != elements[i-1].workflow_element_group.fix_code ? element.workflow_element_group.fix_code : "" )) | translate)
                            :
                            ((i == 0 ? element.workflow_element_group.caption : (elements[i].workflow_element_group.caption != elements[i-1].workflow_element_group.caption ? element.workflow_element_group.caption : "" )))
                            }}
                        </div>
                        <div class="table-text" [style.width]="'30%'">
                            {{element.fix_code?(element.fix_code | translate):element.caption}}
                        </div>
                        <div class="table-text" [style.width]="'20%'">
                            {{element.estimated_work_hours/3600}}
                        </div>
                        <div class="table-text" [style.width]="'10%'">
                            {{math.round(element.utilization * 10000) / 100 + '%'}}
                        </div>
                        <div class="table-text" [style.width]="'10%'">
                            {{findSelectionItem(statusSelectionItems, element.workflow_element_status_sid)?.name | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-container>

<app-workflow-element-detail *ngIf="isElementDetail" (isElementDetailEvent)="setIsElementDetail($event)" [workflowMainGroupSelectionItems]="workflowMainGroupSelectionItems" [statusSelectionItems]="statusSelectionItems" [workflowElement]="selectedWorkflowElement" [coworkerSelectionItems]="coworkerSelectionItems" [contracts]="contracts" [workflow]="template" [customer_id]="customer_id" [customer_currency_iso_code]="customer_currency_iso_code"></app-workflow-element-detail>
