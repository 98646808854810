import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-system-logs',
  templateUrl: './system-logs.component.html',
  styleUrls: ['./system-logs.component.less']
})
export class SystemLogsComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  logs;
  colNames;
  colData;
  dbNames;

  tableName;
  where;
  fields;

  dataWithoutCols;

  isLoadingTable:boolean = false;
  isLoadindgFile:boolean = false;

  dbUrlGet = environment.serverEndpoint + '/admin/tableviewer?payload=';
  logUrlGet = environment.serverEndpoint + '/admin/logviewer?payload=';
  paramsUrl = environment.serverEndpoint + '/admin/sysparm';

  dataForm: FormGroup = new FormGroup({
    tableName: new FormControl(),
    where: new FormControl(),
    fields: new FormControl()
  });

  fileForm: FormGroup = new FormGroup({
    filePath: new FormControl(),
    download: new FormControl(),
    lines: new FormControl(),
    fileRes: new FormControl()
  });

  sysparams: FormGroup = new FormGroup({
    debugLevel: new FormControl()
  });

  async ngOnInit(): Promise<void> {
    this.getParameters();
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }))
    }

  getTableData(tableName, where = '', fields=''){
    let body = {
      "tableName": tableName,
      "where": where,
      "fields": fields
    }
    let encoded = this.b64EncodeUnicode(JSON.stringify(body));
    console.log(encoded);
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let req = this.httpClient.get(this.dbUrlGet + encoded, {headers});
    req.subscribe((data:any) => {
      console.log(data);
      this.colNames = data.payload.colNames;
      this.colData = data.payload.resultSet;
      this.isLoadingTable = false;
    }) 
  }

  exportData(){
    let body = {
      "tableName": this.tableName,
      "where": this.where,
      "fields": this.fields,
      "export": true,
    }
    let encoded = this.b64EncodeUnicode(JSON.stringify(body));
    console.log(encoded);
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let req = this.httpClient.get(this.dbUrlGet + encoded, {headers});
    req.subscribe(data => {
      console.log('export successful');
    })
  }

  getLogFiles(filePath, download=false, lines=0){
    let body = {
      "filePath": filePath,
      "lines": lines,
      "download": download
    }
    let stringify = JSON.stringify(body);
    console.log(stringify);
    let encoded = btoa(stringify);
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let req = this.httpClient.get(this.logUrlGet + encoded, {headers});
    req.subscribe((data:any)=>{
      console.log(data);
      if(data.type == "OK"){
        let log = data.payload;
        console.log(log);
        this.logs = atob(log);
        this.fileForm.get('fileRes').setValue(this.logs);
        this.isLoadindgFile = false;
        console.log(this.logs);
      }else{
        this.fileForm.get('fileRes').setValue('Hiba! Nincs ilyen fájl!');
      }
      
    })
  }

  getParameters(){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let req = this.httpClient.get(this.paramsUrl, {headers});
    req.subscribe((data:any)=> {
      let level = data.debugLevel;
      this.sysparams.get('debugLevel').setValue(level);
      console.log(data);
    })
  }

  sendParameters(){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let debugLevel = this.sysparams.get('debugLevel').value;
    let req = this.httpClient.put(this.paramsUrl, {'debugLevel': debugLevel}, {headers});
    req.subscribe(obs => {
      console.log("send successful");
    });
  }

  submitTableForm(){
    if(this.dataForm.valid){
      this.tableName = this.dataForm.get("tableName").value as String;
      this.where = this.dataForm.get("where").value;
      this.fields = this.dataForm.get("fields").value;
      this.isLoadingTable = true;
      this.getTableData(this.tableName, this.where, this.fields);
    }
  }

  submitFileForm(){
    if(this.fileForm.valid){
      let path = this.fileForm.get("filePath").value;
      let download = this.fileForm.get("download").value;
      let lines = this.fileForm.get("lines").value;
      this.isLoadindgFile = true;
      this.getLogFiles(path,download,lines);
    }
  }



}
