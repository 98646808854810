<h2 mat-dialog-title style="text-align: center;">{{'parameters_working-parameter-modification' | translate}}</h2>
<mat-dialog-content>
    <div style="max-width: 500px; text-align: center; margin-bottom: 1rem;">{{parameter.description | translate}}</div>
    <form [formGroup]="parameterForm">
        <mat-form-field appearance="outline" *ngIf="parameter.origName != 'is_auditor_report_required' && parameter.origName != 'is_bcc_email_enabled'">
            <mat-label>{{parameter.name | translate}}</mat-label>
            <input matInput required [type]="parameter.type" formControlName="value">
            <mat-error *ngIf="parameterForm.controls.value.hasError('required')">
                {{'error_message_field_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <app-select-search *ngIf="parameter.origName == 'is_auditor_report_required'" [selectionItems]="trueFalseSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'parameters_report-setup' | translate" [selectCtrl]="parameterForm.controls.value" [required]="true">
        </app-select-search>
        <app-select-search *ngIf="parameter.origName == 'is_bcc_email_enabled'" [selectionItems]="trueFalseSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'is_bcc_email_enabled' | translate" [selectCtrl]="parameterForm.controls.value" [required]="true">
        </app-select-search>
        
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveParameter()" class="positiveButton">{{'parameters_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'parameters_reject' | translate}}</button>
</mat-dialog-actions>