
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CredentialsService } from 'src/app/services/credentials.service';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.less']
})
export class LostPasswordComponent implements OnInit {

  lostPasswordForm: FormGroup = this.formBuilder.group({
    username: [null, Validators.required]
  });

  translatableTexts: any[] = [
    {value: "common_message_password_reset_email_send_successful", name: ""},
    {value: "error_message_password_reset_email_send_unsuccessful", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, private service: CredentialsService, public dialogRef: MatDialogRef<LostPasswordComponent>, private snackBar: MatSnackBar, private dialogService: MatDialog, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.stream(['common_message_password_reset_email_send_successful', 'error_message_password_reset_email_send_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_password_reset_email_send_successful", name: res["common_message_password_reset_email_send_successful"]},
        {value: "error_message_password_reset_email_send_unsuccessful", name: res["error_message_password_reset_email_send_unsuccessful"]}
      ];
    });
  }

  sendResetPasswordEmail(){
    this.service.getLostPassword(this.lostPasswordForm.get("username").value).subscribe((response) => {
      if (response){
        if (response.type == "OK"){
          this.dialogRef.close();
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_password_reset_email_send_successful").name, null, {panelClass: 'success-snackbar'});
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_password_reset_email_send_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }
      
    });
  }
}
