import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ProgressService } from 'src/app/services/progress.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-balance-sheet-import',
  templateUrl: './balance-sheet-import.component.html',
  styleUrls: ['./balance-sheet-import.component.less']
})
export class BalanceSheetImportComponent implements OnInit {

  _workflow_element_id: any;
  get workflow_element_id() { return this._workflow_element_id };
  set workflow_element_id(value: any) {
    this._workflow_element_id = value;
  }

  balanceSheetUploadForm: FormGroup = this.formBuilder.group({
    file: [null, [Validators.required]],
  });

  translatableTexts: any[] = [
    {value: "error_message_error_modifying_own_data", name: ""}
  ];

  constructor(private dialogService: MatDialog, private translateService: TranslateService, public dialogRef: MatDialogRef<BalanceSheetImportComponent>, private httpClient: HttpClient, private snackBar: MatSnackBar, private progressService: ProgressService,  private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.translateService.stream(['error_message_error_modifying_own_data']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_modifying_own_data", name: res["error_message_error_modifying_own_data"]}
      ];
    });
  }

  toBase64String(file: File){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
    });
  }

  validateFileType(event){
    if (event){
      setTimeout(()=>{this.balanceSheetUploadForm.get("file").setErrors({'incorrect': true})},0)
    }
    else{
      this.balanceSheetUploadForm.get("file").setErrors(null)
    }

    this.balanceSheetUploadForm.get("file").updateValueAndValidity();
 
  }

  async uploadBalanceSheet(){
    if (this.balanceSheetUploadForm.valid) {
      let content:any = await this.toBase64String(this.balanceSheetUploadForm.get("file").value);
      this.upload(content).then((response)=>{
        this.dialogRef.close(response);
      })
    } else {
      this.balanceSheetUploadForm.markAllAsTouched();
    }
  }

  upload(content){
    this.balanceSheetUploadForm.get("file").setValue(content.split(",")[1]);
    let body = {
      content: this.balanceSheetUploadForm.getRawValue().file,
      workflow_element_id:this.workflow_element_id
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(environment.serverEndpoint + '/user/gvupload', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_modifying_own_data").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
