import {Component, OnInit} from '@angular/core';
import {NestedTreeControl} from "@angular/cdk/tree";
import {FolderNode, Folder} from "../../data/folder";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FolderEditorComponent} from "./folder-editor/folder-editor.component";
import {OkDialogComponent} from "../ok-dialog/ok-dialog.component";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-folder-structure',
  templateUrl: './folder-structure.component.html',
  styleUrls: ['./folder-structure.component.less']
})
export class FolderStructureComponent implements OnInit {
  treeControl = new NestedTreeControl<Folder>(node => node.childs);
  dataSource = new MatTreeNestedDataSource<Folder>();
  selectedFolderList: Folder[] = [];

  get flattedFolderList(): Folder[] {
    return this.flatten(this.dataSource.data, true);
  }

  _isInDialog: boolean = false;
  get isInDialog() { return this._isInDialog };
  set isInDialog(value: any) {
    this._isInDialog = value;
  }

  translatableTexts: any[] = [
    {value: "error_message_error_getting_folders", name: ""},
    {value: "common_message_folder_save_successful", name: ""},
    {value: "error_message_folder_save_unsuccessful", name: ""},
    {value: "common_message_folder_delete_successful", name: ""},
    {value: "error_message_folder_delete_unsuccessful", name: ""},
    {value: "error_message_unable_to_deep_copy", name: ""}
  ];

  constructor(private dialogService: MatDialog, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService, public dialogRef: MatDialogRef<FolderStructureComponent>) {
    this.getTreeData();
  }

  allData: any;
  folders = [];
  selectedFolder: any = null;

  ngOnInit() {
    if(this.isInDialog){

    }
    this.translateService.stream(['error_message_error_getting_folders', 'common_message_folder_save_successful', 'error_message_folder_save_unsuccessful', 'common_message_folder_delete_successful', 'error_message_folder_delete_unsuccessful', 'error_message_unable_to_deep_copy']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_getting_folders", name: res["error_message_error_getting_folders"]},
        {value: "common_message_folder_save_successful", name: res["common_message_folder_save_successful"]},
        {value: "error_message_folder_save_unsuccessful", name: res["error_message_folder_save_unsuccessful"]},
        {value: "common_message_folder_delete_successful", name: res["common_message_folder_delete_successful"]},
        {value: "error_message_folder_delete_unsuccessful", name: res["error_message_folder_delete_unsuccessful"]},
        {value: "error_message_unable_to_deep_copy", name: res["error_message_unable_to_deep_copy"]}
      ];
    });
  }

  isFolderProtected(isNewFolder, folderNode, isDelete: boolean = false){
    if (!isNewFolder){
      if (isDelete){
        if (folderNode?.folder_code || folderNode?.childs.length > 0){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        if (folderNode?.folder_code) {
          return true;
        }
        else{
          return false;
        }
      }

    }
    else{
      if (folderNode?.folder_code && folderNode?.childs.length > 0 && folderNode?.childs.every(child => child.folder_code)) {
        return true;
      }
      else{
        return false;
      }
    }
  }

  async getTreeData() {
    await this.getFolders()
    .then((data: any)=>{
      this.allData = this._deepCopyObj(data.payload);
      this.folders = [];
      this.recursiveCreateFolderFromArray(data.payload);
      this.dataSource.data = this._deepCopyObj(data.payload);
    })
  }

  getFolders(){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload=btoa(JSON.stringify({path: "/"}))
    return this.http.get<any>(environment.serverEndpoint + '/owner/folders?payload=' + payload, {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_folders").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  getFolderRelativeName(path){
    let splitPath = path.split("/");
    return splitPath[splitPath.length - 1]
  }

  hasChild = (_: number, node: Folder) => !!node.childs && node.childs.length > 0;

  private flatten(items: Folder[], showOnlyVisible: boolean = false) {
    const flat = [];

    items.forEach(item => {
      flat.push(item);
      if ((!showOnlyVisible || this.treeControl.isExpanded(item)) && item.childs && item.childs.length > 0) {
        if(this.treeControl.isExpanded(item)){
          flat.push(...this.flatten(item.childs));
        }
      }
    });

    return flat;
  }

  private flattenParentFolders(items: any[], showOnlyVisible: boolean = false) {
    const flat = [];

    items.forEach(item => {
      if (item.auditor_program_folder_code == null){
        flat.push(item);
      }
      if ((!showOnlyVisible || this.treeControl.isExpanded(item)) && item.childs && item.childs.length > 0) {
        if(this.treeControl.isExpanded(item)){
          flat.push(...this.flattenParentFolders(item.childs));
        }
      }
    });

    return flat;
  }

  isFolderSelected(folder: FolderNode) {
    return this.selectedFolderList.indexOf(folder) > -1;
  }

  editFolder(folderNode: any, isNewFolder: boolean) {
    const dialog = this.dialogService.open(FolderEditorComponent);
    let folderList = this.flattenParentFolders(this.folders);
    folderList.push({
      is_directory: true,
      is_file: false,
      path: "/",
      size: 1
    })
    
    let splitPath;

    dialog.componentInstance.isNewFolder = isNewFolder;

    if (folderNode) {
      splitPath = folderNode.path.split('/');
      splitPath.pop();
      splitPath = splitPath.join('/');
      if (splitPath == ""){
        splitPath = "/";
      }
      folderNode.caption = this.getFolderRelativeName(folderNode.path);
      folderNode.parentFolder = splitPath;
      folderNode.auditor_program_folder_code = folderNode.auditor_program_folder_code;
      dialog.componentInstance.folderNode = folderNode;
    }

    dialog.afterClosed().subscribe(closeResponse => {
      if (closeResponse){
        if (closeResponse.response.type == "OK") {
          this.getTreeData().then((response: any) => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_folder_save_successful").name, null, {panelClass: 'success-snackbar'});
            this.openUntilFirstOpened(closeResponse);
            if (isNewFolder){
              this.selectedFolderList = [];
            } else{
              console.log(this.flattedFolderList)
              console.log(this.flattedFolderList.find(folder => folder.path == closeResponse.path))
              let selectedFolder = this.flattedFolderList.find(folder => folder.path == closeResponse.path);
              if (selectedFolder){
                this.selectedFolderList = [this.flattedFolderList.find(folder => folder.path == closeResponse.path)]
              }
              else{
                this.selectedFolderList = [];
              }
            }
          });
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_folder_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = closeResponse.response.payload;
        }
      }
      
    })
  }

  deleteFolder(folderNode) {
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('folder_structure-remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.backendDeleteFolder(folderNode).then((resp:any) => {
          if (resp){
            if(resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_folder_delete_successful").name, null, {panelClass: 'success-snackbar'});
              this.getTreeData().then((response: any) =>{

              let splitPath = folderNode.path.split('/');
              splitPath.pop();
              splitPath = splitPath.join('/');

                this.openUntilFirstOpened({path: splitPath});
                this.selectedFolderList = [];
              });
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_folder_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
          
        });
      }
    })
  }

  backendDeleteFolder(folder: any){
    let parentPath =folder.path.split("/");
    parentPath.pop();
    parentPath = parentPath.join("/");
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        parent_path: parentPath,
        caption: this.getFolderRelativeName(folder.path)
      },
    };
    return this.http.delete<any>(environment.serverEndpoint + '/owner/folder', options)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_folder_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  getAuditorFolderName(auditorFolderId: number) {
    /*return AUDITOR_FOLDER_LIST.find(folder => folder.id === auditorFolderId).name;*/
  }

  onFolderClick(item: any) {
    this.selectedFolder = null;
    this.recursiveGetFolderFromArray(this.allData, item.path);
//    if(this.selectedFolderList.length > 0){
//      if (this.selectedFolderList[0] == folder){ this.selectedFolderList.splice(this.selectedFolderList.indexOf(folder), 1)}else{this.selectedFolderList[0] = folder}}else if(this.selectedFolderList.indexOf(folder) > -1){
//        this.selectedFolderList.splice(this.selectedFolderList.indexOf(folder), 1)}else{ this.selectedFolderList.push(folder))};
//alert(this.selectedFolderList[0]);
/*this.selectedFolderList.length > 0 ? (this.selectedFolderList[0] == this.selectedFolder ? this.selectedFolderList.splice(this.selectedFolderList.indexOf(this.selectedFolder), 1) :this.selectedFolderList[0] = this.selectedFolder) : (this.selectedFolderList.indexOf(this.selectedFolder) > -1 ?
this.selectedFolderList.splice(this.selectedFolderList.indexOf(this.selectedFolder), 1) :
this.selectedFolderList.push(this.selectedFolder));*/

  }

  recursiveGetFolderFromArray(items: any, path: any) {
    items.forEach((item: any, index: any)=> {
      if(item.path == path) {
        this.selectedFolder = item;
        return;
      }
      if(item.childs.length > 0){
        this.recursiveGetFolderFromArray(item.childs, path);
      }
    })
  }

  recursiveCreateFolderFromArray(items: any) {
    items.forEach((item: any, index: any)=> {
      if(item.is_directory) {
        this.folders.push({path: item.path, auditor_program_folder_code: item.auditor_program_folder_code});
      }
      if(item.childs.length > 0){
        this.recursiveCreateFolderFromArray(item.childs);
      }
    });
  }

  openUntilFirstOpened(node: any) {
    let paths: any = node.path.split('/');
    if (paths.length > 0) {
      let partPath = ''
      paths.forEach((path: any, index) => {
        partPath += path;

        if(partPath){
          let toggler = document.getElementById(partPath) as HTMLElement;
          if (toggler){
            toggler.click();
          }
        }

        if (index < paths.length -1){
          partPath += '/';
        }
      })

      this.onFolderClick({path: partPath})
    }
  }

  private _deepCopyObj(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    if (obj instanceof Date) {
      let copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      let copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this._deepCopyObj(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      let copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this._deepCopyObj(obj[attr]);
      }
      return copy;
    }
    throw new Error(this.translatableTexts.find(element => element.value === "error_message_unable_to_deep_copy").name);
  }

  private assignFolder(){
    this.dialogRef.close(this.selectedFolderList[0])
  }
}
