<h2 mat-dialog-title>{{'common_title_workflow_element_coworkers-name' | translate}}</h2>
<div style="display:flex; justify-content: space-around;">
    <h2 mat-dialog-title style="margin-right: 50px;">{{'common_title_available_coworkers-name' | translate}}</h2>
    <h2 mat-dialog-title>{{'common_title_coworkers_assigned_to_workflow_element-name' | translate}}</h2> 
</div>

<mat-dialog-content style="width: 80vw;">
    <div class="table-content">
        <div class="column" style="margin-right: 50px;">
            <div class="table-header">
                <div class="table-text" [style.width]="'30%'">
                    {{'coworkers_last-name' | translate}}
                </div>
                <div class="table-text" [style.width]="'30%'">
                    {{'coworkers_first-name' | translate}}
                </div>
                <div class="table-text" [style.width]="'40%'">
                    {{'coworkers_mail-address' | translate}}
                </div>
            </div>
            <div *ngFor="let coworker of allCoworkers; let i = index;" class="table-item" (click)="selectedAllCoworker == coworker ? selectedAllCoworker = null : selectedAllCoworker = coworker" [class.selected]="selectedAllCoworker == coworker">
                <div class="table-text" [style.width]="'30%'">
                    {{coworker.last_name}}
                </div>
                <div class="table-text" [style.width]="'30%'">
                    {{coworker.first_name}}
                </div>
                <div class="table-text" [style.width]="'40%'">
                    {{coworker.email_address}}
                </div>
            </div>
        </div>

        <div class="column">
            <div class="table-header">
                <div class="table-text" [style.width]="'30%'">
                    {{'coworkers_last-name' | translate}}
                </div>
                <div class="table-text" [style.width]="'30%'">
                    {{'coworkers_first-name' | translate}}
                </div>
                <div class="table-text" [style.width]="'40%'">
                    {{'coworkers_mail-address' | translate}}
                </div>
            </div>
            <div *ngFor="let coworker of wfElem?.coworkers; let i = index;" class="table-item" (click)="selectedCurrentCoworker == coworker ? selectedCurrentCoworker = null : selectedCurrentCoworker = coworker" [class.selected]="selectedCurrentCoworker == coworker">
                <div class="table-text" [style.width]="'30%'">
                    {{coworker.last_name}}
                </div>
                <div class="table-text" [style.width]="'30%'">
                    {{coworker.first_name}}
                </div>
                <div class="table-text" [style.width]="'40%'">
                    {{coworker.email_address}}
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="addCoworker()" class="negativeButton" style="margin-right: 50px;" [disabled]="!selectedAllCoworker">{{'button_title_insert' | translate}}</button>
    <button mat-button (click)="removeCoworker()" class="positiveButton" [disabled]="!selectedCurrentCoworker">{{'button_title_delete' | translate}}</button>
</mat-dialog-actions>


<mat-dialog-actions [style.justify-content]="'space-evenly'">
    <button mat-button (click)="saveCoworkers()" class="positiveButton">{{'workflow_templates_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'workflow_templates_reject' | translate}}</button>
</mat-dialog-actions>
