<h2 mat-dialog-title>{{'common_title_record_work' | translate}}</h2>
<mat-dialog-content>

    <form [formGroup]="taskForm">
        <mat-form-field appearance="outline">
          <mat-label>{{'common_title_date' | translate}}</mat-label>
            <input matInput [matDatepicker]="workingDayAt" [imask]="{mask: 'dateformat-mask' | translate}" #input_working_day>
          <mat-datepicker-toggle matSuffix [for]="workingDayAt"></mat-datepicker-toggle>
          <mat-datepicker #workingDayAt></mat-datepicker>
          <!-- input matInput formControlName="working_day" required type="date" [max]="today" -->
          <mat-error *ngIf="taskForm.controls.working_day.hasError('required')">
            {{'error_message_date_cannot_be_empty' | translate}}
          </mat-error>
        </mat-form-field>
          
        <app-select-search [selectionItems]="customerSelectionItems" #customerInput [selectionType]="'singleSelect'" [placeholder]="'timesheet_client' | translate" [selectCtrl]="taskForm.controls.customer_id" [required]="true">
        </app-select-search>
        <app-select-search [selectionItems]="wfSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'timesheet_workflow' | translate" [selectCtrl]="taskForm.controls.workflow" [required]="true">
        </app-select-search>
        <app-select-search [selectionItems]="wfelemSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'timesheet_workflow-element' | translate" [selectCtrl]="taskForm.controls.workflow_element_id" [required]="true">
        </app-select-search>

        <mat-form-field appearance="outline">
            <mat-label>{{'timesheet_man-hours' | translate}}</mat-label>
            <input matInput formControlName="working_time" required type="number">
            <mat-error *ngIf="taskForm.controls.working_time.hasError('required')">
              {{'error_message_man_hours_cannot_be_empty' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'timesheet_comment' | translate}}</mat-label>
            <input matInput formControlName="comment">
          </mat-form-field>
    </form>

</mat-dialog-content>
    
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveTask(false)" class="positiveButton">{{'timesheet_recording' | translate}}</button>
    <button mat-button (click)="saveTask(true)" class="positiveButton">{{'timesheet_recording_close' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'timesheet_close' | translate}}</button>
</mat-dialog-actions>
