import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.less']
})
export class FilterTableComponent implements OnInit {

  _tableColumns: any;
  get tableColumns() { return this._tableColumns };
  set tableColumns(value: any) {
    this._tableColumns = value;
  }
  
  _displayedColumns: any;
  get displayedColumns() { return this._displayedColumns };
  set displayedColumns(value: any) {
    this._displayedColumns = value;
  } 

  _table: any;
  get table() { return this._table };
  set table(value: any) {
    this._table = value;
  } 

  tableColumnForm: FormGroup = this.formBuilder.group({});

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<FilterTableComponent>, private storageService: StorageService) { }

  ngOnInit(): void {
    this.tableColumns.forEach((column)=>{
      if (this.displayedColumns.includes(column.value)){
        this.tableColumnForm.addControl(column.value, new FormControl(true));
      }
      else {
        this.tableColumnForm.addControl(column.value, new FormControl(false));
      }
    })
  }

  saveTableColumns(){
    let form = this.tableColumnForm.getRawValue();
    for (let column in form){
      if (form[column] && !this.displayedColumns.includes(column)){
        this.displayedColumns.push(column);
      }
      if (!form[column] && this.displayedColumns.includes(column)){
        let index = this.displayedColumns.indexOf(column);
        this.displayedColumns.splice(index, 1);
      }
    }      
    let body = {
      "name": this.table +"TableColumns",
      "value": {
        "json": this.displayedColumns
      }}
    this.storageService.writeJsonStorage(body).subscribe(response => this.dialogRef.close(response));
  }

}
