import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { DocumentSubtype, Template } from '../../../data/template';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document-subtype-editor',
  templateUrl: './document-subtype-editor.component.html',
  styleUrls: ['./document-subtype-editor.component.less']
})
export class DocumentSubtypeEditorComponent implements OnInit {

  subtemplateForm: FormGroup = this.formBuilder.group({
    id: null,
    caption: [null, [Validators.required]]
  });

  _subtemplate: DocumentSubtype;
  set subtemplate(value: DocumentSubtype) {
    this._subtemplate = value;
    this.subtemplateForm.patchValue(value)
  }

  translatableTexts: any[] = [
    {value: "error_message_error_creating_document_subtype", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DocumentSubtypeEditorComponent>, private snackBar: MatSnackBar, public http: HttpClient, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.stream(['error_message_error_creating_document_subtype']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_creating_document_subtype", name: res["error_message_error_creating_document_subtype"]}
      ];
    });
  }

  saveSubtype() {
    if (this.subtemplateForm.valid) {
      if(this.subtemplateForm.get("id").value){
        this.modify().then((response) => {
          this.dialogRef.close(response);
         });
      }
      else{
        this.save().then((response) => {
          this.dialogRef.close(response);
       });
      }
    } else {
      this.subtemplateForm.markAllAsTouched();
    }
  }

  save() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(environment.serverEndpoint + '/owner/subtemplate', this.subtemplateForm.getRawValue())
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_document_subtype").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  modify() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.serverEndpoint + '/owner/subtemplate', this.subtemplateForm.getRawValue())
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_document_subtype").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
