import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { IMaskModule } from 'angular-imask';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from './app-material.module';
import { LoginComponent } from './components/login/login.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompanyEditorComponent } from './components/companies/company-editor/company-editor.component';
import { TaxNumberPipe } from './pipes/tax-number.pipe';
import { RegNumberPipe } from './pipes/reg-number.pipe';
import { AuditorsComponent } from './components/auditors/auditors.component';
import { AuditorEditorComponent } from './components/auditors/auditor-editor/auditor-editor.component';
import {OkDialogComponent} from "./components/ok-dialog/ok-dialog.component";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from "@angular/material/snack-bar";
import {CredentialsService} from "./services/credentials.service";
import {StateService} from "./services/state.service";
import {MAT_DATE_LOCALE, DateAdapter as MaterialDateAdapter} from "@angular/material/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {AIWorkflowInterceptorService} from "./services/ai-workflow-interceptor.service";
import {getHunPaginatorIntl} from "./utils/hun-paginator-intl";
import { CountryComponent } from './components/country/country.component';
import { CountryParamEditorComponent } from './components/country/country-param-editor/country-param-editor.component';
import { FolderStructureComponent } from './components/folder-structure/folder-structure.component';
import { FolderEditorComponent } from './components/folder-structure/folder-editor/folder-editor.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { TemplateEditorComponent } from './components/templates/template-editor/template-editor.component';
import {FileUploadComponent} from "./components/file-upload/file-upload.component";
import { TemplateFolderComponent } from './components/template-folder/template-folder.component';
import { TemplateFolderEditorComponent } from './components/template-folder/template-folder-editor/template-folder-editor.component';
import { UploadersComponent } from './components/uploaders/uploaders.component';
import { UploaderLibComponent } from './components/uploaders/uploader-lib/uploader-lib.component';
import { UploaderJsComponent } from './components/uploaders/uploader-js/uploader-js.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ThousandsFormatPipe } from './pipes/thousands-format.pipe';
import { PercentsFormatPipe } from './pipes/percents-format.pipe';
import { CoworkersComponent } from './components/coworkers/coworkers.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { CoworkerEditorComponent } from './components/coworkers/coworker-editor/coworker-editor.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileEditorComponent } from './components/profile/profile-editor/profile-editor.component';
import { ParametersComponent } from './components/parameters/parameters.component';
import { ParameterEditorComponent } from './components/parameters/parameter-editor/parameter-editor.component';
import { WorkflowElementGroupsComponent } from './components/workflow-element-groups/workflow-element-groups.component';
import { WorkflowElementEditorComponent } from './components/workflow-element-groups/workflow-element-editor/workflow-element-editor.component';
import { WorkflowTemplatesComponent } from './components/workflow-templates/workflow-templates.component';
import { WorkflowTemplateComponent } from './components/workflow-templates/workflow-template/workflow-template.component';
import { WorkflowTemplateAddCoworkerComponent } from './components/workflow-templates/workflow-template-add-coworker/workflow-template-add-coworker.component';
import { WorkflowTemplateBasicEditorComponent } from './components/workflow-templates/workflow-template-basic-editor/workflow-template-basic-editor.component';
import { RolesComponent } from './components/roles/roles.component';
import { RoleEditorComponent } from './components/roles/role-editor/role-editor.component';
import { DocumentSubtypesComponent } from './components/document-subtypes/document-subtypes.component';
import { DocumentSubtypeEditorComponent } from './components/document-subtypes/document-subtype-editor/document-subtype-editor.component';
import { CompanyHistoryComponent } from './components/companies/company-history/company-history.component';
import { ContractsComponent } from './components/companies/contracts/contracts.component';
import { ContractEditorComponent } from './components/companies/contracts/contract-editor/contract-editor.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ErrorDialogDetailsComponent } from './components/error-dialog/error-dialog-details/error-dialog-details.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslationHttpLoader } from "./translation-http-loader";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterTableComponent } from './components/filter-table/filter-table.component';
import { LostPasswordComponent } from './components/password/lost-password/lost-password.component';
import { ResetPasswordComponent } from './components/password/reset-password/reset-password.component';
import { FileSystemComponent } from './components/file-system/file-system.component';
import { FolderCreateEditorComponent } from './components/file-system/folder-create-editor/folder-create-editor.component';
import { FileUploaderEditorComponent } from './components/file-system/file-uploader-editor/file-uploader-editor.component';
import { WorkflowsComponent } from './components/workflows/workflows.component';
import { WorkflowCreateComponent } from './components/workflows/workflow-create/workflow-create.component';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { WorkflowTemplateCreateComponent } from './components/workflow-templates/workflow-template-create/workflow-template-create.component';
import { WorkflowDetailComponent } from './components/workflows/workflow-detail/workflow-detail.component';
import { WorkflowElementDetailComponent } from './components/workflows/workflow-element-detail/workflow-element-detail.component';
import { LogEntryDialogComponent } from './components/workflows/log-entry-dialog/log-entry-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AuditReportComponent } from './components/workflows/audit-report/audit-report.component';
import { BalanceStatementComponent } from './components/workflows/balance-statement/balance-statement.component';
import { BaseFileTableComponent } from './components/file-system/base-file-table/base-file-table.component';
import { DetailFileTableComponent } from './components/file-system/detail-file-table/detail-file-table.component';
import { TimesheetComponent } from './components/timesheet/timesheet.component';
import { TimesheetEditorComponent } from './components/timesheet/timesheet-editor/timesheet-editor.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { TimesheetDetailComponent } from './components/timesheet/timesheet-detail/timesheet-detail.component';
import { TimesheetDayComponent } from './components/timesheet/timesheet-day/timesheet-day.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import localeDe from '@angular/common/locales/de';
import { CustomDateAdapter } from './utils/custom-date-adapter';
import { LabelEditorComponent } from './components/file-system/label-editor/label-editor.component';
import { HelperComponent } from './components/helper/helper.component';
import { BalanceSheetComponent } from './components/workflows/balance-sheet/balance-sheet.component';
import { BalanceSheetImportComponent } from './components/workflows/balance-sheet/balance-sheet-import/balance-sheet-import.component';
import { BalanceSheetDetailComponent } from './components/workflows/balance-sheet/balance-sheet-detail/balance-sheet-detail.component';
import { WorkflowElementCoworkerComponent } from './components/workflow-templates/workflow-element-coworker/workflow-element-coworker.component';
import { WorkflowElementAddCoworkerComponent } from './components/workflows/workflow-element-add-coworker/workflow-element-add-coworker.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { SpecialFileTableComponent } from './components/file-system/special-file-table/special-file-table.component';
import {FileUploaderIndependentEditorComponent} from "./components/file-system/file-uploader-independent-editor/file-uploader-independent-editor.component";
import { DebitBalanceComponent } from './debit-balance/debit-balance.component';
import { ReportMunkatarsTimesheetComponent } from './components/reporting/report-munkatars-timesheet/report-munkatars-timesheet.component';
import {ReportUgyfelTimesheetComponent} from "./components/reporting/report-ugyfel-timesheet/report-ugyfel-timesheet.component";
import { CompanyBankaccountComponent } from './components/companies/company-bankaccount/company-bankaccount.component';
import { CompanyRepresentativesComponent } from './components/companies/company-representatives/company-representatives.component';
import {DateParsePipe} from "./pipes/date-parse.pipe";
import {DateFormatPipe} from "./pipes/date-format.pipe";
import { BalanceSheetDetailNewitemComponent } from './components/workflows/balance-sheet/balance-sheet-detail/balance-sheet-detail-newitem/balance-sheet-detail-newitem.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { HelpAiComponent } from './components/help-ai/help-ai.component';
import { MobileWorkflowComponent } from './components/mobile/mobile-workflow/mobile-workflow/mobile-workflow.component';
import { SystemLogsComponent } from './components/system-logs/system-logs.component';
import { BalanceFeedbackComponent } from './components/workflows/balance-statement/balance-feedback/balance-feedback.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component'

registerLocaleData(localeHu);
registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslationHttpLoader(http);
}

const MY_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    CompaniesComponent,
    CompanyEditorComponent,
    TaxNumberPipe,
    RegNumberPipe,
    DateParsePipe,
    DateFormatPipe,
    AuditorsComponent,
    AuditorEditorComponent,
    OkDialogComponent,
    CountryComponent,
    CountryParamEditorComponent,
    FolderStructureComponent,
    FolderEditorComponent,
    TemplatesComponent,
    TemplateEditorComponent,
    FileUploadComponent,
    TemplateFolderComponent,
    TemplateFolderEditorComponent,
    UploadersComponent,
    UploaderLibComponent,
    UploaderJsComponent,
    ThousandsFormatPipe,
    PercentsFormatPipe,
    CoworkersComponent,
    SelectSearchComponent,
    CoworkerEditorComponent,
    ProfileComponent,
    ProfileEditorComponent,
    ParametersComponent,
    ParameterEditorComponent,
    WorkflowElementGroupsComponent,
    WorkflowElementEditorComponent,
    WorkflowTemplatesComponent,
    WorkflowTemplateComponent,
    WorkflowTemplateAddCoworkerComponent,
    WorkflowTemplateBasicEditorComponent,
    RolesComponent,
    RoleEditorComponent,
    DocumentSubtypesComponent,
    DocumentSubtypeEditorComponent,
    CompanyHistoryComponent,
    ContractsComponent,
    ContractEditorComponent,
    ErrorDialogComponent,
    ErrorDialogDetailsComponent,
    FilterTableComponent,
    LostPasswordComponent,
    ResetPasswordComponent,
    FileSystemComponent,
    FolderCreateEditorComponent,
    FileUploaderEditorComponent,
    FileUploaderIndependentEditorComponent,
    WorkflowsComponent,
    WorkflowCreateComponent,
    WorkflowTemplateCreateComponent,
    WorkflowDetailComponent,
    WorkflowElementDetailComponent,
    LogEntryDialogComponent,
    AuditReportComponent,
    BalanceStatementComponent,
    BaseFileTableComponent,
    DetailFileTableComponent,
    TimesheetComponent,
    TimesheetEditorComponent,
    TimesheetDetailComponent,
    TimesheetDayComponent,
    LabelEditorComponent,
    HelperComponent,
    BalanceSheetComponent,
    BalanceSheetImportComponent,
    BalanceSheetDetailComponent,
    WorkflowElementCoworkerComponent,
    WorkflowElementAddCoworkerComponent,
    ReportingComponent,
    SpecialFileTableComponent,
    DebitBalanceComponent,
    ReportMunkatarsTimesheetComponent,
    ReportUgyfelTimesheetComponent,
    CompanyBankaccountComponent,
    CompanyRepresentativesComponent,
    BalanceSheetDetailNewitemComponent,
    MobileComponent,
    HelpAiComponent,
    MobileWorkflowComponent,
    SystemLogsComponent,
    BalanceFeedbackComponent,
    ChatbotComponent
  ],
  imports: [
    IMaskModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgxFileDropModule,
    NgxMatSelectSearchModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    MatExpansionModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    CredentialsService, {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_DATE_LOCALE, useValue: 'hu-HU'},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: HTTP_INTERCEPTORS, useClass: AIWorkflowInterceptorService, multi: true},
    {provide: MatPaginatorIntl, useValue: getHunPaginatorIntl()},
    {provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' }},
    ThousandsFormatPipe,
    TaxNumberPipe,
    RegNumberPipe,
    DateParsePipe,
    DateFormatPipe,
    {provide: MaterialDateAdapter, useClass: CustomDateAdapter },
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
