import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, flatMap} from 'rxjs/operators';
import {CredentialsService} from "./credentials.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { OkDialogComponent } from '../components/ok-dialog/ok-dialog.component';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AIWorkflowInterceptorService implements HttpInterceptor, OnInit {
  translatableTexts: any[] = [
    {value: "error_message_authentication_error_please_login_again", name: "Hiba a bejelentkezés során!"}
  ];
  
  constructor(private snackBar: MatSnackBar, private router: Router, private httpClient: HttpClient, private dialogService: MatDialog, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    /*
    this.translateService.stream(['error_message_authentication_error_please_login_again']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_authentication_error_please_login_again", name: res["error_message_authentication_error_please_login_again"]}
      ];
    });
    */
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (CredentialsService.jwtToken) {
      req = req.clone({
        setHeaders: {
          'Authorization': CredentialsService.jwtToken
        }
      });
    }
    return next.handle(req).pipe(
      catchError((error) => {
          //console.log("Full:"+error);
          //console.log("Msg:"+error.error.payload.message);
          if (error.status == 401) {
            if (error.error.message == "Token is exp") {
              let params = {
                token: CredentialsService.jwtToken,
                refreshToken: CredentialsService.refreshToken
              };
              this.httpClient.post('localhost:8080/auth/refresh', params)
                .pipe(
                  flatMap(
                    (data: any) => {
                      if (data.status == 200) {
                        CredentialsService.jwtToken = data.result.token;
                        CredentialsService.refreshToken = data.result.refreshToken;
                        req = req.clone({
                          setHeaders: {
                            'Authorization': data.result.token
                          }
                        });
                        return next.handle(req);
                      } else {
                        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_authentication_error_please_login_again").name, null, {panelClass: 'error-snackbar'});
                        CredentialsService.jwtToken = null;
                        this.router.navigate(['/login']).then();
                        return of(null);
                      }
                    }
                  )
                );
            } else {
              /*console.log("Interceptor error");
              console.log("translatableTexts tömb elemei:");
                  this.translatableTexts.forEach((element, index) => {
                  console.log(`Index ${index}: Name: ${element.name}, Value: ${element.value}`);
              });*/
              let errMsg = this.translatableTexts.find(element => element.value === "error_message_authentication_error_please_login_again").name;
              errMsg += " "+error.error.payload.message;
              this.snackBar.open(errMsg, null, {panelClass: 'error-snackbar'});
              CredentialsService.jwtToken = null;
              CredentialsService.refreshToken = null;
              this.router.navigate(['/login']).then();
              return of(null);
            }
          } 
          
          if (error.status == 400 || error.status == 403 || error.status == 500){
            const dialog = this.dialogService.open(ErrorDialogComponent);
            dialog.componentInstance.error = error.error;
          }
          
          throw throwError(error);
          
        }
      ));
  }
}
