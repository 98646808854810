<div class="table-content">
    <div class="column">
        <ng-container *ngFor="let workflow of workflows">
            <div  class="table-item" (click)="selectedWorkflow=workflow">
                <div class="table-text" [style.width]="'100%'">
                    {{workflow.caption}}
                </div>
            </div>
        </ng-container>
    </div>
</div>