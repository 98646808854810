<h2 *ngIf="isInDialog && !listType">{{'common_title_assignment' | translate}}</h2>
<h2 *ngIf="isInDialog && listType == 'baseTable'">{{'workflows_tribe-board-selection' | translate}}</h2>
<h2 *ngIf="isInDialog && listType == 'detailTable'">{{'workflows_exhaustive-table-choice' | translate}}</h2>
<mat-toolbar color="primary" *ngIf="!isInDialog">
    <div>
    </div>
    <div>
        <button mat-icon-button matTooltip="{{'file_system_new-folder' | translate}}" (click)="isFolderProtected(true, selectedFolder) ? '' : createFolder(selectedFolder)" [disabled]="selectedFolder == null || isFolderProtected(true, selectedFolder)">
            <mat-icon>create_new_folder</mat-icon>
        </button>

        <button mat-icon-button matTooltip="{{'file_system_folder-remove' | translate}}" [disabled]="selectedFolder == null || isFolderProtected(false, selectedFolder, true)"
            (click)="isFolderProtected(false, selectedFolder, true) ? '' : deleteFolder(selectedFolder)">
            <mat-icon>remove_circle</mat-icon>
        </button>

        <button mat-icon-button matTooltip="{{'file_system_file-upload' | translate}}" (click)="isFolderProtected(true, selectedFolder) ? '' : createFile(selectedFolder)" [disabled]="selectedFolder == null || isFolderProtected(true, selectedFolder)">
            <mat-icon>upload_file</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'file_system_file-remove' | translate}}" [disabled]="selectedFile == null"
        (click)="deleteFile(selectedFile, selectedFolder)">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'file_system_add-label' | translate}}" [disabled]="selectedFile == null"
        (click)="addLabel(selectedFile, selectedFolder)">
            <mat-icon>label</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflows_download' | translate}}" (click)="downloadFile()" [disabled]="!selectedFile">
            <mat-icon>file_download</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'file_system_edit-doc' | translate}}" (click)="editFile()" [disabled]="!selectedFile">
            <mat-icon>edit_note</mat-icon>
        </button>
        <app-select-search [appearanceStyle]="'standard'" [selectionItems]="companySelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflows_companies' | translate" [selectCtrl]="customer" [required]="false"></app-select-search>

    </div>
</mat-toolbar>
<div class="folder-structure-content" [style.width]="isInDialog ? '100vw' : '100%' " [style.height]="isInDialog ? '60vh' : '100%' ">
    <div class="column" style="width: 25%;overflow-y: overlay">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node; when: !hasFiles"  [class.hideNode]="node.is_file">
                <li class="mat-tree-node" [class.active]="selectedFolder?.path == node.path" (click)="onFolderClick(node)" [style.cursor]="'pointer'" *ngIf="node.is_directory">
                    <button mat-icon-button disabled matTreeNodeToggle [id]="node.path"></button>
                    <mat-icon [style.color]="'orange'">folder_open</mat-icon>
                    {{getFolderRelativeName(node.path) | translate}}
                </li>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: hasFiles"  [class.hideNode]="node.is_file">
                <li class="mat-tree-node" [class.active]="selectedFolder?.path == node.path" (click)="onFolderClick(node)" [style.cursor]="'pointer'" *ngIf="node.is_directory">
                    <button mat-icon-button disabled matTreeNodeToggle [id]="node.path"></button>
                    <mat-icon [style.color]="'orange'">folder</mat-icon>
                    {{getFolderRelativeName(node.path) | translate}}
                </li>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li>
                    <div class="mat-tree-node" [class.active]="selectedFolder?.path == node.path" (click)="onFolderClick(node)" [style.cursor]="'pointer'">
                        <button mat-icon-button matTreeNodeToggle [id]="node.path"
                                [attr.aria-label]="'Toggle ' + getFolderRelativeName(node.path)">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <mat-icon [style.color]="'orange'" *ngIf="this.hasFiles">folder</mat-icon>
                        <mat-icon [style.color]="'orange'" *ngIf="!this.hasFiles">folder_open</mat-icon>
                        {{getFolderRelativeName(node.path) | translate}}
                    </div>
                    <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </div>
    <div class="column" style="width: 75%;">
        <div class="folder-item">
            <div [style.width]="'30%'">
                {{'workflows_title' | translate}}
            </div>
            <div [style.width]="'30%'">
                {{'workflows_label' | translate}}
            </div>
            <div [style.width]="'10%'">
                {{'workflows_extension' | translate}}
            </div>
            <div [style.width]="'20%'">
                {{'workflows_uploader' | translate}}
            </div>
            <div [style.width]="'20%'">
                {{'workflows_upload_date' | translate}}
            </div>
            <div [style.width]="'10%'">
                {{'workflows_size' | translate}}
            </div>
        </div>
        <div *ngFor="let file of selectedFolderFiles" class="folder-item"
             (click)="selectedFile == file ? selectedFile = null : selectedFile = file" [class.activeFile]="selectedFile == file">
            <div [style.width]="'30%'">
                {{file.path.split('.')[0].split('/')[file.path.split('/').length -1 ]}}
            </div>
            <div [style.width]="'30%'">
                {{file.caption}}
            </div>
            <div [style.width]="'10%'">
                {{file.path.split('.')[1]}}
            </div>
            <div [style.width]="'20%'">
                {{createName(file.coworker)}}
            </div>
            <div [style.width]="'20%'">
                {{getCreatedDate(file.created_at)}}
            </div>
            <div [style.width]="'10%'">
                {{file.size}} B
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions [style.justify-content]="'space-around'" *ngIf="isInDialog">
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_back' | translate}}</button>
    <button mat-button class="neutralButton" [disabled]="!selectedFile" (click)="assignFile(selectedFile, selectedFolder)" *ngIf="page != 'workflows' && page != 'gvschema'">{{'button_title_assign' | translate}}</button>
    <!--[disabled]="!selectedFile"-->
    <button mat-button class="neutralButton" *ngIf="page == 'workflows'" (click)="openAdditionalTable(selectedFile, selectedFolder)">{{'button_title_select' | translate}}</button>
    <button mat-button class="neutralButton" *ngIf="page == 'gvschema'" (click)="assignToGvItem(selectedFile, selectedFolder)">{{'button_title_select' | translate}}</button>
</mat-dialog-actions>
