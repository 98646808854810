<h2 mat-dialog-title>{{'common_title_balance_sheet_data_fill' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="balanceSheetDetailNewitemForm" autocomplete="off">
        <mat-form-field appearance="outline">
            <mat-label>{{'common_title_hungarian_title' | translate}}</mat-label>
            <input matInput formControlName="magyar" required>
            <mat-error *ngIf="balanceSheetDetailNewitemForm.controls.magyar.hasError('required')">
                {{'error_message_hungarian_title_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'common_title_english_title' | translate}}</mat-label>
            <input matInput formControlName="angol" required>
            <mat-error *ngIf="balanceSheetDetailNewitemForm.controls.angol.hasError('required')">
                {{'error_message_english_title_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'common_title_value' | translate}}</mat-label>
            <input matInput type="number" formControlName="amount" required>
            <mat-error *ngIf="balanceSheetDetailNewitemForm.controls.amount.hasError('required')">
                {{'error_message_value_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="insertItem()" class="positiveButton">{{'workflows_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'workflows_reject' | translate}}</button>
</mat-dialog-actions>
