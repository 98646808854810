import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { WorkflowTemplateBasicEditorComponent } from '../../workflow-templates/workflow-template-basic-editor/workflow-template-basic-editor.component';
import { UserWfsService } from '../../../services/user-wfs.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workflow-create',
  templateUrl: './workflow-create.component.html',
  styleUrls: ['./workflow-create.component.less']
})
export class WorkflowCreateComponent implements OnInit {

  newWorkflowForm: FormGroup = this.formBuilder.group({
    id: null,
    base: [null, Validators.required],
    dateWorkflow: [null, Validators.required],
    templateWorkflow: [null, Validators.required],
    workflow: [null, Validators.required],
    company: [null, Validators.required],
  })

  reportTypeSelectionItems = [];

  _previousDateSelectionItems: any;
  get previousDateSelectionItems() { return this._previousDateSelectionItems };
  set previousDateSelectionItems(value: any) {
    this._previousDateSelectionItems = value;
  }

  _templateSelectionItems: any;
  get templateSelectionItems() { return this._templateSelectionItems };
  set templateSelectionItems(value: any) {
    this._templateSelectionItems = value;
  }

  _workflowCompanySelectionItems: any;
  get workflowCompanySelectionItems() { return this._workflowCompanySelectionItems };
  set workflowCompanySelectionItems(value: any) {
    this._workflowCompanySelectionItems = value;
  }

  _workflows: any;
   get workflows() { return this._workflows };
   set workflows(value: any) {
   this._workflows = value;
   }

  /*_workflowTemplateSelectionItems: any;
  get workflowTemplateSelectionItems() { return this._workflowTemplateSelectionItems };
  set workflowTemplateSelectionItems(value: any) {
    this._workflowTemplateSelectionItems = value;
  }*/

  workflowTemplateSelectionItems = [];


  translatableTexts: any[] = [
    {value: "common_message_attention", name: ""},
    {value: "common_message_discard_changes_and_exit", name: ""},
    {value: "1", name: ""},
    {value: "0", name: ""}
  ];
  
  constructor(private dialogService: MatDialog, public dialogRef: MatDialogRef<WorkflowCreateComponent>, private formBuilder: FormBuilder, private service: UserWfsService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.changeBase("empty");

    this.newWorkflowForm.controls.company.valueChanges.subscribe((response)=>{
      if (response){
        /*this.service.getWfsTable({contract_id: response}).subscribe((wfResponse)=>{
          let wfs = [];
          wfResponse.payload.content.forEach((wf)=>{
            if (wf.is_active){
              wfs.push({name: wf.caption, value: wf.id})
            }
          })
          this.workflowTemplateSelectionItems = wfs;
        })*/
        this.changeCompany();

      }
      else{
        this.workflowTemplateSelectionItems = [];
        this.newWorkflowForm.controls.workflow.setValue(null);
        this.newWorkflowForm.controls.workflow.updateValueAndValidity();
      }
    })

    this.service.getARTypes().subscribe((response)=>{
      response.payload.forEach((types)=>{
        if (types.is_active){
          this.reportTypeSelectionItems.push({name: types.sid, value:types.sid})
        }
      })
    });

    this.translateService.stream(['common_message_attention', 'common_message_discard_changes_and_exit', '1', '0']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_attention", name: res["common_message_attention"]},
        {value: "common_message_discard_changes_and_exit", name: res["common_message_discard_changes_and_exit"]},
        {value: "1", name: res["1"]},
        {value: "0", name: res["0"]}
      ];
    });
  }

  changeBase(value){
    switch(value){
      case "empty":
        this.newWorkflowForm.controls.dateWorkflow.disable();
        this.newWorkflowForm.controls.templateWorkflow.disable();
        this.newWorkflowForm.controls.workflow.disable();
        this.newWorkflowForm.controls.company.disable();
        this.newWorkflowForm.controls.dateWorkflow.reset();
        this.newWorkflowForm.controls.templateWorkflow.reset();
        this.newWorkflowForm.controls.workflow.reset();
        this.newWorkflowForm.controls.company.reset();
        break;
      case "previousDate":
        this.newWorkflowForm.controls.dateWorkflow.enable();
        this.newWorkflowForm.controls.templateWorkflow.disable();
        this.newWorkflowForm.controls.workflow.disable();
        this.newWorkflowForm.controls.company.disable();
        this.newWorkflowForm.controls.templateWorkflow.reset();
        this.newWorkflowForm.controls.workflow.reset();
        this.newWorkflowForm.controls.company.reset();
        break;
      case "template":
        this.newWorkflowForm.controls.dateWorkflow.disable();
        this.newWorkflowForm.controls.templateWorkflow.enable();
        this.newWorkflowForm.controls.workflow.disable();
        this.newWorkflowForm.controls.company.disable();
        this.newWorkflowForm.controls.dateWorkflow.reset();
        this.newWorkflowForm.controls.workflow.reset();
        this.newWorkflowForm.controls.company.reset();
        break;
      case "existingWorkflow":
        this.newWorkflowForm.controls.dateWorkflow.disable();
        this.newWorkflowForm.controls.templateWorkflow.disable();
        this.newWorkflowForm.controls.workflow.enable();
        this.newWorkflowForm.controls.company.enable();
        this.newWorkflowForm.controls.dateWorkflow.reset();
        this.newWorkflowForm.controls.templateWorkflow.reset();
        break;
    }

  }

  reject(){
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = this.translatableTexts.find(element => element.value === "common_message_attention").name;
    dialog.componentInstance.description = this.translatableTexts.find(element => element.value === "common_message_discard_changes_and_exit").name;
    dialog.componentInstance.positiveButtonText = this.translatableTexts.find(element => element.value === "1").name;
    dialog.componentInstance.negativeButtonText = this.translatableTexts.find(element => element.value === "0").name;
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.dialogRef.close();
      }
    })
  }

  next(){
    const dialog = this.dialogService.open(WorkflowTemplateBasicEditorComponent);
    dialog.componentInstance.newWorkflowForm = this.newWorkflowForm;
    dialog.componentInstance.customerSelectionItems = this.workflowCompanySelectionItems;
    dialog.componentInstance.reportTypeSelectionItems = this.reportTypeSelectionItems;
    dialog.afterClosed().subscribe((response) =>{
      this.dialogRef.close(response);
    })
    
  }

  changeCompany(){
    if(this.newWorkflowForm.controls['company'].value){
      this.workflowTemplateSelectionItems = [];
      this.workflows.forEach(template => {
        if(template.customer_id == this.newWorkflowForm.controls['company'].value) {
          this.workflowTemplateSelectionItems.push({"value": template.id, "name": template.caption})
        }
      })
    }
  }

}
