import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Component, OnInit, AfterViewInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkflowElementGroup } from 'src/app/data/workflow-element-group';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {WfTemplateService} from "../../../services/wf-template.service";

@Component({
  selector: 'app-workflow-element-editor',
  templateUrl: './workflow-element-editor.component.html',
  styleUrls: ['./workflow-element-editor.component.less']
})
export class WorkflowElementEditorComponent implements OnInit, AfterViewInit {

  _workflowGroup: WorkflowElementGroup;
  set workflowGroup(value: WorkflowElementGroup) {
    this._workflowGroup = value;
    this.workflowGroupForm.patchValue(value);
    if (value.serial_number == 1 ){
      this.workflowGroupForm.get("isFirst").setValue(true);
      this.changeIsFirst(true);
    }
    else{
      this.workflowGroupForm.get("order").setValue(value.serial_number);
    }
    
  }

  _timeCodeSelectionItems: any;
  get timeCodeSelectionItems() { return this._timeCodeSelectionItems };
  set timeCodeSelectionItems(value: any) {
    this._timeCodeSelectionItems = value;
  }

  _workflowGroupSelectionItems: any;
  get workflowGroupSelectionItems() { return this._workflowGroupSelectionItems };
  set workflowGroupSelectionItems(value: any) {
    this._workflowGroupSelectionItems = value;
  }

  workflowGroupForm: FormGroup = this.formBuilder.group({
    id: null,
    order: [null, [Validators.required]],
    caption: [null, [Validators.required]],
    workflow_element_group_time_sid: [null],
    isFirst: false
  });

  translatableTexts: any[] = [
    {value: "error_message_error_creating_folder", name: ""},
    {value: "error_message_error_editing_folder", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<WorkflowElementEditorComponent>, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService, public wfTemplateService: WfTemplateService) { }

  ngOnInit(): void {
    this.translateService.stream(['error_message_error_creating_folder', 'error_message_error_editing_folder']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_creating_folder", name: res["error_message_error_creating_folder"]},
        {value: "error_message_error_editing_folder", name: res["error_message_error_editing_folder"]}
      ];
    });

    console.log(this.workflowGroupForm.value);

  }

  ngAfterViewInit():void{

  }

  changeIsFirst(checked){
    if(checked){
      this.workflowGroupForm.get("order").setValue(null);
      this.workflowGroupForm.get("order").disable();
    } else {
      this.workflowGroupForm.get("order").enable();
    }
  }

  saveWorkflowGroup() {
    if (this.workflowGroupForm.valid) {
      if (this.workflowGroupForm.get("isFirst").value == true){
        this.workflowGroupForm.get("order").setValue(1);
      }
      if(this.workflowGroupForm.get("id").value){
        this.modify().then((response) => {
          this.dialogRef.close(response);
         });
      }
      else{
        this.save().then((response) => {
        this.dialogRef.close(response);
       });
      }
    } else {
      this.workflowGroupForm.markAllAsTouched();
    }
  }

  save() {
    let body = {
      serial_number: this.workflowGroupForm.get("order").value,
      workflow_element_group_time_sid: this.workflowGroupForm.get("workflow_element_group_time_sid").value,
      caption: this.workflowGroupForm.get("caption").value
  };
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(environment.serverEndpoint + '/owner/wfelementgroup', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_folder").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  modify() {
    let body = {
      id: this.workflowGroupForm.get("id").value,
      serial_number: this.workflowGroupForm.get("order").value,
      workflow_element_group_time_sid: this.workflowGroupForm.get("workflow_element_group_time_sid").value,
      caption: this.workflowGroupForm.get("caption").value
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.serverEndpoint + '/owner/wfelementgroup', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_editing_folder").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
