<h2 mat-dialog-title>{{'country_specific-parameter' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="countryParamForm">
        <mat-checkbox formControlName="is_auditor_registration_required">{{'country_should-register-auditor-in-registry' | translate}}</mat-checkbox>
        <mat-checkbox formControlName="is_online_provider_available">{{'country_is-available-online-firmdata-provider' | translate}}</mat-checkbox>
        <mat-checkbox formControlName="is_auditor_contract_required">{{'country_is-necessary-auditor-contract' | translate}}</mat-checkbox>
        <mat-checkbox formControlName="is_esignature_allowed">{{'country_is-acceptable-electronic-signature' | translate}}</mat-checkbox>
        <mat-checkbox formControlName="is_chamber_report_required">{{'country_is-required-toward-chamber' | translate}}</mat-checkbox>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button class="positiveButton" (click)="saveCountryParams()">
        {{'country_parameter-recording' | translate}}
    </button>
    <button mat-button class="negativeButton" (click)="dialogRef.close()">{{'country_reject' | translate}}</button>
</mat-dialog-actions>
