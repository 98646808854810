import {MatPaginatorIntl} from '@angular/material/paginator';

const hunRangeLabel = (page: number, pageSize: number, length: number) => {
//    if (length === 0 || pageSize === 0) { return `0 (összesen: ${length})`; }
    if (length === 0 || pageSize === 0) { return `0 (sum of: ${length})`; }

    

    length = Math.max(length, 0);

    

    const startIndex = page * pageSize;

    

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    
//    return `${startIndex + 1} - ${endIndex} (összesen: ${length})`;
    return `${startIndex + 1} - ${endIndex} (sum of: ${length})`;
};


export function getHunPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Results per page:';
    paginatorIntl.nextPageLabel = 'Next page'; 
    paginatorIntl.previousPageLabel = 'Previous page';
    paginatorIntl.getRangeLabel = hunRangeLabel;
    paginatorIntl.lastPageLabel = 'Last page';
    paginatorIntl.firstPageLabel = 'First page';

    /*
    paginatorIntl.itemsPerPageLabel = 'Találatok oldalanként:';
    paginatorIntl.nextPageLabel = 'Következő oldal';
    paginatorIntl.previousPageLabel = 'Előző oldal';
    paginatorIntl.getRangeLabel = hunRangeLabel;
    paginatorIntl.lastPageLabel = 'Utolsó oldal';
    paginatorIntl.firstPageLabel = 'Első oldal';
    */

    return paginatorIntl;
}
