<div class="picker">
    <input type="file" id="picker" name="fileList" webkitdirectory multiple (change)="upload($event)">
</div>

<table class="table">
    <thead>
        <tr>
            <th>{{'common_title_name' | translate}}</th>
            <th>{{'common_title_relative_path' | translate}}</th>
            <th>{{'common_title_size' | translate}}</th>
            <th>{{'common_title_last_modified_date' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let file of files">
            <td>{{ file.name}}</td>
            <td>{{ file.webkitRelativePath}}</td>
            <td>{{ file.size}}</td>
            <td>{{ file.lastModified}}</td>
        </tr>
    </tbody>
</table>