import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, AfterContentInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { PaginatedAnswer } from 'src/app/data/response';
import { ProgressService } from 'src/app/services/progress.service';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../../../services/company.service';
import { UserWfsService } from '../../../services/user-wfs.service';
import { TranslateService } from '@ngx-translate/core';
import {MatSelect} from "@angular/material/select";
import {DateAdapter} from "@angular/material/core";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import {DateParsePipe} from "../../../pipes/date-parse.pipe";
import {Workflows} from "../../../data/workflows";

@Component({
  selector: 'app-timesheet-editor',
  templateUrl: './timesheet-editor.component.html',
  styleUrls: ['./timesheet-editor.component.less']
})
export class TimesheetEditorComponent implements AfterContentInit, OnInit, AfterViewInit {

  today = new Date(new Date().getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0];

  @ViewChild("input_working_day") inputWorkingDay: ElementRef;

  taskForm: FormGroup = this.formBuilder.group({
    working_day: [null, [Validators.required]],
    customer_id: [null, [Validators.required]],
    workflow: [null, [Validators.required]],
    workflow_element_id: [null, [Validators.required]],
    working_time: [null, [Validators.required]],
    comment: [null],
  });

  //TODO
  customerSelectionItems: any[] = [];
  wfSelectionItems: any[] = [];
  wfelemSelectionItems: any[] = [];

  workflows: Workflows[];

  translatableTexts: any[] = [
    {value: "error_message_work_save_unsuccessful", name: ""},
    ];

  _clickedDay: any;
  get clickedDay() { return this._clickedDay };
  set clickedDay(value: any) {
    this._clickedDay = value;
  }

  get formData() { return <FormArray>this.taskForm.get('customer_id'); }

  constructor(private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, private dialogService: MatDialog, public dialogRef: MatDialogRef<TimesheetEditorComponent>, private formBuilder: FormBuilder, private el: ElementRef, private httpClient: HttpClient, private snackBar: MatSnackBar, private progressService: ProgressService, private companyService: CompanyService, private userWfsService: UserWfsService, private translateService: TranslateService) { }

  @ViewChild('customerInput') customerInput: MatSelect;

  ngAfterContentInit(): void{
    this._adapter.setLocale(localStorage.getItem("language") == "hu_HU"?'hu':'en');
  }



  ngOnInit(): void {

    this.workflows = [];
    this.userWfsService.getWfs("timesheet").subscribe((response)=>{
      response.payload.content.forEach((wf)=>{
        if (wf.is_active){
          this.workflows.push(wf);
        }
      })
    });

    if(this.clickedDay!=null){

//      this.taskForm.controls.working_day.setValue(this.clickedDay);
//      console.log(this.formData.nativeElement);
//      this.customerInput.nativeElement.focus();
//      console.log(this.taskForm.controls.account_id);
//      this.taskForm.get('account_id').nativeElement.focus();
//      this.taskForm.controls.account_id.nativeElement.focus();
    }
    this.companyService.getCompanies().subscribe((response) =>{
      let customers = [];
      response.payload.content.forEach((company) => {
        if (company.is_active){
          customers.push({name: company.short_name, value: company.id})
        }
      })
      this.customerSelectionItems = customers;
    })

    this.taskForm.get("customer_id").valueChanges.subscribe((value) =>{
      if (value){
        this.taskForm.get("workflow").setValue(null);
        this.taskForm.get("workflow").markAsUntouched();
        this.taskForm.get("workflow").updateValueAndValidity();
        this.taskForm.get("workflow_element_id").setValue(null);
        this.taskForm.get("workflow_element_id").markAsUntouched();
        this.taskForm.get("workflow_element_id").updateValueAndValidity();
        this.wfSelectionItems = [];
        this.wfelemSelectionItems = [];
        this.workflows.forEach((wf : any) => {
          if(wf.customer_id == value){
            this.wfSelectionItems.push({name:wf.caption, value:wf.id});
          }
        })
      } else{
        this.taskForm.get("workflow").setValue(null);
        this.taskForm.get("workflow").markAsUntouched();
        this.taskForm.get("workflow").updateValueAndValidity();
        this.taskForm.get("workflow_element_id").setValue(null);
        this.taskForm.get("workflow_element_id").markAsUntouched();
        this.taskForm.get("workflow_element_id").updateValueAndValidity();
        this.wfSelectionItems = [];
        this.wfelemSelectionItems = [];
      }
    })

    this.taskForm.get("workflow").valueChanges.subscribe((value) =>{
      if (value) {
        this.taskForm.get("workflow_element_id").setValue(null);
        this.taskForm.get("workflow_element_id").markAsUntouched();
        this.taskForm.get("workflow_element_id").updateValueAndValidity();
        this.wfelemSelectionItems = [];
        this.userWfsService.getWfes(value).subscribe((response) => {
          let wfes = [];
          response.payload.content.forEach((wfe) => {
            if (wfe.is_active){
              wfes.push({name: wfe.caption, value: wfe.id})
            }
          })
          this.wfelemSelectionItems = wfes;
        })
      }
      else{
        this.taskForm.get("workflow_element_id").setValue(null);
        this.taskForm.get("workflow_element_id").markAsUntouched();
        this.taskForm.get("workflow_element_id").updateValueAndValidity();
        this.wfelemSelectionItems = [];
      }
    })

    this.translateService.stream(['error_message_work_save_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_work_save_unsuccessful", name: res["error_message_work_save_unsuccessful"]}
      ];
    });
  }

  ngAfterViewInit() {
    if(this.clickedDay!=null) {
      this.inputWorkingDay.nativeElement.value = this.pipeFormat.transform(this.clickedDay);
    }
//    this.taskForm.get("account_id").focus();
//    console.log(this.el.nativeElement);
//    this.el.nativeElement.querySelector("form #mat-select-0").focus();
  }

  saveTask(close: boolean) {

    this.taskForm.get("working_day").setValue(this.inputWorkingDay.nativeElement.value);
    if (this.taskForm.valid) {
      let body = JSON.parse(JSON.stringify(this.taskForm.getRawValue()));
      body.working_day = new Date(this.pipeParse.transform(this.inputWorkingDay.nativeElement.value)).getTime() / 1000;
      body.working_time = body.working_time * 3600;
      //body.working_day = new Date(this.taskForm.get("working_day").value).getTime()/1000;
      this.saveBackendTask(body).subscribe((response) => {
        if (close) {
          // console.log("bezár");
          this.dialogRef.close(response);
        } else {
          // console.log("folytat");
          // console.log(response);
          this.snackBar.open("A munka mentése sikeres", null, {panelClass: 'success-snackbar'});
        }
      });
    } else {
      this.taskForm.markAllAsTouched();
      let firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
      this.scrollToFirstInvalidControl(firstInvalidControl);
    }
  }

  private scrollToFirstInvalidControl(firstInvalidControl) {
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  saveBackendTask(body) {
    const task = this.progressService.addTask(this.constructor.name + '_save_task');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/timesheethours',body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_work_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

}
