import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import {ApiEndpoint} from "../../environments/api-endpoint";
import {catchError, finalize} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {CountAnswer, PaginatedAnswer, PaginatedRequest, Response} from "../data/response";
import { Role } from '../data/role';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RolesService extends BaseService<Role> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_roles_list_unsuccessful", name: ""},
    {value: "error_message_role_get_unsuccessful", name: ""},
    {value: "error_message_role_delete_unsuccessful", name: ""},
    {value: "error_message_role_create_unsuccessful", name: ""},
    {value: "error_message_role_update_unsuccessful", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
        {
          listUrl: ApiEndpoint.auditorsEndpoint,
          getUrl: ApiEndpoint.auditorsGetEndpoint,
          createUrl: ApiEndpoint.auditorsEndpoint + "/create",
          deleteUrl: '',
          updateUrl: ApiEndpoint.auditorsEndpoint
        },
        {
          listError: 'A szerepkörök letöltése sikertelen',
          getError: 'A szerepkör letöltése sikertelen',
          deleteError: 'A szerepkör törlése sikertelen',
          createError: 'A szerepkör létrehozása sikertelen',
          updateError: 'A szerepkör módosítása sikertelen'
        },
        httpClient, snackBar, progressService);
  }

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_roles_list_unsuccessful',
      'error_message_role_get_unsuccessful',
      'error_message_role_delete_unsuccessful',
      'error_message_role_create_unsuccessful',
      'error_message_role_update_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_roles_list_unsuccessful", name: res["error_message_roles_list_unsuccessful"]},
        {value: "error_message_role_get_unsuccessful", name: res["error_message_role_get_unsuccessful"]},
        {value: "error_message_role_delete_unsuccessful", name: res["error_message_role_delete_unsuccessful"]},
        {value: "error_message_role_create_unsuccessful", name: res["error_message_role_create_unsuccessful"]},
        {value: "error_message_role_update_unsuccessful", name: res["error_message_role_update_unsuccessful"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_roles_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_role_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_role_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_role_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_role_update_unsuccessful").name
  }

  listPrivgroups(paginatedRequest): Observable<PaginatedAnswer<Role>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(paginatedRequest));
    return this.httpClient.get<PaginatedAnswer<Role>>(environment.serverEndpoint + '/owner/privgroups?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getPrivgroup(id): Observable<PaginatedAnswer<Role>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify({id: id}));
    return this.httpClient.get<PaginatedAnswer<Role>>(environment.serverEndpoint + '/owner/privgroup?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  listRoles(): Observable<PaginatedAnswer<Role>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<PaginatedAnswer<Role>>(environment.serverEndpoint + '/common/roles', {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  addRole(body) {
    const task = this.progressService.addTask(this.constructor.name + '_add_role');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<PaginatedAnswer<Role>>(environment.serverEndpoint + '/owner/privgroup', body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  modifyRole(body) {
    const task = this.progressService.addTask(this.constructor.name + '_add_role');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.put<PaginatedAnswer<Role>>(environment.serverEndpoint + '/owner/privgroup', body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  deleteRole(id: any): Observable<PaginatedAnswer<Role>> {
    const task = this.progressService.addTask(this.constructor.name + '_delete');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id
      },
    };
    
    return this.httpClient.delete<any>(environment.serverEndpoint + '/owner/privgroup', options).pipe(
      catchError(error => {
        this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

}
