import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { PaginatedAnswer } from 'src/app/data/response';
import { ProgressService } from 'src/app/services/progress.service';
import { environment } from 'src/environments/environment';
import { BalanceSheetComponent } from '../balance-sheet.component';
import {BalanceSheetDetailNewitemComponent} from "./balance-sheet-detail-newitem/balance-sheet-detail-newitem.component";
import {UserWfsService} from "../../../../services/user-wfs.service";
import {OkDialogComponent} from "../../../ok-dialog/ok-dialog.component";
import {FileSystemComponent} from "../../../file-system/file-system.component";
import {FileSystemService} from "../../../../services/file-system.service";

@Component({
  selector: 'app-balance-sheet-detail',
  templateUrl: './balance-sheet-detail.component.html',
  styleUrls: ['./balance-sheet-detail.component.less']
})
export class BalanceSheetDetailComponent implements OnInit {

  _element: any;
  get element() { return this._element };
  set element(value: any) {
    this._element = value;
  }

    _elements: any;
    get elements() { return this._elements };
    set elements(value: any) {
        this._elements = value;
    }

    _customer_id: any;
    get customer_id() { return this._customer_id };
    set customer_id(value: any) {
        this._customer_id = value;
    }

  balanceForm: FormGroup = this.formBuilder.group({
    data: [null, [Validators.required]]
  });

    _isDirty: any;
    get isDirty() { return this._isDirty };
    set isDirty(value: any) {
        this._isDirty = value;
    }

    translatableTexts: any[] = [
      {value: "error_message_balance_sheet_save_unsuccessful", name: ""}
    ];

  constructor(private userWfService: UserWfsService, private dialogService: MatDialog, private translateService: TranslateService, public dialogRef: MatDialogRef<BalanceSheetComponent>, private fsService: FileSystemService, private httpClient: HttpClient, private snackBar: MatSnackBar, private progressService: ProgressService,  private formBuilder: FormBuilder) { }

  ngOnInit(): void {
      this.isDirty = false;
      this.prepare();

      this.translateService.stream(['error_message_balance_sheet_save_unsuccessful']).subscribe((res: string) => {
        this.translatableTexts = [
          {value: "error_message_balance_sheet_save_unsuccessful", name: res["error_message_balance_sheet_save_unsuccessful"]}
        ];
      });
  }

  refreshForm(){
      this.userWfService.getGVItems(this.element.gv_value_id).subscribe((response)=>{
          if(response){
              if(response.type == "OK"){
                  this.elements = response.payload;
                  this.prepare();
              }
          }
      })
  }

  prepare(){
      let ssz = 0;
      this.elements.forEach(single => {
          ssz++;
          if(single.path){
              let fileName = single.path.split("/")[single.path.split("/").length-1];
              single.fileName = fileName;
          }
          single.ssz = ssz;
          single.title.forEach(tSingle => {
              if(tSingle.locale=='en_GB')single.angol = tSingle.title;
              if(tSingle.locale=='hu_HU')single.magyar = tSingle.title;
          })
      })
  }
  saveBalanceSheetDetail(){

        this.saveBackend(this.balanceForm.getRawValue()).subscribe((response) => {
          this.dialogRef.close(response);
        });
  }

  saveBackend(body){
    const task = this.progressService.addTask(this.constructor.name + '_save_balance_sheet');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '',body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_balance_sheet_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

    addNewItem(){
        const dialog = this.dialogService.open(BalanceSheetDetailNewitemComponent, {width: '95vw'});
        dialog.componentInstance.gvItem = this.element;
        dialog.afterClosed().subscribe((response) => {
            if(response) {
                this.isDirty = true;
                this.refreshForm()
            }
        });
    }

    uploadFile(item){
        const dialog = this.dialogService.open(FileSystemComponent);
        dialog.componentInstance.isInDialog = true;
        dialog.componentInstance.page = 'gvschema';
        dialog.componentInstance.listType = event;
        dialog.componentInstance.customer_id = this.customer_id;
        dialog.componentInstance.gv_item_id = item.id;
        dialog.afterClosed().subscribe(() => this.refreshForm());
    }

    deleteFromGvItem(id){
        const dialog = this.dialogService.open(OkDialogComponent);
        this.translateService.get('file_system_file-remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
        this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
        this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
        dialog.afterClosed().subscribe(response => {
            if (response) {
                this.fsService.deleteFileFromGVItem(id).then((resp: any)=> {
                    if (resp) {
                        if (resp.type == "OK")this.refreshForm();
                    }
                });
            }
        });
    }

    modifyItem(item){
        const dialog = this.dialogService.open(BalanceSheetDetailNewitemComponent, {width: '95vw'});
        dialog.componentInstance.actualItem = item;
        dialog.componentInstance.gvItem = this.element;
        dialog.afterClosed().subscribe((response) => {
            if(response) {
                this.isDirty = true;
                this.refreshForm()
            }
        });
    }

    deleteItem(item){
        const dialog = this.dialogService.open(OkDialogComponent);
        dialog.componentInstance.title = 'Figyelem';
        dialog.componentInstance.description =
            'Biztosan törli a naplóbejegyzést?';
        dialog.componentInstance.positiveButtonText = "Igen";
        dialog.componentInstance.negativeButtonText = "Nem";
        dialog.afterClosed().subscribe(response => {
            if (response) {
                let body = {id:item.id}
                this.userWfService.deleteGVItem(body).then((resp) => {
                    if(resp){
                        if(resp.type=="OK"){
                            this.isDirty = true;
                            this.refreshForm();
                        }
                    }
                });
            }
        });
    }

}
