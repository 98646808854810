import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { Event } from "@angular/router";
import { UserWfsService } from "src/app/services/user-wfs.service";

@Component({
    selector: 'app-balance-feedback',
    templateUrl: './balance-feedback.component.html',
    styleUrls: ['./balance-feedback.component.less']
  })
export class BalanceFeedbackComponent implements OnInit {

    _companies;
    get companies() { return this._companies};
    set companies(value:any){
        this._companies = value;
    }

    /*balanceFeedbackForm: FormGroup = this.formBuilder.group({
        isAnswered: [],
        isPayableAllowed: [],
        isReceivableAllowed: [],
        isPayableRejected: [],
        isReceivableRejected: [],
        payableAnswerText: [],
        receivableAnswerText: [],
    });*/
    formToSend: FormGroup = this.formBuilder.group({
        balanceFeedbackForm: this.formBuilder.array([])
    })

    answer = null;

    constructor(private userWfsService: UserWfsService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<BalanceFeedbackComponent>,){}


    ngOnInit(): void {
        this._companies.forEach(company => {
            this.addFormField(company);
        });
        this.getFormArray().controls.forEach(form => {
            if(form.get('is_receivable_allowed').value){
                if(form.get('is_receivable_accepted').value){
                    form.get('is_receivable_answer').setValue('accept');
                }
                if(form.get('is_receivable_rejected').value){
                    form.get('is_receivable_answer').setValue('reject');
                }
            }
            if(form.get('is_payable_allowed')){
                if(form.get('is_payable_accepted').value){
                    form.get('is_payable_answer').setValue('accept');
                }
                if(form.get('is_payable_rejected').value){
                    form.get('is_payable_answer').setValue('reject');
                }
            }
        })
        console.log(this.formToSend);
    }

    
    clearRadio(id){
        this.getFormArray().controls.forEach(form => {
            if(form.get('debit_balance_item_id').value == id){
                if(form.get('is_answered').value == false){
                    console.log(false);
                    form.get('is_receivable_answer').reset()
                    form.get('is_payable_answer').reset()
                    form.get('receivable_answer').reset()
                    form.get('payable_answer').reset()
                    form.get('is_receivable_accepted').setValue(false)
                    form.get('is_receivable_rejected').setValue(false)
                    form.get('is_payable_accepted').setValue(false)
                    form.get('is_payable_rejected').setValue(false)

                    console.log(form);
                }
            }
        })
    }
    acceptReject(id, type:string, event: MatRadioChange){
        this.getFormArray().controls.forEach(form => { 
            if(id == form.get('debit_balance_item_id').value){
                form.get('is_answered').setValue(true);
                if(type == 'rec'){
                    if(event.value == 'accept'){
                        form.get('is_receivable_accepted').setValue(true);
                        form.get('is_receivable_rejected').setValue(false);
                    }
                    if(event.value == 'reject'){
                        form.get('is_receivable_rejected').setValue(true);
                        form.get('is_receivable_accepted').setValue(false);
                    }
                }
            }
        })
    }

    getFormArray(){
        return this.formToSend.get("balanceFeedbackForm") as FormArray;
    }

    newFormField(params?){
        return this.formBuilder.group({
          workflow_element_id: [params.workflow_element_id, Validators.required],
          debit_balance_item_id: [params.debit_balance_item_id],
          company_name: [params.company_name],
          is_answered: [params.is_answered],
          is_receivable_allowed: [params.is_receivable_allowed],
          is_receivable_accepted: [params.is_receivable_accepted],
          is_receivable_rejected: [params.is_receivable_rejected],
          receivable_answer: [params.receivable_answer],
          receivable_sum: [params.receivable_sum],
          is_payable_allowed: [params.is_payable_allowed],
          is_payable_rejected: [params.is_payable_rejected],
          is_payable_accepted: [params.is_payable_accepted],
          payable_answer: [params.payable_answer],
          payable_sum: [params.payable_sum],
          responsible_account_id: [params.responsible_account_id],
          currency: [params.currency],
          is_receivable_answer: new FormControl(),
          is_payable_answer: new FormControl(),
        });
        
      }
    
    
    addFormField(params){
        this.getFormArray().push(this.newFormField(params));
    }

    close(){
        this.dialogRef.close('closed')
    }

    saveAndSend(){
        let form = [];
        this.getFormArray().value.forEach(formEl => {
            form.push(formEl);
        });
        console.log(form);
        this.userWfsService.postDebitBalandeFeedback(form).subscribe(value => {
            this.dialogRef.close('saved');
        })
    }
}