<h2 mat-dialog-title [style.textAlign]="'center'">{{'common_title_bank_accounts' | translate}}</h2>

<mat-dialog-content>
    <form [formGroup]="bankAccountForm" [style.display]="'flex'" [style.justify-content]="'space-between'">
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_title' | translate}}</mat-label>
                <input matInput formControlName="name" required>
                <mat-error *ngIf="bankAccountForm.controls.name.hasError('required')">
                    {{'error_message_name_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_bank_account_number' | translate}}</mat-label>
                <input matInput formControlName="account" required>
                <mat-error *ngIf="bankAccountForm.controls.account.hasError('required')">
                    {{'error_message_bank_account_number_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_address' | translate}}</mat-label>
                <input matInput formControlName="address" required>
                <mat-error *ngIf="bankAccountForm.controls.address.hasError('required')">
                    {{'error_message_postal_address_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <mat-dialog-actions [style.justify-content]="'center'">
        <button (click)="appendAccount()" mat-button class="positiveButton">{{'button_title_insert' | translate}}</button>
    </mat-dialog-actions>

    <div class="table-content" flex>
        <div class="column">
            <div class="table-header">
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'30%'">
                    {{'common_title_title' | translate}}
                </div>
                <div class="table-text" [style.width]="'30%'">
                    {{'common_title_bank_account_number' | translate}}
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'40%'">
                    {{'postal_address' | translate}}
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'20%'">
                   
                </div>
            </div>
            <div class="make-scrollable" flex>
                <div *ngFor="let row of bankAccounts; let i = index" class="table-item">
                    <div (click)="editRow(row)" class="table-text" [style.justify-content]="'flex-start'" [style.width]="'30%'">
                        {{row.name}}
                    </div>
                    <div class="table-text" (click)="editRow(row)" [style.width]="'30%'">
                        {{row.account}}
                    </div>
                    <div class="table-text" (click)="editRow(row)" [style.justify-content]="'flex-start'" [style.width]="'40%'">
                        {{row.address}}
                    </div>
                    <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'20%'">
                        <button mat-button class="negativeButton" (click)="deleteAcc(row)">Törlés</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveAccount()" class="positiveButton">{{'companies_save' | translate}}</button>
    <button mat-button (click)="reject()" class="negativeButton">{{'companies_reject' | translate}}</button>
</mat-dialog-actions>
