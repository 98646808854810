import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: number): String {
    if(value == null || value == 0)return "";
    let dt = new Date(value);
    return dt.toLocaleDateString(localStorage.getItem("language").replace("_","-"));
    /*if(localStorage.getItem("language") == "hu_HU"){
      return dt.getFullYear() +". "+ ('0' + (dt.getMonth()+1)).slice(-2) + '. ' + ('0' + dt.getDate()).slice(-2)+".";
    }else {
      return ('0' + dt.getDate()).slice(-2) + '/' + ('0' + (dt.getMonth()+1)).slice(-2) + '/' + dt.getFullYear();
    }*/
  }

}
