import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';

export interface UrlHolderObject {
  createUrl: string;
  updateUrl: string;
  deleteUrl: string;
  getUrl: string;
  listUrl: string;
}

export interface ErrorMessageHolderObject {
  createError: string;
  updateError: string;
  deleteError: string;
  getError: string;
  listError: string;
}

export abstract class BaseService<T> {

  protected constructor(urlObject: UrlHolderObject, errorMessageHolder: ErrorMessageHolderObject, protected httpClient: HttpClient, protected snackBar: MatSnackBar,
                        protected progressService: ProgressService) {
    this.urlObject = urlObject;
    this.errorMessages = errorMessageHolder;
  }

  protected _items = new BehaviorSubject<T[]>([]);
  protected schemaId = 'id';

  protected readonly urlObject: UrlHolderObject;
  protected readonly errorMessages: ErrorMessageHolderObject;

  public getItems(): Observable<T[]> {
    return this._items.asObservable().pipe(
      filter(list => list.length > 0)
    );
  }

  protected mapCreateResponse(item: any): T {
    return item as T;
  }

  create(item: any): Observable<T> {
    const task = this.progressService.addTask(this.constructor.name + '_create');
    return this.httpClient.post<any>(this.urlObject.createUrl, item).pipe(
      map(response => this.mapCreateResponse(response)),
      tap(response => {
        const newArray = [...this._items.getValue()];
        newArray.unshift(response);
        this._items.next(newArray);
      }),
      catchError(error => {
        this.snackBar.open(this.errorMessages.createError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  protected mapUpdateResponse(item: any): T {
    return item as T;
  }

  update(item: any): Observable<T> {
    const task = this.progressService.addTask(this.constructor.name + '_update');
    return this.httpClient.put<any>(this.urlObject.updateUrl, item).pipe(
      map(response => this.mapUpdateResponse(response)),
      tap(response => {
        const newArray = [...this._items.getValue()];
        const index = newArray.findIndex(element => element[this.schemaId] === response[this.schemaId]);
        if (index > -1) {
          newArray[index] = response;
          this._items.next(newArray);
        }
      }),
      catchError(error => {
        this.snackBar.open(this.errorMessages.updateError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  protected mapGetResponse(item: any): T {
    return item as T;
  }

  get(id: any): Observable<T> {
    const task = this.progressService.addTask(this.constructor.name + '_get');
    return this.httpClient.post<any>(this.urlObject.getUrl, { id }).pipe(
      map(response => this.mapGetResponse(response)),
      tap(response => {
        const newArray = [...this._items.getValue()];
        const index = newArray.findIndex(element => element[this.schemaId] === response[this.schemaId]);
        if (index > -1) {
          newArray[index] = response;
          this._items.next(newArray);
        }
      }),
      catchError(error => {
        this.snackBar.open(this.errorMessages.getError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  protected mapListResponse(item: any[]): T[] {
    return item as T[];
  }

  list(): Observable<T[]> {
    const task = this.progressService.addTask(this.constructor.name + '_list');
    return this.httpClient.get<any[]>(this.urlObject.listUrl).pipe(
      map(response => this.mapListResponse(response)),
      tap(response => {
        this._items.next(response);
      }),
      catchError(error => {
        this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  delete(id: any): Observable<T> {
    const task = this.progressService.addTask(this.constructor.name + '_delete');
    return this.httpClient.delete<any>(this.urlObject.createUrl + '/' + id).pipe(
      tap(() => {
        const index = this._items.getValue().findIndex(element => element[this.schemaId] === id);
        if (index > -1) {
          const newArray = [...this._items.getValue()];
          newArray.splice(index, 1);
          this._items.next(newArray);
        }
      }),
      catchError(error => {
        this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }
}
