<div class="center">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            {{'common_title_optional_custom_content_that_replaces_the_entire_default_content' | translate}}
            <button type="button" (click)="openFileSelector()">{{'button_title_browse_files' | translate}}</button>
        </ng-template>
    </ngx-file-drop>
    <div class="upload-table">
        <table class="table">
            <thead>
                <tr>
                    <th>{{'common_title_name' | translate}}</th>
                    <th>{{'common_title_relative_path' | translate}}</th>
                    <th>{{'common_title_size' | translate}}</th>
                    <th>{{'common_title_last_modified_date' | translate}}</th>
                </tr>
            </thead>
            <tbody class="upload-name-style">
                <!--Eredeti példa 
                <tr *ngFor="let item of files">
                    <td><strong>{{ item.relativePath}}</strong></td>
                </tr>-->
                <tr *ngFor="let file of realFiles">
                    <td>{{ file.name}}</td>
                    <td>{{ file.webkitRelativePath}}</td>
                    <td>{{ file.size}}</td>
                    <td>{{ file.lastModified}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>