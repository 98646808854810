import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-log-entry-dialog',
  templateUrl: './log-entry-dialog.component.html',
  styleUrls: ['./log-entry-dialog.component.less']
})
export class LogEntryDialogComponent implements OnInit {

  _text: any;
  get text() { return this._text };
  set text(value: any) {
    this._text = value;
  }

  constructor(public dialogRef: MatDialogRef<LogEntryDialogComponent>) { }

  ngOnInit(): void {
  }

}
