import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-dialog-details',
  templateUrl: './error-dialog-details.component.html',
  styleUrls: ['./error-dialog-details.component.less']
})
export class ErrorDialogDetailsComponent implements OnInit {

  _error;
  get error() { return this._error; };
  set error(value: any) {
    this._error = value;
  }

  objectKeys = Object.keys;

  title = 'Hiba!'
  errorText = '';
  buttonText = 'Bezár';

  translatableTexts: any[] = [
    {value: "error_message_error", name: ""},
    {value: "error_message_close", name: ""},
    ];
  
  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
//    console.log(this.error)

    this.translateService.stream(['error_message_error', 'error_message_close']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error", name: res["error_message_error"]},
        {value: "error_message_close", name: res["error_message_close"]}
      ];
    });
  }

}
