<h2 mat-dialog-title>{{(roleForm.controls.id.value ? ('roles_role-modification'| translate) : ('roles_new-role-creation'| translate))}}</h2>
<mat-dialog-content [style.maxWidth] = "'750px'" [style.marginBottom]="'1rem'">
    <form [formGroup]="roleForm" >
        <mat-form-field appearance="outline" *ngIf="!this._role">
            <mat-label>{{'roles_role-name'| translate}}</mat-label>
            <input matInput formControlName="caption" required>
            <mat-error *ngIf="roleForm.controls.caption.hasError('required')">
                {{'error_message_name_cannot_be_empty'| translate}}
            </mat-error>
        </mat-form-field>
        <h3 *ngIf="this._role" [style.display]="'flex'" [style.marginRight]="'2rem'">
            {{this._role.group_code | translate}}
        </h3>
        <div formGroupName="roles">
            <div [style.display]="'flex'">
                <div [style.marginRight]="'2rem'">
                    <ng-container *ngFor="let func of functionsText; let i = index">
                        <div *ngIf="i < 7" [style.padding]="'10px 0'">
                            <mat-checkbox formControlName="{{func.value}}" labelPosition="before" [style.width]="'100%'">{{func.text |translate}}</mat-checkbox>
                        </div>                    
                    </ng-container>
                </div>
                <div>
                    <ng-container *ngFor="let func of functionsText; let i = index">
                        <div *ngIf="i >= 7" [style.padding]="'10px 0'">
                            <mat-checkbox formControlName="{{func.value}}" labelPosition="before" [style.width]="'100%'">{{func.text | translate}}</mat-checkbox>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveRole()" class="positiveButton">{{'roles_save'| translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'roles_reject'| translate}}</button>
</mat-dialog-actions>
