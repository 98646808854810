import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Country} from "../../data/country";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {merge, of} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {PaginatedAnswer} from "../../data/response";
import {CountryService} from "../../services/country.service";
import {CountryParamEditorComponent} from "./country-param-editor/country-param-editor.component";
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.less']
})
export class CountryComponent implements OnInit, AfterViewInit {
  data: Country[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  displayedColumns: string[] = ['select', 'id', 'name'];
  selection = new SelectionModel<Country>(false, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSize: number = 10;
  currentPage: number = 1;

  translatableTexts: any[] = [
    {value: "common_message_parameter_save_successful", name: ""}, 
    {value: "error_message_parameter_save_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private service: CountryService, private snackBar: MatSnackBar, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.translateService.stream(['common_message_parameter_save_successful', 'error_message_parameter_save_unsuccessful',]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_parameter_save_successful", name: res["common_message_parameter_save_successful"]},
        {value: "error_message_parameter_save_unsuccessful", name: res["error_message_parameter_save_unsuccessful"]}
      ];
    });
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.loadCountries();
  }

  loadCountries(){
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.service.listCountries(
            {
              "page": this.currentPage,
              "size": this.pageSize
            }
          );
        }),
        map((data: PaginatedAnswer<Country>) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.payload.total;

          return data.payload.content;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return of([]);
        })
    ).subscribe(data => this.data = data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.forEach(row => this.selection.select(row));
  }

  handlePage(event){
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.selection.clear();
  }

  setCountryParams(country: Country) {
    const dialog = this.dialogService.open(CountryParamEditorComponent);
    dialog.componentInstance.country = country;
    this.service.getCountryParam(country.iso_code).subscribe((params) => {
      dialog.componentInstance.params = params.payload;
    });
    dialog.afterClosed().subscribe(response => {
      if (response){
        if (response.type == "OK") {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_parameter_save_successful").name, null, {panelClass: 'success-snackbar'});
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_parameter_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }
    })
  }
}
