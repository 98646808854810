import { Component, AfterViewChecked } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})

export class ChatbotComponent implements  AfterViewChecked {

  constructor(private http: HttpClient) {}
  
  chatbotAPIEndpoint = environment.serverEndpoint + '/common/chatbot';
  chatbotStandardError = 'Problémába ütköztél? Kérjük, keresd meg a rendszergazdát a <a href="mailto:ugyfelszolgalat@aiaudit.hu">ugyfelszolgalat@aiaudit.hu</a> email címen.';
  
  trackById = '';

  chatbotSessionID = Math.random().toString(16).slice(2);
  chatbotWindowStatus = '';
  chatbotMessages = [
    { id: 0, type: 'response', message: 'Miben segíthetek?'}
    ];
  chatbotQuestion = '';
  chatbotPending = '';
  
  chatbotBody = document.querySelector('.chatbot-body');


  ngAfterViewChecked() {
    this.autoSscroll();
  } 

  addItemToMessages(tpe: string, msg: string) {
    if (tpe == 'error') {
      if (msg != '') {
        msg += '<br><br>';
      } 
      msg += this.chatbotStandardError;
    }
    this.chatbotMessages.push({
      id:  this.chatbotMessages.length,
      type: tpe,
      message: msg
    });
  }

  autoSscroll() {
    let chatbotBody = document.querySelector('.chatbot-body');
    if (chatbotBody != null) {
      chatbotBody.scrollTo({top: chatbotBody.scrollHeight, behavior: 'smooth'});
    }
  }

  openChat() {
    this.chatbotWindowStatus = 'chatbot-window-opened';
  }
  
  closeChat() {
    this.chatbotWindowStatus = '';
  }
  
  chatHelp() {
    this.addItemToMessages('error','');
  }

  setPending(pending: boolean) {
    this.chatbotPending = '';
    if (pending)
      this.chatbotPending = 'chatbot-body-pending';
  }

  messageClassByType(type: string) {
    switch (type) {
      case 'question':
        return 'chatbot-chat-question';
      case 'response':
        return 'chatbot-chat-answer';
    }
    return 'chatbot-chat-answer chatbot-chat-answer-error';
  }

  onSubmit(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.getResponse()
  }

  getResponse() {
    let questionInput = document.querySelector('.chatbot-window div.chatbot-question');
    if (questionInput && questionInput.textContent != null) {
      this.chatbotQuestion = questionInput.textContent.trim();
      questionInput.innerHTML = '';
      if (this.chatbotQuestion.length < 1) {
        questionInput.innerHTML = '';
        return;
      }
    }
    
    this.addItemToMessages( 'question', this.chatbotQuestion );
    this.setPending(true);
    /*
    fetch(this.chatbotAPIEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin" : "*" },
      body: JSON.stringify({ question: this.chatbotQuestion, sessionId: this.chatbotSessionID })
      })
      .then(response => {
            if (!response.ok) {
              throw new Error(response.status.toString());
            }
            return response.json();        
          })
      .then(data => {
            this.addItemToMessages('response', data.response  );
            this.setPending(false);
          })
      .catch(error => {
            this.addItemToMessages('error', error );
            this.setPending(false);
          });
*/
    
    this.http.post<any>(this.chatbotAPIEndpoint, {
        question: this.chatbotQuestion,
        sessionId: this.chatbotSessionID
      }, {
        headers: new HttpHeaders({'Content-Type': 'application/json' }),
        responseType: 'json' 
      })
      .subscribe(
        (data) => {
          this.addItemToMessages('response', data.response);
          this.setPending(false);
        },
        (error) => {
          this.addItemToMessages('error', error);
          this.setPending(false);
        }
      );    
    
    this.chatbotQuestion = '';
  }
}
