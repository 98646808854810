import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserWfsService } from 'src/app/services/user-wfs.service';
import { WfTemplateService } from 'src/app/services/wf-template.service';

@Component({
  selector: 'app-workflow-element-add-coworker',
  templateUrl: './workflow-element-add-coworker.component.html',
  styleUrls: ['./workflow-element-add-coworker.component.less']
})
export class WorkflowElementAddCoworkerComponent implements OnInit {

  addCoworkerForm: FormGroup = this.formBuilder.group({
    coworker: [null, [Validators.required]]
  });

  _coworkerSelectionItems: any;
  get coworkerSelectionItems() { return this._coworkerSelectionItems };
  set coworkerSelectionItems(value: any) {
    this._coworkerSelectionItems = value;
  }

  _workflowElement: any;
  get workflowElement() { return this._workflowElement };
  set workflowElement(value: any) {
    this._workflowElement = value;
  }

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<WorkflowElementAddCoworkerComponent>, private service: WfTemplateService, private userWfService: UserWfsService) { }

  ngOnInit(): void {
  }

  addCoworker(){
    if (this.addCoworkerForm.valid) {
      let body = {
        id: this.workflowElement.id,
        coworker_ids: [this.addCoworkerForm.get("coworker").value]
      }
      this.userWfService.postWfeCoworkers(body).subscribe((response) =>{
        this.dialogRef.close(response);
      })

    } else {
      this.addCoworkerForm.markAllAsTouched();
    }
  }

}
