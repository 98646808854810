import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService } from 'src/app/services/credentials.service';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup = this.formBuilder.group({
    login_password: [null, Validators.required],
    login_password_again: [null, Validators.required],
    token: null
  });

  translatableTexts: any[] = [
    {value: "common_message_new_password_set_successful", name: ""},
    {value: "error_message_new_password_set_unsuccessful", name: ""}
  ];

  constructor(private formBuilder: FormBuilder, private service: CredentialsService, private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar, private router: Router, private dialogService: MatDialog, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.resetPasswordForm.get("token").setValue(params.token);
    });

    this.translateService.stream(['common_message_new_password_set_successful', 'error_message_new_password_set_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_new_password_set_successful", name: res["common_message_new_password_set_successful"]},
        {value: "error_message_new_password_set_unsuccessful", name: res["error_message_new_password_set_unsuccessful"]}
      ];
    });
  }

  resetPassword(){
    if(this.resetPasswordForm.valid) {
      if(this.resetPasswordForm.get("login_password").value == this.resetPasswordForm.get("login_password_again").value) {
        this.service.postLostPassword(this.resetPasswordForm.getRawValue()).subscribe((response) => {
          if (response) {
            if (response.type == "OK") {
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_new_password_set_successful").name, null, {panelClass: 'success-snackbar'});
              this.router.navigate(['/login'])
            }
            else {
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_new_password_set_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = response.payload;
            }
          }

        });
      }else{
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_new_password_set_unsuccessful").name, null, {panelClass: 'error-snackbar'})
      }
    }
  }

}
