<div *ngIf="!isTemplate">
    <mat-toolbar color="primary">
        <div>
        </div>
        <div>
            <button mat-icon-button matTooltip="{{'workflow_templates_new' | translate}}" (click)="editBasicData()">
                <mat-icon>add</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'workflow_templates_modification' | translate}}" [disabled]="!selectedTemplate" (click)="viewWorkflowTemplate()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'workflow_templates_remove' | translate}}" [disabled]="!selectedTemplate" (click)="deleteWorkflowTemplate(selectedTemplate)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="table-content">
        <div class="column">
            <div class="table-header">
                <div class="table-text" [style.justify-content]="'center'" [style.width]="'100%'">
                    {{"workflow_templates_name" | translate}}
                </div>
            </div>
            <ng-container *ngFor="let template of templates" >
                <div class="table-item" (click)="selectedTemplate == template ? selectedTemplate = null : selectedTemplate = template" [class.selected]="selectedTemplate?.id == template.id" *ngIf="template.is_active">
                    <div class="table-text" [style.justify-content]="'center'" [style.width]="'100%'">
                        {{template.caption}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<app-workflow-template *ngIf="isTemplate" (isTemplateEvent)="setIsTemplate($event)" [template]="selectedTemplate" [strictAssignmentControllSelectionItems]="strictAssignmentControllSelectionItems" [strictClosingControllSelectionItems]="strictClosingControllSelectionItems" [coworkersChangedSelectionItems]="coworkersChangedSelectionItems"></app-workflow-template>
