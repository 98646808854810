import { Component, OnInit } from '@angular/core';
import {Validators, FormGroup, FormBuilder} from "@angular/forms";
import {UserWfsService} from "../services/user-wfs.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ErrorDialogComponent} from "../components/error-dialog/error-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-debit-balance',
  templateUrl: './debit-balance.component.html',
  styleUrls: ['./debit-balance.component.less']
})
export class DebitBalanceComponent implements OnInit {

  displayedColumnsRec: string[] = [];
  displayedColumnsPay: string[] = [];
  uuid: string;

  dataSourceRec = new MatTableDataSource<any>([]);
  dataSourcePay = new MatTableDataSource<any>([]);

  debitBalanceForm: FormGroup = this.formBuilder.group({
    isReceivableAccepted: [null],
    isReceivableDeclined: [null],
    receivableAnswerText: [null],
    isPayableAccepted: [null],
    isPayableDeclined: [null],
    payableAnswerText: [null],
    token: null
  });

  isReceivable = false;
  isPayable = false;
  isSendingOk = false;
  thankYou = false;

  locale;

  summary = [];

  translatableTexts: any[] = [
    {value: "common_message_statement_save_successful", name: ""},
    {value: "error_message_statement_save_unsuccessful", name: ""}
  ];

  constructor(private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, public HttpClient: HttpClient, private formBuilder: FormBuilder, private userWfsService: UserWfsService, private router: Router, private dialogService: MatDialog, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.locale = localStorage.getItem('locale');
    this.activatedRoute.params.subscribe(params => {
      this.debitBalanceForm.get("token").setValue(params.token);
      this.uuid = params.token;
      this.userWfsService.getDebitbalanceApproval(params.token).subscribe((response) => {
        if (response){
          if (response.type == "OK"){
            response.payload.forEach(debitblock => {
              if(debitblock.type == "REC"){
                this.isReceivable = true;
                var uc = 0;
                debitblock.header.forEach(colName => {
                  if(!colName){
//                    uc++;
//                    this.displayedColumnsRec.push("col"+uc)
                  }else{
                    this.displayedColumnsRec.push(colName);
                    // console.log(this.displayedColumnsRec);
                  }
                });
                debitblock.rows.forEach(line => {
                  var row = [];
                  var item = {};
                  var oszlop = 0;
                  line.forEach(cellValue => {
                    item[this.displayedColumnsRec[oszlop]] = cellValue;
                    oszlop++;
                  });
                  this.dataSourceRec.data.push(item);
                });
                debitblock.summary.forEach(sum => {
                  this.summary.push(sum);
                })
              }
              if(debitblock.type == "PAY"){
                this.isPayable = true;
                debitblock.header.forEach(colName => {
                  if(!colName){
//                    uc++;
//                    this.displayedColumnsPay.push("col"+uc)
                  }else{
                    this.displayedColumnsPay.push(colName)
                  }
                });
                debitblock.rows.forEach(line => {
                  var row = [];
                  var item = {};
                  var oszlop = 0;
                  line.forEach(cellValue => {
                    item[this.displayedColumnsPay[oszlop]] = cellValue;
                    oszlop++;
                  });
                  this.dataSourcePay.data.push(item);
                });
                debitblock.summary.forEach(sum => {
                  this.summary.push(sum);
                })
              }
            });
          }
        }
      });
    });

    this.translateService.stream(['common_message_statement_save_successful', 'error_message_statement_save_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_statement_save_successful", name: res["common_message_statement_save_successful"]},
        {value: "error_message_statement_save_unsuccessful", name: res["error_message_statement_save_unsuccessful"]}
      ];
    });
  }

  setValues(){
    var answerRec = true;
    var answerPay = true;
    if(this.isReceivable){
      if(this.debitBalanceForm.controls.isReceivableAccepted.value == null){
        answerRec = false
      }
      else
      {
        answerRec = (this.debitBalanceForm.controls.isReceivableAccepted.value) || ( this.debitBalanceForm.controls.isReceivableDeclined.value && this.debitBalanceForm.controls.receivableAnswerText.value != undefined && this.debitBalanceForm.controls.receivableAnswerText.value != "" );
      }
    }
    if(this.isPayable){
      if(this.debitBalanceForm.controls.isPayableAccepted.value == null){
        answerPay = false
      }
      else {
        answerPay = this.debitBalanceForm.controls.isPayableAccepted.value || ( this.debitBalanceForm.controls.isPayableDeclined.value && this.debitBalanceForm.controls.payableAnswerText.value != undefined && this.debitBalanceForm.controls.payableAnswerText.value != "" );
      }
    }
    this.isSendingOk = answerRec && answerPay;
  }

  saveForm(){
    this.userWfsService.saveDebitbalanceApproval(
        this.debitBalanceForm.controls.token.value,
        this.debitBalanceForm.controls.isReceivableAccepted.value,
        this.debitBalanceForm.controls.isReceivableDeclined.value,
        this.debitBalanceForm.controls.receivableAnswerText.value,
        this.debitBalanceForm.controls.isPayableAccepted.value,
        this.debitBalanceForm.controls.isPayableDeclined.value,
        this.debitBalanceForm.controls.payableAnswerText.value,
    ).subscribe((response) => {
      if (response){
        if (response.type == "OK"){
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_statement_save_successful").name, null, {panelClass: 'success-snackbar'});
          //this.router.navigate(['/login'])
          this.thankYou = true;
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_statement_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }

    });
  }

  export() {
    this.userWfsService.getDebitbalance(this.uuid).subscribe((response)=>{
      if(response){
        if(response.type == "OK"){
          var byteCharacters = atob(response.payload.content);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var file = new File([byteArray], `Report`, { type: response.payload.mime_type});
//          var fileURL = URL.createObjectURL(file);
//          window.open(fileURL);
          var link = document.createElement("a");
          var fileURL = URL.createObjectURL(file);        
          link.href = fileURL;
          link.download = response.payload.name;
          link.click();           
        }
      }
    });
  }

 
}
