import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParameterEditorComponent } from './parameter-editor/parameter-editor.component';
import { Parameter } from 'src/app/data/parameter';
import { ParameterService } from '../../services/parameter.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.less']
})
export class ParametersComponent implements OnInit {

  selectedParameter: Parameter;

  trueFalseSelectionItems = [{value: true, name: "1"}, {value: false, name: "0"}];

  parameters: Parameter[]

  translatableTexts: any[] = [
    {value: "error_message_error_getting_operating_parameters", name: ""},
    {value: "error_message_operating_parameter_save_unsuccessful", name: ""},
    {value: "common_message_operating_parameter_save_successful", name: ""},

    {value: "parameters_expiration-warning-description", name: ""},
    {value: "parameters_registry-controll-description", name: ""},
    {value: "parameters_contract-prefix-description", name: ""},
    {value: "parameters_is-bcc-email-enabled-description", name: ""},
    {value: "parameters_logger-mail-description", name: ""},
    {value: "parameters_contract-respond-not-arrive-description", name: ""},
    {value: "parameters_password-expire-description", name: ""},
    {value: "parameters_report-setup-description", name: ""},
    {value: "parameters_account-lock-out-time-description", name: ""},
    {value: "parameters_token-ttl-period-description", name: ""},
    {value: "parameters_signer-mail-address", name: ""},
    {value: "parameters_signer-password", name: ""}
    ];

  constructor(private dialogService: MatDialog, public service: ParameterService, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.getParameterData().then((response)=>{
      this.setParameterData(response);
    })
    
    this.translateService.stream(['error_message_error_getting_operating_parameters', 'error_message_operating_parameter_save_unsuccessful', 'common_message_operating_parameter_save_successful', 'parameters_expiration-warning-description', 'parameters_registry-controll-description', 'parameters_contract-prefix-description', 'parameters_is-bcc-email-enabled-description', 'parameters_logger-mail-description', 'parameters_contract-respond-not-arrive-description', 'parameters_password-expire-description', 'parameters_report-setup-description', 'parameters_account-lock-out-time-description', 'parameters_token-ttl-period-description']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_getting_operating_parameters", name: res["error_message_error_getting_operating_parameters"]},
        {value: "error_message_operating_parameter_save_unsuccessful", name: res["error_message_operating_parameter_save_unsuccessful"]},
        {value: "common_message_operating_parameter_save_successful", name: res["common_message_operating_parameter_save_successful"]},
        {value: "parameters_expiration-warning-description", name: res["parameters_expiration-warning-description"]},
        {value: "parameters_registry-controll-description", name: res["parameters_registry-controll-description"]},
        {value: "parameters_contract-prefix-description", name: res["parameters_contract-prefix-description"]},
        {value: "parameters_is-bcc-email-enabled-description", name: res["parameters_is-bcc-email-enabled-description"]},
        {value: "parameters_logger-mail-description", name: res["parameters_logger-mail-description"]},
        {value: "parameters_contract-respond-not-arrive-description", name: res["parameters_contract-respond-not-arrive-description"]},
        {value: "parameters_password-expire-description", name: res["parameters_password-expire-description"]},
        {value: "parameters_report-setup-description", name: res["parameters_report-setup-description"]},
        {value: "parameters_account-lock-out-time-description", name: res["parameters_account-lock-out-time-description"]},
        {value: "parameters_token-ttl-period-description", name: res["parameters_token-ttl-period-description"]},
        {value: "parameters_signer-mail-address", name: res["parameters_signer-mail-address"]},
        {value: "parameters_signer-password", name: res["parameters_signer-password"]}
      ];
    });
  }

  setParameterData(response){
      this.parameters = [
        {
          name:"parameters_expiration-warning",
          value: this.calculateDayFromSec(response.payload.contract_expiration_warning_period),
          description: this.translatableTexts.find(element => element.value === "parameters_expiration-warning-description").name,
          type: "number",
          origValue: response.payload.contract_expiration_warning_period,
          origName: "contract_expiration_warning_period"
        },
        {
          name:"parameters_registry-controll",
          value: this.calculateDayFromSec(response.payload.auditor_registration_warning_period),
          description: this.translatableTexts.find(element => element.value === "parameters_registry-controll-description").name,
          type: "number",
          origValue: response.payload.auditor_registration_warning_period,
          origName: "auditor_registration_warning_period"
        },
        {
          name:"parameters_contract-prefix",
          value: response.payload.contract_number_prefix,
          description: this.translatableTexts.find(element => element.value === "parameters_contract-prefix-description").name,
          type: "string",
          origValue: response.payload.contract_number_prefix,
          origName: "contract_number_prefix"
        },
        {
          name:"is_bcc_email_enabled_description",
          value: response.payload.is_bcc_email_enabled,
          description: this.translatableTexts.find(element => element.value === "parameters_is-bcc-email-enabled-description").name,
          type: "boolean",
          origValue: response.payload.is_bcc_email_enabled,
          origName: "is_bcc_email_enabled"
        },
        {
          name:"parameters_logger-mail",
          value: response.payload.bcc_email_address,
          description: this.translatableTexts.find(element => element.value === "parameters_logger-mail-description").name,
          type: "string",
          origValue: response.payload.bcc_email_address,
          origName: "bcc_email_address"
        },
        {
          name:"parameters_contract-respond-not-arrive",
          value: this.calculateDayFromSec(response.payload.contract_validation_warning_period),
          description: this.translatableTexts.find(element => element.value === "parameters_contract-respond-not-arrive-description").name,
          type: "number",
          origValue: response.payload.contract_validation_warning_period,
          origName: "contract_validation_warning_period"
        },
        {
          name:"parameters_password-expire",
          value: this.calculateDayFromSec(response.payload.password_deadline_period),
          description: this.translatableTexts.find(element => element.value === "parameters_password-expire-description").name,
          type: "number",
          origValue: response.payload.password_deadline_period,
          origName: "password_deadline_period"
        },
        {
          name:"parameters_report-setup",
          value: response.payload.is_auditor_report_required,
          description: this.translatableTexts.find(element => element.value === "parameters_report-setup-description").name,
          type: "string",
          origValue: response.payload.is_auditor_report_required,
          origName: "is_auditor_report_required"
        },
        {
          name:"parameters_account-lock-out-time",
          value: this.calculateDayFromSec(response.payload.password_ttl_period),
          description: this.translatableTexts.find(element => element.value === "parameters_account-lock-out-time-description").name,
          type: "number",
          origValue: response.payload.password_ttl_period,
          origName: "password_ttl_period"
        },
        {
          name:"token_ttl_period",
          value: this.calculateDayFromSec(response.payload.token_ttl_period),
          description: this.translatableTexts.find(element => element.value === "parameters_token-ttl-period-description").name,
          type: "number",
          origValue: response.payload.token_ttl_period,
          origName: "token_ttl_period"
        },
        {
          name:"parameters_signer-mail-address",
          value: response.payload.signer_mail_address,
          description: this.translatableTexts.find(element => element.value === "parameters_signer-mail-address").name,
          type: "string",
          origValue: response.payload.signer_mail_address,
          origName: "signer_mail_address"
        },
        {
          name:"parameters_signer-password",
          value: response.payload.signer_password,
          description: this.translatableTexts.find(element => element.value === "parameters_signer-password").name,
          type: "password",
          origValue: response.payload.signer_password,
          origName: "signer_password"
        },/*,
        {
          name:"parameters_contract-creaton-controll",
          value:60,
          description: "Leírás"
        }*/
      ]
  }

  getParameterData(){
    return this.http.get<any>(environment.serverEndpoint + '/owner/operatingparams')
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_operating_parameters").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  editParameter(parameter){
    const dialog = this.dialogService.open(ParameterEditorComponent);
    dialog.componentInstance.parameters = this.parameters;
    dialog.componentInstance.parameter = parameter;
    dialog.componentInstance.trueFalseSelectionItems = this.trueFalseSelectionItems;

    dialog.afterClosed().subscribe(closeResponse => {
      if (closeResponse){
        if (closeResponse.response.type == "OK") {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_operating_parameter_save_successful").name, null, {panelClass: 'success-snackbar'});
          this.getParameterData().then((response)=>{
            this.setParameterData(response);
            this.selectedParameter = this.parameters.find(param => param.origName == closeResponse.param.origName);
          })
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_operating_parameter_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = closeResponse.response.payload;
        }
      }
    })
  }

  calculateDayFromSec(sec){
    return (sec / 60 / 60 / 24).toFixed(0);
  }

}
