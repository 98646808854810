<div style="display: flex; justify-content: space-between; align-items: baseline;">
    <h2 mat-dialog-title>{{'common_title_help' | translate}}</h2>
    <button mat-button (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<mat-card [formGroup]="helpForm" class="login-card">
    <mat-dialog-content>
        <div class="folder-structure-content">
            <mat-form-field appearance="outline">
                <mat-label>Kérem, írja be a kérdését!</mat-label>
                <input matInput (keydown.enter)="goWatson()" formControlName="question">
                <mat-error *ngIf="helpForm.controls.question.hasError('required')">
                    Kérem, írja be kérdését!
                </mat-error>
            </mat-form-field>
            <button mat-button (click)="goWatson()">
                <mat-icon>help_outline</mat-icon>
            </button>
        </div>
        <div class="chat-content">
            <div *ngFor="let element of chats">
                <div class="table-text style_gen_{{element.type}}">
                    <span class="table-text style_{{element.type}}">{{element.text}}</span>
                </div>
            </div>
            <div class="table-text style_gen">
            </div>
        </div>
    </mat-dialog-content>
</mat-card>