import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { WfTemplateService } from '../../../services/wf-template.service';
import { CredentialsService } from '../../../services/credentials.service';
import { UserWfsService } from '../../../services/user-wfs.service';

@Component({
  selector: 'app-workflow-template-add-coworker',
  templateUrl: './workflow-template-add-coworker.component.html',
  styleUrls: ['./workflow-template-add-coworker.component.less']
})
export class WorkflowTemplateAddCoworkerComponent implements OnInit {

  addCoworkerForm: FormGroup = this.formBuilder.group({
    coworker: [null, [Validators.required]]
  });

  _coworkerSelectionItems: any;
  get coworkerSelectionItems() { return this._coworkerSelectionItems };
  set coworkerSelectionItems(value: any) {
    this._coworkerSelectionItems = value;
  }

  _workflowTemplate: any;
  get workflowTemplate() { return this._workflowTemplate };
  set workflowTemplate(value: any) {
    this._workflowTemplate = value;
  }

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<WorkflowTemplateAddCoworkerComponent>, private service: WfTemplateService, private userWfService: UserWfsService) { }

  ngOnInit(): void {
  }

  addCoworker(){
    if (this.addCoworkerForm.valid) {
      if (CredentialsService.role == 'owner'){
        let wfTemplate = JSON.parse(JSON.stringify(this.workflowTemplate));
        wfTemplate.coworker_ids = [];
        wfTemplate.coworkers.forEach((coworker)=>{
          wfTemplate.coworker_ids.push(coworker.id)
        })
        wfTemplate.coworker_ids.push(this.addCoworkerForm.get("coworker").value);
        delete wfTemplate.coworkers;
        this.service.modifyWfTemplate(wfTemplate).subscribe((response)=>{
          this.dialogRef.close(response);
        })
      }
      else if (CredentialsService.role == 'user'){
        let body = {
          id: this.workflowTemplate.id,
          coworker_id: this.addCoworkerForm.get("coworker").value
        }
        this.userWfService.postWfCoworker(body).subscribe((response) =>{
          this.dialogRef.close(response);
        })
      }
    } else {
      this.addCoworkerForm.markAllAsTouched();
    }
  }



}
