<h2 mat-dialog-title>{{'file_system_create-folder' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="folderForm">
        <div class="row">
            <div class="col-12 ">
                <mat-form-field appearance="outline">
                    <mat-label>{{'file_system_name' | translate}}</mat-label>
                    <input matInput formControlName="folderName" required>
                    <mat-error *ngIf="folderForm.controls.folderName.hasError('required')">
                        {{'error_message_name_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Szülő mappa</mat-label>
                    <mat-select formControlName="parentFolder" required>
                        <mat-option *ngFor="let folder of folderList" [value]="folder.path">{{folder.path}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="folderForm.controls.parentFolder.hasError('required')">
                        A szülő mappa nem lehet üres
                    </mat-error>
                </mat-form-field>
            </div>
        </div>-->
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button class="positiveButton" (click)="saveFolder()">{{'button_title_save_folder' | translate}}</button>
    <button mat-button class="negativeButton" (click)="dialogRef.close()">{{'button_title_cancel' | translate}}</button>
</mat-dialog-actions>