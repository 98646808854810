import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-history',
  templateUrl: './company-history.component.html',
  styleUrls: ['./company-history.component.less']
})
export class CompanyHistoryComponent implements OnInit {

  today = new Date(new Date().getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0];

  historyForm: FormGroup = this.formBuilder.group({
    history: [null, [Validators.required]],
    date: [null, [Validators.required]]
  });

  _data: any;
  get data() { return this._data };
  set data(value: any) {
    this._data = value;
  }

  _isMore: any;
  get isMore() { return this._isMore };
  set isMore(value: any) {
    this._isMore = value;
  }

  translatableTexts: any[] = [
    {value: "common_message_attention", name: ""}, 
    {value: "common_message_discard_changes_and_exit", name: ""}, 
    {value: "1", name: ""}, 
    {value: "0", name: ""}
  ];

  constructor(public dialogRef: MatDialogRef<CompanyHistoryComponent>, private dialogService: MatDialog, private formBuilder: FormBuilder, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.sortByDate(this.data.history);

    this.translateService.stream(['common_message_attention', 'common_message_discard_changes_and_exit', '1', '0']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_attention", name: res["common_message_attention"]},
        {value: "common_message_discard_changes_and_exit", name: res["common_message_discard_changes_and_exit"]},
        {value: "1", name: res["1"]},
        {value: "0", name: res["0"]}
      ];
    });
  }

  sortByDate(data){
    data.sort(function(a,b){return +a.validity - +b.validity;});
  }

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

  addHistory(){
    if (this.historyForm.valid) {
      this.data.history.push({name: this.historyForm.get("history").value, validity:this.historyForm.get("date").value})
    } 
    else {
    this.historyForm.markAllAsTouched();
    }
  }

  deleteHistory(i){
    this.data.history.splice(i, 1);
  }

  reject(){
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = this.translatableTexts.find(element => element.value === "common_message_attention").name;
    dialog.componentInstance.description =
    this.translatableTexts.find(element => element.value === "common_message_discard_changes_and_exit").name;
    dialog.componentInstance.positiveButtonText = this.translatableTexts.find(element => element.value === "1").name;
    dialog.componentInstance.negativeButtonText = this.translatableTexts.find(element => element.value === "0").name;
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.dialogRef.close();
        //TODO reload historydata
      }
    })
  }

  saveHistory(){

  }

}
