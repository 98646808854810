import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProgressService } from './progress.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
  })
export class StorageService implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_save_unsuccessful", name: ""},
    {value: "error_message_load_unsuccessful", name: ""},
    {value: "error_message_delete_unsuccessful", name: ""}
  ];

  constructor(private http: HttpClient, private progressService: ProgressService, private snackBar: MatSnackBar, private translateService: TranslateService) {
  }
  
  ngOnInit(): void {
    this.translateService.stream([
      'error_message_save_unsuccessful',
      'error_message_load_unsuccessful',
      'error_message_delete_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_save_unsuccessful", name: res["error_message_save_unsuccessful"]},
        {value: "error_message_load_unsuccessful", name: res["error_message_load_unsuccessful"]},
        {value: "error_message_delete_unsuccessful", name: res["error_message_delete_unsuccessful"]}
      ];
    });
  }

  writeJsonStorage(body){
    const task = this.progressService.addTask(this.constructor.name + '_write_json_storage');
    console.log(body);
    return this.http.put<any>(environment.serverEndpoint + '/common/tablecolumns', body).pipe(
      catchError(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
        }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  readJsonStorage(body){
    const task = this.progressService.addTask(this.constructor.name + '_read_json_storage');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify(body));
    console.log(payload);
    return this.http.get<any>(environment.serverEndpoint + '/common/tablecolumns?payload=' + payload, {headers}).pipe(
      catchError(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_load_unsuccessful").name, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
        }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  deleteJsonStorage(tablecolumn){
    const task = this.progressService.addTask(this.constructor.name + '_read_json_storage');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        name: tablecolumn
      }
    };
    return this.http.delete<any>(environment.serverEndpoint + '/common/tablecolumns', options).pipe(
      catchError(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return throwError(error);
        }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }
}