import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CredentialsService} from "../../services/credentials.service";
import {Router} from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { LostPasswordComponent } from '../password/lost-password/lost-password.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = this.formBuilder.group({
    username: [null, Validators.required],
    password: [null, Validators.required]
  });

  hide: boolean = true;
  language: string = localStorage.getItem("language");
  defaultLang: string = navigator.language.replace("-", "_");

  constructor(private formBuilder: FormBuilder, private credentialsService: CredentialsService, private router: Router, private dialogService: MatDialog, private translateService: TranslateService, public languageService: LanguageService, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    sessionStorage.clear();
  }

  login() {
    if (this.loginForm.valid) {
      this.credentialsService.login(this.loginForm.getRawValue()).subscribe((response) => {
        if (response){
          // console.log("Login resp exists");
          this.credentialsService.getWhoAmI().subscribe((data)=>{
            // console.log("getWhoAmI resp exists");
            this.credentialsService.userData = data.payload;

            if (data.payload.groups.find(group => group.caption == 'GROUP_CODE_ADMIN')){
              CredentialsService.role = 'admin';
            } else if (data.payload.groups.find(group => group.caption == 'GROUP_CODE_OWNER')) {
              CredentialsService.role = 'owner';
            } else {
              CredentialsService.role = 'user';
            }

            if (CredentialsService.isMobileDevice){
              if (CredentialsService.role === 'user') {
                this.router.navigate(['/mobile']).then();
              }else{
                this.router.navigate(['/mobileError']).then();
              }
            }else {
              if (CredentialsService.role === 'admin') {
                this.router.navigate(['/auditors']).then();
              } else if (CredentialsService.role === 'owner') {
                this.router.navigate(['/folderstructure']).then();
              } else if (CredentialsService.role === 'user') {
                this.router.navigate(['/companies']).then();
              }
            }
            localStorage.setItem("accountId", data.payload.id);
            localStorage.setItem("auditorId", data.payload.auditor_id);
            this.language = this.language ? this.language : data.payload.locale;
            localStorage.setItem("language", this.language)
            this.translateService.use(this.language);
            this.languageService.loadLanguages().subscribe((response) => {
              this.languageService.languageList = response.payload;
            });
          }, 
          (error) => {
             console.error("Error in getWhoAmI:", error);
          });
          this.credentialsService.getSysParms().subscribe((response) => {
            this.credentialsService.sysData = response.payload;
            localStorage.setItem("WOPISrc", this.credentialsService.sysData.wopiSrc);
            localStorage.setItem("wsdServer", this.credentialsService.sysData.wsdServer);
          });
        } else {
          // console.log("Login resp does not exist");
        };
      }, 
      (error) => {
          console.error("Error in login:", error);
      });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  openLostPassword(){
    const dialog = this.dialogService.open(LostPasswordComponent);
  }
}
