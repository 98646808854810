<mat-toolbar color="primary">
    <div>
        <button mat-icon-button matTooltip="{{'button_title_back' | translate}}" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'common_title_filter_table_columns' | translate}}" (click)="filterTableColumns()">
          <mat-icon>filter_alt</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'companies_new-contract-recording' | translate}}" (click)="editContract()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'companies_modification' | translate}}" [disabled]="selection.selected.length !== 1" (click)="editContract(selection.selected[0],true)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflows_open' | translate}}" [disabled]="selection.selected.length !== 1" (click)="editContract(selection.selected[0],false)">
            <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button *ngIf="selection.selected[0]?.contract_status_sid == 'CON_STAT_WAITING'" matTooltip="Küldés aláírásra" [disabled]="selection.selected.length !== 1" (click)="signContract(selection.selected[0],false)">
            <mat-icon>mail</mat-icon>
        </button>

        <button mat-icon-button matTooltip="Szerződés törlése" [disabled]="selection.selected.length !== 1" (click)="deleteContract(selection.selected[0])">
            <mat-icon>delete</mat-icon>
        </button>
        <!--<mat-form-field>
          <mat-label>Keresés</mat-label>
          <input matInput (keydown.enter)="searchContract($event)" #searchInput>
          <button mat-button *ngIf="searchInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"><mat-icon>close</mat-icon></button>
        </mat-form-field>-->
    </div>
    <div style="width:100%;display: flex;justify-content: flex-end !important;">
        <h2 mat-dialog-title>{{this.customer?this.customer.long_name:""}}</h2>
    </div>
</mat-toolbar>
<table mat-table [dataSource]="dataSource" matSort cdkDropListOrientation="horizontal"
  cdkDropList (cdkDropListDropped)="reorderColumns($event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef cdkDragLockAxis="x" cdkDrag>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-radio-button (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-radio-button>
      </td>
    </ng-container>

    <ng-container matColumnDef="contract_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_contract-record-token' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.contract_code}} </td>
    </ng-container>
    <ng-container matColumnDef="registered_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_cause' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{createDateString(row.signed_at*1000)}} </td>
    </ng-container>
    <ng-container matColumnDef="registered_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_signing-auditor' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{findSelectionItem(signatoryAuditorSelectionItems, row.signed_by)?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="registered_by_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_signing-auditor' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{findSelectionItem(signatoryAuditorSelectionItems, row.signed_by)?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_type' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{findSelectionItem(typeSelectionItems, row.contract_type_sid)?.name | translate}}</td>
    </ng-container>
    <ng-container matColumnDef="valid_from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_validity-start' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{createDateString(row.valid_from*1000)}} </td>
    </ng-container>
    <ng-container matColumnDef="valid_to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'companies_validity-expiration' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{createDateString(row.valid_to*1000)}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'workflows_status' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{findSelectionItem(statusSelectionItems, row.contract_status_sid)?.name | translate}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data to display</td>
    </tr>
  </table>

  <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="handlePage($event)" [showFirstLastButtons]="true"></mat-paginator>