import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'regNumber'
})
export class RegNumberPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    const stringValue = value.toString(10);
    if (stringValue.length === 11) {
      return stringValue.slice(0, 1) + ' ' + stringValue.slice(1, 2) + ' ' + stringValue.slice(2, 7);
    } else {
      return stringValue.toString();
    }
  }

}
