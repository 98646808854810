<mat-toolbar *ngIf="!isInDialog" color="primary">
    <div>
    </div>
    <div>
        <button mat-icon-button matTooltip="{{'folder_structure-new-folder' | translate}}" [disabled]="selectedFolderList.length !== 1 || isFolderProtected(true, selectedFolderList[0])"
        (click)="isFolderProtected(true, selectedFolderList[0]) ? '' : editFolder(selectedFolderList[0], true)">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'folder_structure-modification' | translate}}" [disabled]="selectedFolderList.length !== 1 || isFolderProtected(false, selectedFolderList[0])"
                (click)="isFolderProtected(false, selectedFolderList[0]) ? '' : editFolder(selectedFolderList[0], false)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'folder_structure-remove' | translate}}" [disabled]="selectedFolderList.length !== 1 || isFolderProtected(false, selectedFolderList[0], true)"
            (click)="isFolderProtected(false, selectedFolderList[0], true) ? '' : deleteFolder(selectedFolderList[0])">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="folder-structure-content"  style="cursor: pointer;" [style.width]="isInDialog ? '80vw' : '100%' " [style.height]="isInDialog ? '60vh' : '100%' ">
    <div class="column" style="width: 20%;overflow-y: none">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node">
                <li class="mat-tree-node" [class.active]="selectedFolder?.path == node.path" (click)="onFolderClick(node)">
  
                    <button mat-icon-button disabled matTreeNodeToggle [id]="node.path"></button>
                    <mat-icon [style.color]="'orange'">folder</mat-icon>
                    {{getFolderRelativeName(node.path) | translate}}
                </li>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li>
                    <div class="mat-tree-node" [class.active]="selectedFolder?.path == node.path" (click)="onFolderClick(node)">
                        <button mat-icon-button matTreeNodeToggle [id]="node.path"
                                [attr.aria-label]="'Toggle ' + getFolderRelativeName(node.path)">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <mat-icon [style.color]="'orange'">folder</mat-icon>
                        {{getFolderRelativeName(node.path) | translate}}
                    </div>
                    <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </div>
    <div class="column" style="width: 80%;overflow-y: none">
        <div class="folder-item">
            <div [style.width]="'10%'">
            </div>
            <div [style.width]="'20%'">
                {{'folder_structure-folder-code' | translate}}
            </div>
            <div [style.width]="'50%'">
                {{'folder_structure-name' | translate}}
            </div>
            <div [style.width]="'20%'">
                {{'folder_structure-audit-folder-code' | translate}}
            </div>
        </div>
        <div *ngFor="let folder of flattedFolderList" class="folder-item"
             (click)="selectedFolderList.length > 0 ? (selectedFolderList[0] == folder ? selectedFolderList.splice(selectedFolderList.indexOf(folder), 1) :selectedFolderList[0] = folder) : (selectedFolderList.indexOf(folder) > -1 ?
         selectedFolderList.splice(selectedFolderList.indexOf(folder), 1) : selectedFolderList.push(folder)); selectedFolder == folder ? selectedFolder = null : selectedFolder = folder" [class.activeFolder]="selectedFolder == folder">
            <div [style.width]="'10%'">
                <mat-radio-button
                                  [checked]="isFolderSelected(folder)">
                </mat-radio-button>
            </div>
            <div [style.width]="'20%'">
                {{folder.folder_code}}
            </div>
            <div [style.width]="'50%'">
                {{getFolderRelativeName(folder.path) | translate}}
            </div>
            <div [style.width]="'20%'">
                {{folder.auditor_program_folder_code | translate}}
            </div>
        </div>
    </div>

</div>
<mat-dialog-actions [style.justify-content]="'space-around'" *ngIf="isInDialog">
    <button mat-button [disabled]="selectedFolderList.length==0" class="positiveButton" (click)="assignFolder()">{{'button_title_select' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_back' | translate}}</button>
</mat-dialog-actions>
