<h2 mat-dialog-title>{{this.listType == 'baseTable' ? 'Törzslista választás' : 'workflows_opened-item-list-choice' | translate}}</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns; let indCol = index">
            <th mat-header-cell *matHeaderCellDef>
                <mat-select [disabled]="doNotChange" (selectionChange)="isitchanged(column)" [(ngModel)]="columnCode[column]">
                    <mat-option *ngFor="let v of valueSet; let ind = index" [value]="ind">
                        {{v | translate}}
                    </mat-option>
                </mat-select>
                {{headers[indCol]}}
            </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button [disabled]="doNotChange" (click)="save()" class="positiveButton">{{'button_title_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_back' | translate}}</button>
</mat-dialog-actions>