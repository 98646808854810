import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-detail-file-table',
  templateUrl: './detail-file-table.component.html',
  styleUrls: ['./detail-file-table.component.less']
})
export class DetailFileTableComponent implements OnInit {

  displayedColumns: string[] = ['companyName', 'receiptNumber', 'supplierBuyer', 'receiptDate', 'currency', 'totalAmount', 'openBalance'];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(false, []);
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<DetailFileTableComponent>) { }

  ngOnInit(): void {
    this.dataSource.data = [{companyName: "Kiss és Társa Bt.", receiptNumber: "V2", supplierBuyer: "R",currency: "HUF", totalAmount: 10000, openBalance: 1000}, 
    {companyName: "Nagy és Társa Bt.", receiptNumber: "V3", supplierBuyer: "PR",currency: "EUR", totalAmount: 250000, openBalance: 2000},
    {companyName: "Közepes és Társa Bt.", receiptNumber: "V4", supplierBuyer: "R",currency: "USD", totalAmount: 50000, openBalance: 500}]

    console.log(this.dataSource.data);
    console.log(this.displayedColumns);

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }


  save(){

  }

}
