import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowElementGroup } from 'src/app/data/workflow-element-group';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { WorkflowElementEditorComponent } from './workflow-element-editor/workflow-element-editor.component';
import {WfTemplateService} from "../../services/wf-template.service";

@Component({
  selector: 'app-workflow-element-groups',
  templateUrl: './workflow-element-groups.component.html',
  styleUrls: ['./workflow-element-groups.component.less']
})
export class WorkflowElementGroupsComponent implements OnInit {

  selectedGroup: WorkflowElementGroup;

  workflowElementGroups: WorkflowElementGroup[]

  timeCodeSelectionItems = []

  translatableTexts: any[] = [
    {value: "error_message_error_getting_workflow_element_groups", name: ""},
    {value: "common_message_workflow_element_group_save_successful", name: ""},
    {value: "error_message_workflow_element_group_save_unsuccessful", name: ""},
    {value: "common_message_workflow_element_group_delete_successful", name: ""},
    {value: "error_message_workflow_element_group_delete_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private snackBar: MatSnackBar, public http: HttpClient, private translateService: TranslateService, public wfTemplateService: WfTemplateService) { }

  ngOnInit(): void { 
    this.getGroups()
    .then((data: any)=>{this.workflowElementGroups = data.payload;})

    this.translateService.stream(['error_message_error_getting_workflow_element_groups', 'common_message_workflow_element_group_save_successful', 'error_message_workflow_element_group_save_unsuccessful', 'common_message_workflow_element_group_delete_successful', 'error_message_workflow_element_group_delete_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_error_getting_workflow_element_groups", name: res["error_message_error_getting_workflow_element_groups"]},
        {value: "common_message_workflow_element_group_save_successful", name: res["common_message_workflow_element_group_save_successful"]},
        {value: "error_message_workflow_element_group_save_unsuccessful", name: res["error_message_workflow_element_group_save_unsuccessful"]},
        {value: "common_message_workflow_element_group_delete_successful", name: res["common_message_workflow_element_group_delete_successful"]},
        {value: "error_message_workflow_element_group_delete_unsuccessful", name: res["error_message_workflow_element_group_delete_unsuccessful"]}
      ];
    });

    this.wfTemplateService.getWfeTimes().subscribe((response)=>{
      response.payload.forEach((time)=>{
        if (time.is_active){
          this.timeCodeSelectionItems.push({value: time.sid, name: time.sid})
        }
      })
    })

  }

  getGroups(){
      /*let payload = btoa(JSON.stringify({"toggle": {
    "operator": "OR",
    "filters": [
      {
          "column": "is_active",
          "value": 1
      }]
    }})
  )*/
    return this.http.get<any>(environment.serverEndpoint + '/owner/wfelementgroups')
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_groups").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  editGroup(group?: WorkflowElementGroup){
    const dialog = this.dialogService.open(WorkflowElementEditorComponent);

    let workflowGroupSelectionItems = [];
    if (this.workflowElementGroups.length > 0){
      for (let workflowElementGroup of this.workflowElementGroups){
        if ((workflowElementGroup.caption != group?.caption) && (workflowElementGroup.fix_code != group?.fix_code) && workflowElementGroup.is_active){
          if(workflowElementGroup.fix_code==null) {
            workflowGroupSelectionItems.push({
              value: workflowElementGroup.serial_number + 1,
              name: workflowElementGroup.caption
            });
          }else{
            this.translateService.get(group.fix_code).subscribe((res:string)=>{
              workflowGroupSelectionItems.push({
                value: workflowElementGroup.serial_number + 1,
                name: res
              });
            });
          }
        }
      }
    }

    dialog.componentInstance.workflowGroupSelectionItems = workflowGroupSelectionItems;
    dialog.componentInstance.timeCodeSelectionItems = this.timeCodeSelectionItems;
    if (group) {
      dialog.componentInstance.workflowGroup = group;
    }    
    dialog.afterClosed().subscribe((resp) => {
      if (resp){
        if (resp.type == "OK") {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_element_group_save_successful").name, null, {panelClass: 'success-snackbar'});
          this.getGroups()
          .then((data: any)=>{
            this.workflowElementGroups = data.payload;
            if (group){
              this.selectedGroup = this.workflowElementGroups.find(group => group.id == resp.payload.id);
            }else{
              this.selectedGroup = null; 
            }
          })
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_group_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = resp.payload;
        } 
      }
    })
  }

  deleteGroup(group: WorkflowElementGroup){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('workflow_element_groups_remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.backendDeleteFolder(group).then((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_element_group_delete_successful").name, null, {panelClass: 'success-snackbar'});
              this.getGroups()
            .then((data: any)=>{this.workflowElementGroups = data.payload.content;
              this.selectedGroup = null;})
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_group_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
        });
        
      }
    })

  }

  backendDeleteFolder(group: any){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: group.id
      },
    };
    return this.http.delete<any>(environment.serverEndpoint + '/owner/wfelementgroup', options)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_group_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
