<mat-toolbar color="primary">
    <div>
        <button mat-icon-button matTooltip="{{'document_subtype-new-doc-subtype' | translate}}" (click)="editSubtype()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'document_subtype-modification' | translate}}" [disabled]="!selectedSubtemplate || selectedSubtemplate.subtemplate_code" (click)="selectedSubtemplate.subtemplate_code ? '' : editSubtype(selectedSubtemplate)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'document_subtype-remove' | translate}}" [disabled]="!selectedSubtemplate || selectedSubtemplate.subtemplate_code" (click)="selectedSubtemplate.subtemplate_code ? '' : deleteSubtype(selectedSubtemplate)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.width]="'100%'">
                {{'document_subtype-documentum-subtype' | translate}}
            </div>
        </div>
        <div [style.display] = "'flex'">
            <div [style.width]="'100%'">
                <ng-container *ngFor="let subtemplate of subtemplateList">
                    <div  class="table-item" (click)="selectedSubtemplate == subtemplate ? selectedSubtemplate = null : selectedSubtemplate = subtemplate" [class.selected]="selectedSubtemplate == subtemplate" *ngIf="subtemplate.is_active">
                        <div class="table-text" [style.width]="'100%'">
                            {{subtemplate.caption | translate}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
