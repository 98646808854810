import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FileSystemComponent} from '../../file-system/file-system.component';
import {OkDialogComponent} from '../../ok-dialog/ok-dialog.component';
import {UserWfsService} from "../../../services/user-wfs.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SpecialFileTableComponent} from "../../file-system/special-file-table/special-file-table.component";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import {DateParsePipe} from 'src/app/pipes/date-parse.pipe';
import {BalanceFeedbackComponent} from './balance-feedback/balance-feedback.component';

@Component({
    selector: 'app-balance-statement',
    templateUrl: './balance-statement.component.html',
    styleUrls: ['./balance-statement.component.less']
})
export class BalanceStatementComponent implements OnInit {

    balanceType: string;

    balanceStatementForm: FormGroup = this.formBuilder.group({
        id: null,
        baseTable: [null/*, Validators.required*/],
        detailTable: [null/*, Validators.required*/],
        reportType: [null, Validators.required],
        isReceivableThreshold: [null, Validators.required],
        isPayableThreshold: [null, Validators.required],
        uniqueSignificant: [null, Validators.required],
        balanceSheetType: [null],
        isReceivable: [null],
        isPayable: [null],
        //  debitBalanceMilestone: [[{ value: '', disabled: true }], Validators.required],
        debitBalanceMilestone: [null],
        debitBalanceDeadline: [null],
    })

    detailFilePath: ''
    masterFilePath: ''

    _customer;
    get customer() {
        return this._customer
    };

    set customer(value: any) {
        this._customer = value;
    }

    _customer_currency_iso_code;
    get customer_currency_iso_code() {
        return this._customer_currency_iso_code
    };

    set customer_currency_iso_code(value: any) {
        this._customer_currency_iso_code = value;
    }

    _customer_id;
    get customer_id() {
        return this._customer_id
    };

    set customer_id(value: any) {
        this._customer_id = value;
    }

    _workflow_element_id;
    get workflow_element_id() {
        return this._workflow_element_id
    };

    set workflow_element_id(value: any) {
        this._workflow_element_id = value;
    }

    isDataChecked: boolean = false;
    isEmailSent: boolean = false;
    isReceivable: boolean = true;
    isPayable: boolean = false;
    isCheckButtonEnabled: boolean = false;
    touchedPartners = 0;
    wholeCustomersValue = 0;
    affectedCustomersValue = 0;
    wholeSupplierValue = 0;
    affectedSupplierValue = 0;
    debitBalanceFormStatus = "";
    processIsDisabled = true;

    deadlineComing: number;


    @ViewChild("debit_milestone") debitBalanceMilestone: ElementRef;
    @ViewChild("debit_deadline") debitBalanceDeadline: ElementRef;

    balanceStatementStat = [];
    balanceStatementParams = [];

    translatableTexts: any[] = [
        {value: "error_message_error_getting_balance_statement_parameters", name: ""},
        {value: "error_message_error_starting_balance_statement", name: ""},
        {value: "common_title_processable", name: ""},
        {value: "error_message_error_touched_partners_zero", name: ""},
        {value: "error_message_error_detail_ignored_rows_not_empty", name: ""},
        {value: "warning_message_error_master_ignored_rows_not_empty", name: ""}
    ];

    constructor(private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private dialogService: MatDialog, public dialogRef: MatDialogRef<BalanceStatementComponent>, private snackBar: MatSnackBar, private userWfsService: UserWfsService, private formBuilder: FormBuilder, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.checkParams(true);
        this.checkValue();
        //this.balanceStatementForm.controls.isReceivableThreshold.disable();
        //this.balanceStatementForm.controls.isPayableThreshold.disable();

        this.translateService.stream(['error_message_error_getting_balance_statement_parameters',
            'error_message_error_starting_balance_statement',
            'common_title_processable',
            'common_title_rows',
            'error_message_error_touched_partners_zero',
            'error_message_error_detail_ignored_rows_not_empty',
            'warning_message_error_master_ignored_rows_not_empty']).subscribe((res: string) => {
            this.translatableTexts = [
                {
                    value: "error_message_error_getting_balance_statement_parameters",
                    name: res["error_message_error_getting_balance_statement_parameters"]
                },
                {
                    value: "error_message_error_starting_balance_statement",
                    name: res["error_message_error_starting_balance_statement"]
                },
                {value: "common_title_processable", name: res["common_title_processable"]},
                {value: "common_title_rows", name: res["common_title_rows"]},
                {
                    value: "error_message_error_touched_partners_zero",
                    name: res["error_message_error_touched_partners_zero"]
                },
                {
                    value: "error_message_error_detail_ignored_rows_not_empty",
                    name: res["error_message_error_detail_ignored_rows_not_empty"]
                },
                {
                    value: "warning_message_error_master_ignored_rows_not_empty",
                    name: res["warning_message_error_master_ignored_rows_not_empty"]
                }
            ];
        });
        this.balanceStatementForm.get('balanceSheetType').valueChanges.subscribe(value => {
            this.balanceType = value;
            this.changeBase(value);
            // this.balanceStatementForm.get('uniqueSignificant').setValue(null);
            if (value == 'isPayable') {
                this.balanceStatementForm.get('isPayable').setValue(true);
                this.balanceStatementForm.get('isReceivable').setValue(false);
                this.balanceStatementForm.get('isReceivableThreshold').setValue(null);
            }
            if (value == 'isReceivable') {
                this.balanceStatementForm.get('isReceivable').setValue(true);
                this.balanceStatementForm.get('isPayable').setValue(false);
                this.balanceStatementForm.get('isPayableThreshold').setValue(null);
            }
        })
    }

    setStatistics() {
        this.userWfsService.getBalanceStatus(this.workflow_element_id).subscribe((response) => {
            if (response) {
                if (response.type == "OK") {
                    this.balanceStatementStat = response.payload;
                    // console.log(this.balanceStatementStat);
                }
            }
        });
    }

    retryReset() {
        let id = this.workflow_element_id;
        let deadline = this.balanceStatementForm.get("debitBalanceDeadline").value;
        this.userWfsService.debitBalanceReset(id, deadline).subscribe(resp => {
            if (resp) {
                this.snackBar.open('Retry counter reset successfull!', 'OK', {panelClass: 'success-snackbar'});
            }
        })
    }

    retryCancel() {
        let id = this.workflow_element_id;
        this.userWfsService.debitBalanceCancel(id).subscribe(resp => {
            if (resp) {
                this.snackBar.open('Retry cancel successfull!', 'OK', {panelClass: 'success-snackbar'});
            }
        })
    }

    openFeedback() {
        let id = this.workflow_element_id;
        let companies;
        this.userWfsService.getDebitBalanceFeedback(id).subscribe(res => {
            // console.log(res);
            companies = res.payload;
            const dialog = this.dialogService.open(BalanceFeedbackComponent);
            dialog.componentInstance.companies = companies;
            dialog.afterClosed().subscribe(value => {
                if (value == 'saved') {
                    this.snackBar.open('Visszajelzés küldése sikeres', 'OK', {panelClass: 'success-snackbar'});
                }
            })
        })

    }

    checkParams(checkOthers = false) {
        this.userWfsService.getBalanceParam(this.workflow_element_id).subscribe((response) => {
            if (response) {
                // console.log("Loading debit balance parameters...");
                // console.log(response.payload);
                this.balanceStatementParams = response.payload;
                console.log(this.balanceStatementParams);
                this.deadlineComing = response.payload.deadlineComing;
                if (response.type == "OK") {
                    if (response.payload.status == "DEBIT_STAT_STARTED" || response.payload.status == "DEBIT_STAT_FINISHED") {
                        this.isEmailSent = true;
                        this.isCheckButtonEnabled = false;
                        this.setStatistics();
                        this.balanceStatementForm.get("balanceSheetType").disable();
                        this.balanceStatementForm.get("isReceivableThreshold").disable();
                        this.balanceStatementForm.get("isPayableThreshold").disable();
                        this.balanceStatementForm.get("uniqueSignificant").disable();
                    }
                    this.debitBalanceFormStatus = response.payload.status;
                    if (response.payload.master_file_row_count) {
                        this.balanceStatementForm.controls.baseTable.setValue(this.translatableTexts.find(element => element.value === "common_title_processable").name + response.payload.master_file_row_count + this.translatableTexts.find(element => element.value === "common_title_rows").name);
                    }
                    if (response.payload.detail_file_row_count) {
                        this.balanceStatementForm.controls.detailTable.setValue(this.translatableTexts.find(element => element.value === "common_title_processable").name + response.payload.detail_file_row_count + this.translatableTexts.find(element => element.value === "common_title_rows").name);
                    }
                    if (response.payload.detail_file_path) {
                        const fileName = response.payload.detail_file_path.split('/');
                        this.detailFilePath = fileName[fileName.length - 1];
                    }
                    if (response.payload.master_file_path) {
                        const fileName = response.payload.master_file_path.split('/');
                        this.masterFilePath = fileName[fileName.length - 1];
                    }
                    if (response.payload.debit_balance_milestone) {
//           console.log("Loading milestone date:"+this.pipeFormat.transform(response.payload.debit_balance_milestone * 1000));
                        //this.balanceStatementForm.get("debitBalanceMilestone").setValue(new Date(new Date(response.payload.debit_balance_milestone * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
                        //this.balanceStatementForm.get("debitBalanceMilestone").setValue(new Date(response.payload.debit_balance_milestone * 1000).getTime());
                        this.debitBalanceMilestone.nativeElement.value = this.pipeFormat.transform(response.payload.debit_balance_milestone * 1000);
                        //new Date(new Date(response.payload.debit_balance_milestone * 1000).getTime());

                    }
                    if (response.payload.custom_deadline) {
//            console.log("Loading deadline date:"+new Date(response.payload.custom_deadline * 1000).getTime());
//             this.balanceStatementForm.get("debitBalanceDeadline").enable();
//            this.balanceStatementForm.get("debitBalanceDeadline").setValue(new Date(response.payload.custom_deadline * 1000).getTime());
                        this.debitBalanceDeadline.nativeElement.value = this.pipeFormat.transform(response.payload.custom_deadline * 1000);
                        //new Date(new Date(response.payload.custom_deadline * 1000).getTime());
                    }
                    if (checkOthers) this.setOtherParams(response);
                    this.affectedCustomersValue = response.payload.affected_receivable_sum;
                    this.affectedSupplierValue = response.payload.affected_payable_sum;
                    this.wholeCustomersValue = response.payload.total_receivable_sum;
                    this.wholeSupplierValue = response.payload.total_payable_sum;
                    this.touchedPartners = response.payload.partners_involved;
                    this.isDataChecked = !(response.payload.is_dirty || response.payload.status != "DEBIT_STAT_PENDING" || response.payload.detail_file_row_count == 0 || response.payload.master_file_row_count == 0);
                    if (this.touchedPartners === 0) {
                        this.isDataChecked = false;
                    }
                } else {
                    this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_balance_statement_parameters").name, null, {panelClass: 'error-snackbar'});
                }
            }
        })
    }

    showDetailAgain() {
        if (!this.isEmailSent) {
            this.userWfsService.getBalanceDetail(this.workflow_element_id).subscribe((response) => {
                if (response) {
                    this.userWfsService.getBalanceDetailDim(this.workflow_element_id).subscribe((dims) => {
                        if (dims) {
                            this.handleFileContent(response, dims.payload, "detailTable");
                        }
                    });
                }
            })
        } else {
            this.userWfsService.getBalanceDetailGenerated(this.workflow_element_id).subscribe((dims) => {
                if (dims) {
                    const dialog = this.dialogService.open(SpecialFileTableComponent);
                    dialog.componentInstance.listType = "detailTable";
                    dialog.componentInstance.headers = dims.payload.headers;
                    dialog.componentInstance.lines = dims.payload.lines;
                    dialog.componentInstance.dimensions = dims;
                    dialog.componentInstance.column_count = dims.payload.headers.length;
                    dialog.componentInstance.workflow_element_id = this.workflow_element_id;
                    dialog.componentInstance.doNotChange = this.isEmailSent;
                    dialog.afterClosed().subscribe(() => {
                        this.checkParams(false)
                    });
                }
            });

        }

    }

    handleFileContent(response, dims, listType) {
        let headers = [];
        let lines = [];
        if (response) {
            response.payload.headers.forEach((header) => {
                headers.push(header);
            });
            response.payload.lines.forEach((line) => {
                lines.push(line);
            });

            const dialog = this.dialogService.open(SpecialFileTableComponent);
            dialog.componentInstance.listType = listType;
            dialog.componentInstance.headers = headers;
            dialog.componentInstance.lines = lines;
            dialog.componentInstance.dimensions = dims;
            dialog.componentInstance.column_count = response.payload.column_count;
            dialog.componentInstance.workflow_element_id = this.workflow_element_id;
            dialog.componentInstance.doNotChange = this.isEmailSent;
            dialog.afterClosed().subscribe(() => {
                this.checkParams(false)
            });
        }
    }

    showMasterAgain() {
        if (!this.isEmailSent) {
            this.userWfsService.getBalanceMaster(this.workflow_element_id).subscribe((response) => {
                if (response) {
                    this.userWfsService.getBalanceMasterDim(this.workflow_element_id).subscribe((dims) => {
                        if (dims) {
                            this.handleFileContent(response, dims.payload, "baseTable");
                        }
                    });
                }
            })
        } else {
            this.userWfsService.getBalanceMasterGenerated(this.workflow_element_id).subscribe((dims) => {
                if (dims) {
                    const dialog = this.dialogService.open(SpecialFileTableComponent);
                    dialog.componentInstance.listType = "baseTable";
                    dialog.componentInstance.headers = dims.payload.headers;
                    dialog.componentInstance.lines = dims.payload.lines;
                    dialog.componentInstance.dimensions = dims;
                    dialog.componentInstance.column_count = dims.payload.headers.length;
                    dialog.componentInstance.workflow_element_id = this.workflow_element_id;
                    dialog.componentInstance.doNotChange = this.isEmailSent;
                    dialog.afterClosed().subscribe(() => {
                        this.checkParams(false)
                    });
                }
            });

        }
    }

    setOtherParams(response) {
        console.log("RSL:"+response.payload.receivable_sum_limit);
        console.log("UQS:"+response.payload.unique_significant);
        this.balanceStatementForm.controls.isPayable.setValue(response.payload.is_payable);
        this.balanceStatementForm.controls.isReceivable.setValue(response.payload.is_receivable);
        this.balanceStatementForm.controls.uniqueSignificant.setValue(response.payload.unique_significant);
        this.changeBase(null);
        if (response.payload.is_receivable) {
            this.balanceStatementForm.controls.isReceivableThreshold.setValue(response.payload.receivable_sum_limit);
            this.balanceStatementForm.controls.balanceSheetType.setValue('isReceivable');
        }
        if (response.payload.is_payable) {
            this.balanceStatementForm.controls.isPayableThreshold.setValue(response.payload.payable_sum_limit);
            this.balanceStatementForm.controls.balanceSheetType.setValue('isPayable');
        }
    }

    checkValue() {
        if (!this.isEmailSent &&
            this.balanceStatementForm.controls.baseTable.value &&
            this.balanceStatementForm.controls.detailTable.value &&
            this.balanceStatementForm.controls.balanceSheetType.value) {
            this.isCheckButtonEnabled = true;
        } else {
            this.isCheckButtonEnabled = false;
        }
    }

    changeBase(value) {
        if (this.isEmailSent) {
            //this.balanceStatementForm.controls.isReceivableThreshold.disable();
            //this.balanceStatementForm.controls.isPayableThreshold.disable();
            this.balanceStatementForm.controls.debitBalanceMilestone.disable();
        } else {
            this.balanceStatementForm.controls.uniqueSignificant.enable();
            if (this.balanceStatementForm.controls.balanceSheetType.value == 'isReceivable' || this.balanceStatementForm.controls.isReceivable.value) {
                this.balanceStatementForm.controls.isReceivableThreshold.enable();
            } else {
                this.balanceStatementForm.controls.isReceivableThreshold.disable();
                this.balanceStatementForm.controls.isReceivableThreshold.reset();
            }
            if (this.balanceStatementForm.controls.isPayable.value || this.balanceStatementForm.controls.balanceSheetType.value == 'isPayable') {
                this.balanceStatementForm.controls.isPayableThreshold.enable();
            } else {
                this.balanceStatementForm.controls.isPayableThreshold.disable();
                this.balanceStatementForm.controls.isPayableThreshold.reset();
            }
        }
        this.checkValue();
    }

    blurBalanceMilestone() {
        console.log("blurBalanceMilestone hit");
        this.balanceStatementForm.get("debitBalanceMilestone").setValue(new Date(this.pipeParse.transform(this.debitBalanceMilestone.nativeElement.value)).getTime() / 1000);
        this.balanceStatementForm.markAsDirty();
    }

    addFolder(event) {
        const dialog = this.dialogService.open(FileSystemComponent);
        dialog.componentInstance.isInDialog = true;
        dialog.componentInstance.page = 'workflows';
        dialog.componentInstance.listType = event;
        dialog.componentInstance.customer_id = this.customer_id;
        dialog.componentInstance.workflow_element_id = this.workflow_element_id;
        dialog.afterClosed().subscribe(() => this.checkParams());
    }

    saveAndCheck() {
        console.log("Saving...");
        console.log("MS:" + new Date(this.pipeParse.transform(this.debitBalanceMilestone.nativeElement.value)).getTime() / 1000);
        this.userWfsService.saveBalanceParam(
            this.balanceStatementForm.controls.isReceivable.value,
            this.balanceStatementForm.controls.isReceivableThreshold.value,
            this.balanceStatementForm.controls.isPayable.value,
            this.balanceStatementForm.controls.isPayableThreshold.value,
            this.workflow_element_id,
            new Date(this.pipeParse.transform(this.debitBalanceMilestone.nativeElement.value)).getTime() / 1000,
            new Date(this.pipeParse.transform(this.debitBalanceDeadline.nativeElement.value)).getTime() / 1000,
            this.balanceStatementForm.controls.uniqueSignificant.value
        ).subscribe((response) => {
            if (response) {
                if (response.type == "OK") {
                    const message = {
                        type: 'error',
                        token: null
                    }

                    this.isDataChecked = true;
                    this.touchedPartners = response.payload.partners_involved;
                    this.wholeCustomersValue = response.payload.total_receivable_sum;
                    this.wholeSupplierValue = response.payload.total_payable_sum;
                    this.affectedCustomersValue = response.payload.affected_receivable_sum;
                    this.affectedSupplierValue = response.payload.affected_payable_sum;
                    this.isDataChecked = true;
                    if (this.touchedPartners === 0) {
                        this.isDataChecked = false;
                        message.token = 'error_message_error_touched_partners_zero';
                        //TODO: error
                    } else if (response.payload.detail_ignored_rows.length) {
                        this.isDataChecked = false;
                        message.token = 'error_message_error_detail_ignored_rows_not_empty';
                        //TODO: error
                    } else if (response.payload.master_ignored_rows.length) {
                        message.type = 'warning';
                        message.token = 'warning_message_error_master_ignored_rows_not_empty';
                        //TODO: warning
                    }

                    if (message.token) {
                        this.snackBar.open(this.translatableTexts.find(element => element.value === message.token).name, null, {panelClass: `${message.type}-snackbar`});
                    }

                } else {
                    this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_balance_statement_parameters").name, null, {panelClass: 'error-snackbar'});
                }
            }
        })
    }

    startProcess() {
        const dialog = this.dialogService.open(OkDialogComponent);
        this.translateService.get('workflows_is-selected-mail-send-start').subscribe((value) => dialog.componentInstance.title = value)
        this.translateService.get('1').subscribe((value) => dialog.componentInstance.positiveButtonText = value)
        this.translateService.get('0').subscribe((value) => dialog.componentInstance.negativeButtonText = value)
        dialog.afterClosed().subscribe(response => {
            if (response) {
                this.userWfsService.startDebitBalanceProcess(this.workflow_element_id).subscribe(response => {
                    if (response) {
                        this.isEmailSent = true;
                        this.isCheckButtonEnabled = false;
                        this.balanceStatementForm.controls.reportType.disable();
                        this.balanceStatementForm.controls.isReceivableThreshold.disable();
                        this.balanceStatementForm.controls.isPayableThreshold.disable();
                        this.balanceStatementForm.controls.uniqueSignificant.disable();
                    } else {
                        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_starting_balance_statement").name, null, {panelClass: 'error-snackbar'});
                    }
                })
            }
        })
    }

    downloadPdf() {

    }

    getAnswers() {
        this.userWfsService.getDebitbalanceAnswers(this.workflow_element_id).subscribe((response) => {
            if (response) {
                if (response.type == "OK") {
                    var byteCharacters = atob(response.payload.content);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new File([byteArray], `Report`, {type: response.payload.mime_type});

                    var link = document.createElement("a");
                    var fileURL = URL.createObjectURL(file);
                    link.href = fileURL;
                    link.download = response.payload.name;
                    link.click();

//        var fileURL = URL.createObjectURL(file);
//        window.open(fileURL);
                }
            }
        });
    }

}
