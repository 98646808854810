<h2 mat-dialog-title>{{'common_title_balance_sheet_import_from_xlsx_file' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="balanceSheetUploadForm">
        <!--TODO fájlformátumok-->
        <span>{{'common_title_format' | translate}}: .xlsx</span>
        <app-file-upload formControlName="file" (fileTypeErrorEvent)="validateFileType($event)" [filetype]="['.sheet']" [accept]="'.xlsx'"></app-file-upload>
        <mat-error *ngIf="balanceSheetUploadForm.controls.file.hasError('incorrect')">
            {{'error_message_format_not_acceptable' | translate}}
        </mat-error>
    </form>

</mat-dialog-content>
    
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="uploadBalanceSheet()" class="positiveButton">{{'button_title_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'button_title_cancel' | translate}}</button>
</mat-dialog-actions>