import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TemplateCodes } from 'src/app/data/mail-address';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { TemplateFolderEditorComponent } from './template-folder-editor/template-folder-editor.component';
import {TemplateService} from "../../services/template.service";
import {FileSystemComponent} from "../file-system/file-system.component";
import {FolderStructureComponent} from "../folder-structure/folder-structure.component";

@Component({
  selector: 'app-template-folder',
  templateUrl: './template-folder.component.html',
  styleUrls: ['./template-folder.component.less']
})
export class TemplateFolderComponent implements OnInit {

  selectedTemplate = null;
  templates = [];

  constructor(private dialogService: MatDialog, private snackBar: MatSnackBar, private translateService: TranslateService, private templateService : TemplateService) { }

  ngOnInit(): void { 
    this.templateService.getTemplateCodes().then((response) => {
      if(response){
        this.templates = response.payload;
        this.getRelatedFolders();
      }
    })
  }

  getRelatedFolders(){
    this.templateService.getTemplateFolders().then((resp) => {
      if(resp){
        if(resp.type=="OK"){
          resp.payload.forEach(single => {
            let tmpl = this.templates.find(element => element.template_code === single.template_code);
            if(tmpl)tmpl.path = single.path;
          })
        }
      }
    })
  }

  editTemplate(selectedTemplateFolder?: TemplateCodes) {
    const dialog = this.dialogService.open(FolderStructureComponent);
    dialog.componentInstance.isInDialog = true;
    dialog.afterClosed().subscribe(response=>{
      if (response){
        console.log(this.selectedTemplate);
        let body = {"template_code": this.selectedTemplate.template_code, "path":response.path}
        this.templateService.postTemplateFolder(body).subscribe((resp) => {
          if(resp){
            if(resp.type == "OK"){
              this.getRelatedFolders();
            }
          }
        });
      }
    })
  }

}
