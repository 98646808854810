import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogDetailsComponent } from './error-dialog-details/error-dialog-details.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.less']
})
export class ErrorDialogComponent implements OnInit {

  _error;
  get error() { return this._error; };
  set error(value: any) {
    this._error = value;
  }

  _warning;
  get warning() {return this._warning;};
  set warning(value:any){
    this._warning = value;
  }

  objectKeys = Object.keys;

  title = 'Hiba!'
  errorText = '';
  buttonText = 'Bezár';
  buttonDetailsText = 'Műszaki információ';

  debitbalance = false;

  translatableTexts: any[] = [
    {value: "error_message_error", name: ""},
    {value: "error_message_close", name: ""},
    {value: "error_message_technical_informations", name: ""},
    {value: "warning_message-no-subtype-selected", name: ""}
  ];

  constructor(private dialogService: MatDialog, private translateService: TranslateService) { }

  ngOnInit(): void {
    console.log(this.error, this.warning);

    if(this._warning){
      this.title = "Figyelem!";
    }

    if(this._error.error == "ANSWERED"){
      this.debitbalance = true;
      this.title = 'Már megválaszolta / Already answered';
    }
    else if(this._error.error == "CANTFIND"){
      this.debitbalance = true;
      this.title = 'Egyenlegközlő nem található / Debit balance not found';
    }else{
      this.translateService.stream([''])

      this.translateService.stream(['error_message_error', 'error_message_close', 'error_message_technical_informations', 'warning_message-no-subtype.selected']).subscribe((res: string) => {
        this.translatableTexts = [
          {value: "error_message_error", name: res["error_message_error"]},
          {value: "error_message_close", name: res["error_message_close"]},
          {value: "error_message_technical_informations", name: res["error_message_technical_informations"]},
          {value: "warning_message-no-subtype-selected", name: res["warning_message-no-subtype-selected"]}
        ];
      });
    }
    

    //
    
  }

  detailWindowOpen(){
    const detailsDialog = this.dialogService.open(ErrorDialogDetailsComponent);
    detailsDialog.componentInstance.error = this.error.payload;
  }
}
