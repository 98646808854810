<h2 mat-dialog-title>{{'common_title_upload_balance_sheet' | translate}}</h2>
<mat-dialog-content>
    <div class="table-content">
        <div class="column">
            <div class="table-header">
                <div class="table-text" [style.width]="'5%'">
                    Ssz.
                </div>
                <div class="table-text splitTableHeader" [style.width]="'50%'" [style.flex-direction]="'column'">
                    <div class="table-text">{{'common_title_title' | translate}}</div>
                    <div style="width: 100%; display: flex; justify-content: center;">
                        <div class="table-text" [style.width]="'50%'">{{'common_title_hungarian' | translate}}</div>
                        <div class="table-text" [style.width]="'50%'">{{'common_title_english' | translate}}</div>
                    </div>
                </div>
                <div class="table-text" [style.width]="'15%'">
                    {{'common_title_amount' | translate}}
                </div>
                <div class="table-text" [style.width]="'20%'">
                    {{'common_title_file' | translate}}
                </div>
                <div class="table-text" [style.width]="'10%'">
                    #
                </div>
            </div>
            <div>
                <div *ngFor="let item of elements" class="table-item">
                    <div class="table-text" [style.width]="'5%'">
                        {{item.ssz}}
                    </div>
                    <div class="table-text" [style.width]="'25%'">
                        {{item.magyar}}
                    </div>
                    <div class="table-text" [style.width]="'25%'">
                        {{item.angol}}
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                        {{item.amount}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{item.fileName}}
                        <button mat-icon-button matTooltip="{{'common_title_file_upload' | translate}}" (click)="uploadFile(item)">
                            <mat-icon>upload</mat-icon>
                        </button>
                        <button *ngIf="item.path != null" mat-icon-button matTooltip="{{'common_title_file_delete' | translate}}" (click)="deleteFromGvItem(item.id)">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        <button mat-icon-button matTooltip="{{'common_title_edit_item' | translate}}" (click)="modifyItem(item)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="{{'common_title_delete_item' | translate}}" (click)="deleteItem(item)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="table-item">
                    <div class="table-text" [style.width]="'5%'">
                        <button mat-icon-button matTooltip="{{'common_title_new_item' | translate}}" (click)="addNewItem()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div class="table-text" [style.width]="'25%'">

                    </div>
                    <div class="table-text" [style.width]="'25%'">

                    </div>
                    <div class="table-text" [style.width]="'15%'">

                    </div>
                    <div class="table-text" [style.width]="'20%'">

                    </div>
                    <div class="table-text" [style.width]="'10%'">

                    </div>
                </div>
            </div>
        </div>
    </div>


</mat-dialog-content>
    
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="dialogRef.close(this.isDirty)" class="negativeButton">{{'workflows_close' | translate}}</button>
</mat-dialog-actions>