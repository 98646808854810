import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-uploader-js',
  templateUrl: './uploader-js.component.html',
  styleUrls: ['./uploader-js.component.less']
})
export class UploaderJsComponent implements OnInit {

  @Input() fileUploaderFormControl;

  translatableTexts: any[] = [
    {value: "error_message_file_upload_unsuccessful", name: ""}
  ];

  constructor(public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.stream(['error_message_file_upload_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_file_upload_unsuccessful", name: res["error_message_file_upload_unsuccessful"]}
      ];
    });
  }

  files = [];

  async upload(event) {
    for (let file of (Array.from(event.target.files) as any)) {
      this.files.push(file);
      let content:any = await this.toBase64String(file);
      content = content.split(",")[1];
      let postData:  any = {
        content: content,
        customer_id: 1,
        path: '/' + file.webkitRelativePath
      }
      await this.uploadFile(postData);
    };
  }

  toBase64String(file: File){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
  });
}

  async uploadFile(body: any){
    return await this.http.post<any>(environment.serverEndpoint + '/common/filestorage/file', body)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_file_upload_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
