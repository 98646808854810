<div class="container">
<div class="table-content" [formGroup]="formToSend">
    <div class="table-row-header">
        <div class="column">
            <div class="table-header">
                Cég név
            </div>
        </div>
        <div class="column">
            <div class="table-header">
                Összeg
            </div>
        </div>
        <div class="column">
            <div class="table-header">
                Válasz érkezett
            </div>
        </div>
        <div class="column">
            <div class="table-header">
                Visszajelzés
            </div>
        </div>
        <div class="column">
            <div class="table-header">
                Válasz szövege
            </div>
        </div>
    </div>
<div formArrayName="balanceFeedbackForm">
    <div class="table-row" *ngFor="let field of this.getFormArray().controls, let i = index">
        <div [formGroupName]="i" class="flex-row" >
            <div class="column">
                <div class="table-data">
                    {{field.value.company_name}}
                </div>  
            </div>
            <div class="column">
                <div class="table-data justify-right">
                    <div *ngIf="field.value.is_payable_allowed">{{field.value.payable_sum | number: '3.0-0'}} {{field.value.currency}}</div>
                    <div *ngIf="field.value.is_receivable_allowed">{{field.value.receivable_sum | number: '3.0-0'}} {{field.value.currency}}</div>
                </div>  
            </div>
            <div class="column">
                <div class="table-data">
                    <mat-checkbox [checked]="field.value.is_answered" formControlName='is_answered' (change)="clearRadio(field.value.debit_balance_item_id)"></mat-checkbox>
                </div>
            </div>
            <div class="column">
                <div class="table-data">
                    <div *ngIf="field.value.is_payable_allowed" class="checkboxes">
                        <mat-radio-group formControlName="is_payable_answer">
                            <mat-radio-button value="accept" [checked]="field.value.is_payable_accepted">Elfogadva</mat-radio-button>
                            <mat-radio-button value="reject" [checked]="field.value.is_payable_rejected">Elutasítva</mat-radio-button>
                        </mat-radio-group>
                        <!--<div>
                            <mat-label>Elfogadva </mat-label>
                            <mat-checkbox formControlName="is_payable_accepted" (change)="acceptReject(field.value.debit_balance_item_id,'pay', 'accept')"></mat-checkbox>
                        </div>
                        <div>
                            <mat-label>Elutasítva </mat-label>
                            <mat-checkbox formControlName="is_payabale_rejected" (change)="acceptReject(field.value.debit_balance_item_id,'pay', 'reject')"></mat-checkbox>
                        </div>-->
                    </div>
                    <div *ngIf="field.value.is_receivable_allowed" >
                        <mat-radio-group (change)="acceptReject(field.value.debit_balance_item_id, 'rec',$event)" formControlName="is_receivable_answer" class="checkboxes">
                            <mat-radio-button value="accept" [checked]="field.value.is_receivable_accepted">Elfogadva</mat-radio-button>
                            <mat-radio-button value="reject" [checked]="field.value.is_receivable_rejected">Elutasítva</mat-radio-button>
                        </mat-radio-group>
                        <!--<div>
                            <mat-label>Elfogadva </mat-label>
                            <mat-checkbox formControlName="is_receivable_accepted" [id]='field.value.debit_balance_item_id' (change)="acceptReject('rec', 'accept', $event)"></mat-checkbox>
                        </div>
                        <div>
                            <mat-label>Elutasítva </mat-label>
                            <mat-checkbox formControlName="is_receivable_rejected" [id]='field.value.debit_balance_item_id + 0.5' (change)="acceptReject('rec', 'reject', $event)"></mat-checkbox>
                        </div> --> 
                    </div>
                </div>  
            </div>
            <div class="column" >
                <div class="table-data">
                    <mat-form-field *ngIf="field.value.is_payable_allowed" appearance="outline">
                        <mat-label>Válasz üzenet</mat-label>
                        <input matInput formControlName="payable_answer">
                    </mat-form-field>
                    <mat-form-field *ngIf="field.value.is_receivable_allowed" appearance="outline">
                        <mat-label>Válasz üzenet</mat-label>
                        <input matInput formControlName="receivable_answer">
                    </mat-form-field>
                </div>              
            </div>
        </div>
    </div>
</div>
    <div class="buttons">
        <button (click)="saveAndSend()" mat-button class="positiveButton">Mentés</button>
        <button (click)="close()" mat-button class="negativeButton">Mégsem</button>
    </div>
</div>
</div>
