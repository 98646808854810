import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DocumentSubtype, Template, TemplateDictionary } from 'src/app/data/template';
import { environment } from 'src/environments/environment';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { DocumentSubtypeEditorComponent } from './document-subtype-editor/document-subtype-editor.component';
import { TemplateService } from '../../services/template.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {CredentialsService} from "../../services/credentials.service";

@Component({
  selector: 'app-document-subtypes',
  templateUrl: './document-subtypes.component.html',
  styleUrls: ['./document-subtypes.component.less']
})
export class DocumentSubtypesComponent implements OnInit {

  selectedSubtemplate: DocumentSubtype;
  subtemplateList: DocumentSubtype[] = []

  translatableTexts: any[] = [
    {value: "common_message_document_subtype_save_successful", name: ""},
    {value: "error_message_document_subtype_save_unsuccessful", name: ""},
    {value: "common_message_document_subtype_delete_successful", name: ""},
    {value: "error_message_document_subtype_delete_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private snackBar: MatSnackBar, public http: HttpClient, private translateService: TranslateService, private templateService: TemplateService) { }

  ngOnInit(): void {
    this.templateService.getSubtemplates(CredentialsService.role)
    .then((data)=>{
      this.subtemplateList = [];
      data.payload.content.forEach((subtemplate) => {
        if(subtemplate.is_active && !subtemplate.is_empty)this.subtemplateList.push(subtemplate);
      })
      //this.subtemplateList = data.payload.content;
    })

    this.translateService.stream(['common_message_document_subtype_save_successful', 'error_message_document_subtype_save_unsuccessful', 'common_message_document_subtype_delete_successful', 'error_message_document_subtype_delete_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_document_subtype_save_successful", name: res["common_message_document_subtype_save_successful"]},
        {value: "error_message_document_subtype_save_unsuccessful", name: res["error_message_document_subtype_save_unsuccessful"]},
        {value: "common_message_document_subtype_delete_successful", name: res["common_message_document_subtype_delete_successful"]},
        {value: "error_message_document_subtype_delete_unsuccessful", name: res["error_message_document_subtype_delete_unsuccessful"]}
      ];
    });
  }



  editSubtype(subtemplate?: DocumentSubtype){
    const dialog = this.dialogService.open(DocumentSubtypeEditorComponent);
    if (subtemplate) {
      dialog.componentInstance.subtemplate = subtemplate;
    }
    dialog.afterClosed().subscribe((response)=>{
      if (response){
        if (response.type == "OK"){
          this.templateService.getSubtemplates(CredentialsService.role)
          .then((data)=>{
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_document_subtype_save_successful").name, null, {panelClass: 'success-snackbar'});
            this.subtemplateList = [];
            data.payload.content.forEach((subtemplate) => {
              if(subtemplate.is_active && !subtemplate.is_empty)this.subtemplateList.push(subtemplate);
            })
            //this.subtemplateList = data.payload.content;
            if (subtemplate){
              this.selectedSubtemplate = this.subtemplateList.find(item => item.caption == response.payload.caption);
            } else{
              this.selectedSubtemplate = null;
            }
          });
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_document_subtype_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        } 
      }
      
    })
  }

  deleteSubtype(selectedSubtemplate){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('document_subtype-remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.backendDeleteSubtype(selectedSubtemplate)
        .then((resp)=>{
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_document_subtype_delete_successful").name, null, {panelClass: 'success-snackbar'});
              this.templateService.getSubtemplates(CredentialsService.role)
              .then((data)=>{
                this.subtemplateList = [];
                data.payload.content.forEach((subtemplate) => {
                  if(subtemplate.is_active && !subtemplate.is_empty)this.subtemplateList.push(subtemplate);
                })
                //this.subtemplateList = data.payload.content;
                this.selectedSubtemplate = null;
              });
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_document_subtype_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            } 
          }
          
        })
      }
    })

  }

  backendDeleteSubtype(selectedSubtemplate){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: selectedSubtemplate.id
      },
    };
    
    return this.http.delete<any>(environment.serverEndpoint + '/owner/subtemplate', options)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_document_subtype_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

}
