<mat-toolbar color="primary">
</mat-toolbar>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.width]="'100%'">
                {{'common_title_report_type' | translate}}
            </div>
        </div>
        <div [style.display] = "'flex'">
            <div [style.width]="'100%'">
                <ng-container *ngFor="let report of reports">
                    <div  class="table-item" (click)="selectedReport=report" [class.selected]="selectedReport?.name == report?.name">
                        <div class="table-text" [style.width]="'100%'">
                            {{ report.caption | translate}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.width]="'100%'">
                {{'common_title_filtering_conditions' | translate}}
            </div>
        </div>
        <div [style.display] = "'flex'">
            <div [style.width]="'100%'">
                <app-report-munkatars-timesheet *ngIf="selectedReport" [workflows]="this.workflows" [signerSelectionItems]="this.signerSelectionItems" [coworkerSelectionItems]="this.coworkerSelectionItems" [customerSelectionItems]="this.customerSelectionItems" [reportType]="this.selectedReport"></app-report-munkatars-timesheet>
            </div>
        </div>
    </div>
</div>