import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import {ApiEndpoint} from "../../environments/api-endpoint";
import {catchError, finalize} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {CountAnswer, PaginatedAnswer, PaginatedRequest, Response} from "../data/response";
import { Parameter } from '../data/parameter';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ParameterService extends BaseService<Parameter> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_parameters_list_unsuccessful", name: ""},
    {value: "error_message_parameter_get_unsuccessful", name: ""},
    {value: "error_message_parameter_delete_unsuccessful", name: ""},
    {value: "error_message_parameter_create_unsuccessful", name: ""},
    {value: "error_message_parameter_update_unsuccessful", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
        {
          listUrl: ApiEndpoint.auditorsEndpoint,
          getUrl: ApiEndpoint.auditorsGetEndpoint,
          createUrl: ApiEndpoint.auditorsEndpoint + "/create",
          deleteUrl: '',
          updateUrl: ApiEndpoint.auditorsEndpoint
        },
        {
          listError: 'A szerepkörök letöltése sikertelen',
          getError: 'A szerepkör letöltése sikertelen',
          deleteError: 'A szerepkör törlése sikertelen',
          createError: 'A szerepkör létrehozása sikertelen',
          updateError: 'A szerepkör módosítása sikertelen'
        },
        httpClient, snackBar, progressService);
  }

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_parameters_list_unsuccessful',
      'error_message_parameter_get_unsuccessful',
      'error_message_parameter_delete_unsuccessful',
      'error_message_parameter_create_unsuccessful',
      'error_message_parameter_update_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_parameters_list_unsuccessful", name: res["error_message_parameters_list_unsuccessful"]},
        {value: "error_message_parameter_get_unsuccessful", name: res["error_message_parameter_get_unsuccessful"]},
        {value: "error_message_parameter_delete_unsuccessful", name: res["error_message_parameter_delete_unsuccessful"]},
        {value: "error_message_parameter_create_unsuccessful", name: res["error_message_parameter_create_unsuccessful"]},
        {value: "error_message_parameter_update_unsuccessful", name: res["error_message_parameter_update_unsuccessful"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_parameters_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_parameter_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_parameter_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_parameter_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_parameter_update_unsuccessful").name
  }

}
