import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {CountryService} from "../../../services/country.service";

@Component({
  selector: 'app-country-param-editor',
  templateUrl: './country-param-editor.component.html',
  styleUrls: ['./country-param-editor.component.less']
})
export class CountryParamEditorComponent implements OnInit {
  countryParamForm: FormGroup = this.formBuilder.group({
    iso_code: null,
    currency_iso_code: null,
    name: null,
    is_auditor_contract_required: false,
    is_chamber_report_required: false,
    is_esignature_allowed: false,
    is_online_provider_available: false,
    is_auditor_registration_required: false
  });

  set params(value: any) {
    this.countryParamForm.patchValue(value);
  }

  _country: any;
  get country() { return this._country };
  set country(value: any) {
    this._country = value;
    this.countryParamForm.patchValue(value);
  }

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<CountryParamEditorComponent>,
              private countryService: CountryService) {
  }

  ngOnInit(): void {
  }

  saveCountryParams() {
    if (this.countryParamForm.valid) {
      this.countryService.postCountryParam(this.countryParamForm.getRawValue()).subscribe((response) => {
        this.dialogRef.close(response);
      });
    } else {
      this.countryParamForm.markAllAsTouched();
    }
  }
}
