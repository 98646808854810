import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploaders',
  templateUrl: './uploaders.component.html',
  styleUrls: ['./uploaders.component.less']
})
export class UploadersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
