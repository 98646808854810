<mat-card class="login-card" *ngIf="!thankYou">
    <h3 class="login-title">Fordulónapi nyitott tételek / Open balances at the end of business year</h3>
    <form [formGroup]="debitBalanceForm">
        <mat-dialog-content>
        <div *ngIf="isReceivable">
            <table mat-table [dataSource]="dataSourceRec">
                <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumnsRec; let index">
                    <th mat-header-cell *matHeaderCellDef>{{column}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="column == 'Számla végösszeg / Invoice sum' || column== 'Nyitott egyenleg / Open balance'; else otherCols" [ngClass]="column == 'Számla végösszeg / Invoice sum' || column== 'Nyitott egyenleg / Open balance' ? 'flex-end' : ''">
                            <p *ngIf="column == 'Számla végösszeg / Invoice sum' || column== 'Nyitott egyenleg / Open balance'">
                                {{element[column] | number: '3.2-2'}}
                            </p>
                        </div>  
                        <ng-template #otherCols>
                            <p>
                                {{element[column]}}
                            </p>
                        </ng-template>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsRec"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsRec;"></tr>
            </table>
        <div class="sum">
            <p>Összegzés devizánként / Summary in currencies:</p>
            <div class="flex-summaries" *ngFor="let sum of summary">
                <p>{{sum.obsum | number: '3.2-2'}}</p>
                <p>&nbsp;</p>
                <p>{{sum.currency}}</p>
            </div>
        </div>
        <mat-checkbox matInput formControlName="isReceivableAccepted" (change)="setValues()" (click)="this.debitBalanceForm.controls.isReceivableDeclined.setValue(false)">Elfogadom az egyenleget / Accept receivable balance</mat-checkbox><br/>
        <mat-checkbox matInput formControlName="isReceivableDeclined" (change)="setValues()" (click)="this.debitBalanceForm.controls.isReceivableAccepted.setValue(false)">Nem fogadom el az egyenleget / Decline receivable balance</mat-checkbox>
        <mat-form-field appearance="fill">
            <mat-label>Észrevételek a vevői egyenlegközlőhöz kapcsolódóan / Remarks according to receivable open balance</mat-label>
            <textarea  [disabled]="this.debitBalanceForm.controls.isReceivableAccepted.value == true"
            matInput (keyup)="setValues()"
                      formControlName="receivableAnswerText"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>

        </div>
        <mat-divider *ngIf="isReceivable && isPayable" style="padding-bottom: 1.6rem;"></mat-divider>

        <div *ngIf="isPayable">
            <table mat-table [dataSource]="dataSourcePay">
                <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumnsPay; let indCol = index">
                    <th mat-header-cell *matHeaderCellDef>
                        {{column}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsPay"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsPay;"></tr>
            </table>
            <div class="sum">
            <p>Összegzés devizánként / Summary in currencies:</p>
            <div class="flex-summaries" *ngFor="let sum of summary">
                <p>{{sum.obsum | number: '3.2-2'}}</p>
                <p>&nbsp;</p>
                <p>{{sum.currency}}</p>
            </div>
        </div>
        
        <mat-checkbox matInput formControlName="isPayableAccepted" (change)="setValues()" (click)="this.debitBalanceForm.controls.isPayableDeclined.setValue(false)">Elfogadom az egyenleget / Accept payable balance</mat-checkbox><br/>
        <mat-checkbox matInput formControlName="isPayableDeclined" (change)="setValues()" (click)="this.debitBalanceForm.controls.isPayableAccepted.setValue(false)">Nem fogadom el az egyenleget / Decline payable balance</mat-checkbox>
        <mat-form-field appearance="fill">
            <mat-label>Észrevételek a szállítói egyenlegközlőhöz kapcsolódóan / Remarks according to payable open balance</mat-label>
            <textarea [disabled]="this.debitBalanceForm.controls.isPayableAccepted.value == true"
                      matInput (keyup)="setValues()"
                      formControlName="payableAnswerText"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
        </div>
        </mat-dialog-content>
    </form>
    <mat-dialog-actions [style.justify-content]="'space-between'">
        <button mat-button class="neutralButton" [style.margin-right]="'15px'" (click)="export()">
            Nyitott tételek letöltése excelbe / Download as XLSX
        </button>
        <button mat-raised-button color="primary" [disabled]="!isSendingOk" class="login-button" (click)="saveForm()">
            Küldés / Send
        </button>
        
    </mat-dialog-actions>
</mat-card>
<mat-card class="login-card" *ngIf="thankYou">
    <h3 class="login-title">Open balance statement</h3>
    <form [formGroup]="debitBalanceForm">
        <mat-dialog-content>
            Köszönjük a segítségét! / We appreciate your reply!
        </mat-dialog-content>
    </form>
</mat-card>