import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.less']
})
export class HelperComponent implements OnInit {

  helpTree =  []
  helpContent;
  helpContentDefault = "<h2>Segítség az adott help node-hoz.</h2><p>Kedves Felhasználó, itt olvashatja a súgót a kiválasztott témakörhöz!</p><p>Üdvözlettel: Support</p>"

  selectedTree;
  selectedContent;

  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  translatableTexts: any[] = [
    {value: "common_title_help_content_default", name: ""},
    {value: "error_message_error_getting_helps", name: ""},
  ];

  constructor(private dialogService: MatDialog, public http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService, public dialogRef: MatDialogRef<HelperComponent>) { }

  ngOnInit(): void {
    this.getHelpTree().then((data)=>{
      // console.log(data)
      data.forEach((item) =>{
         if (item.topic == 'root'){
          item.parent_topic = '';
        }
      })
     
      this.helpTree = data;
      this.createTreeData(this.helpTree)
    })
    
    this.translateService.stream(['common_title_help_content_default', 'error_message_error_getting_helps']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_title_help_content_default", name: res["common_title_help_content_default"]},
        {value: "error_message_error_getting_helps", name: res["error_message_error_getting_helps"]}
      ];
    });

    this.helpContent = this.translatableTexts.find(element => element.value === "common_title_help_content_default").name;
  }

  getHelpTree(){
    return this.http.get<any>(environment.serverEndpoint + '/common/helptree')
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_helps").name, null, {panelClass: 'error-snackbar'});
      // console.log(error);
      return Promise.reject(error);
    })
  }

  createTreeData(list){
    let map = {};
    let node;
    let treeData = [];
  
    for ( let i = 0; i < list.length; i += 1) {
      map[list[i].topic] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    
    for (let i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent_topic) {
        // if you have dangling branches check that map[node.parent_topic] exists
        list[map[node.parent_topic]].children.push(node);
      } else {
        treeData.push(node);
      }
    }
    //console.log(treeData)
    this.dataSource.data=treeData;
  }

  hasChild = (_: number, node) => !!node.children && node.children.length > 0;

  onTreeClick(node: any) {
    if(this.selectedTree?.topic == node.topic){
      this.selectedTree = null;
      this.helpContent = this.helpContentDefault;
    }
    else{
      this.selectedTree = node;
      this.getHelpContent(this.selectedTree.topic).then((data)=>{
        this.helpContent = data;
      })
    }    
  }

  getHelpContent(topic){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify({topic: topic}));
    return this.http.get<any>(environment.serverEndpoint + '/common/helpcontent?payload=' + payload, {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_helps").name, null, {panelClass: 'error-snackbar'});
      // console.log(error);
      return Promise.reject(error);
    })
  }

}
