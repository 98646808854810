<mat-toolbar color="primary">
  <div>
  </div>
  <div>
      <button mat-icon-button matTooltip="{{'coworkers_new-worker' | translate}}" (click)="editCoworker()">
          <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button matTooltip="{{'coworkers_modification' | translate}}" [disabled]="selection.selected.length !== 1" (click)="editCoworker(selection.selected[0])">
          <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button matTooltip="{{'button_title_delete' | translate}}" [disabled]="selection.selected.length !== 1 || selection.selected[0].is_disabled" (click)="deleteCoworker(selection.selected[0])">
          <mat-icon>delete</mat-icon>
      </button>
  </div>
</mat-toolbar>

<table mat-table [dataSource]="coworkers" matSort>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-radio-button (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-radio-button>
    </td>
  </ng-container>

  <ng-container matColumnDef="last_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'coworkers_last-name' | translate}}</th>
    <td mat-cell *matCellDef="let row"> {{row.last_name}} </td>
  </ng-container>
  <ng-container matColumnDef="first_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'coworkers_first-name' | translate}}</th>
    <td mat-cell *matCellDef="let row"> {{row.first_name}} </td>
  </ng-container>
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'coworkers_username' | translate}}</th>
    <td mat-cell *matCellDef="let row"> {{row.username}} </td>
  </ng-container>
  <ng-container matColumnDef="email_address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'coworkers_mail-address' | translate}}</th>
    <td mat-cell *matCellDef="let row"> {{row.email_address}} </td>
  </ng-container>
  <ng-container matColumnDef="groups">
    <th mat-header-cell *matHeaderCellDef>
      {{'coworkers_role' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <ng-container *ngFor="let group of row.groups; last as isLast">
        {{group.caption | translate}}<ng-container *ngIf="!isLast">,</ng-container>
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="is_qc_partner">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'coworkers_qc-partner' | translate}}</th>
    <td mat-cell *matCellDef="let row"> {{findSelectionItem(qcPartnerSelectionItems, row.is_qc_partner)?.name  | translate}} </td>
  </ng-container>
  <ng-container matColumnDef="is_active">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'coworkers_status' | translate}}</th>
    <td mat-cell *matCellDef="let row"> {{findSelectionItem(statusSelectionItems, row.is_active)?.name | translate}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
  </tr>
</table>

<mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="handlePage($event)" [showFirstLastButtons]="true"></mat-paginator>
