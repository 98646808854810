<h2 mat-dialog-title>{{'profile_data-modification' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="profileForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'profile_postal-code' | translate}}</mat-label>
            <input matInput formControlName="postal_code" required>
            <mat-error *ngIf="profileForm.controls.postal_code.hasError('required')">
                {{'error_message_postal_code_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'profile_city' | translate}}</mat-label>
            <input matInput formControlName="city" required>
            <mat-error *ngIf="profileForm.controls.city.hasError('required')">
                {{'error_message_city_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'profile_postal-address' | translate}}</mat-label>
            <input matInput formControlName="postal_address" required>
            <mat-error *ngIf="profileForm.controls.postal_address.hasError('required')">
                {{'error_message_postal_address_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'profile_chamber-code' | translate}}</mat-label>
            <input matInput formControlName="chamber_code">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'profile_tax-number' | translate}}</mat-label>
            <input matInput formControlName="tax_number" required>
<!--            (input)="profileForm.get('tax_number').setValue(this.taxNumberPipe.transform(profileForm.get('tax_number').value))">-->
            <mat-error *ngIf="profileForm.controls.tax_number.hasError('required')">
                {{'error_message_tax_number_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'profile_registration-code' | translate}}</mat-label>
            <input matInput formControlName="registration_code" required>
<!--            (input)="profileForm.get('registration_code').setValue(this.regNumberPipe.transform(profileForm.get('registration_code').value))">-->
            <mat-error *ngIf="profileForm.controls.registration_code.hasError('required')">
                {{'error_message_registration_code_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'profile_email-address' | translate}}</mat-label>
            <input matInput formControlName="email_address" required>
            <mat-error *ngIf="profileForm.controls.email_address.hasError('required')">
                {{'error_message_email_address_cannot_be_empty' | translate}}
            </mat-error>
            <mat-error *ngIf="profileForm.controls.email_address.hasError('pattern')">
                {{'error_message_email_address_inappropriate_format' | translate}}
            </mat-error>
        </mat-form-field>

        <span>{{'common_title_format' | translate}}: .jpg/jpeg, .png</span>
        <app-file-upload formControlName="logo" (fileTypeErrorEvent)="validateFileType($event)" [filetype]="['jpg', 'jpeg', 'png']" [accept]="'.jpg, .jpeg, .png'" [required]="false"></app-file-upload>
        <mat-error *ngIf="profileForm.controls.logo.hasError('incorrect')">
            {{'error_message_format_not_acceptable' | translate}}
        </mat-error>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveProfile()" class="positiveButton">{{'profile_save' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'profile_reject' | translate}}</button>
</mat-dialog-actions>
