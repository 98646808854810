<mat-toolbar color="primary">
    <div>
    </div>
    <div>
        <button mat-icon-button matTooltip="{{'profile_data-modification' | translate}}" (click)="editProfile()">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="table-content">
    <div class="column">
        <div class="table-header">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                {{'profile_name' | translate}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                {{'profile_value' | translate}}
            </div>
        </div>
        <div *ngFor="let key of objectKeys(profile)" class="table-item">
            <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                {{profileData[key] | translate}}
            </div>
            <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                <ng-container *ngIf="key != 'logo'">
                    {{profile[key] ? profile[key] : ""}}
                </ng-container>
                <ng-container *ngIf="key == 'logo' && ownParams?.logo">
                    <div style="width: 100px; height: 100px;">
                        <img [src]="profile[key]" style="width: 100%;
                        height: 100%;
                        object-fit: contain;">
                    </div>
                    
                </ng-container>
            </div>
        </div>
    </div>
</div>