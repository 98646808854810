<h2 mat-dialog-title style="text-align: center;"><span style="text-transform: capitalize;">{{createDateString(data.date, { month: 'long', day: 'numeric' })}}</span> {{'timesheet_recorded-works' | translate}}</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="timesheetListDay">
        
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{'common_title_date' | translate}}</th>
          <td mat-cell *matCellDef="let row" (click)="openDetail(row)"> {{createDateString(row.working_day * 1000)}} </td>
        </ng-container>
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef >{{'timesheet_client' | translate}}</th>
          <td mat-cell *matCellDef="let row" (click)="openDetail(row)"> {{row.customer.short_name}} </td>
        </ng-container>
        <ng-container matColumnDef="workflow">
          <th mat-header-cell *matHeaderCellDef>{{'timesheet_workflow' | translate}}</th>
          <td mat-cell *matCellDef="let row" (click)="openDetail(row)"> {{row.workflow}} </td>
        </ng-container>
        <ng-container matColumnDef="workflow_elem">
          <th mat-header-cell *matHeaderCellDef>{{'timesheet_workflow-element' | translate}}</th>
          <td mat-cell *matCellDef="let row" (click)="openDetail(row)">{{row.workflow_element_caption}}</td>
        </ng-container>
        <ng-container matColumnDef="coworker">
          <th mat-header-cell *matHeaderCellDef>{{'timesheet_coworker' | translate}}</th>
          <td mat-cell *matCellDef="let row" (click)="openDetail(row)">{{createName(row.coworker)}}</td>
        </ng-container>
        <ng-container matColumnDef="working_hours">
          <th mat-header-cell *matHeaderCellDef>{{'timesheet_man-hours' | translate}}</th>
          <td mat-cell *matCellDef="let row" (click)="openDetail(row)"> {{row.working_time/3600 + (row.working_time > 1 ? " óra": " óra")}} </td>
        </ng-container>
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef>{{'timesheet_comment' | translate}}</th>
          <td mat-cell *matCellDef="let row" (click)="openDetail(row)"> {{row.comment}}</td>
        </ng-container>
        <ng-container matColumnDef="deleteIcon">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row"><mat-icon (click)="removeItem(row)">delete</mat-icon></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>
      
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
        </tr>
      </table>
</mat-dialog-content>

<mat-dialog-actions [style.justify-content]="'space-around'" style="margin-top: 1rem;">
    <button mat-button (click)="addNewTask()" class="positiveButton">{{'timesheet_work_recording' | translate}}</button>
    <button mat-button (click)="dialogRef.close()" class="negativeButton">{{'timesheet_close' | translate}}</button>
</mat-dialog-actions>
