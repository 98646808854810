<h2 mat-dialog-title [style.textAlign]="'center'">{{'companies_company-representatives' | translate}}</h2>

<mat-dialog-content>
    <form [formGroup]="representativeForm" [style.display]="'flex'" [style.justify-content]="'space-between'">
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_name' | translate}}</mat-label>
                <input matInput formControlName="name" required>
                <mat-error *ngIf="representativeForm.controls.name.hasError('required')">
                    {{'error_message_name_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_address' | translate}}</mat-label>
                <input matInput formControlName="address" required>
                <mat-error *ngIf="representativeForm.controls.address.hasError('required')">
                    {{'error_message_postal_address_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{'coworkers_tax-sign' | translate}}</mat-label>
                <input matInput formControlName="tax_number" >
<!--                <mat-error *ngIf="representativeForm.controls.birth_date.hasError('required')">-->
<!--                    {{'error_message_tax_number_cannot_be_empty' | translate}}-->
<!--                </mat-error>-->
            </mat-form-field>
        </div>
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{'common_title_birth_date' | translate}}</mat-label>
                <input matInput formControlName="birth_date" required>
                <mat-error *ngIf="representativeForm.controls.birth_date.hasError('required')">
                    {{'error_message_birth_date_cannot_be_empty' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <mat-dialog-actions [style.justify-content]="'center'">
        <button (click)="appendRepresentative()" mat-button class="positiveButton">{{'button_title_insert' | translate}}</button>
    </mat-dialog-actions>

    <div class="table-content" flex>
        <div class="column">
            <div class="table-header">
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'20%'">
                    {{'common_title_name' | translate}}
                </div>
                <div class="table-text" [style.width]="'30%'">
                    {{'common_title_address' | translate}}
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'20%'">
                    {{'tax_number' | translate}}
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'15%'">
                    {{'common_title_birth_date' | translate}}
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'15%'">

                </div>
            </div>
            <div class="make-scrollable" flex>
                <div *ngFor="let row of representatives; let i = index" class="table-item">
                    <div (click)="editRow(row)" class="table-text" [style.justify-content]="'flex-start'" [style.width]="'20%'">
                        {{row.name}}
                    </div>
                    <div (click)="editRow(row)" class="table-text" [style.justify-content]="'flex-start'" [style.width]="'30%'">
                        {{row.address}}
                    </div>
                    <div (click)="editRow(row)" class="table-text" [style.justify-content]="'flex-start'" [style.width]="'20%'">
                        {{row.tax_number ? row.tax_number : row.value}}
                    </div>
                    <div (click)="editRow(row)" class="table-text" [style.justify-content]="'flex-start'" [style.width]="'15%'">
                        {{row.birth_date}}
                    </div>
                    <div class="table-text" [style.justyify-content]="'flex-start'" [style.width]="'15%'">
                        <button mat-button (click)="deleteRepr(row)" class="negativeButton">Törlés</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveRepresentative()" class="positiveButton">{{'companies_save' | translate}}</button>
    <button mat-button (click)="reject()" class="negativeButton">{{'companies_reject' | translate}}</button>
</mat-dialog-actions>
