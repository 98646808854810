import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import { CountAnswer, PaginatedAnswer, PaginatedRequest } from '../data/response';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkflowTemplate } from 'src/app/data/workflow-template';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Contract} from "../data/company";

@Injectable({
  providedIn: 'root'
})
export class UserWfsService extends BaseService<WorkflowTemplate> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_workflows_list_unsuccessful", name: ""},
    {value: "error_message_workflow_get_unsuccessful", name: ""},
    {value: "error_message_workflow_delete_unsuccessful", name: ""},
    {value: "error_message_workflow_create_unsuccessful", name: ""},
    {value: "error_message_workflow_update_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_elements", name: ""},
    {value: "error_message_error_getting_workflow_statuses", name: ""},
    {value: "error_message_error_getting_workflow_element_statuses", name: ""},
    {value: "error_message_error_saving_coworker", name: ""},
    {value: "error_message_coworker_delete_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_element", name: ""},
    {value: "error_message_error_saving_workflow_element_group", name: ""},
    {value: "error_message_error_getting_balance_status", name: ""},
    {value: "error_message_error_displaying_debit_balance", name: ""},
    {value: "error_message_error_saving_workflow_element_log_entry", name: ""},
    {value: "error_message_workflow_element_delete_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_element_log_entries", name: ""},
    {value: "error_message_log_entry_delete_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_element_file_system", name: ""},
    {value: "error_message_error_getting_workflow_element_coworkers", name: ""},
    {value: "error_message_error_saving_workflow_element_coworkers", name: ""},
    {value: "error_message_error_deleting_coworkers", name: ""},
    {value: "error_message_error_uploading_file", name: ""},
    {value: "error_message_error_saving_debit_balance_approval", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
      {
        listUrl: '/user',
        getUrl: '/user',
        createUrl: '/user/wf',
        deleteUrl: '/user/wf',
        updateUrl: '/user/wf'
      },
      {
        listError: 'A workflowk letöltése sikertelen',
        getError: 'A workflow letöltése sikertelen',
        deleteError: 'A workflow törlése sikertelen',
        createError: 'A workflow létrehozása sikertelen',
        updateError: 'A workflow módosítása sikertelen'
      },
      httpClient, snackBar, progressService);
    }

    ngOnInit(): void {
      this.translateService.stream([
        'error_message_workflows_list_unsuccessful',
        'error_message_workflow_get_unsuccessful',
        'error_message_workflow_delete_unsuccessful',
        'error_message_workflow_create_unsuccessful',
        'error_message_workflow_update_unsuccessful',
        'error_message_error_getting_workflow_elements',
        'error_message_error_getting_workflow_statuses',
        'error_message_error_getting_workflow_element_statuses',
        'error_message_error_saving_coworker',
        'error_message_coworker_delete_unsuccessful',
        'error_message_error_getting_workflow_element',
        'error_message_error_saving_workflow_element_group',
        'error_message_error_getting_balance_status',
        'error_message_error_displaying_debit_balance',
        'error_message_error_saving_workflow_element_log_entry',
        'error_message_workflow_element_delete_unsuccessful',
        'error_message_error_getting_workflow_element_log_entries',
        'error_message_log_entry_delete_unsuccessful',
        'error_message_error_getting_workflow_element_file_system',
        'error_message_error_getting_workflow_element_coworkers',
        'error_message_error_saving_workflow_element_coworkers',
        'error_message_error_deleting_coworkers',
        'error_message_error_uploading_file',
        'error_message_error_saving_debit_balance_approval'
      ]).subscribe((res: string) => {
        this.translatableTexts = [
          {value: "error_message_workflows_list_unsuccessful", name: res["error_message_workflows_list_unsuccessful"]},
          {value: "error_message_workflow_get_unsuccessful", name: res["error_message_workflow_get_unsuccessful"]},
          {value: "error_message_workflow_delete_unsuccessful", name: res["error_message_workflow_delete_unsuccessful"]},
          {value: "error_message_workflow_create_unsuccessful", name: res["error_message_workflow_create_unsuccessful"]},
          {value: "error_message_workflow_update_unsuccessful", name: res["error_message_workflow_update_unsuccessful"]},
          {value: "error_message_error_getting_workflow_elements", name: res["error_message_error_getting_workflow_elements"]},
          {value: "error_message_error_getting_workflow_statuses", name: res["error_message_error_getting_workflow_statuses"]},
          {value: "error_message_error_getting_workflow_element_statuses", name: res["error_message_error_getting_workflow_element_statuses"]},
          {value: "error_message_error_saving_coworker", name: res["error_message_error_saving_coworker"]},
          {value: "error_message_coworker_delete_unsuccessful", name: res["error_message_coworker_delete_unsuccessful"]},
          {value: "error_message_error_getting_workflow_element", name: res["error_message_error_getting_workflow_element"]},
          {value: "error_message_error_saving_workflow_element_group", name: res["error_message_error_saving_workflow_element_group"]},
          {value: "error_message_error_getting_balance_status", name: res["error_message_error_getting_balance_status"]},
          {value: "error_message_error_displaying_debit_balance", name: res["error_message_error_displaying_debit_balance"]},
          {value: "error_message_error_saving_workflow_element_log_entry", name: res["error_message_error_saving_workflow_element_log_entry"]},
          {value: "error_message_workflow_element_delete_unsuccessful", name: res["error_message_workflow_element_delete_unsuccessful"]},
          {value: "error_message_error_getting_workflow_element_log_entries", name: res["error_message_error_getting_workflow_element_log_entries"]},
          {value: "error_message_log_entry_delete_unsuccessful", name: res["error_message_log_entry_delete_unsuccessful"]},
          {value: "error_message_error_getting_workflow_element_file_system", name: res["error_message_error_getting_workflow_element_file_system"]},
          {value: "error_message_error_getting_workflow_element_coworkers", name: res["error_message_error_getting_workflow_element_coworkers"]},
          {value: "error_message_error_saving_workflow_element_coworkers", name: res["error_message_error_saving_workflow_element_coworkers"]},
          {value: "error_message_error_deleting_coworkers", name: res["error_message_error_deleting_coworkers"]},
          {value: "error_message_error_uploading_file", name: res["error_message_error_uploading_file"]},
          {value: "error_message_error_saving_debit_balance_approval", name: res["error_message_error_saving_debit_balance_approval"]}
        ];
      });
  
      this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_workflows_list_unsuccessful").name
      this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_workflow_get_unsuccessful").name
      this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_workflow_delete_unsuccessful").name
      this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_workflow_create_unsuccessful").name
      this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_workflow_update_unsuccessful").name
    }

    startEsign(workflowId){
        const task = this.progressService.addTask(this.constructor.name + 'startEsign');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(workflowId));
        // console.log(payload);
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/auditreportsign?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
              }),
              finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getWfsTable(paginatedRequest){
      const task = this.progressService.addTask(this.constructor.name + 'get_wfstable');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      let payload = btoa(JSON.stringify(paginatedRequest));
      // console.log(payload);
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfs?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getWfsByCustomer(customer_id){
        const task = this.progressService.addTask(this.constructor.name + 'get_wfs_customer');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        var body = {"select": {"operator": "AND", "filters": [{"column": "contract.customer_id", "relation":"=", "value":customer_id}]}}
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfs?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    //ezt átalakítani payload source = timesheet;
    getWfs(source?:string){
      const task = this.progressService.addTask(this.constructor.name + 'get_wfs');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      let body = {};
      if(source){
        body = {source: source};
      }
      let payload = btoa(JSON.stringify(body));
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfs?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    deleteWorkflow(id) {
        const task = this.progressService.addTask(this.constructor.name + 'del_wfs');
        let payload = btoa(JSON.stringify({id: id}));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.delete<any>(environment.serverEndpoint + '/user/wf?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.errorMessages.deleteError, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }


    getWf(id){
      const task = this.progressService.addTask(this.constructor.name + 'get_wfs');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      let payload = btoa(JSON.stringify({id: id}));
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/wf?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.errorMessages.getError, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getWfesAll(){
      const task = this.progressService.addTask(this.constructor.name + 'get_wfes');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      let payload = btoa(JSON.stringify({}));
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfes?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_elements").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getWfes(wf_id){
      const task = this.progressService.addTask(this.constructor.name + 'get_wfes');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      let payload = btoa(JSON.stringify({workflow_id: wf_id, locale: localStorage.getItem('language')}));
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfes?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_elements").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getWfStatuses(){
      const task = this.progressService.addTask(this.constructor.name + 'get_wf_statuses');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.get<any>(environment.serverEndpoint + '/common/wfstatuses', {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_statuses").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getWfeStatuses(){
      const task = this.progressService.addTask(this.constructor.name + 'get_wfe_statuses');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.get<any>(environment.serverEndpoint + '/common/wfestatuses', {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_statuses").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getWfCoworkers(id){
      const task = this.progressService.addTask(this.constructor.name + 'get_wfes');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      let payload = btoa(JSON.stringify({id: id}));
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfcoworkers?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_elements").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    postWfCoworker(body){
      const task = this.progressService.addTask(this.constructor.name + 'add_wf_coworker');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfcoworker', body, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_coworker").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    deleteWfCoworker(body){
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body
      };
      return this.httpClient.delete<any>(environment.serverEndpoint + '/user/wfcoworker', options)
      .toPromise()
      .catch(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return Promise.reject(error);
      })
    }

    postTemplateContentCopy(id, workflow_template_id){
        let body = {
            id: id,
            workflow_template_id: workflow_template_id
        }
        const task = this.progressService.addTask(this.constructor.name + 'add_copy_template_content');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/wf_template_content_copy', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_content").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    postWorkflowContentCopy(id, workflow_id){
        let body = {
            id: id,
            workflow_id: workflow_id
        }
        const task = this.progressService.addTask(this.constructor.name + 'add_copy_workflow_content');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/wf_workflow_content_copy', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_content").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getWfElement(id){
      let body = {
        id: id
      }
      let payload = btoa(JSON.stringify(body));
      const task = this.progressService.addTask(this.constructor.name + 'get_wf_element');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfe?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }
  
    createWfElement(body){
      const task = this.progressService.addTask(this.constructor.name + 'create_wf_element');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfe', body, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element_group").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getBalanceStatus(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_status');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalancestatus?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_balance_status").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceParam(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_param');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalanceparam?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_displaying_debit_balance").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceMaster(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_master');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalancemaster?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a master tábla lekérdezésekor!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceMasterGenerated(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_master_generated');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalancemastergenerated?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a master tábla lekérdezésekor!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceDetail(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_detail');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalancedetail?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a detail tábla lekérdezésekor!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceDetailGenerated(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_detail_generated');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalancedetailgenerated?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a detail tábla lekérdezésekor!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceMasterDim(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_master_dim');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalancemasterdim?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a master tábla dimenzióinak lekérdezésekor!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceDetailDim(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_detail_dim');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalancedetaildim?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a detail tábla dimenzióinak lekérdezésekor!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    saveBalanceParam(isRec, rSum, isPay, pSum, wfe_id, milestoneDate, deadlineDate, uniqueSignificant){
        let body = {
            is_receivable: isRec,
            receivable_sum: rSum,
            is_payable: isPay,
            payable_sum: pSum,
            workflow_element_id: wfe_id,
            debitBalanceMilestone: milestoneDate,
            custom_deadline: deadlineDate,
            unique_significant: uniqueSignificant
        }
        // console.log(body);
        const task = this.progressService.addTask(this.constructor.name + 'set_balance_param');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/debitbalanceparam', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element_log_entry").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    startDebitBalanceProcess(wfe_id){
        let body = {
            workflow_element_id: wfe_id
        }
        const task = this.progressService.addTask(this.constructor.name + 'start_debit_balance');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/debitbalancestart', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element_log_entry").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getValueSet(id, fileType){
        let body = {
            workflow_element_id: id
        }
        let endpoint = "debitbalancemasterdim";
        if("baseTable" == fileType)endpoint = "debitbalancemasterdim";
        if("detailTable" == fileType)endpoint = "debitbalancedetaildim";

        const task = this.progressService.addTask(this.constructor.name + 'get_balance_valueset');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/'+endpoint+'?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_displaying_debit_balance").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    modifyWfElement(body){
      const task = this.progressService.addTask(this.constructor.name + 'create_wf_element');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.put<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfe', body, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element_group").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    deleteWfElement(id){
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: {
          id: id
        },
      };
      return this.httpClient.delete<any>(environment.serverEndpoint + '/user/wfe', options)
      .toPromise()
      .catch(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return Promise.reject(error);
      })
    }

    getWfeComments(wfe_id){
      let body = {
        workflow_element_id: wfe_id
      }
      let payload = btoa(JSON.stringify(body));
      const task = this.progressService.addTask(this.constructor.name + 'get_wf_element_comment');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.get<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfecomments?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_log_entries").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    postWfeComment(text, wfe_id){
      let body = {
        text: text,
        workflow_element_id: wfe_id
      }
      const task = this.progressService.addTask(this.constructor.name + 'get_wf_element_comment');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfecomment', body, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element_log_entry").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    deleteWfeComment(id){
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: {
          id: id
        },
      };
      return this.httpClient.delete<any>(environment.serverEndpoint + '/user/wfecomment', options)
      .toPromise()
      .catch(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_log_entry_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return Promise.reject(error);
      })
    }

    getWfeFiles(wfe_id){
      let body = {
        id: wfe_id
      }
      let payload = btoa(JSON.stringify(body));
      const task = this.progressService.addTask(this.constructor.name + 'get_wf_element_coworkers');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.get<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfefiles?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_file_system").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    getWfeFilesFlat(wfe_id){
        let body = {
            id: wfe_id
        }
        let payload = btoa(JSON.stringify(body));
        const task = this.progressService.addTask(this.constructor.name + 'get_wf_element_coworkers');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfefiles?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a workflow-elem aktarendszer lekérése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getWfeCoworkers(wfe_id){
      let body = {
        id: wfe_id
      }
      let payload = btoa(JSON.stringify(body));
      const task = this.progressService.addTask(this.constructor.name + 'get_wf_element_coworkers');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.get<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfecoworkers?payload=' + payload, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_coworkers").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    postWfeCoworkers(body){
      const task = this.progressService.addTask(this.constructor.name + 'get_wf_element_comment');
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfecoworker', body, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element_coworkers").name, null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }

    removeFile(wfe, name, path){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: 1,
                parent_path: path,
                name: name
            },
        };
        return this.httpClient.delete<any>(environment.serverEndpoint + '/user/wfefile', options)
            .toPromise()
            .catch(error => {
                this.snackBar.open("Hiba a fájl törlése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    deleteWfeCoworkers(body){
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: body
      };
      return this.httpClient.delete<any>(environment.serverEndpoint + '/user/wfecoworker', options)
      .toPromise()
      .catch(error => {
        this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_deleting_coworkers").name, null, {panelClass: 'error-snackbar'});
        console.log(error);
        return Promise.reject(error);
      })
    }

    addIndependenceFile(body) {
        const task = this.progressService.addTask(this.constructor.name + '_add_independence_file');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/wfindependence', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_uploading_file").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    saveDebitbalanceApproval(token, isReceivableAccepted, isReceivableDeclined, receivableAnswerText, isPayableAccepted, isPayableDeclined, payableAnswerText){
        let body = {
            token: token,
            is_receivable_accepted: isReceivableAccepted,
            is_receivable_declined: isReceivableDeclined,
            receivable_answer: receivableAnswerText,
            is_payable_accepted: isPayableAccepted,
            is_payable_declined: isPayableDeclined,
            payable_answer: payableAnswerText
        }
        const task = this.progressService.addTask(this.constructor.name + '_save_debitbalance_approval');
//        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/debitbalance', body).pipe(
                catchError(error => {
                 this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_debit_balance_approval").name, null, {panelClass: 'error-snackbar'});
                 console.log(error);
                 return throwError(error);
                 }),
//            this.progressService.removeTask(task.id);
                finalize(() => {
                    console.log(task.id);
                    this.progressService.removeTask(task.id);
                })
        );
    }

    getDebitbalanceApproval(token){
        let body = {
            token: token
        }
        let payload = btoa(JSON.stringify(body));
        const task = this.progressService.addTask(this.constructor.name + 'get_debitbalance_approval');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/debitbalance?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_coworkers").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceSheetSchema(gv_id, type_sid){
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_sheet_schema');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify({workflow_element_id: gv_id, gv_schema_sid: type_sid}));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/gvsummary?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a mérlegelemek lekérése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getBalanceSheetSummary(gv_id){
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_sheet_summary');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify({workflow_element_id: gv_id}));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/gvsummary?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a mérlegértékek lekérése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    putGVValue(gv_line_id, workflow_element_id, basis_amount, current_amount){
        let body = {
            gv_line_id: gv_line_id*1,
            workflow_element_id: workflow_element_id,
            basis_amount: basis_amount,
            current_amount: current_amount
        }
        // console.log(body);
        const task = this.progressService.addTask(this.constructor.name + 'put_gv_value');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.put<any>(environment.serverEndpoint + '/user/gvvalue', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a mérlegadat mentése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getGVItems(gv_id){
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_sheet_gvitems');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify({gv_value_id: gv_id}));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/gvitems?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a mérleg alátámasztó értékeinek lekérése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    postGVItem(body){
        const task = this.progressService.addTask(this.constructor.name + 'post_gv_item');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/gvitem', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a mérleg részletezőadat mentése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    putGVItem(body){
        const task = this.progressService.addTask(this.constructor.name + 'put_gv_item');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.put<any>(environment.serverEndpoint + '/user/gvitem', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a mérleg részletezőadat módosítása közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    deleteGVItem(body){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body
        };
        return this.httpClient.delete<any>(environment.serverEndpoint + '/user/gvitem', options)
            .toPromise()
            .catch(error => {
                this.snackBar.open("A GV elem törlése sikertelen!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    wfTemplateCopy(body){
        const task = this.progressService.addTask(this.constructor.name + 'copy_wf_template');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/wf_template_content_copy', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba workflow template másolása közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }


    ////////////////////////////// Audit report
    getARTypes(){
        const task = this.progressService.addTask(this.constructor.name + 'get_ar_types');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/common/auditreportreporttype', {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_auditreport_reporttypes").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getAROpinionForms(){
        const task = this.progressService.addTask(this.constructor.name + 'get_ar_opinionforms');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/common/auditreportopinionform', {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_auditreport_opinionforms").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getARModifyReason(){
        const task = this.progressService.addTask(this.constructor.name + 'get_ar_modifyreason');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/common/auditreportmodifyreason', {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_auditreport_modifyreason").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getARPlurality(){
        const task = this.progressService.addTask(this.constructor.name + 'get_ar_plurality');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/common/auditreportplurality', {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_auditreport_auditreportplurality").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getAROtherLaw(){
        const task = this.progressService.addTask(this.constructor.name + 'get_ar_otherlaw');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/common/auditreportotherlaws', {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_auditreport_otherlaw").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getAuditReportFile(locale, workflow_id){
        let body = {
            workflow_id: workflow_id,
            locale: locale
        }
        let payload = btoa(JSON.stringify(body));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/auditreportfile?payload=' + payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open("Hiba a fájl letöltése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    getGVFile(locale, workflow_element_id){
        let body = {
            locale: locale,
            workflow_element_id: workflow_element_id,
            template_code: "TEMPLATE_CODE_GV_YEARLY"
        }
        let payload = btoa(JSON.stringify(body));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/gvdownload?payload='+payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open("Hiba a fájl letöltése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    putWorkHour(wfeid, param) {
        let body = {
            workflow_element_id: wfeid,
            real_work_hours: parseInt(param)
        }
        console.log("PWH",body)
        let payload = btoa(JSON.stringify(body))
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.put<any>(environment.serverEndpoint + '/user/wfewh', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a munkaóra mentése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
        );
    }

    postAuditReport(body){
        const task = this.progressService.addTask(this.constructor.name + 'audit_report_save');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/auditreport', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a jelentés paramétereinek mentése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getAuditReport(workflowId:number){
        const task = this.progressService.addTask(this.constructor.name + 'audit_report_load');
        let body = {
            workflow_id: workflowId
        }
        let payload = btoa(JSON.stringify(body));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/auditreport?payload='+payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_auditreport_otherlaw").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getDebitbalanceAnswers(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_answers');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalanceanswers?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba az egyenlegközlő válaszok lekérdezése során!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getDebitBalanceFeedback(id){
        let body = {
            workflow_element_id: id
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance_feedback');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/debitbalanceanswermod?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba az egyenlegközlő visszajelzések lekérdezése során!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    postDebitBalandeFeedback(value){
        let body = {
            payload: value
        }
        const task = this.progressService.addTask(this.constructor.name + 'post_balance_feedback');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        // console.log(body);
        let data = this.b64EncodeUnicode(JSON.stringify(body));
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/debitbalanceanswermod?payload=' + data, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba az egyenlegközlő válaszok mentése során!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getDebitbalance(uuid:string){
        let body = {
            uuid
        }
        const task = this.progressService.addTask(this.constructor.name + 'get_balance');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.get<any>(environment.serverEndpoint + '/debitbalancedl?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a nyitott tételek lekérdezése során!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    debitBalanceReset(id:string, deadline:string){
        let body = {
            workflow_element_id: id,
            custom_deadline: deadline
        };
        const task = this.progressService.addTask(this.constructor.name + 'balance_retry');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.put<any>(environment.serverEndpoint + '/user/debitbalancereset?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a számláló beállítása során", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    debitBalanceCancel(id:string){
        let body = {
            workflow_element_id: id
        };
        const task = this.progressService.addTask(this.constructor.name + 'balance_retry');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        let payload = btoa(JSON.stringify(body));
        return this.httpClient.put<any>(environment.serverEndpoint + '/user/debitbalancecancel?payload=' + payload, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba a számláló leállítása során", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        }))
        }
}
