import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-company-representatives',
  templateUrl: './company-representatives.component.html',
  styleUrls: ['./company-representatives.component.less']
})
export class CompanyRepresentativesComponent implements OnInit {

  representativeForm: FormGroup = this.formBuilder.group({
    name: [null, [Validators.required]],
    tax_number: [null, []],
    address: [null, [Validators.required]],
    mother_name: [null, []],
    birth_date: [null, [Validators.required]]
  });

  representative_single = {birth_date:"",name:"",address:"",tax_number:"",mother_name:""}

  _representatives: any;
  get representatives() { return this._representatives };
  set representatives(value: any) {
    this._representatives = value;
  }

  _saving: boolean;
  get saving() { return this._saving };
  set saving(value: boolean) {
    this._saving = value;
  }

  constructor(public dialogRef: MatDialogRef<CompanyRepresentativesComponent>, private formBuilder: FormBuilder, private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    // console.log(this._representatives);
  }

  saveRepresentative(){
    this.saving = true;
    this.dialogRef.close();
  }

  reject(){
    this.saving = false;
    this.dialogRef.close();
  }

  appendRepresentative(){
    let acc = Object.create(this.representative_single);
    acc.tax_number = this.representativeForm.controls.tax_number.value;
    acc.address = this.representativeForm.controls.address.value;
    acc.name = this.representativeForm.controls.name.value;
    acc.birth_date = this.representativeForm.controls.birth_date.value;
    acc.mother_name = this.representativeForm.controls.mother_name.value;
    if(this.representativeForm.valid){
      // console.log(this.representatives);
      let repr = this.representatives.find(rep => (rep.tax_number ? rep.tax_number : rep.value) == acc.tax_number);
      // console.log(repr);
      if(repr){
        let index = this.representatives.findIndex(rep => (rep.tax_number ? rep.tax_number : rep.value) == acc.tax_number);
        // console.log(index);
        if(index > -1){
          this.representatives[index] = acc;
          // console.log(this.representatives);
          this.changeDetection.detectChanges();
          return;
        }
      }
      this.representatives.push(acc);
      this.changeDetection.detectChanges();
    }else{
      // console.log("invalid");
      // console.log(this.representativeForm.value);
    }
  }

  deleteRepr(rep){
    let index = this.representatives.findIndex(item => item.tax_number == rep.tax_number);
    if(index > -1){
      this.representatives.splice(index, 1);
    }
    this.changeDetection.detectChanges();
  }

  editRow(rep){
    this.representativeForm.get("name").setValue(rep.name);
    this.representativeForm.get("tax_number").setValue(rep.tax_number);
    this.representativeForm.get("address").setValue(rep.address);
    this.representativeForm.get("birth_date").setValue(rep.birth_date);
  }

}
