import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { File, Folder } from 'src/app/data/file-system';
import { environment } from 'src/environments/environment';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { FileUploaderEditorComponent } from './file-uploader-editor/file-uploader-editor.component';
import { FolderCreateEditorComponent } from './folder-create-editor/folder-create-editor.component';
import { BaseFileTableComponent } from './base-file-table/base-file-table.component';
import { DetailFileTableComponent } from './detail-file-table/detail-file-table.component';
import { TranslateService } from '@ngx-translate/core';
import { LabelEditorComponent } from './label-editor/label-editor.component';
import { FormControl } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { FileSystemService } from '../../services/file-system.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {SpecialFileTableComponent} from "./special-file-table/special-file-table.component";

@Component({
  selector: 'app-file-system',
  templateUrl: './file-system.component.html',
  styleUrls: ['./file-system.component.less']
})
export class FileSystemComponent implements OnInit {

  _isInDialog: boolean = false;
  get isInDialog() { return this._isInDialog };
  set isInDialog(value: any) {
    this._isInDialog = value;
  }

  _page: boolean = false;
  get page() { return this._page };
  set page(value: any) {
    this._page = value;
  }

  _listType: boolean = false;
  get listType() { return this._listType };
  set listType(value: any) {
    this._listType = value;
  }

  _customer_id = 0
  get customer_id() { return this._customer_id };
  set customer_id(value: any) {
    this._customer_id = value;
    let payload = btoa(JSON.stringify({
      customer_id: this.customer_id,
      parent_path: '/',
      //is_recursive: true
    }));
    this.getTreeData(payload)
  }

  _customer = new FormControl(null)
  get customer() { return this._customer };
  set customer(value: any) {
    this._customer.setValue(value);
    let payload = btoa(JSON.stringify({
      customer_id: this.customer.value,
      parent_path: '/',
      //is_recursive: true
    }));
    this.getTreeData(payload)
  }

  _workflow_element_id;
  get workflow_element_id() { return this._workflow_element_id };
  set workflow_element_id(value: any) {
    this._workflow_element_id = value;
  }

  _gv_item_id;
  get gv_item_id() { return this._gv_item_id };
  set gv_item_id(value: any) {
    this._gv_item_id = value;
  }

  companySelectionItems = [];

  //customer = new FormControl(null)

  treeControl = new NestedTreeControl<Folder>(node => node.childs);
  dataSource = new MatTreeNestedDataSource<Folder>();
  selectedFolderList: Folder[] = [];
  selectedFolderFilesList: File[] = [];

  allData: any;
  folders = [];
  files = []

  selectedFolderFiles = [];
  selectedFolder: any = null;
  selectedFile: any = null;
  get flattedFolderList(): Folder[] {
    return this.flatten(this.dataSource.data, true);
  }

  translatableTexts: any[] = [
    {value: "common_message_folder_create_successful", name: ""},
    {value: "error_message_folder_create_unsuccessful", name: ""},
    {value: "common_message_file_upload_successful", name: ""},
    {value: "error_message_file_upload_unsuccessful", name: ""},
    {value: "common_message_folder_delete_successful", name: ""},
    {value: "error_message_folder_delete_unsuccessful", name: ""},
    {value: "common_message_file_delete_successful", name: ""},
    {value: "error_message_file_delete_unsuccessful", name: ""},
    {value: "error_message_unable_to_deep_copy", name: ""}
    ];

  constructor(private dialogService: MatDialog, public http: HttpClient, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<FileSystemComponent>, private translateService: TranslateService, private companyService: CompanyService, private service: FileSystemService) {
  }

  async getTreeData(payload) {
    await this.service.getFolders(payload)
    .then((data: any)=>{
    
      this.allData = this._deepCopyObj(data.payload);
      this.folders = [];
      this.recursiveCreateFolderFromArray(data.payload);
//      console.log(this.folders);
      this.dataSource.data = this._deepCopyObj(data.payload);
    })
  }

  recursiveCreateFolderFromArray(items: any) {
    items.forEach((item: any, index: any)=> {
      if(item.is_directory) {
        this.folders.push({path: item.path});
      }
      if(item.childs.length > 0){
        this.recursiveCreateFolderFromArray(item.childs);
      }
    });
  }

  openFolders(){

  }

  isFolderProtected(isNewFolder, folderNode, isDelete: boolean = false){
    if (!isNewFolder){
      if (isDelete){
        if (folderNode?.folder_code || folderNode?.childs.length > 0){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        if (folderNode?.folder_code) {
          return true;
        }
        else{
          return false;
        }
      }

    }
    else{
      if (folderNode?.folder_code && folderNode?.childs.length > 0 && folderNode?.childs.every(child => child.folder_code)) {
        return true;
      }
      else{
        return false;
      }
    }
  }

  onFolderClick(item: any) {
    this.selectedFolder = null;
    this.selectedFile = null;
    this.selectedFolderFiles = [];
    this.recursiveGetFolderFromArray(this.allData, item.path);
    if(this.selectedFolder && this.selectedFolder.childs.length > 0){
      this.selectedFolderFiles = this.getFilesByFolder(this.selectedFolder.childs);
      // console.log(this.selectedFolderFiles);
    }
  }

  recursiveGetFolderFromArray(items: any, path: any) {
    items.forEach((item: any, index: any)=> {
      if(item.path == path) {
        this.selectedFolder = item;
        return;
      }
      if(item.childs.length > 0){
        this.recursiveGetFolderFromArray(item.childs, path);
      }
    })
  }

  getFilesByFolder(items: any):any {
    let result: any = [];
    items.forEach((item: any)=> {
      if(item.is_file) {
        result.push(item);
      }
    })
    return result;
  }

  ngOnInit() {
    let companies = [];
    this.companyService.getCompanies().subscribe((response)=>{
      response.payload.content.forEach((company)=>{
        if (company.is_active){
          companies.push({name: company.short_name, value: company.id});
          companies.sort((a, b) => a.name.localeCompare(b.name));
        }
      })
      this.companySelectionItems = companies;
    })

    this.customer.valueChanges.subscribe((value)=>{
      // console.log("2");
      let payload = btoa(JSON.stringify({
        customer_id: this.customer_id ? this.customer_id : this.customer.value,
        parent_path: '/',
        //is_recursive: true
      }));
      this.getTreeData(payload)
    });

    this.translateService.stream(['common_message_folder_create_successful', 'error_message_folder_create_unsuccessful', 'common_message_file_upload_successful', 'error_message_file_upload_unsuccessful', 'common_message_folder_delete_successful', 'error_message_folder_delete_unsuccessful', 'common_message_file_delete_successful', 'error_message_file_delete_unsuccessful', 'error_message_unable_to_deep_copy']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_folder_create_successful", name: res["common_message_folder_create_successful"]},
        {value: "error_message_folder_create_unsuccessful", name: res["error_message_folder_create_unsuccessful"]},
        {value: "common_message_file_upload_successful", name: res["common_message_file_upload_successful"]},
        {value: "error_message_file_upload_unsuccessful", name: res["error_message_file_upload_unsuccessful"]},
        {value: "common_message_folder_delete_successful", name: res["common_message_folder_delete_successful"]},
        {value: "error_message_folder_delete_unsuccessful", name: res["error_message_folder_delete_unsuccessful"]},
        {value: "common_message_file_delete_successful", name: res["common_message_file_delete_successful"]},
        {value: "error_message_file_delete_unsuccessful", name: res["error_message_file_delete_unsuccessful"]},
        {value: "error_message_unable_to_deep_copy", name: res["error_message_unable_to_deep_copy"]}
      ];
    });

  }

  getFolderRelativeName(path){
    let splitPath = path.split("/");
    return splitPath[splitPath.length - 1]
  }

  hasChild = (_: number, node: Folder) => !!node.childs && node.childs.length > 0 && !this.checkAllFiles(node.childs);
  hasFiles = (_: number, node: Folder) => this.checkIfFolderHasFiles(node.childs);

  isFolderWithoutChildren = (_: number, node: any) => node.is_directory && !!node.childs  && node.childs.length == 0 ;

  checkAllFiles(items: any) {
    let fileItems = items.filter((item: any) => item.is_file);
    return fileItems.length == items.length;
  }

  checkIfFolderHasFiles(items: any){
    return items.filter((item: any) => item.is_file).length > 0;
  }

  private flatten(items: Folder[], showOnlyVisible: boolean = false) {
    const flat = [];

    items.forEach(item => {
      flat.push(item);
      if ((!showOnlyVisible || this.treeControl.isExpanded(item)) && item.childs && item.childs.length > 0) {
        flat.push(...this.flatten(item.childs));
      }
    });

    return flat;
  }

  isFolderSelected(folder: Folder) {
    return this.selectedFolderList.indexOf(folder) > -1;
  }

  isFileSelected(file: File) {
    return this.selectedFolderFilesList.indexOf(file) > -1;
  }

  createFolder(folder) {
    const dialog = this.dialogService.open(FolderCreateEditorComponent);
    dialog.componentInstance.folderNode = folder;
    dialog.componentInstance.customer = this.customer.value;
    dialog.afterClosed().subscribe(closeResponse => {
      if (closeResponse){
        if (closeResponse.response.type == "OK") {
          // console.log("3");
          let payload = btoa(JSON.stringify({
            customer_id: this.customer_id ? this.customer_id : this.customer.value,
            parent_path: '/',
            //is_recursive: true
          }));
          this.getTreeData(payload).then((response: any) => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_folder_create_successful").name, null, {panelClass: 'success-snackbar'});
            this.openUntilFirstOpened(closeResponse);
          });
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_folder_create_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = closeResponse.response.payload;
        }
      }
      
    })
  }

  getCreatedDate(created_at){
    var a = new Date(created_at * 1000);
    // console.log(a);
    var year = a.getFullYear();
    var month = (a.getMonth()<=9?"0":"") + (a.getMonth()+1);
    // console.log(a.getMonth());
    // console.log(month);
    var date = (a.getDate()<=9?"0":"") + a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = year + '.' + month + '.' + date + ' ' + hour + ':' + min + ':' + sec ;
    // console.log(time);
    return time;
  }

  createFile(folderNode?: Folder) {
    const dialog = this.dialogService.open(FileUploaderEditorComponent);
    let folderList = this.flatten(this.folders);
    dialog.componentInstance.folderNode = folderNode;
    dialog.componentInstance.customer = this.customer.value;
    dialog.componentInstance.label = true;
    dialog.afterClosed().subscribe(closeResponse => {
      if (closeResponse){
        if (closeResponse.response.type == "OK") {
          // console.log("1");
          let payload = btoa(JSON.stringify({
            customer_id: this.customer_id ? this.customer_id : this.customer.value,
            parent_path: '/',
            //is_recursive: true
          }));
          this.getTreeData(payload).then((response: any) => {
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_file_upload_successful").name, null, {panelClass: 'success-snackbar'});
            this.openUntilFirstOpened(closeResponse);
          });
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_file_upload_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = closeResponse.response.payload;
        }
      }
      
    })
  }

  /*getAuditorFolderName(auditorFolderId: number) {
    return AUDITOR_FOLDER_LIST.find(folder => folder.id === auditorFolderId).name;
  }*/

  deleteFolder(selectedFolder) {
    // console.log(selectedFolder)
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('file_system_folder-remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)

    let parentPath = selectedFolder.path.split("/");
      parentPath.pop();
      parentPath = parentPath.join("/");

    let body = {
      customer_id: this.customer_id ? this.customer_id : this.customer.value,
      parent_path: parentPath,
      name: selectedFolder.path.split("/")[selectedFolder.path.split("/").length-1]
    }

    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.service.deleteFolder(body)
        .then((resp:any) => {
          if (resp){
            if(resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_folder_delete_successful").name, null, {panelClass: 'success-snackbar'});
              // console.log("4");
              let payload = btoa(JSON.stringify({
                customer_id: this.customer_id ? this.customer_id : this.customer.value,
                parent_path: '/',
                //is_recursive: true
              }));
              this.getTreeData(payload).then((response: any) =>{

              let splitPath = this.selectedFolder.path.split('/');
              splitPath.pop();
              splitPath = splitPath.join('/');

                this.openUntilFirstOpened({path: splitPath});
              });
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_folder_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
        });
      }
    })
  }

  deleteFile(selectedFile, selectedFolder) {
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('file_system_file-remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.service.deleteFile(this.selectedFile, this.customer_id ? this.customer_id : this.customer.value)
        .then((resp:any) => {
          if (resp){
            if(resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_file_delete_successful").name, null, {panelClass: 'success-snackbar'});
              // console.log("5");
              let payload = btoa(JSON.stringify({
                customer_id: this.customer_id ? this.customer_id : this.customer.value,
                parent_path: '/',
                //is_recursive: true
              }));
              this.getTreeData(payload).then((response: any) => {
                this.openUntilFirstOpened(this.selectedFolder);
              });
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_file_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }
        });
      }
    })
  }

  addLabel(file, folderNode){
    const dialog = this.dialogService.open(LabelEditorComponent);
    dialog.componentInstance.file = file;
    dialog.componentInstance.folderNode = folderNode;
    dialog.componentInstance.customer = this.customer.value;
  }

  handleFileContent(response){
    let headers = [];
    let lines = [];
    if(response){
      response.payload.headers.forEach((header) =>{
        headers.push(header);
      });
      response.payload.lines.forEach((line) =>{
        lines.push(line);
      });

      const dialog = this.dialogService.open(SpecialFileTableComponent);
      dialog.componentInstance.listType = this.listType;
      dialog.componentInstance.headers = headers;
      dialog.componentInstance.lines = lines;
      dialog.componentInstance.column_count = response.payload.column_count;
      dialog.componentInstance.workflow_element_id = this.workflow_element_id;
      dialog.afterClosed().subscribe(() => this.dialogRef.close());
    }
  }

    assignFile(file, folderNode){
        let payload = {parent_path : folderNode.path, name : file.path.split("/")[file.path.split("/").length-1], id : this.workflow_element_id};
        this.service.assignFileToWFE(payload).subscribe((response)=>{
            if(response){
                if(response.type == "OK")this.dialogRef.close();
            }
        });
    }

  assignToGvItem(file, folderNode){
    let payload = {parent_path : folderNode.path, name : file.path.split("/")[file.path.split("/").length-1], id : this.gv_item_id};
    this.service.assignFileToGVItem(payload).subscribe((response)=>{
      if(response){
        if(response.type == "OK")this.dialogRef.close();
      }
    });
  }

  openAdditionalTable(file, folderNode){
//    console.log(file);
    let payload = {parent_path : folderNode.path, name : file.path.split("/")[file.path.split("/").length-1], workflow_element_id : this.workflow_element_id};
    if ('baseTable' == this.listType) {
      this.service.setMasterFile(payload).subscribe((response)=>this.handleFileContent(response));
    }
    if ('detailTable' == this.listType) {
//      const dialog = this.dialogService.open(DetailFileTableComponent);
      this.service.setDetailFile(payload).subscribe((response)=>this.handleFileContent(response));
    }

    /*
    if (this.listType=='baseTable'){
      const dialog = this.dialogService.open(BaseFileTableComponent);
    }
    if (this.listType=='detailTable'){
      const dialog = this.dialogService.open(DetailFileTableComponent);
    }
    */
  }

  // openPostWindow(url: string, data: Record<string, string>) {
  //   // Open a new window with the desired features
  //   const newWindow = window.open('', '_blank', 'toolbar=no,location=no,menubar=no');
  //
  //   if (newWindow) {
  //     // Create a form element
  //     const form = newWindow.document.createElement('form');
  //     form.method = 'POST';
  //     form.action = url;
  //
  //     // Append data as hidden input fields
  //     for (const key in data) {
  //       if (data.hasOwnProperty(key)) {
  //         const input = newWindow.document.createElement('input');
  //         input.type = 'hidden';
  //         input.name = key;
  //         input.value = data[key];
  //         form.appendChild(input);
  //       }
  //     }
  //
  //     // Append the form to the new window's body and submit it
  //     newWindow.document.body.appendChild(form);
  //     form.submit();
  //   } else {
  //     console.error('Failed to open the new window.');
  //   }
  // }

  openPostWindow(url: string, data: Record<string, string>) {
    const features = 'toolbar=no,location=no,menubar=no,width=1000,height=700';
    const newWindow = window.open('', '_blank', features);

    if (newWindow) {
      // Create a new HTML document in the new window
      newWindow.document.write(`
            <html>
            <head>
                <title>AIAudit Doc Edit</title>
            </head>
            <body>
                <form id="postForm" method="POST" action="${url}" target="_self">
                </form>
                <script>
                    (function() {
                        var form = document.getElementById('postForm');
                        var data = ${JSON.stringify(data)};
                        for (var key in data) {
                            if (data.hasOwnProperty(key)) {
                                var input = document.createElement('input');
                                input.type = 'hidden';
                                input.name = key;
                                input.value = data[key];
                                form.appendChild(input);
                            }
                        }
                        form.submit();
                    })();
                </script>
            </body>
            </html>
        `);

      newWindow.document.close(); // Close the document stream
    } else {
      console.error('Failed to open the new window.');
    }
  }


  editFile() : void {
    // console.log(this.selectedFile)
    const wopiSrc = localStorage.getItem("WOPISrc");
    const wsdServer = localStorage.getItem("wsdServer");
    const accountId = localStorage.getItem("accountId");
    const auditorId = localStorage.getItem("auditorId");
    const WopiSrc = encodeURIComponent(wopiSrc+"/wopi/files/"+this.selectedFile.uuid);
    const dynamicURL = wsdServer+ "?WOPISrc="+WopiSrc;
        //"https://awf.datastep.solutions:4000/browser/dist/cool.html?WOPISrc=https://awf.datastep.solutions:4000/wopi/files/"+this.selectedFile.uuid;

    const data = {
      access_token: accountId+"@"+auditorId
    };

    this.openPostWindow(dynamicURL, data);

  }

  downloadFile(){
//    console.log(this.selectedFile);
    let fileName = this.selectedFile.path.split("/")[this.selectedFile.path.split("/").length-1];
    let filePath = this.selectedFile.path.replace(fileName,"");
      this.service.getFile(filePath, fileName,this.customer.value).then(response =>{
        var byteCharacters = atob(response.payload.content);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new File([byteArray], `${this.selectedFile.path}`, { type: response.payload.mime_type});
//        var fileURL = URL.createObjectURL(file);
//        window.open(fileURL);
        var link = document.createElement("a");
        var fileURL = URL.createObjectURL(file);        
        link.href = fileURL;
        link.download = fileName;
        link.click();          
      })
  }



  openUntilFirstOpened(node: any) {
    let paths: any = node.path.split('/');
    if (paths.length > 0) {
      let partPath = ''
      paths.forEach((path: any, index) => {
        partPath += path;

        if(partPath){
          let toggler = document.getElementById(partPath) as HTMLElement;
          toggler.click();
        }

        if (index < paths.length -1){
          partPath += '/';
        }
      })

      this.onFolderClick({path: partPath})
    }
  }

  private _deepCopyObj(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    if (obj instanceof Date) {
      let copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      let copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this._deepCopyObj(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      let copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this._deepCopyObj(obj[attr]);
      }
      return copy;
    }
    throw new Error(this.translatableTexts.find(element => element.value === "error_message_unable_to_deep_copy").name);
  }

  private createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }
}
