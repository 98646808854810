<mat-form-field *ngIf="!chips" appearance="{{appearanceStyle}}">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select [formControl]="selectCtrl" placeholder="{{placeholder}}" [multiple]="selectionType == 'multiSelect'" #selection [required]="required" [value]="selectCtrl.value">
    <mat-option>
      <ngx-mat-select-search [formControl]="selectFilterCtrl" placeholderLabel="{{placeholderLabel}}" 
      noEntriesFoundLabel="{{noEntriesFoundLabel}}"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let selected of filteredSelection | async" [value]="selected.value">
      {{selected.name | translate}}
    </mat-option>
  </mat-select>
  <button mat-button id="clearButton" *ngIf="showClearButton && (selectCtrl.value?.length > 0 || selectCtrl.value != null)" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection($event)" [disabled]="disabled"><mat-icon>close</mat-icon></button >
  <mat-error *ngIf="selectCtrl.hasError('required')">
    {{placeholderError || placeholder}} {{'error_message_selection_is_mandatory' | translate}}
  </mat-error>
</mat-form-field>

 <mat-form-field *ngIf="chips" appearance="{{appearanceStyle}}">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select [formControl]="selectCtrl" multiple #selection [required]="required" [value]="selectCtrl.value">
    <mat-select-trigger>
      <mat-chip-list *ngFor="let selected of selectedChips">
        <mat-chip [matTooltip]="'select-as-primary' | translate" (click)="selectAsPrimary(selected)" [style.cursor]="'pointer'" [selected]="selected.value.is_primary===true || selected.value.is_primary===1">{{selected.name}}
          <!--<mat-icon [matTooltip]="'select-as-primary' | translate" *ngIf="!selected.value.is_primary" (click)="selectAsPrimary(selected)">check_circle</mat-icon>
          <!--<mat-icon (click)="removeChip(selected, false)">cancel</mat-icon>-->
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [formControl]="selectFilterCtrl" placeholderLabel="{{placeholderLabel}}" 
      noEntriesFoundLabel="{{noEntriesFoundLabel}}"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let selected of filteredSelection | async" [value]="selected.value" (onSelectionChange)="selectChips($event)">{{selected.name}}</mat-option>
  </mat-select>  
  <button mat-button id="clearButton" *ngIf="showClearButton && (selectCtrl.value?.length > 0 || selectCtrl.value != null)" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection($event)" [disabled]="disabled"><mat-icon>close</mat-icon></button >
  <mat-error *ngIf="selectCtrl.hasError('required')">
    {{placeholderError || placeholder}} {{'error_message_selection_is_mandatory' | translate}}
  </mat-error>
</mat-form-field>