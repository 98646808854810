<h2 mat-dialog-title [style.textAlign]="'center'">{{data.title | translate}}</h2>

<mat-dialog-content>
    <form [formGroup]="historyForm" [style.display]="'flex'" [style.justify-content]="'space-between'">
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{data.name | translate}}</mat-label>
                <input matInput formControlName="history" required>
                <mat-error *ngIf="historyForm.controls.history.hasError('required')">
                    {{data.name | translate}} {{error_message_cannot_be_empty | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div [style.width]="'48%'">
            <mat-form-field appearance="outline">
                <mat-label>{{isMore ? ('companies_validity-start' | translate ): ('companies_validity' | translate)}}</mat-label>
                <input matInput [type]="'date'" formControlName="date" required [max]="today">
                <mat-error *ngIf="historyForm.controls.date.hasError('required')">
                    {{error_message_validity_cannot_be_empty | translate}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <mat-dialog-actions [style.justify-content]="'center'">
        <button mat-button (click)="addHistory()" class="positiveButton">{{'companies_instert' | translate}}</button>
    </mat-dialog-actions>

    <div class="table-content">
        <div class="column">
            <div class="table-header">
                <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                    {{data.name | translate}}
                </div>
                <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                    {{isMore ? ('companies_validity-start' | translate ): ('companies_validity' | translate)}}
                </div>
            </div>
            <div *ngFor="let row of data.history; let i = index" class="table-item">
                <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                    {{row.name}}
                </div>
                <div class="table-text" [style.justify-content]="'space-between'" [style.width]="'50%'">
                    <span>{{createDateString(row.validity)}}</span>
                    <div>
                        <button mat-icon-button matTooltip="{{'companies_validity_delete' | translate}}" (click)="deleteHistory(i)">
                            <span class="material-icons" [style.color]="'red'">clear</span>
                          </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="saveHistory()" class="positiveButton">{{'companies_save' | translate}}</button>
    <button mat-button (click)="reject()" class="negativeButton">{{'companies_reject' | translate}}</button>
</mat-dialog-actions>
