import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowTemplate } from 'src/app/data/workflow-template';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { WorkflowTemplateBasicEditorComponent } from './workflow-template-basic-editor/workflow-template-basic-editor.component';
import { WfTemplateService } from '../../services/wf-template.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {CredentialsService} from "../../services/credentials.service";

@Component({
  selector: 'app-workflow-templates',
  templateUrl: './workflow-templates.component.html',
  styleUrls: ['./workflow-templates.component.less']
})
export class WorkflowTemplatesComponent implements OnInit {

  isTemplate:boolean = false;

  selectedTemplate: WorkflowTemplate;

  templates: any[];

  companySelectionItems: any = [{}];
  contractSelectionItems: any = [{}];
  strictAssignmentControllSelectionItems: any = [{value: true, name: "Yes"}, {value: false, name: "No"}];
  strictClosingControllSelectionItems: any = [{value: true, name: "Yes"}, {value: false, name: "No"}];
  statusSelectionItems: any = [{}];
  
  setIsTemplate(event){
    this.isTemplate = event.value;
    this.getWfTemplates();
    this.selectedTemplate = event.template;
  }

  translatableTexts: any[] = [
    {value: "common_message_workflow_template_save_successful", name: ""},
    {value: "error_message_workflow_template_save_unsuccessful", name: ""},
    {value: "common_message_workflow_template_delete_successful", name: ""},
    {value: "error_message_workflow_template_delete_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private snackBar: MatSnackBar, private translateService: TranslateService, private service: WfTemplateService) { }

  ngOnInit(): void {
    this.getWfTemplates();

    this.translateService.stream(['common_message_workflow_template_save_successful', 'error_message_workflow_template_save_unsuccessful', 'common_message_workflow_template_delete_successful', 'error_message_workflow_template_delete_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_workflow_template_save_successful", name: res["common_message_workflow_template_save_successful"]},
        {value: "error_message_workflow_template_save_unsuccessful", name: res["error_message_workflow_template_save_unsuccessful"]},
        {value: "common_message_workflow_template_delete_successful", name: res["common_message_workflow_template_delete_successful"]},
        {value: "error_message_workflow_template_delete_unsuccessful", name: res["error_message_workflow_template_delete_unsuccessful"]}
      ];
    });
  }

  getWfTemplates(){
    this.service.listWfTemplates(CredentialsService.role).subscribe((response)=>{
      this.templates = response.payload.content;
    })
  }
  
  editBasicData(elem?: WorkflowTemplate){
    const dialog = this.dialogService.open(WorkflowTemplateBasicEditorComponent);
    dialog.afterClosed().subscribe(response =>{
      if (response){
        if (response.type == "OK"){
          this.service.listWfTemplates(CredentialsService.role).subscribe((resp)=>{
            this.templates = resp.payload.content;
            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_template_save_successful").name, null, {panelClass: 'success-snackbar'})
          })
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_template_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }
      
    })
    //dialog.componentInstance.template = this.selectedTemplate;
    /*dialog.componentInstance.companySelectionItems = this.companySelectionItems;
    dialog.componentInstance.contractSelectionItems = this.contractSelectionItems;
    dialog.componentInstance.strictAssignmentControllSelectionItems = this.strictAssignmentControllSelectionItems;
    dialog.componentInstance.strictClosingControllSelectionItems = this.strictClosingControllSelectionItems;
    dialog.componentInstance.statusSelectionItems = this.statusSelectionItems;*/
  }

  viewWorkflowTemplate(){
    this.isTemplate = true;
  }

  deleteWorkflowTemplate(template: WorkflowTemplate){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('workflow_templates_remove-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.service.deleteTemplate(template.id).then((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_template_delete_successful").name, null, {panelClass: 'success-snackbar'});
              this.getWfTemplates();
              this.selectedTemplate = null;
            }
            else{
              this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_template_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            } 
          }
        });
      }
    })
  }

}
