import { Component, Input, Optional, Output, Self, EventEmitter } from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.less'],
})
export class FileUploadComponent implements ControlValueAccessor {
  Object: any = Object;
  onChange: (_: any) => void;
  onTouched: () => void;
  file: File | null = null;
  hovered = false;

  standard_description = 'Kattintson ide a fájl hozzáadásához, vagy húzza erre a területre a feltöltendő fájlt';

  @Input() label: string = null;
  @Input() description: string = null;
  @Input() filetype;
  @Input() accept;
  @Input() required = true;

  @Output() fileTypeErrorEvent = new EventEmitter<any>();

  constructor(@Self() @Optional() public ngControl: NgControl, private translateService: TranslateService) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(){
    this.translateService.get('common_message_click_here_to_add_the_file').subscribe((value)=>this.description = value)

    if(!this.description)this.description = this.standard_description;
    if (this.required){
      this.description += " *";
    }
  }

  touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: File | null) {
    this.file = value;
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onFileDrop(event: any) {
    event.preventDefault();
    if (event.dataTransfer.items.length > 0) {
      this.setFile(event.dataTransfer.files[0]);
      this.description = event.dataTransfer.files[0].name;
      this.hovered = false;
    }
  }

  onFileDragOver(event: any) {
    event.preventDefault();
    this.hovered = true;
    this.touched();
  }

  onFileDragLeave(event: any) {
    event.preventDefault();
    this.hovered = false;
  }

  setFile(file: File) {
    this.touched();
    this.file = file;
    if (this.filetype){
      let found = this.filetype.find(element => this.file?.type.includes(element));
      if (!found && this.file?.type){
        this.fileTypeErrorEvent.emit(true)
      }
      else{
        this.fileTypeErrorEvent.emit(false)
      }
    }

    if (this.onChange) {
      this.onChange(this.file);
      if(this.file){this.description = this.file.name;}else{this.description = this.standard_description}
    }
  }
}
